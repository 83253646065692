import { ScheduleCheckup } from "../models/checkup";
import { CheckupDto } from "./checkupDto";

export const ScheduleCheckupDto = (data: any): ScheduleCheckup => {
    return {
        id: data.schedule?.id,
        checkup: CheckupDto({...data.checkup, odometer: data.schedule?.odometer}),
        odometer: data.schedule?.odometer,
        hourmeter: data.schedule?.hourmeter,
        os: '',
        date: data.schedule?.scheduledDate && new Date(data.schedule.scheduledDate),
        status: data.schedule?.state,
        done: data.schedule?.state === 'Finalizado',
        isSigned: true,
        canSchedule: data.canSchedule,
        maintenanceStatus: data.maintenance?.statusId,
    };
}