
/**
 * Formats a given phone number string by adding parentheses and dashes.
 * 
 * - If the input value is empty, it returns the input value.
 * - Removes all non-digit characters from the input value.
 * - If the resulting phone number has less than 4 digits, it returns the phone number as is.
 * - If the resulting phone number has less than 7 digits, it formats the phone number as (XX) XXXX.
 * - Otherwise, it formats the phone number as (XX) XXXXX-XXXX.
 * 
 * @param value - The phone number string to be formatted.
 * @returns The formatted phone number string.
 */
export const formatPhoneNumber = (value: string): string => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    
    if (phoneNumberLength >= 4 && phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2)}`;
    }

    if (phoneNumberLength > 7) {
        return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 7)}-${phoneNumber.slice(7, 11)}`;
    }

    return phoneNumber;
};