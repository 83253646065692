import { useState, useEffect } from "react";
import isEmpty from "lodash/fp/isEmpty";
import TreeCategory from "@rio-cloud/rio-uikit/TreeCategory";
import { AssetGroup, AssetSummary } from "../../models/asset";
import { fetchAssets } from "../../api/fetchAssets";
import { fetchAssetGroups } from "../../api/fetchAssetGroups";
import AtosSidebar, { Asset } from "./AtosSidebar";

import "./styles.scss";

const CATEGORY_FILTER = "filter";
const CATEGORY_ASSETS = "assets";

interface IAssetsTreeProps {
  assetIds: string[];
  groupIds: string[];
  setAssetIds: (assetIds: string[]) => void;
  setGroupIds: (groupIds: string[]) => void;
}

const AssetTreeSidebar = (props: IAssetsTreeProps) => {
  const [assets, setAssets] = useState<AssetSummary[]>([]);
  const [assetGroups, setAssetGroups] = useState<AssetGroup[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, ] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      
      const [assets, groups] = await Promise.all([
        fetchAssets(),
        fetchAssetGroups(),
      ]);
      
      setAssets(
        assets.map((asset) => ({
          ...asset,
          groupIds: 
            asset.groupIds.length === 0 || !(groups.some(group => asset.groupIds.includes(group.id))) ? 
              ['ungruped'] : asset.groupIds,
        }))
      );
      props.setAssetIds(assets.map(asset => asset.id));

      setAssetGroups([...groups, { id: "ungruped", name: "Desagrupadas" }]);
      
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleSelectAsset = ({ items, groups }: any) => {
    props.setAssetIds(items);
    props.setGroupIds(groups);

    if(groups.length > 0) {
      props.setAssetIds(
        assets.filter(asset => groups.some((group: any) => asset.groupIds.includes(group))).map(asset => asset.id)
      );
    }
  };

  if (loading) {
    return <div className="loading-indicator">Carregando...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="display-flex flex-wrap gap-25 overflow-auto">
      <div className="w-full" style={{ height: 'calc(100vh - 50px)' }}>
        <TreeCategory
          key={CATEGORY_FILTER}
          id={CATEGORY_FILTER}
          label="Filter"
          icon="rioglyph-filter"
        />

        <TreeCategory
          key={CATEGORY_ASSETS}
          id={CATEGORY_ASSETS}
          label="Assets"
          icon="rioglyph-truck"
          hasSelection={!isEmpty([]) || !isEmpty([])}
        >
          <AtosSidebar 
            tags={
              assetGroups.map(group => ({
                id: group.id,
                name: group.name,
                count: assets.filter(asset => asset.groupIds.includes(group.id)).length,
              }))
            } 
            assets={
              assets.map(asset => ({
                id: asset.id,
                name: asset.name,
                type: asset.type,
                groupIds: asset.groupIds,
                chassis: asset.chassis,
              })) as Asset[]
            } 
            selectedTags={props.groupIds} 
            selectedAssets={props.assetIds} 
            onSelectionChange={handleSelectAsset}
          />
        </TreeCategory>
      </div>
    </div>
  );
};

export default AssetTreeSidebar;
