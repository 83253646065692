import { Navigate, Route, Routes } from "react-router-dom";
import MaintenanceControl from "./maintenanceControl";
import { MyAppointmens } from "./myAppointments";
import { NewAppointments } from "./newAppointment";
import ControlTowerMaintenanceMonitoringRoutes from "./maintenanceMonitoring/routes";

const ControlTowerRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/control-tower/maintenance-control" />} />
    <Route path="/maintenance-control" element={<MaintenanceControl />} />
    <Route path="/maintenance-monitoring/*" index element={<ControlTowerMaintenanceMonitoringRoutes />} />
    <Route path="/my-appointments" element={<MyAppointmens />} />
    <Route path="/new-appointment" element={<NewAppointments />} />
  </Routes>
);

export default ControlTowerRoutes;
