import SimpleDialog from "@rio-cloud/rio-uikit/SimpleDialog";
import Checkbox from "@rio-cloud/rio-uikit/Checkbox";
import { Scheduling } from "../../../../../../models/schedule";
import { useNavigate } from "react-router-dom";

import "../styles.css";

interface ISchedulingDetailDialogProps {
  show: boolean;
  onClose: () => void;
  scheduling: Scheduling | undefined;
}

const SchedulingDetailDialog = ({
  show,
  onClose,
  scheduling,
}: ISchedulingDetailDialogProps) => {
  const navigate = useNavigate();

  const dialogContent = (
    <div className="padding-25">
      <div className="text-size-20 text-bold padding-bottom-25">
        {scheduling?.asset?.group}
      </div>

      <div className="row">
        <div className="col-4">
          <label className="block text-color-black text-size-20 text-bold margin-bottom-10">
            Motorista
          </label>
          <div className="mt-1 p-2 border schedule-data__info">
            <div className="truncate">{scheduling?.driver.name}</div>
          </div>
        </div>
        <div className="col-2">
          <label className="block text-color-black text-size-20 text-bold margin-bottom-10">
            CNH
          </label>
          <div className="mt-1 p-2 border schedule-data__info">
            <div className="truncate">{scheduling?.driver.license}</div>
          </div>
        </div>
        <div className="col-3">
          <label className="block text-color-black text-size-20 text-bold margin-bottom-10">
            Telefone
          </label>
          <div className="mt-1 p-2 border schedule-data__info">
            <div className="truncate">{scheduling?.driver.phone}</div>
          </div>
        </div>
        <div className="col-3">
          <label className="block text-color-black text-size-20 text-bold margin-bottom-10">
            E-mail
          </label>
          <div className="mt-1 p-2 border schedule-data__info">
            <div className="truncate">{scheduling?.driver.email}</div>
          </div>
        </div>
      </div>

      <div className="padding-20 bg-lighter margin-y-20 vehicle-detais">
        <div className="flex items-start gap-4">
          <img
            src="/assets/images/vehicle-thumb.png"
            alt="Vehicle"
            className="w-20 h-20 rounded"
          />

          <div className="flex-1 padding-left-10">
            <p className="text-color-black text-size-16 font-medium m-0">
              {scheduling?.asset.name} | Placa: {scheduling?.asset.licensePlate}
            </p>
            <p className="text-color-status-resting text-size-14 m-0">
              Chassi - {scheduling?.asset.chassis}
              <br />
              Odômetro: {scheduling?.asset.odometer} km | Horímetro:{" "}
              {scheduling?.asset.hourmeter} h
            </p>
          </div>

          <div className="flex gap-6 margin-bottom-20">
            {scheduling?.asset.tags?.map((tag, index) => (
              <span key={index} className="px-2 py-1 green-tag">
                {tag}
              </span>
            ))}
            {/* <span className="px-2 py-1 green-tag">RIO</span>
                        <span className="px-2 py-1 green-tag">Em Garantia</span>
                        <span className="px-2 py-1 bg-white white-tag">Volkstotal Prev</span> */}
          </div>
        </div>

        <div className="padding-10">
          <div className="car-workshop">
            <ul className="flex flex-column gap-15 m-0">
              <li className="flex justify-between">
                <div className="text-color-black">Campanhas de campo</div>
                <div className="text-bold">Grátis</div>
              </li>

              <li className="flex justify-between">
                <div className="text-color-black">
                  {scheduling?.description}
                </div>
                <div className="text-bold">Grátis</div>
              </li>

              {/* <li className="flex justify-between">
                                <div className="text-color-black">
                                    Total em peças
                                </div>
                                <div className="text-color-black">R$4.200,00</div>
                            </li> */}

              <li className="flex justify-between">
                <div className="text-color-black">Total em serviços</div>
                <div className="text-color-black">{scheduling?.totalPrice}</div>
              </li>
              <li className="flex justify-between border-top-only border-width-1">
                <div className="text-color-black text-bold">Total</div>
                <div className="text-color-black text-bold">
                  {scheduling?.totalPrice}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <div className="text-size-14 text-medium">Importante!</div>
        <div className="text-size-14">{scheduling?.observation}</div>
      </div>

      <div className="revision-itens margin-y-20">
        <h5 className="text-size-20 text-bold margin-bottom-10">
          Itens cobertos na revisão
        </h5>
        <div className="p-4 bg-white border">
          <div className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
            {scheduling?.services.length === 0 && <p>-</p>}
            {scheduling?.services.map((item, index) => (
              <div key={index} className="padding-bottom-10">
                <p className="text-bold">{item.name}</p>
                {item.items.map((subItem, subIndex) => (
                  <p key={subIndex} className="padding-left-20">
                    {subItem}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="revision-itens">
        <h5 className="text-size-20 text-bold margin-bottom-0">
          Descrição dos Serviços Contratados
        </h5>
        <div className="text-size-14 margin-bottom-10">
          Espaço para descrever informações importantes sobre “Serviços
          Contratados”, adicionados no agendamento.
        </div>
        <div className="p-4 bg-white border">
          {scheduling?.otherServices?.map((item, index) => (
            <div key={index}>
              <p>{item}</p>
            </div>
          ))}
          {(scheduling?.otherServices ?? []).length === 0 && <p>-</p>}
        </div>
      </div>

      <div className="revision-itens observation">
        <h5 className="text-size-20 text-bold margin-bottom-0">Observações</h5>
        <div className="text-size-14 margin-bottom-10">
          Espaço para relatar problemas na manutenção do seu veículo.
        </div>
        <div className="p-4 bg-white border">
          <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
            <li className="flex justify-between">
              <span>{scheduling?.observation || "-"}</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="revision-itens flex justify-between gap-6">
        <div className="w-1/3">
          <h5 className="text-size-20 text-bold margin-bottom-10">
            Concessionária
          </h5>
          <div className="p-4 bg-white border card">
            <p className="m-0 text-size-h5">{scheduling?.dealership.name}</p>
            <p className="m-0">{scheduling?.dealership.address}</p>
          </div>
        </div>

        <div className="w-1/3">
          <h5 className="text-size-20 text-bold margin-bottom-10">
            Data e horário:
          </h5>
          <div className="p-4 bg-white border card">
            <p className="m-0 text-size-h5">
              {scheduling?.scheduleTime.toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              })}
            </p>
            <p className="m-0">
              {scheduling?.scheduleTime.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
          </div>
        </div>

        <div className="w-1/3">
          <h5 className="text-size-20 text-bold margin-bottom-10">
            Consultor:
          </h5>
          <div className="p-4 bg-white border card">
            <p className="m-0 text-size-h5 text-capitalize">
              {scheduling?.consultant}
            </p>
          </div>
        </div>
      </div>

      {/* <div className="warning-row">
        <h5 className="text-color-status-resting">Atenção!</h5>
        <p className="text-color-warning text-size-18 font-semibold">
          Os itens de cobertura do seu plano de contrato de manutenção estão
          sujeitas a análise técnica de garantia, podendo a garantia ser
          indeferida por violação e ou mau uso.
        </p>
        <p className="padding-top-20">
          <Checkbox indeterminate checked>
            Estou ciente sobre as regras da garantia
          </Checkbox>
        </p>
      </div> */}
    </div>
  );

  const dialogFooter = (
    <div className="display-flex justify-content-center gap-20">
      <button
        type="button"
        className="btn btn-primary width-250"
        onClick={onClose}
      >
        Fechar
      </button>

      <button
        type="button"
        className="btn btn-secondary width-250"
        onClick={() =>
          navigate(
            `/dealership/maintenance-monitoring/monitoring?scheduling-id=${scheduling?.id}`
          )
        }
      >
        Acompanhar manutenção
      </button>
    </div>
  );

  return (
    <SimpleDialog
      show={show}
      title="Detalhes do agendamento"
      subtitle="Confira abaixo as informações do agendamento"
      content={dialogContent}
      footer={dialogFooter}
      bsSize="lg"
      onClose={onClose}
      useOverflow
    />
  );
};

export default SchedulingDetailDialog;
