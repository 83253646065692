import { dealershipConsultantDto } from "../dtos/dealershipDto";
import { DealershipConsultant } from "../models/dealership";
import ApiService from "../services/api";

export const fetchConsultants = async (date: Date, dealershipId: string): Promise<DealershipConsultant[]> => {
    const apiService = new ApiService();
    
    try {
        const formattedDate = date.toLocaleDateString('pt-BR').split('/').reverse().join('-');
        const response = await apiService.get<any>(`/users/consultants?date=${formattedDate}&dn=${dealershipId}`);
        if (response.status === 200) {
            return response.data.map(dealershipConsultantDto);
        } else return [];
    } catch (error) {
        console.error(error);
        return [];
    }
}