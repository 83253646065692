import { useEffect, useState } from "react";
import FilePicker from "@rio-cloud/rio-uikit/FilePicker";
import { TicketRelease } from "../../../../../models/ticket";
import {
  downloadTicketImage,
  fetchTicketImages,
  ITicketFile,
  removeTicketImage,
  uploadTicketImage,
} from "../../../../../api/ticket";
import ErrorDialog from "../../../../../components/ErrorDialog";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import Tooltip from "@rio-cloud/rio-uikit/Tooltip";
import OverlayTrigger from "@rio-cloud/rio-uikit/OverlayTrigger";
import moment from "moment";
import { formatFileSize } from "../../../../../utils/imageSizeUtils";

const accept = {
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
  "image/svg": [],
};

interface IReleaseProps {
  ticketId?: string | undefined;
  release?: TicketRelease | undefined;
  setRelease: (release: TicketRelease) => void;
  setCheckOut: () => void;
}

const Release = ({
  ticketId,
  release,
  setRelease,
  setCheckOut,
}: IReleaseProps) => {
  const [files, setFiles] = useState<ITicketFile[]>([]);
  const [uploadErrorMessage, setUploadErrorMessage] = useState<
    string | undefined
  >();
  const [estimatedTimeError, setEstimatedTimeError] = useState<
    string | undefined
  >();

  const validateEstimatedTime = (value: string) => {
    const regex = /^(?:[01]\d|2[0-3]):[0-5]\d$/; // Aceita de 00:00 até 23:59
    if (!regex.test(value)) {
      setEstimatedTimeError("Formato inválido. Use o padrão HH:mm (00:00).");
      return false;
    }
    setEstimatedTimeError(undefined);
    return true;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (release && ticketId) {
        const images = await fetchTicketImages({
          ticketId: ticketId,
          step: "RELEASE",
        });
        setFiles(images ?? []);
      }
    };
    fetchData();
  }, [ticketId]);

  return (
    <div>
      <div className="display-grid gap-20 grid-cols-2">
        <div className="form-group">
          <label htmlFor="serviceOrder">Fechamento da OS*</label>
          <input
            id="serviceOrder"
            className="form-control"
            type="text"
            placeholder="Número"
            disabled={!release?.checkIn || !!release?.checkOut}
            onChange={(e) =>
              setRelease({ ...release, serviceOrder: e.target.value })
            }
            value={release?.serviceOrder}
          />
        </div>
        <div className="form-group relative">
          <label htmlFor="fanceInHour">Tempo Estimado*</label>
          <div className="input-group">
            <span className="input-group-addon">
              <span className="rioglyph rioglyph-time"></span>
            </span>
            <input
              id="fanceInHour"
              className={`form-control ${
                estimatedTimeError ? "border border-danger" : ""
              }`}
              type="text"
              placeholder="00:00"
              value={release?.estimatedTime || ""}
              onChange={(e) => {
                let value = e.target.value.replace(/\D/g, "").slice(0, 4);
                if (value.length === 4) {
                  value = value.replace(/(\d{2})(\d{2})/, "$1:$2");
                }

                setRelease({ ...release, estimatedTime: value });

                if (value.length === 5) {
                  validateEstimatedTime(value);
                } else {
                  setEstimatedTimeError("Formato inválido. Use hh:mm (00:00).");
                }
              }}
              disabled={!release?.checkIn || !!release?.checkOut}
            />
            {estimatedTimeError && (
              <div className="text-danger text-sm error-time-message">
                {estimatedTimeError}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="form-group ">
        <label htmlFor="textArea">Relato</label>
        <textarea
          className="form-control"
          rows={3}
          id="textArea"
          placeholder="Descritivo"
          value={release?.report}
          onChange={(e) => setRelease({ ...release, report: e.target.value })}
          disabled={!release?.checkIn || !!release?.checkOut}
        />
      </div>

      <div>
        <div className="infoFiles">
          <label htmlFor="relato">Inspeção visual</label>
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip id="tooltip" allowOnTouch>
                <div className="flex flex-col padding-10">
                  <p>
                    Arquivos permitidos: .jpg, .jpeg e .png
                    <br />
                    Tamanho máximo permitido: 2MB
                  </p>
                </div>
              </Tooltip>
            }
          >
            <span className="rioglyph rioglyph-question-sign margin-left-10 cursor-pointer text-color-gray" />
          </OverlayTrigger>
        </div>
        <div>
          {!!!release?.checkOut &&
            (files?.length === 0 || !files) &&
            !(!release?.checkIn || !!release?.checkOut) && (
              <FilePicker
                displayMode="dropzone"
                className="margin-bottom-20"
                onPick={async (acceptedFiles: FileList | null) => {
                  const maxSize = 5 * 1024 * 1024;
                  const filePicked = acceptedFiles?.[0];

                  if (!filePicked) return;

                  if (filePicked.size > maxSize) {
                    return setUploadErrorMessage(
                      "O arquivo selecionado excede o limite de 5MB. Escolha um arquivo menor."
                    );
                  }

                  if (acceptedFiles?.[0] && ticketId) {
                    const uploaded = await uploadTicketImage({
                      ticketId: ticketId,
                      imageFile: Array.from(acceptedFiles),
                      step: "RELEASE",
                    });
                    if (!uploaded)
                      return setUploadErrorMessage(
                        "Arquivo invalido. Erro ao realizar o upload, verifique o arquivo e tente novamente."
                      );
                    setFiles(
                      filePicked
                        ? [
                            {
                              name: filePicked.name,
                              updatedAt: new Date(
                                filePicked.lastModified
                              ).toLocaleDateString("pt-BR"),
                              contentType: filePicked.type,
                              contentSize: filePicked.size,
                              link: URL.createObjectURL(filePicked),
                            },
                          ]
                        : []
                    );
                  }
                }}
                multiple={true}
                accept={accept}
              >
                {dropArea}
              </FilePicker>
            )}
          <div className="display-grid grid-cols-1 grid-cols-3-sm gap-25">
            {(files ?? []).map((file) => (
              <FilePreview
                key={file.name}
                file={file}
                ticketId={ticketId}
                removeFile={() =>
                  setFiles((prevFiles) =>
                    (prevFiles ?? []).filter((f) => f.name !== file.name)
                  )
                }
                disabled={!release?.checkIn || !!release?.checkOut}
              />
            ))}
          </div>
        </div>

        <ErrorDialog
          show={!!uploadErrorMessage}
          title={"Erro ao fazer upload"}
          body={uploadErrorMessage ?? ""}
          onClose={() => setUploadErrorMessage(undefined)}
        />
      </div>

      <div className="display-grid gap-20 grid-cols-3 padding-y-50">
        <div className="alert alert-dismissible alert-success">
          <div className="display-flex gap-10">
            <span className="text-color-success text-size-h4 rioglyph rioglyph-ok-sign"></span>
            <div>
              <strong className="text-size-16">Conta já criada</strong>
              <div className="margin-y-5">
                Conta criada anteriormente. Pronta para acesso.
              </div>
              <div className="text-medium text-uppercase text-size-12">
                <a href="#" className="alert-link">
                  VISUALIZAR
                </a>
                <span className="text-color-success margin-left-3 rioglyph rioglyph-new-window"></span>
              </div>
            </div>
          </div>
        </div>

        <div className="alert alert-dismissible alert-info">
          <div className="display-flex gap-10">
            <span className="text-color-info text-size-h4 rioglyph rioglyph-info-sign"></span>
            <div>
              <strong className="text-size-16">Adicionar frota</strong>
              <div className="margin-y-5">
                Clique para configurar a frota e disponibilizar ao cliente
              </div>
              <div className="text-medium text-uppercase text-size-12">
                <a href="#" className="alert-link">
                  IR PARA CRIAÇÃO DE FROTA
                </a>
                <span className="text-color-info margin-left-3 rioglyph rioglyph-new-window"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end w-full">
        <button
          disabled={
            !release?.checkIn ||
            !!release?.checkOut ||
            !release?.serviceOrder ||
            !release?.estimatedTime ||
            !!estimatedTimeError
          }
          type="button"
          className="btn btn-primary btn-lg padding-left-25 padding-right-25 margin-top-10"
          onClick={() => {
            if (!release?.serviceOrder) {
              alert(
                "O campo 'Fechamento OS' é obrigatório para concluir a liberação."
              );
              return;
            }
            if (!validateEstimatedTime(release?.estimatedTime || "")) {
              alert(
                "Preencha o campo 'Tempo Estimado' corretamente no formato hh:mm."
              );
              return;
            }
            setCheckOut();
          }}
        >
          <span className="text-capitalize">Concluir Liberação</span>
        </button>
      </div>
    </div>
  );
};

const dropArea = ({ isDragActive }: { isDragActive: boolean }) => (
  <div
    className={`
            rounded border border-style-dashed border-color-gray bg-lightest text-size-14
            text-center text-color-darker opacity-50 padding-25 cursor-pointer
        `}
  >
    <span className="rioglyph rioglyph-files text-size-200pct opacity-50 margin-top-20" />
    <div className="margin-top-10">
      {isDragActive
        ? "Jogue os arquivos aqui ..."
        : "Arraste arquivos de imagem para cá ou clique para selecionar no computador"}
    </div>
  </div>
);

const FilePreview = ({
  file,
  ticketId,
  removeFile,
  disabled,
}: {
  file: ITicketFile | undefined;
  ticketId: string | undefined;
  removeFile: () => void;
  disabled: boolean;
}) => {
  const [deleteFileDialog, setDeleteFileDialog] = useState<boolean>(false);

  return (
    <>
      <div
        className="panel panel-default margin-top-20 cursor-pointer"
        onClick={async () => {
          if (ticketId && file) {
            const response = await downloadTicketImage({
              ticketId: ticketId,
              step: "RELEASE",
              filename: file.name,
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file.name);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
          }
        }}
      >
        <div className="panel-body">
          {file && (
            <div className="flex">
              {file.link && (
                <div className="thumbnail margin-right-10">
                  <img
                    src={file.link}
                    alt={file.name}
                    style={{
                      width: "200px",
                      height: "120px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              )}
              <div className="flex justify-between w-full">
                <div>
                  <div>
                    <b>{`${file?.name}`}</b>
                  </div>
                  <div>tipo: {`${file?.contentType}`}</div>
                  <div>tamanho: {formatFileSize(file?.contentSize)}</div>
                  <div>
                    data da última modificação:{" "}
                    {file?.updatedAt
                      ? moment(file.updatedAt).format("DD/MM/YYYY")
                      : "Data não disponível"}
                  </div>{" "}
                  <div>caminho: {`${file?.name}`}</div>
                </div>
                {!disabled && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteFileDialog(true);
                    }}
                  >
                    <span className="rioglyph rioglyph-remove" />
                  </div>
                )}
              </div>
            </div>
          )}
          {!file && (
            <div className="text-color-dark text-italic">Nenhum arquivo</div>
          )}
        </div>
      </div>
      <ConfirmDialog
        show={deleteFileDialog}
        title="Remover arquivo"
        body="Deseja realmente remover o arquivo?"
        onClose={() => setDeleteFileDialog(false)}
        onConfirm={async () => {
          if (ticketId && file) {
            const response = await removeTicketImage({
              ticketId: ticketId,
              step: "RELEASE",
              filename: file.name,
            });
            if (response) {
              removeFile();
              setDeleteFileDialog(false);
            }
          }
        }}
      />
    </>
  );
};

export default Release;
