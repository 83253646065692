import { AssetSummary } from "../models/asset";
import ApiService from "../services/api";
import { getAssetIdsByChassisList } from "../utils/assetUtils";

let attentionScorescancelAbortController: AbortController | undefined;
export const fetchAttentionScores = async (assetIds: string[]): Promise<[number, string[]][]> => {
    if(attentionScorescancelAbortController) attentionScorescancelAbortController.abort();
    attentionScorescancelAbortController = new AbortController();

    const apiService = new ApiService();
    
    try {
        let totalScores: [number, string[]][] = [[0, []], [0, []]];

        const chunkSize = 5000;
        const attentionResponsePromisses = [];
        const criticalResponsePromisses = [];

        for (let i = 0; i < assetIds.length; i += chunkSize) {
            const chunk = assetIds.slice(i, i + chunkSize);
            const date = new Date().toISOString();
            attentionResponsePromisses.push(apiService.post<any>(`/big-numbers/vehicle/status`, { assetIds: chunk, filterType: 'ATTENTION_ERRORS', "page": 0, "pageSize": chunkSize, startAt: date, endAt: date }, { signal: attentionScorescancelAbortController.signal }));
            criticalResponsePromisses.push(apiService.post<any>(`/big-numbers/vehicle/status`, { assetIds: chunk, filterType: 'CRITICAL_ERRORS', "page": 0, "pageSize": chunkSize, startAt: date, endAt: date }, { signal: attentionScorescancelAbortController.signal }));
        }
        
        const [attentionResponses, criticalResponses] = await Promise.all([
            Promise.all(attentionResponsePromisses),
            Promise.all(criticalResponsePromisses)
        ]);

        attentionResponses.forEach((attentionResponse: any) => {
            if (attentionResponse.status === 200) {
                totalScores[0][0] += attentionResponse.data.total ?? 0;
                totalScores[0][1] = [...totalScores[0][1], ...attentionResponse.data.data.map((el: any) => el.vehicleId)];
            }
        });

        criticalResponses.forEach((criticalResponse: any) => {
            if (criticalResponse.status === 200) {
                totalScores[1][0] += criticalResponse.data.total ?? 0;
                totalScores[1][1] = [...totalScores[1][1], ...criticalResponse.data.data.map((el: any) => el.vehicleId)];
            }
        });

        attentionScorescancelAbortController = undefined;
        return totalScores;
    } catch (error: any) {
        if(error.code === "ERR_CANCELED") throw new Error("ERR_CANCELED");
        attentionScorescancelAbortController = undefined;
        console.error(error);
        return [[0, []], [0, []]];
    }
}

let pastDueMaintenanceAbortController: AbortController | undefined;
export const fetchPastDueMaintenance = async (assetIds: string[], assets: AssetSummary[]): Promise<[number, string[]]> => {
    if(pastDueMaintenanceAbortController) pastDueMaintenanceAbortController.abort();
    pastDueMaintenanceAbortController = new AbortController();

    const apiService = new ApiService();
    
    try {
        let total = 0;
        let pasDueMaintenanceAssetIds: string[] = [];

        const chunkSize = 5000;
        const promises = [];
        for (let i = 0; i < assetIds.length; i += chunkSize) {
            const chunk = assetIds.slice(i, i + chunkSize);
            promises.push(apiService.post<any>(
                `/big-numbers/vehicle/past-due-maintenance`, 
                { assets: assets.filter(el => el && chunk.includes(el.id)).map(el => ({ id: el.id, identification: el.chassis })).filter(el => el.id && el.identification) },
                { signal: pastDueMaintenanceAbortController.signal }
            ));
        }

        const responses = await Promise.all(promises);
        responses.forEach((response: any) => {
            if (response.status === 200) {
                total += response.data.total ?? 0;
                pasDueMaintenanceAssetIds = [...pasDueMaintenanceAssetIds, ...getAssetIdsByChassisList(assets, response.data.chassisList)];
            }
        });
        
        pastDueMaintenanceAbortController = undefined;
        return [total, pasDueMaintenanceAssetIds];
    } catch (error: any) {
        if(error.code === "ERR_CANCELED") throw new Error("ERR_CANCELED");
        pastDueMaintenanceAbortController = undefined;
        console.error(error);
        return [0, []];
    }
}

let maintenanceInProgressAbortController: AbortController | undefined;
export const fetchInProgress = async (assetIds: string[], assets: AssetSummary[]): Promise<[number, string[]]> => {
    if(maintenanceInProgressAbortController) maintenanceInProgressAbortController.abort();
    maintenanceInProgressAbortController = new AbortController();

    if(!assetIds.length || !assets.length) return [0, []];
    const apiService = new ApiService();
    
    try {
        let total = 0;
        let inProgressAssetIds: string[] = [];

        const chunkSize = 5000;
        const promises = [];
        for (let i = 0; i < assetIds.length; i += chunkSize) {
            const chunkAssetIds = assetIds.slice(i, i + chunkSize);
            const chunkChassisList = assets.filter(el => el && chunkAssetIds.includes(el.id)).map(el => el.chassis);
            promises.push(apiService.post<any>(`/big-numbers/vehicle/in-progress`, { assetIds: chunkAssetIds.filter(el => el), chassisList: chunkChassisList.filter(el => el) }, { signal: maintenanceInProgressAbortController.signal }));
        }

        const responses = await Promise.all(promises);
        responses.forEach((response: any) => {
            if (response.status === 200) {
                total += response.data.total ?? 0;
                inProgressAssetIds = [...inProgressAssetIds, ...getAssetIdsByChassisList(assets, response.data.chassisList)];
            }
        });

        maintenanceInProgressAbortController = undefined;
        return [total, inProgressAssetIds];
    } catch (error: any) {
        if(error.code === "ERR_CANCELED") throw new Error("ERR_CANCELED");
        maintenanceInProgressAbortController = undefined;
        console.error(error);
        return [0, []];
    }
}

let availableToScheduleAbortController: AbortController | undefined;
export const fetchAvailableToSchedule = async (assetIds: string[], assets: AssetSummary[]): Promise<[number, string[]]> => {
    if(availableToScheduleAbortController) availableToScheduleAbortController.abort();
    availableToScheduleAbortController = new AbortController();

    if(!assetIds.length || !assets.length) return [0, []];
    
    const apiService = new ApiService();
    
    try {
        let total = 0;
        let availableToScheduleAssetIds: string[] = [];

        const chunkSize = 5000;
        const promises = [];
        for (let i = 0; i < assetIds.length; i += chunkSize) {
            const chunk = assetIds.slice(i, i + chunkSize);
            promises.push(apiService.post<any>(
                `/big-numbers/available-to-schedule`, 
                { assets: assets.filter(el => el && chunk.includes(el.id)).map(el => ({ id: el.id, identification: el.chassis })).filter(el => el.id && el.identification) },
                { signal: availableToScheduleAbortController.signal }
            ));
        }
        
        const responses = await Promise.all(promises);
        responses.forEach((response: any) => {
            if (response.status === 200) {
                total += response.data.total ?? 0;
                availableToScheduleAssetIds = [...availableToScheduleAssetIds, ...getAssetIdsByChassisList(assets, response.data.chassisList)];
            }
        });

        availableToScheduleAbortController = undefined;
        return [total, availableToScheduleAssetIds];
    } catch (error: any) {
        if(error.code === "ERR_CANCELED") throw new Error("ERR_CANCELED");
        availableToScheduleAbortController = undefined;
        console.error(error);
        return [0, []];
    }
}

export const fetchDelayedMaintenance = async (assetIds: string[], assets: AssetSummary[]): Promise<[number, string[]]> => {
    const apiService = new ApiService();
    
    try {
        let total = 0;
        let delayedMaintenanceAssetIds: string[] = [];

        const chunkSize = 5000;
        const promises = [];
        for (let i = 0; i < assetIds.length; i += chunkSize) {
            const chunkAssetIds = assetIds.slice(i, i + chunkSize);
            const chunkChassisList = assets.filter(el => el && chunkAssetIds.includes(el.id)).map(el => el.chassis);
            promises.push(apiService.post<any>(`/big-numbers/vehicle/delayed-maintenance`, { chassisList: chunkChassisList.filter(el => el) }));
        }
        const responses = await Promise.all(promises);
        responses.forEach((response: any) => {
            if (response.status === 200) {
                total += response.data.total ?? 0;
                delayedMaintenanceAssetIds = [...delayedMaintenanceAssetIds, ...getAssetIdsByChassisList(assets, response.data.chassisList)];
            }
        });

        return [total, delayedMaintenanceAssetIds];
    } catch (error) {
        console.error(error);
        return [0, []];
    }
}

export const fetchScheduled = async (assetIds: string[], assets: AssetSummary[]): Promise<[number, string[]]> => {
    const apiService = new ApiService();
    
    try {
        let total = 0;
        let scheduledAssetIds: string[] = [];

        const chunkSize = 5000;
        const promises = [];
        for (let i = 0; i < assetIds.length; i += chunkSize) {
            const chunkAssetIds = assetIds.slice(i, i + chunkSize);
            const chunkChassisList = assets.filter(el => el && chunkAssetIds.includes(el.id)).map(el => el.chassis);
            promises.push(apiService.post<any>(`/big-numbers/vehicle/scheduled`, { assetIds: chunkAssetIds.filter(el => el), chassisList: chunkChassisList.filter(el => el) }));
        }
        
        const responses = await Promise.all(promises);
        responses.forEach((response: any) => {
            if (response.status === 200) {
                total += response.data.total ?? 0;
                scheduledAssetIds = [...scheduledAssetIds, ...getAssetIdsByChassisList(assets, response.data.chassisList)];
            }
        });

        return [total, scheduledAssetIds];
    } catch (error) {
        console.error(error);
        return [0, []];
    }
}

export const fetchRioAssets = async (assetIds: string[]): Promise<[number, string[]]> => {
    const apiService = new ApiService();
    
    try {
        let total = 0;
        let rioAssetIds: string[] = [];

        const chunkSize = 5000;
        const promises = [];
        for (let i = 0; i < assetIds.length; i += chunkSize) {
            const chunk = assetIds.slice(i, i + chunkSize);
            promises.push(apiService.post<any>(`/big-numbers/vehicle/rio`, { assetIds: chunk }));
        }
        
        const responses = await Promise.all(promises);
        responses.forEach((response: any) => {
            if (response.status === 200) {
                total += response.data.total ?? 0;
                rioAssetIds = [...rioAssetIds, ...(response.data?.chassisList ?? [])];
            }
        });

        return [total, rioAssetIds];
    } catch (error) {
        console.error(error);
        return [0, []];
    }
}

export const fetchAssetsByRioPlans = async (assetIds: string[]): Promise<{ [key: string]: string[] }> => {
    const apiService = new ApiService();
    
    try {
        let assetIdsByPlans: { [key: string]: string[] } = {};

        const chunkSize = 5000;
        const promises = [];
        for (let i = 0; i < assetIds.length; i += chunkSize) {
            const chunk = assetIds.slice(i, i + chunkSize);
            promises.push(apiService.post<any>(`/big-numbers/rio`, { assetIds: chunk }));
        }
        
        const responses = await Promise.all(promises);
        responses.forEach((response: any) => {
            if (response.status === 200) {
                response.data.plans.forEach((plan: any) => {
                    assetIdsByPlans[plan.name] = Array.from(new Set([...(assetIdsByPlans[plan.name] ?? []), ...plan.assetIds]));
                });
            }
        });

        return assetIdsByPlans;
    } catch (error) {
        console.error(error);
        return {};
    }
}

export const fetchVolksTotalAssets = async (assetIds: string[]): Promise<[number, string[]]> => {
    const apiService = new ApiService();
    
    try {
        let total = 0;
        let volksTotalAssetIds: string[] = [];

        const chunkSize = 5000;
        const promises = [];
        for (let i = 0; i < assetIds.length; i += chunkSize) {
            const chunk = assetIds.slice(i, i + chunkSize);
            promises.push(apiService.post<any>(`/big-numbers/vehicle/volks-total`, { assetIds: chunk }));
        }
        
        const responses = await Promise.all(promises);
        responses.forEach((response: any) => {
            if (response.status === 200) {
                total += response.data.total ?? 0;
                volksTotalAssetIds = [...volksTotalAssetIds, ...(response.data?.chassisList ?? [])];
            }
        });

        return [total, volksTotalAssetIds];
    } catch (error) {
        console.error(error);
        return [0, []];
    }
}