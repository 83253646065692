import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import TableToolbar from "@rio-cloud/rio-uikit/TableToolbar";
import TableSettingsDialog from "@rio-cloud/rio-uikit/TableSettingsDialog";
import SortArrows from "@rio-cloud/rio-uikit/SortArrows";
import useEsc from "@rio-cloud/rio-uikit/useEsc";

import VehicleAlerts from "../../../../components/vehicleAlerts";
import PeriodicReviews from "../../../../components/periodicReviews";
import Inspections from "../../../../components/inspections";

import { Asset, UptimeAsset } from "../../../../models/asset";

import ToSchedule from "./toSchedule";
import NextScheduling from "./nextScheduling";

import { ManualDialog } from "../dialogs/manualDialog";
import { EditOrdometerHourmeterDialog } from "../dialogs/editOrdometerHourmeterDialog";

import { fetchAlerts } from "../../../../api/fetchAlerts";
import { fetchManuals } from "../../../../api/fetchManuals";

import {
  assetHasMetrics,
  getUptimeAsset,
} from "../../../../services/assetService";

import "../styles.scss";

type ColumnLabel = { [key: string]: string };
type ColumnDetails = { [key: string]: number };
type ColumnDetailsMap = { [key: string]: ColumnDetails };

const defaultColumnOrder = [
  "agendamento",
  "veiculo",
  "chassi",
  "odometro",
  "horimetro",
];
const defaultColumnLabels: ColumnLabel = {
  agendamento: "Agendamento",
  veiculo: "Veículo",
  chassi: "Chassi",
  odometro: "Odômetro",
  horimetro: "Horímetro",
};
const defaultColumnsDetails: ColumnDetailsMap = {
  agendamento: { width: 60, defaultWidth: 60, maxWidth: 300 },
  veiculo: { width: 0, defaultWidth: 200, maxWidth: 350 },
  chassi: { width: 0, defaultWidth: 0, maxWidth: 350 },
  odometro: { width: 0, defaultWidth: 0, maxWidth: 350 },
  horimetro: { width: 0, defaultWidth: 0, maxWidth: 350 },
};

const secondTableColumnOrder = [
  "acoes",
  "revisoes",
  "score",
  "grupoManutencao",
  "statusGarantia",
  "rio",
  "status",
  "volksTotal",
  "ultimaRevisao",
  "proximaRevisao",
];
const secondTableColumnLabels: ColumnLabel = {
  acoes: "Campanhas de Campo",
  revisoes: "Revisões Periódicas",
  score: "Vehicle score",
  grupoManutencao: "Grupo Manutencao",
  statusGarantia: "Status Garantia",
  rio: "RIO",
  status: "Status",
  volksTotal: "Volks Total",
  ultimaRevisao: "Última Revisão",
  proximaRevisao: "Próxima Revisão",
};
const secondTableColumnsDetails: ColumnDetailsMap = {
  acoes: { width: 150, defaultWidth: 150, maxWidth: 150 },
  revisoes: { width: 120, defaultWidth: 120, maxWidth: 150 },
  score: { width: 80, defaultWidth: 80, maxWidth: 130 },
  grupoManutencao: { width: 130, defaultWidth: 130, maxWidth: 150 },
  statusGarantia: { width: 100, defaultWidth: 100, maxWidth: 100 },
  rio: { width: 180, defaultWidth: 180, maxWidth: 180 },
  status: { width: 80, defaultWidth: 80, maxWidth: 100 },
  volksTotal: { width: 100, defaultWidth: 100, maxWidth: 120 },
  ultimaRevisao: { width: 140, defaultWidth: 140, maxWidth: 160 },
  proximaRevisao: { width: 180, defaultWidth: 180, maxWidth: 200 },
};

interface IMaintenanceTableProps {
  assets: Asset[];
  handleSearchValueChange: (searchValue: string) => void;
  setSearchValue: (searchValue: string) => void;
  searchValue: string;
  uptimeAssets: UptimeAsset[];
}

const MaintenanceTable = ({ assets, uptimeAssets }: IMaintenanceTableProps) => {
  const navigate = useNavigate();
  const [sortBy] = useState<string>("veiculo");
  const [showTableSettingsDialog, setShowTableSettingsDialog] = useState(false);
  const [columnOrder, setColumnOrder] = useState<string[]>(defaultColumnOrder);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [columnsDetails, setColumnsDetails] = useState(defaultColumnsDetails);
  const [secondTableColumns, setSecondTableColumns] = useState(
    secondTableColumnsDetails
  );
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [sidebarContent, setSidebarContent] = useState<string | null>(null);
  const [showManualModal, setShowManualModal] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [vehicleAlert, setVehicleAlert] = useState<any>(null);
  const [asset, setAsset] = useState<Asset | null>(null);
  const [selectedCampaigns, setSelectedCampaigns] = useState<any[]>([]);
  const [manuals, setManuals] = useState<{
    chassis?: string;
    year?: number;
    manuals?: { name: string; downloadLink: string }[];
  }>({ manuals: [] });
  const [chassisSearch, setChassisSearch] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [, setToggle] = useState(false);

  const [, setStateUpdatedAt] = useState(new Date().getTime());

  useEsc(() => setToggle(false));

  const preventBreakRow = {
    whiteSpace: "nowrap",
  };

  const chooseAsset = (assetId: string) => {
    setAsset(assets.find((asset) => asset.id === assetId) || null);
  };

  const showSidebar = (content: string, assetId?: string) => {
    if (assetId) chooseAsset(assetId);

    setSidebarContent(content);
    setSidebarVisible(true);
  };

  const hideSidebar = () => {
    setSidebarVisible(false);
    setSidebarContent(null);
  };

  const handleItemClick = (type: string, assetId: string) => {
    const chassis = getChassisById(assetId);

    if (!chassis) {
      console.error("Chassis not found for vehicle ID:", assetId);
      return;
    }

    if (type === "manual") {
      fetchManuals(chassis)
        .then((manuals) => {
          setManuals(manuals);
          setShowManualModal(true);
        })
        .catch((error) => {
          console.error("Error fetching manuals for chassis:", error);
          alert("Erro ao buscar manuais. Por favor, tente novamente.");
        });
    }

    const selectedAsset = assets.find((asset) => asset.id === assetId);

    if (!selectedAsset) {
      console.error("Asset not found for ID:", assetId);
      return;
    }

    if (type === "warning-vehicle") {
      const vehicleAlerts = data.filter((alert) => alert.vehicleId === assetId);
      setVehicleAlert({ vehicleId: assetId, alerts: vehicleAlerts });
    }

    if (type === "acoes-oficina") {
      if (!selectedAsset.campaigns) {
        console.error("No campaigns found for asset ID:", assetId);
        return;
      }

      if (
        !selectedAsset.campaigns.list ||
        selectedAsset.campaigns.list.length === 0
      ) {
        console.warn(
          "Campaigns exist but the list is empty for asset ID:",
          assetId
        );
      } else {
        setSelectedCampaigns(selectedAsset.campaigns.list);
      }
    }

    const sidebarMapping: Record<string, string> = {
      "warning-vehicle": "warning-vehicle",
      "revisoes-periodicas": "revisoes-periodicas",
      "acoes-oficina": "acoes-oficina",
    };

    const newSidebarContent = sidebarMapping[type] || null;

    if (newSidebarContent) {
      if (isSidebarVisible && sidebarContent !== newSidebarContent) {
        showSidebar(newSidebarContent, assetId);
      } else if (!isSidebarVisible) {
        showSidebar(newSidebarContent, assetId);
      } else {
        hideSidebar();
      }
    } else {
      console.error("Invalid type for sidebar content:", type);
    }
  };

  const renderSidebarContent = (assetId: string) => {
    if (sidebarContent === "warning-vehicle") {
      return (
        <VehicleAlerts vehicleData={vehicleAlert} closeSidebar={hideSidebar} />
      );
    } else if (sidebarContent === "revisoes-periodicas") {
      return <PeriodicReviews asset={asset} closeSidebar={hideSidebar} />;
    } else if (sidebarContent === "acoes-oficina") {
      return (
        <Inspections
          inspectionsData={{ list: selectedCampaigns }}
          closeSidebar={hideSidebar}
          assetId={assetId}
          userRole="control-tower"
        />
      );
    }
    return null;
  };

  const handleColumnChange = (
    newColumnOrder: string[],
    newHiddenColumns: string[],
    newColumnsDetails = columnsDetails
  ) => {
    setColumnOrder(newColumnOrder);
    setHiddenColumns(newHiddenColumns);
    setColumnsDetails(newColumnsDetails);
    setSecondTableColumns(newColumnsDetails);
  };

  const handleColumnDetailsChange = (
    column: string,
    newColumnDetails: ColumnDetails
  ) => {
    const updatedColumnsDetails = { ...columnsDetails };
    updatedColumnsDetails[column] = newColumnDetails;
    setColumnsDetails(updatedColumnsDetails);
  };

  const handleSortChange = (event: React.MouseEvent<HTMLElement>) => {};

  const renderTableHead = (
    column: string,
    label: string,
    sortByColumn: string
  ) => {
    const tableHeadClassNames = classNames("user-select-none", "sort-column");

    return (
      <th
        key={column}
        className={tableHeadClassNames}
        onClick={handleSortChange}
        data-field={column}
        data-sortby={column}
        title={label}
        style={{ textAlign: column === "rio" ? "center" : undefined }}
      >
        <span>
          {sortByColumn === column ? <SortArrows /> : <SortArrows />}
          <span>{label}</span>
        </span>
      </th>
    );
  };

  const renderTableCaption = (column: string, columnDetails: ColumnDetails) => {
    const style = columnDetails?.width
      ? {
          minWidth: columnDetails.width,
          width: columnDetails.width,
        }
      : {};

    return <col key={column} style={style} />;
  };

  const columns = columnOrder.filter((name) => !hiddenColumns.includes(name));

  const tableClassNames = classNames(
    "table",
    "table-layout-fixed",
    "table-column-overflow-hidden",
    "table-bordered",
    "table-head-filled"
  );

  useEffect(() => {
    const scrollableTable = document.querySelector(".scrollabe-table");
    let isDragging = false;
    let startX = 0;
    let scrollLeft = 0;

    if (scrollableTable) {
      const onMouseDown = (e: any) => {
        isDragging = true;
        scrollableTable.classList.add("active");
        startX = e.pageX - (scrollableTable as HTMLElement).offsetLeft;
        scrollLeft = scrollableTable.scrollLeft;
      };

      const onMouseMove = (e: any) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - (scrollableTable as HTMLElement).offsetLeft;
        const walk = (x - startX) * 2;
        scrollableTable.scrollLeft = scrollLeft - walk;
      };

      const onMouseUpOrLeave = () => {
        isDragging = false;
        scrollableTable.classList.remove("active");
      };

      scrollableTable.addEventListener("mousedown", onMouseDown);
      scrollableTable.addEventListener("mousemove", onMouseMove);
      scrollableTable.addEventListener("mouseup", onMouseUpOrLeave);
      scrollableTable.addEventListener("mouseleave", onMouseUpOrLeave);

      return () => {
        scrollableTable.removeEventListener("mousedown", onMouseDown);
        scrollableTable.removeEventListener("mousemove", onMouseMove);
        scrollableTable.removeEventListener("mouseup", onMouseUpOrLeave);
        scrollableTable.removeEventListener("mouseleave", onMouseUpOrLeave);
      };
    }
  }, []);

  const isAlert = (assetId: string) => {
    if (!assetId && !data.length) return false;
    const filteredData = data?.filter((alert) => alert?.vehicleId === assetId);
    return filteredData?.length > 0 ? true : false;
  };

  useEffect(() => {
    const today = new Date();
    const startAt = new Date(today.setHours(0, 0, 0, 0)).toISOString();
    const endAt = new Date(today.setHours(23, 59, 59, 999)).toISOString();

    const bodyData = {
      pageSize: 50,
      page: 0,
      assetIds: assets?.map((asset) => asset.id),
      tagIds: [],
      lampFilter: [],
      startAt: startAt,
      endAt: endAt,
      search: "",
      zone: "UTC-3",
    };

    fetchAlerts(bodyData).then((response: any) => setData(response.data));
  }, [assets]);

  const getChassisById = (assetId: string): string | undefined => {
    const selectedAsset = assets.find((asset) => asset.id === assetId);
    return selectedAsset?.chassis;
  };

  return (
    <div id="TableCommonDemo">
      <TableToolbar>
        <div className="table-toolbar-container">
          <div className="table-toolbar-group-left">
            {/* <TableSearch
              value={searchValue}
              onChange={e => [setSearchValue(e), handleSearchValueChange(e)]}
              placeholder="Busque por chassi"
              onClear={() => [setSearchValue("")]}
            /> */}
          </div>
          <div className="table-toolbar-group-right">
            <div className="table-toolbar-column table-toolbar-column-spacer">
              <button
                type="button"
                className="btn bg-highlight-darker padding-7"
                onClick={() => navigate(`/control-tower/new-appointment`)}
              >
                <span className="text-capitalize padding-right-5">
                  Criar agendamento
                </span>
                <span
                  className="rioglyph rioglyph-plus-sign"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
          {showTableSettingsDialog && (
            <TableSettingsDialog
              show={showTableSettingsDialog}
              title="Configurações da tabela"
              onHide={() => setShowTableSettingsDialog(false)}
              onColumnChange={handleColumnChange}
              defaultColumnOrder={defaultColumnOrder}
              defaultHiddenColumns={[]}
              columnOrder={columnOrder}
              hiddenColumns={hiddenColumns}
              columnLabels={defaultColumnLabels}
              disabledColumns={["vin"]}
              closeButtonText="Fechar"
              resetButtonText="Restaurar padrão"
              searchPlaceholder="Buscar coluna"
              notFoundMessage="Coluna não encontrada"
              columnsDetails={columnsDetails}
              autoLabel="Automático"
              onColumnDetailsChange={handleColumnDetailsChange}
              immediateChange
            />
          )}
        </div>
      </TableToolbar>
      <div className="tables-row">
        <div className="tables-row__fixed">
          <table
            className={classNames(
              "table",
              "table-layout-fixed",
              "table-bordered"
            )}
          >
            {" "}
            <colgroup>
              {columns.map((column) =>
                renderTableCaption(column, columnsDetails[column])
              )}
            </colgroup>
            <thead>
              <tr>
                {columns.map((column) =>
                  renderTableHead(
                    column,
                    defaultColumnLabels[column],
                    sortBy as string
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {assets.map((asset: Asset, assetIndex: number) => (
                <tr key={assetIndex}>
                  {columns.map((col, colIndex) => (
                    <td
                      key={`${col}-${assetIndex}-${colIndex}`}
                      data-field={defaultColumnLabels[col]}
                      style={col !== "veiculo" ? preventBreakRow : {}}
                    >
                      {col === "agendamento" ? (
                        <ToSchedule
                          asset={asset}
                          hasMetrics={assetHasMetrics(asset, uptimeAssets)}
                        />
                      ) : col === "veiculo" ? (
                        asset.name || asset.model ? (
                          <div className="warning-vehicle">
                            <span className="vehicle-name" title={asset.name}>
                              {asset.name ?? asset.model}
                            </span>
                            {isAlert(asset.id) ? (
                              <button
                                type="button"
                                className="btn btn-secondary btn-action text-size-h6"
                                onClick={() =>
                                  handleItemClick("warning-vehicle", asset.id)
                                }
                              >
                                <span
                                  className="rioglyph rioglyph-warning-sign"
                                  aria-hidden="true"
                                ></span>
                              </button>
                            ) : null}
                          </div>
                        ) : null
                      ) : col === "chassi" ? (
                        <button
                          type="button"
                          onClick={() => handleItemClick("manual", asset.id)}
                        >
                          <span className="chassi-info">{asset.chassis}</span>
                        </button>
                      ) : col === "odometro" ? (
                        <div className="text-center">
                          {asset.odometer ? (
                            <>
                              {asset.odometer.toLocaleString("pt-BR") + " km"}
                              <div className="text-size-11 margin-top-5 text-color-highlight">
                                Atualizado em{" "}
                                {asset?.vehicleMetric?.metricDates?.odometer
                                  ? new Date(
                                      asset.vehicleMetric.metricDates.odometer
                                    ).toLocaleDateString("pt-BR")
                                  : ""}
                              </div>
                            </>
                          ) : getUptimeAsset(asset, uptimeAssets)?.odometer ? (
                            <>
                              {getUptimeAsset(
                                asset,
                                uptimeAssets
                              )?.odometer?.toLocaleString("pt-BR") + " km"}
                              <span className="margin-left-10">
                                <EditOrdometerHourmeterDialog
                                  type="odometer"
                                  asset={asset}
                                  onClose={() =>
                                    setStateUpdatedAt(new Date().getTime())
                                  }
                                />
                              </span>
                              {getUptimeAsset(asset, uptimeAssets)
                                ?.lastOdometerUpdate ? (
                                <div className="text-size-11 margin-top-5 text-color-highlight">
                                  Atualizado em{" "}
                                  {getUptimeAsset(
                                    asset,
                                    uptimeAssets
                                  )?.lastOdometerUpdate?.toLocaleDateString(
                                    "pt-BR"
                                  )}
                                </div>
                              ) : null}
                            </>
                          ) : (
                            <EditOrdometerHourmeterDialog
                              type="odometer"
                              asset={asset}
                              onClose={() =>
                                setStateUpdatedAt(new Date().getTime())
                              }
                            />
                          )}
                        </div>
                      ) : col === "horimetro" ? (
                        <div className="text-center">
                          {asset.hourmeter ? (
                            <>
                              {asset.hourmeter.toLocaleString("pt-BR") + " h"}
                              <div className="text-size-11 margin-top-5 text-color-highlight">
                                Atualizado em{" "}
                                {asset?.vehicleMetric?.metricDates?.hourMeter
                                  ? new Date(
                                      asset.vehicleMetric.metricDates.hourMeter
                                    ).toLocaleDateString("pt-BR")
                                  : ""}
                              </div>
                            </>
                          ) : getUptimeAsset(asset, uptimeAssets)?.hourmeter ? (
                            <>
                              {getUptimeAsset(
                                asset,
                                uptimeAssets
                              )?.hourmeter?.toLocaleString("pt-BR") + " h"}
                              <span className="margin-left-10">
                                <EditOrdometerHourmeterDialog
                                  type="hourmeter"
                                  asset={asset}
                                  onClose={() =>
                                    setStateUpdatedAt(new Date().getTime())
                                  }
                                />
                              </span>
                              {getUptimeAsset(asset, uptimeAssets)
                                ?.lastHourmeterUpdate ? (
                                <div className="text-size-11 margin-top-5 text-color-highlight">
                                  Atualizado em{" "}
                                  {getUptimeAsset(
                                    asset,
                                    uptimeAssets
                                  )?.lastHourmeterUpdate?.toLocaleDateString(
                                    "pt-BR"
                                  )}
                                </div>
                              ) : null}
                            </>
                          ) : (
                            <EditOrdometerHourmeterDialog
                              type="hourmeter"
                              asset={asset}
                              onClose={() =>
                                setStateUpdatedAt(new Date().getTime())
                              }
                            />
                          )}
                        </div>
                      ) : (
                        (asset[col as keyof Asset] as React.ReactNode)
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="scrollabe-table">
          <table className={tableClassNames} style={{ minWidth: "1650px" }}>
            <colgroup>
              {secondTableColumnOrder.map((column) =>
                renderTableCaption(column, secondTableColumns[column])
              )}
            </colgroup>
            <thead>
              <tr>
                {secondTableColumnOrder.map((column) =>
                  renderTableHead(
                    column,
                    secondTableColumnLabels[column],
                    sortBy
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {assets.map((asset: Asset, assetIndex: number) => (
                <tr key={assetIndex}>
                  {secondTableColumnOrder.map((col) => (
                    <td key={col}>
                      {(() => {
                        if (col === "acoes") {
                          return asset?.campaigns &&
                            asset?.campaigns?.number > 0 ? (
                            <div
                              className="acoes-oficina cursor-pointer"
                              onClick={() =>
                                handleItemClick("acoes-oficina", asset.id)
                              }
                            >
                              <span className="rioglyph rioglyph-calendar-today" />
                              {asset?.campaigns?.number > 0 && (
                                <span className="alert-info">
                                  {asset?.campaigns?.number}
                                </span>
                              )}
                            </div>
                          ) : (
                            <div className="acoes-oficina cursor-pointer">
                              <span className="rioglyph rioglyph-calendar-today" />
                            </div>
                          );
                        }
                        if (col === "revisoes") {
                          return (
                            <div className="revisoes-periodicas">
                              <button
                                type="button"
                                className="btn btn-primary btn-action text-size-22"
                                onClick={() =>
                                  handleItemClick(
                                    "revisoes-periodicas",
                                    asset.id
                                  )
                                }
                              >
                                <span
                                  className="rioglyph rioglyph-book"
                                  aria-hidden="true"
                                ></span>
                              </button>
                            </div>
                          );
                        }

                        if (col === "score") {
                          return (
                            <div className="text-center text-color-light">
                              -
                            </div>
                          );
                        }

                        if (col === "grupoManutencao") {
                          return (
                            <div className="text-center">{asset.groupName}</div>
                          );
                        }

                        if (col === "status") {
                          return (
                            <div className="truncate">
                              <span
                                className={
                                  ["Online", "Conectado"].includes(
                                    asset.status ?? ""
                                  )
                                    ? "rioglyph rioglyph-wifi text-color-status-available padding-right-5"
                                    : ["Offline", "Desconectado"].includes(
                                        asset.status ?? ""
                                      )
                                    ? "rioglyph rioglyph-wifi-off padding-right-5"
                                    : undefined
                                }
                              ></span>
                              {["Online", "Conectado"].includes(
                                asset.status ?? ""
                              )
                                ? "Conectado"
                                : "Não conectado"}
                            </div>
                          );
                        }

                        if (col === "rio") {
                          return (
                            <div
                              className="rio text-center"
                              style={preventBreakRow}
                            >
                              {asset?.plans?.length > 0 ? (
                                <span className="text-color-gray">
                                  {asset.plans
                                    ?.map((plan) => plan.name)
                                    .join(", ")}
                                </span>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled
                                  // onClick={() => navigate("https://latam-marketplace.rio.cloud/customerCenter/services")}
                                >
                                  <span
                                    className="rioglyph rioglyph-shopping-cart"
                                    aria-hidden="true"
                                  ></span>
                                </button>
                              )}
                            </div>
                          );
                        }

                        if (col === "statusGarantia") {
                          return (
                            <div className="status-garantia display-grid place-items-center">
                              <span
                                className={
                                  asset.warrantyStatus === "Em Garantia"
                                    ? "text-color-garantia"
                                    : "text-color-expirada"
                                }
                              >
                                {asset.warrantyStatus}
                              </span>
                            </div>
                          );
                        }

                        if (col === "volksTotal") {
                          return (
                            <div className="volks-total text-center">
                              {asset.volksTotalPlan !== "" ? (
                                <span>{asset.volksTotalPlan}</span>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled
                                >
                                  <span
                                    className="rioglyph rioglyph-shopping-cart"
                                    aria-hidden="true"
                                  ></span>
                                </button>
                              )}
                            </div>
                          );
                        }

                        if (col === "ultimaRevisao") {
                          return (
                            <div className="ultima-revisao">
                              {asset.lastCheckup && (
                                <div
                                  className="display-flex flex-column"
                                  key={assetIndex}
                                >
                                  <span className="flex items-center justify-between">
                                    {asset.lastCheckup.checkup.name}
                                    <span
                                      className={
                                        asset.lastCheckup.date
                                          ? "rioglyph-ok-circle text-color-status-available"
                                          : ""
                                      }
                                    ></span>
                                  </span>
                                  <span
                                    className={
                                      asset.lastCheckup.date
                                        ? "text-color-info"
                                        : "text-color-secondary"
                                    }
                                  >
                                    {!asset.lastCheckup.date ? "" : `Realizada em ${asset.lastCheckup.date.toLocaleDateString("pt-BR")}`}
                                  </span>
                                </div>
                              )}
                            </div>
                          );
                        }
                        if (col === "proximaRevisao") {
                          return (
                            <div className="proxima-revisao">
                              <div
                                className="display-flex flex-column"
                                key={assetIndex}
                              >
                                <NextScheduling asset={asset} hasMetrics={assetHasMetrics(asset, uptimeAssets)} />
                              </div>
                            </div>
                          );
                        }
                        return (asset as any)[col] as React.ReactNode;
                      })()}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {isSidebarVisible && (
        <div className="sidebar">
          {renderSidebarContent((asset as Asset).id)}
        </div>
      )}

      <ManualDialog
        showManualModal={showManualModal}
        setShowManualModal={setShowManualModal}
        manuals={manuals}
        setManuals={setManuals}
        setChassisSearch={setChassisSearch}
        isLoading={isLoading}
      />
    </div>
  );
};

export default MaintenanceTable;
