import { useEffect, useState } from "react";
import "./styles.scss";
import { Loading } from "../../utils/loading";
import { Scheduling } from "../../models/schedule";
import { fetchSchedule } from "../../api/fetchSchedule";
import Card from "./card";
import SchedulingDetailDialog from "./dialogs/SchedulingDetailDialog";
import { fetchNewConsultantSchedule } from "../../api/fetchConsultantSchedule";
import { fetchUserRole } from "../../api/fetchUser";
import { UserRoleEnum } from "../../enums/role";

interface IICalendarProps {
  closeSidebar: () => void;
}

const Calendar = ({ closeSidebar }: IICalendarProps) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [showDialog, setShowDialog] = useState(false);
  const [schedulingChoosed, setSchedulingChoosed] = useState<Scheduling | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [monthSchedule, setMonthSchedule] = useState<Scheduling[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const role = await fetchUserRole();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        let schedules: Scheduling[] = [];

        if (role === UserRoleEnum.CONSULTANT || role === UserRoleEnum.TOWER) {
          schedules = await fetchNewConsultantSchedule(
            currentDate.getDate(),
            month,
            year
          );
        } else if (role === UserRoleEnum.MANAGER) {
          schedules = await fetchSchedule(month, year);
        }

        setMonthSchedule(schedules);
      } catch (error) {
        console.error("Erro ao buscar agendamentos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentDate]);

  const daysOfWeek = ["S", "T", "Q", "Q", "S", "S", "D"];
  const monthNames = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const changeMonth = async (direction: any) => {
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + direction,
      1
    );
    setCurrentDate(newDate);
    setLoading(true);
    const schedules = await fetchSchedule(
      newDate.getMonth() + 1,
      newDate.getFullYear()
    );
    setMonthSchedule(schedules);
    setLoading(false);
  };

  const renderDays = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1).getDay();
    const lastDate = new Date(year, month + 1, 0).getDate();

    const days = [];
    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }

    for (let date = 1; date <= lastDate; date++) {
      const isToday =
        date === currentDate.getDate() &&
        month === currentDate.getMonth() &&
        year === currentDate.getFullYear();

      const hasScheduling =
        monthSchedule.filter(
          (scheduling) =>
            scheduling.scheduleTime.getDate() ===
            new Date(year, month, date).getDate()
        ).length > 0;

      days.push(
        <div
          key={date}
          className={`text-size-14 font-weight-normal calendar-day ${
            isToday ? "today" : ""
          } ${hasScheduling ? "bg-scheduling" : ""}`}
          onClick={() => {
            setCurrentDate(new Date(year, month, date));
          }}
        >
          {date}
        </div>
      );
    }

    return days;
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  return (
    <>
      <div className="bg-calendar-sidebar" onClick={() => closeSidebar()} />
      <div className="calendar-sidebar">
        <div className="flex justify-between padding-right-20 calendar-sidebar__header">
          <h3 className="flex items-center gap-6 chat-sidebar__title text-color-map-marker-asset">
            <span className="rioglyph rioglyph-calendar text-color-map-marker-asset"></span>
            Calendário
          </h3>
          <div
            className="padding-top-20 close-sidebar cursor-pointer"
            onClick={() => closeSidebar()}
          >
            <span className="rioglyph rioglyph-remove"></span>
          </div>
        </div>

        <div className="calendar-container">
          <div className="calendar-header">
            <span className="text-color-status-available text-normal text-size-16">
              {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
            </span>
            <div className="calendar-header__nav">
              <button
                className="rioglyph rioglyph-chevron-left text-size-14"
                onClick={() => changeMonth(-1)}
              />
              <button
                className="rioglyph rioglyph-chevron-right text-size-14"
                onClick={() => changeMonth(1)}
              />
            </div>
          </div>
          <div className="calendar-days-of-week">
            {daysOfWeek.map((day, index) => (
              <div key={index} className="calendar-day-name">
                {day}
              </div>
            ))}
          </div>
          <div className="calendar-days">{renderDays()}</div>
        </div>

        <div className="schedule-container">
          <h3 className="flex items-center gap-4 chat-sidebar__title text-color-darker">
            <div className="border-b w-full pb-1">
              <span className="rioglyph rioglyph-checkboxes text-color-darker"></span>
              Agendamento
            </div>
          </h3>

          <div className="schedule-cards">
            {loading && <Loading label="Carregando agendamentos..." />}
            {monthSchedule
              .filter(
                (scheduling) =>
                  scheduling.scheduleTime.getDate() === currentDate.getDate()
              )
              .map((scheduling, index) => (
                <Card
                  key={index}
                  setShowDialog={setShowDialog}
                  scheduling={scheduling}
                  chooseScheduling={() => setSchedulingChoosed(scheduling)}
                />
              ))}
          </div>
        </div>
      </div>
      <SchedulingDetailDialog
        show={showDialog}
        onClose={handleCloseDialog}
        scheduling={schedulingChoosed}
      />
    </>
  );
};

export default Calendar;
