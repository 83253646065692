import Button from "@rio-cloud/rio-uikit/Button";
import Dialog from "@rio-cloud/rio-uikit/Dialog";

import { ScheduleCheckup } from "../../../../models/checkup";

interface ICheckupDetailDialogProps {
    scheduling?: ScheduleCheckup;
    showCheckupDetailDialog: boolean;
    setShowCheckupDetailDialog: (show: boolean) => void;
}

const CheckupDetailDialog = ({ scheduling, showCheckupDetailDialog, setShowCheckupDetailDialog }: ICheckupDetailDialogProps) => {
    if (!scheduling) return null;

    return (
        <Dialog
            show={showCheckupDetailDialog}
            title="Detalhes da revisão"
            subtitle="Confira abaixo as informações sobre sua revisão:"
            footer={
                <Button
                    onClick={() => setShowCheckupDetailDialog(false)}
                    className="btn btn-primary"
                    bsSize="lg"
                >
                    Fechar
                </Button>
            }
            body={
                <div className="w-full mx-auto bg-white rounded-lg p-6">
                    <h1 className="text-size-20 font-bold mb-4">Plano de Revisão</h1>

                    <section className="margin-bottom-20">
                        <h2 className="text-size-16 font-semibold mb-2">
                            {scheduling?.checkup?.name}
                        </h2>
                        <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
                            {scheduling?.checkup?.services.map(
                                (item, index) => (
                                    <div key={index} className="padding-bottom-10">
                                        <p className="text-bold">{item.name}</p>
                                        {item.items.map((subItem, subIndex) => (
                                            <p key={subIndex} className="padding-left-20">
                                                {subItem}
                                            </p>
                                        ))}
                                    </div>
                                )
                            )}
                        </ul>
                    </section>

                    {/*
                    TODO: implementar revisão de lubrificação
                    asset.nextOilChangeCheckups && (asset.nextOilChangeCheckups.length > 0) &&
                        <section className="margin-bottom-20">
                            <h2 className="text-size-16 font-semibold mb-2">
                                Revisão de Lubrificação
                            </h2>
                            <ul className="list-disc list-inside space-y-1">
                                {
                                    asset.nextOilChangeCheckups?.[0]?.checkup.services
                                        .map((service, index) => (
                                            <li key={index}>{service.name}</li>
                                        ))
                                }
                            </ul>
                        </section>
                    */}
                </div>
            }
            onCloseValidation={() => {
                setShowCheckupDetailDialog(false)
                return true;
            }}
            className="dialog-agendamento"
            showCloseButton
        />
    );
}

export default CheckupDetailDialog;