import { useEffect, useState } from "react";
import { ClearableInput, Select } from "@rio-cloud/rio-uikit";
import DatePicker from "@rio-cloud/rio-uikit/DatePicker";
import { Scheduling } from "../../../../models/schedule";
import AppointmentCard from "./appointmentCard";
import moment from "../../../../utils/momentConfig";
import "../styles.scss";
import { fetchAllConsultantSchedule } from "../../../../api/fetchConsultantSchedule";
import { Loading } from "../../../../utils/loading";
import Appointment from "./appointment";

const Appointments = () => {
  const [sortType, setSortType] = useState<string>("asc");
  const [appointments, setAppointments] = useState<Scheduling[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [scheduling, setScheduling] = useState<Scheduling | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [datePickerKey, setDatePickerKey] = useState<number>(0);

  const handleDateChange = (date: moment.Moment | string) => {
    if (moment.isMoment(date)) {
      setSelectedDate(date);
    }
  };

  const fetchAppointments = async () => {
    setLoading(true);
    const appointments = await fetchAllConsultantSchedule();
    setAppointments(sortAppointments(appointments, sortType));
    setLoading(false);
  };

  const sortAppointments = (data: Scheduling[], order: string) => {
    return [...data].sort((a, b) => {
      const dateA = new Date(a.scheduleTime).getTime();
      const dateB = new Date(b.scheduleTime).getTime();
      return order === "asc" ? dateA - dateB : dateB - dateA;
    });
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  const clearDateFilter = () => {
    setSelectedDate(null);
    setDatePickerKey((prevKey) => prevKey + 1);
  };

  return loading ? (
    <Loading label="Carregando agendamentos..." />
  ) : scheduling ? (
    <Appointment appointment={scheduling} goBack={() => setScheduling(null)} />
  ) : (
    <>
      <h1>Agendamentos</h1>
      <div className="flex gap-6 margin-top-15 search-filter">
        <div className="form-group search-group">
          <div className="input-group">
            <span className="input-group-addon">
              <span className="rioglyph rioglyph-search" aria-hidden="true" />
            </span>
            <ClearableInput
              id="search-button"
              placeholder="Busque por chassi"
              onChange={(value: string) => setSearchTerm(value)}
            />
          </div>
        </div>

        <div className="form-group search-group relative">
          <DatePicker
            key={datePickerKey}
            id="date-picker"
            inputProps={{ placeholder: "Escolha a data" }}
            dateFormat="DD-MM-YYYY"
            timeFormat={false}
            locale="pt-br"
            onChange={handleDateChange}
            value={selectedDate || undefined}
          />

          {selectedDate && (
            <span
              className="rioglyph rioglyph-remove-sign absolute right-10 top-10 cursor-pointer text-color-gray"
              onClick={clearDateFilter}
            />
          )}
        </div>

        <div className="form-group select-group">
          <Select
            id="sort-type"
            options={[
              {
                id: "desc",
                label: <span>Decrescente</span>,
                selected: sortType === "desc",
              },
              {
                id: "asc",
                label: <span>Crescente</span>,
                selected: sortType === "asc",
              },
            ]}
            onChange={(selected) => setSortType(selected?.id || "desc")}
          />
        </div>
      </div>

      {appointments.length === 0 ? (
        <div className="flex justify-center padding-top-10">
          <span className="text-color-gray">
            Não existem agendamentos realizados.
          </span>
        </div>
      ) : (
        appointments
          .sort((a, b) =>
            sortType === "asc"
              ? a.scheduleTime.getTime() - b.scheduleTime.getTime()
              : b.scheduleTime.getTime() - a.scheduleTime.getTime()
          )
          .filter((appointment) => appointment.asset.chassis
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
          )
          .filter((appointment) => {
            if (!selectedDate) return true;
            const appointmentDate = moment(appointment.scheduleTime).format("DD-MM-YYYY");
            const selectedFormattedDate = selectedDate.format("DD-MM-YYYY");
            return appointmentDate === selectedFormattedDate;
          })
          .map((appointment) => (
            <AppointmentCard
              key={crypto.randomUUID()}
              appointment={appointment}
              chooseScheduling={(schedulingChoosed) =>
                setScheduling(schedulingChoosed)
              }
              fetchAppointmens={fetchAppointments}
            />
          ))
      )}
    </>
  );
};

export default Appointments;
