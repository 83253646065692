import { useNavigate } from "react-router";
import { SortArrows, TableSearch, TableToolbar } from "@rio-cloud/rio-uikit";
import classNames from "classnames";

import { useState } from "react";
import { sortBy } from "lodash";

import "../styles.scss";

type ColumnLabel = { [key: string]: string };
type ColumnDetails = { [key: string]: number };
type ColumnDetailsMap = { [key: string]: ColumnDetails };

const defaultColumnOrder = [
  "agendamento",
  "veiculo",
  "chassi",
  "odometro",
  "horimetro",
];
const defaultColumnLabels: ColumnLabel = {
  agendamento: "Agendamento",
  veiculo: "Veículo",
  chassi: "Chassi",
  odometro: "Odômetro",
  horimetro: "Horímetro",
};
const defaultColumnsDetails: ColumnDetailsMap = {
  agendamento: { width: 60, defaultWidth: 60, maxWidth: 300 },
  veiculo: { width: 0, defaultWidth: 200, maxWidth: 350 },
  chassi: { width: 0, defaultWidth: 0, maxWidth: 350 },
  odometro: { width: 0, defaultWidth: 0, maxWidth: 350 },
  horimetro: { width: 0, defaultWidth: 0, maxWidth: 350 },
};

const secondTableColumnOrder = [
  "acoes",
  "revisoes",
  "score", "grupoManutencao", "statusGarantia", "status",
  //"rio", 
  "volksTotal", 
  "ultimaRevisao",
  "proximaRevisao",
];
const secondTableColumnLabels: ColumnLabel = {
  acoes: "Campanhas de Campo",
  revisoes: "Revisões Periódicas",
  score: "Vehicle score",
  grupoManutencao: "Grupo Manutencao",
  statusGarantia: "Status Garantia",
  rio: "RIO",
  status: "Status",
  volksTotal: "Volks Total",
  ultimaRevisao: "Última Revisão",
  proximaRevisao: "Próxima Revisão",
};
const secondTableColumnsDetails: ColumnDetailsMap = {
  acoes: { width: 150, defaultWidth: 150, maxWidth: 150 },
  revisoes: { width: 120, defaultWidth: 120, maxWidth: 150 },
  score: { width: 80, defaultWidth: 80, maxWidth: 130 },
  grupoManutencao: { width: 130, defaultWidth: 130, maxWidth: 150 },
  statusGarantia: { width: 100, defaultWidth: 100, maxWidth: 100 },
  rio: { width: 180, defaultWidth: 180, maxWidth: 180 },
  status: { width: 80, defaultWidth: 80, maxWidth: 100 },
  volksTotal: { width: 100, defaultWidth: 100, maxWidth: 120 },
  ultimaRevisao: { width: 140, defaultWidth: 140, maxWidth: 160 },
  proximaRevisao: { width: 180, defaultWidth: 180, maxWidth: 200 },
};

const SkeletonTable = ({ onlyRows, searchValue }: { onlyRows?: boolean | undefined, searchValue?: string | undefined }) => {
  const navigate = useNavigate();

  const [columnOrder] = useState<string[]>(defaultColumnOrder);
  const [columnsDetails] = useState(defaultColumnsDetails);
  const [secondTableColumns] = useState(secondTableColumnsDetails);

  const renderTableHead = (
    column: string,
    label: string,
    sortByColumn: string
  ) => {
    const tableHeadClassNames = classNames("user-select-none", "sort-column");

    return (
      <th
        key={column}
        className={tableHeadClassNames}
        onClick={() => {}}
        data-field={column}
        data-sortby={column}
        title={label}
        style={{ textAlign: column === "rio" ? "center" : undefined }}
      >
        <span>
          {sortByColumn === column ? <SortArrows /> : <SortArrows />}
          <span>{label}</span>
        </span>
      </th>
    );
  };

  const renderTableCaption = (column: string, columnDetails: ColumnDetails) => {
    const style = columnDetails?.width
      ? {
          minWidth: columnDetails.width,
          width: columnDetails.width,
        }
      : {};

    return <col key={column} style={style} />;
  };

  const preventBreakRow = { whiteSpace: "nowrap" };

  const tableClassNames = classNames(
    "table",
    "table-layout-fixed",
    "table-column-overflow-hidden",
    "table-bordered",
    "table-head-filled"
  );

  return (
    <div id="TableCommonDemo">
      {!onlyRows && (
        <TableToolbar>
          <div className="table-toolbar-container">
            <div className="table-toolbar-group-left">
              {/* <TableSearch placeholder="Busque por chassi" disabled value={searchValue} /> */}
            </div>
            <div className="table-toolbar-group-right">
              <div className="table-toolbar-column table-toolbar-column-spacer">
                <button
                  type="button"
                  className="btn bg-highlight-darker padding-7"
                  onClick={() => navigate(`/control-tower/new-appointment`)}
                >
                  <span className="text-capitalize padding-right-5">
                    Criar agendamento
                  </span>
                  <span
                    className="rioglyph rioglyph-plus-sign"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </TableToolbar>
      )}

      <div className="tables-row">
        <div className="tables-row__fixed">
          <table
            className={classNames(
              "table",
              "table-layout-fixed",
              "table-bordered"
            )}
          >
            {" "}
            <colgroup>
              {columnOrder.map((column) =>
                renderTableCaption(column, columnsDetails[column])
              )}
            </colgroup>
            {!onlyRows && (
              <thead>
                <tr>
                  {columnOrder.map((column) =>
                    renderTableHead(
                      column,
                      defaultColumnLabels[column],
                      sortBy as unknown as string
                    )
                  )}
                </tr>
              </thead>
            )}
            <tbody>
              {new Array(50).fill(0).map((row: any, rowIndex: number) => (
                <tr key={rowIndex}>
                  {columnOrder.map((col, colIndex) => (
                    <td
                      key={`${col}-${rowIndex}-${colIndex}`}
                      data-field={defaultColumnLabels[col]}
                      style={col !== "veiculo" ? preventBreakRow : {}}
                      className="animate-pulse"
                    >
                      <div className="w-3/4 h-4 bg-gray-300 rounded" />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="scrollabe-table">
          <table className={tableClassNames} style={{ minWidth: '1650px' }}>
            <colgroup>
              {secondTableColumnOrder.map((column) =>
                renderTableCaption(column, secondTableColumns[column])
              )}
            </colgroup>
            {!onlyRows && (
              <thead>
                <tr>
                  {secondTableColumnOrder.map((column) =>
                    renderTableHead(
                      column,
                      secondTableColumnLabels[column],
                      sortBy as unknown as string
                    )
                  )}
                </tr>
              </thead>
            )}
            <tbody>
              {new Array(50).fill(0).map((row: any, rowIndex: number) => (
                <tr key={rowIndex}>
                  {secondTableColumnOrder.map((col) => (
                    <td key={col} className="animate-pulse">
                      <div className="w-3/4 h-4 bg-gray-300 rounded" />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SkeletonTable;
