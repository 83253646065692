import { SchedulingDto } from "../dtos/schedulingDto";
import { Scheduling } from "../models/schedule";
import { MainSchedule } from "../models/scheduleList";
import ApiService from "../services/api";

export const fetchScheduleList = async (): Promise<MainSchedule[]> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.get<MainSchedule[]>(
      `/schedules/checkups/schedules-by-user`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      console.warn("Unexpected status code:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching schedule list:", error);
    return [];
  }
};

export const fetchDeletedSchedule = async (id: string): Promise<any[]> => {
  const apiService = new ApiService();

  try {
    const response = await apiService.post<any[]>(
      `/schedules/checkups/cancel/${id}`,
      {}
    );

    if (response.status === 200) {
      return response.data;
    } else {
      console.warn("Unexpected status code:", response.status);
      return [];
    }
  } catch (error: any) {
    console.error(
      "Error fetching deleted schedule list:",
      error.response?.data || error.message || error
    );
    return [];
  }
};

export const fetchScheduleListByChassisList = async (chassisList: string[]): Promise<MainSchedule[]> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.post<MainSchedule[]>(
      `/schedules/checkups/by-vehicle-chassis`,
      { chassisList: chassisList.filter(el => el) }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      console.warn("Unexpected status code:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching schedule list:", error);
    return [];
  }
};

export const fetchSchedulingByChassisList = async (chassisList: string[]): Promise<Scheduling[]> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.post<any[]>(
      `/schedules/checkups/by-vehicle-chassis`,
      { chassisList: chassisList.filter(el => el) }
    );
    if (response.status === 200) {
      return response.data.map(SchedulingDto);
    } else {
      console.warn("Unexpected status code:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching schedule list:", error);
    return [];
  }
};

export const fetchAllScheduleList = async (): Promise<MainSchedule[]> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.get<MainSchedule[]>(
      `/schedules/checkups/schedules-by-consultant?limit=50`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      console.warn("Unexpected status code:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching schedule list:", error);
    return [];
  }
};