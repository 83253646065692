import { SchedulingDto } from "../dtos/schedulingDto";
import { Scheduling } from "../models/schedule";
import ApiService from "../services/api";

export const fetchScheduling = async (schedulingId: string): Promise<Scheduling | undefined> => {
    const apiService = new ApiService();
    
    try {
        const response = await apiService.get<any>(`/schedules/checkups/${schedulingId}`);
        if (response.status === 200) {
            return SchedulingDto(response.data);
        } else return undefined;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}