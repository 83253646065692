import { SchedulingType } from "../../../../../enums/taskType";
import { Scheduling } from "../../../../../models/schedule";
import {
  MaintenanceMonitoringStatusEnum,
  MaintenanceMonitoringStepsEnum,
} from "../../../../../enums/maintenanceMonitoring";

import "./styles.css";

interface ISchedulingCardProps {
  task: Scheduling;
  handleScheduling: (scheduling: Scheduling) => void;
}

const SchedulingCard = ({
  task,
  handleScheduling,
}: ISchedulingCardProps) => {
  const primaryColor = (): string => {
    if (task.type === SchedulingType.PREVENTIVA) return "highlight-dark";
    if (task.type === SchedulingType.CORRETIVA) return "rating-2";
    return "";
  };

  const getSecondaryColor = (): string => {
    if (task.type === SchedulingType.PREVENTIVA) return "highlight-lighter";
    if (task.type === SchedulingType.CORRETIVA) return "card-secondary";
    return "";
  };

  const translateStatus = (status: string): string => {
    return (
      MaintenanceMonitoringStepsEnum[
        status as keyof typeof MaintenanceMonitoringStepsEnum
      ] ||
      MaintenanceMonitoringStatusEnum[
        status as keyof typeof MaintenanceMonitoringStatusEnum
      ] ||
      "Desconhecido"
    );
  };

  return (
    <div className="padding-x-3 height-100pct">
      <div
        className={`
                    container width-100pct height-100pct rounded border display-flex align-items-center cursor-pointer
                    border-color-${primaryColor()} bg-${getSecondaryColor()}
                `}
        onClick={() => handleScheduling(task)}
      >
        <div className="text-size-10 width-100pct">
          <div className="flex items-center justify-end">
            <span className="rounded-full text-center border payment-info">
              {task.maintenanceStatus
                ? `Status: ${translateStatus(task.maintenanceStatus)}`
                : "Não iniciado"}
            </span>
          </div>

          <div
            className={`
                            text-bold padding-bottom-5 text-capitalize truncate
                            text-color-${primaryColor()}
                        `}
          >
            Revisão {task.type}
          </div>
          <div className="display-grid grid-cols-2-1fr-max-content truncate">
            <div className="text-bold truncate">{task.asset.name}</div>
            <div className="text-medium">
              {new Date(task.scheduleTime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
          </div>
          <div className="display-grid grid-cols-2-1fr-max-content">
            <div className={`text-color-gray`}>{task.asset.group}</div>
            <div>-</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchedulingCard;
