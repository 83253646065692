import { useEffect, useState } from "react";
import SchedulingCard from "./SchedulingCard";
import { getMaintenanceWeekSchedule } from "../../../../../services/consultantScheduleService";
import {
  Scheduling,
  WeekSchedule,
  DaySchedule,
} from "../../../../../models/schedule";
import { useNavigate } from "react-router-dom";
interface IDayProps {
  firstWeekDay: Date;
  currentDate: Date;
  consultantMonthSchedule: Scheduling[];
  handleScheduling: (scheduling: Scheduling) => void;
}

const Day = ({
  firstWeekDay,
  currentDate,
  consultantMonthSchedule,
  handleScheduling,
}: IDayProps) => {
  const navigate = useNavigate();
  
  const [weekSchedule, setWeekSchedule] = useState<WeekSchedule | undefined>(
    undefined
  );

  useEffect(() => {
    const fetchWeekSchedule = async () => {
      const weekSchedule = await getMaintenanceWeekSchedule(
        firstWeekDay,
        consultantMonthSchedule
      );
      setWeekSchedule(weekSchedule);
    };
    fetchWeekSchedule();
  }, [firstWeekDay, consultantMonthSchedule]);

  const getDaySchedule = (date: Date): DaySchedule | undefined =>
    weekSchedule?.days.find((daySchedule) => {
      const day = new Date(daySchedule.date);
      return (
        day.getFullYear() === date.getFullYear() &&
        day.getMonth() === date.getMonth() &&
        day.getDate() === date.getDate()
      );
    });

  const getScheduleTask = (
    date: Date,
    hour: number
  ): JSX.Element | undefined => {
    const daySchedule = getDaySchedule(date);
    if (!daySchedule) return undefined;

    const task = daySchedule.schedulings.find((task) => {
      const taskDate = new Date(task.scheduleTime);
      return taskDate.getHours() === hour;
    });
    return task ? (
      <SchedulingCard
        task={task}
        handleScheduling={handleScheduling}
      />
    ) : (
      <div
        className="width-100pct height-100pct cursor-pointer"
        onClick={() => navigate(`/dealership/new-appointment?date=${new Date(date.setHours(hour - 3, 0, 0, 0)).toISOString()}`)}
      />
    );
  };

  return (
    <table className="width-100pct table-fixed">
      <thead>
        <tr>
          <th className="width-10 height-20"></th>
          <th style={{ width: 100 }} />
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 11 }).map((_, hour) => {
          const availableDateTime = !(currentDate.getDay() === 0 || (currentDate.getDay() === 6 && (hour + 8) > 12));
          return <tr key={hour + 8}>
            <td className="display-flex justify-content-center align-items-center">{`${
              hour + 8
            }:00`}</td>
            <td
              style={{ height: 121, width: 100 }}
              className={`border ${!availableDateTime ? 'bg-lighter' : 'bg-highlight-decent'}`}
            >
              {availableDateTime ? getScheduleTask(currentDate, hour + 8) : null}
            </td>
          </tr>
        })}
      </tbody>
    </table>
  );
};

export default Day;
