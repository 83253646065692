import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import ApplicationLayoutBodyNavigation from "@rio-cloud/rio-uikit/ApplicationLayoutBodyNavigation";
import SubNavigation from "@rio-cloud/rio-uikit/SubNavigation";
import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

interface ISubnavitationHeaderProps {
  children: ReactNode;
}

const SubNavigationHeader = ({ children }: ISubnavitationHeaderProps) => {
  const bodyNavigationClasses = ""; // isMobile ? '' : 'has-offset';

  return (
    <ApplicationLayout.Body
      className="uikitDemo"
      navigation={
        <ApplicationLayoutBodyNavigation className={bodyNavigationClasses}>
          <SubNavigation
            navItems={[
              {
                key: "Manutenções preventivas",
                route: (
                  <NavLink to="/fleet-manager/preventive-maintenance">
                    Manutenções preventivas
                  </NavLink>
                ),
              },
              {
                key: "Acompanhamento de manutenção",
                route: (
                  <NavLink to="/fleet-manager/maintenance-monitoring">
                    Acompanhamento da manutenção
                  </NavLink>
                ),
              },
              {
                key: "Agendamentos",
                route: (
                  <NavLink to="/fleet-manager/my-appointments">
                    Agendamentos
                  </NavLink>
                ),
              },
              {
                key: "Historico",
                route: (
                  <span
                    style={{ pointerEvents: "none" }}
                    className="text-color-gray"
                  >
                    Histórico
                  </span>
                ),
              },
            ]}
          />
        </ApplicationLayoutBodyNavigation>
      }
    >
      {children}
    </ApplicationLayout.Body>
  );
};

export default SubNavigationHeader;
