export const getGroupIdByName = (name: string): string => {
    const groups = [
        {
            id: '1',
            label: "Rodoviário",
        },
        {
            id: '2',
            label: "Misto",
        },
        {
            id: '3',
            label: "Severo",
        },
        {
            id: '4',
            label: "Especial",
        },
    ];
    const group = groups.find((group) => group.label === name);
    return group ? group.id : "";
}