import { Route, Routes } from "react-router-dom";

import MaintenanceMonitoring from "./monitoring";
import Assessment from "./monitoring/assessment";
import Report from "./monitoring/report";
import MonitoringList from "./list";

const DealershipMaintenanceMonitoringRoutes = () => (
  <Routes>
    <Route path="/" element={<MonitoringList />}/>
    <Route path="/monitoring" element={<MaintenanceMonitoring />} />
    <Route path="/report" element={<Report />} />
    <Route path="/assessment" element={<Assessment />} />
  </Routes>
);

export default DealershipMaintenanceMonitoringRoutes;