import { ClearableInput, Dialog } from "@rio-cloud/rio-uikit";
import { Loading } from "../../../../utils/loading";

interface IManuals {
    chassis?: string;
    year?: number;
    manuals?: { name: string; downloadLink: string }[];
}

interface IManualDialogProps {
    showManualModal: boolean;
    setShowManualModal: (show: boolean) => void;
    manuals: IManuals;
    setManuals: (manuals: IManuals) => void;
    setChassisSearch: (chassis: string) => void;
    isLoading: boolean;
}

export const ManualDialog = ({ showManualModal, setChassisSearch, setManuals, setShowManualModal, manuals, isLoading }: IManualDialogProps) => {
    return (
        <Dialog
          show={showManualModal}
          title="Buscar Manuais por Chassi"
          onCloseValidation={() => {
            setShowManualModal(false);
            setManuals({});
            setChassisSearch("");
            return true;
          }}
          showCloseButton
          bsSize="md"
          body={
            <div className="w-full mx-auto bg-white rounded-lg manuals-content">
              <div className="flex items-end gap-6 border-b pb-5">
                <div className="form-group w-4/6">
                  <label htmlFor="chassi-search">Chassi do veículo*</label>
                  <div className="input-group">
                    <ClearableInput
                      id="chassi-search"
                      value={manuals.chassis || ""}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group w-2/6">
                  <label htmlFor="ano">Ano</label>
                  <div className="input-group">
                    <input
                      id="ano"
                      type="number"
                      value={manuals?.year}
                      className="form-control no-controls"
                      disabled
                    />
                  </div>
                </div>
              </div>

              {isLoading ? (
                <div className="text-center mt-4">
                  <Loading label="Carregando..." />
                </div>
              ) : manuals.manuals && manuals.manuals.length > 0 ? (
                <table className="table table-bordered mt-4">
                  <thead>
                    <tr>
                      <th>Nome do Manual</th>
                      <th className="text-center">Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {manuals.manuals.map((manual, index) => (
                      <tr key={index}>
                        <td>{manual.name}</td>
                        <td className="text-center">
                          <a
                            href={manual.downloadLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-color-darker"
                          >
                            <span className="rioglyph rioglyph-cloud-download text-size-20 text-color-darker" />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-center mt-4">Nenhum manual encontrado.</p>
              )}
            </div>
          }
        />
    )
}