import { Driver } from "../models/driver";

export const driverDto = (data: any): Driver => {
    return {
        id: data?.id ?? '-',
        name: data?.displayName ?? '-',
        license: data?.driverLicence ?? '-',
        email: data?.email ?? '-',
        phone: data?.phoneNumber ?? '-'
    };
}