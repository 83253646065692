import { useEffect, useState } from "react";
import { TicketRepair } from "../../../../../models/ticket";
import {
  downloadTicketImage,
  fetchTicketImages,
  ITicketFile,
} from "../../../../../api/ticket";
import moment from "moment";
import { formatFileSize } from "../../../../../utils/imageSizeUtils";

interface IRepairProps {
  ticketId: string | undefined;
  repair: TicketRepair | undefined;
}

const Repair = ({ ticketId, repair }: IRepairProps) => {
  const [files, setFiles] = useState<ITicketFile[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (repair && ticketId) {
        const images = await fetchTicketImages({
          ticketId: ticketId,
          step: "REPAIR",
        });
        setFiles(images);
      }
    };

    fetchData();
  }, [repair]);

  return (
    <div>
      <div className="resume-section">
        <div className="resume-container padding-0">
          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-full">
              <label htmlFor="data">Data</label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-calendar"></span>
                </span>
                <input
                  id="data"
                  className="form-control"
                  type="text"
                  placeholder="--/--/----"
                  disabled
                  value={repair?.repairDate?.toLocaleDateString("pt-BR")}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="checkin">Check-in</label>
              <input
                id="checkin"
                className="form-control"
                type="text"
                placeholder="--/--/----"
                disabled
                value={repair?.checkIn?.toLocaleDateString("pt-BR")}
              />
            </div>

            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="hora-checkin" className="opacity-0">
                Hora
              </label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="hora-checkin"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  disabled
                  value={repair?.checkIn?.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="checkout">Check-out</label>
              <input
                id="checkout"
                className="form-control"
                type="text"
                placeholder="--/--/----"
                disabled
                value={repair?.checkOut?.toLocaleDateString("pt-BR")}
              />
            </div>

            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="hora-checkout" className="opacity-0">
                Hora
              </label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="hora-checkout"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  disabled
                  value={repair?.checkOut?.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-full">
              <label htmlFor="pecas">Aguardando Peças</label>
              <div className="input-group">
                <input
                  id="pecas"
                  className="form-control"
                  type="text"
                  value={repair?.awaitingParts}
                  onChange={(e) => (repair!.awaitingParts = e.target.value)}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="literatura">
                Tempo Estimado Conforme Literatura
              </label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="literatura"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  value={repair?.estimatedTime}
                  onChange={(e) => (repair!.estimatedTime = e.target.value)}
                  disabled
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="troubleshooting">
                Troubleshoot Estimado em Solução Conforme Literatura
              </label>
              <input
                id="troubleshooting"
                className="form-control"
                type="text"
                value={repair?.estimatedTroubleshooting}
                onChange={(e) =>
                  (repair!.estimatedTroubleshooting = e.target.value)
                }
                disabled
              />
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-full">
              <label htmlFor="relato">Relato</label>
              <textarea
                className="form-control height-100"
                id="relato"
                placeholder="Descritivo"
                value={repair?.report}
                onChange={(e) => (repair!.report = e.target.value)}
                disabled
              ></textarea>
            </div>
          </div>
          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-full">
              <label htmlFor="relato">Inspeção visual</label>
              <div className="display-grid grid-cols-1 grid-cols-3-sm gap-25">
                {files.length > 0 ? (
                  files.map((file, index) => (
                    <FilePreview key={index} file={file} ticketId={ticketId} />
                  ))
                ) : (
                  <div className="text-color-dark text-italic">
                    Nenhum arquivo
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FilePreview = ({
  file,
  ticketId,
}: {
  file: ITicketFile | undefined;
  ticketId: string | undefined;
}) => (
  <div
    className="panel panel-default margin-top-20 cursor-pointer"
    onClick={async () => {
      if (ticketId && file) {
        const response = await downloadTicketImage({
          ticketId: ticketId,
          step: "REPAIR",
          filename: file.name,
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.name);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      }
    }}
  >
    <div className="panel-body">
      {file && (
        <div className="flex">
          {file.link && (
            <div className="thumbnail margin-right-10">
              <img
                src={file.link}
                alt={file.name}
                style={{
                  width: "200px",
                  height: "120px",
                  objectFit: "cover",
                }}
              />
            </div>
          )}
          <div className="flex justify-between w-full">
            <div>
              <div>
                <b>{`${file?.name}`}</b>
              </div>
              <div>tipo: {`${file?.contentType}`}</div>
              <div>tamanho: {formatFileSize(file?.contentSize)}</div>
              <div>
                data da última modificação:{" "}
                {file?.updatedAt
                  ? moment(file.updatedAt).format("DD/MM/YYYY")
                  : "Data não disponível"}
              </div>{" "}
              <div>caminho: {`${file?.name}`}</div>
            </div>
          </div>
        </div>
      )}
      {!file && (
        <div className="text-color-dark text-italic">Nenhum arquivo</div>
      )}
    </div>
  </div>
);

export default Repair;
