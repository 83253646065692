import { Checkbox, ClearableInput, Select, SimpleTooltip, Spinner } from "@rio-cloud/rio-uikit";
import { debounce } from "lodash";
import { ChangeEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import TagRow from "./components/TagRow";

import "./styles.scss";

interface AtosSidebarProps {
	tags: Tag[];
	assets: Asset[];
	selectedTags: string[];
	selectedAssets: string[];
	onSelectionChange: (selection: { groups: string[]; items: string[] }) => void;
}

interface Tag {
	id: string;
	name: string;
}

export interface Asset {
	id: string;
	name?: string;
	type: string;
	groupIds: string[];
	chassis: string;
}

const CustomSidebar = ({
	onSelectionChange,
	tags = [],
	selectedAssets: initialSelectedAssets = [],
	selectedTags: initialSelectedTags = [],
	assets = [],
}: AtosSidebarProps) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [hasError, setHasError] = useState(false);
	const [viewMode, setViewMode] = useState("assets");

	const [expandedTags, setExpandedTags] = useState<Record<string, boolean>>({});
	const [selectedTags, setSelectedTags] = useState<Set<string>>(new Set());
	const [selectedAssets, setSelectedAssets] = useState<Set<string>>(new Set());
	const [indeterminateTags, setIndeterminateTags] = useState<Record<string, boolean>>({});
	const [headerIndeterminate, setHeaderIndeterminate] = useState(false);

	const [truckCount, setTruckCount] = useState(0);
	const [busCount, setBusCount] = useState(0);

	const initialized = useRef(false);
	const isInitialized = useRef(false);
	const batchUpdateRef = useRef<NodeJS.Timeout | null>(null);
	const initialSelectionSet = useRef(false);

	// Helper to create unique tag-asset identifier
	const createTagAssetId = (tagId: string, assetId: string) => `${tagId}:${assetId}`;
	const parseTagAssetId = (tagAssetId: string) => {
		const [tagId, assetId] = tagAssetId.split(":");
		return { tagId, assetId };
	};

	const areAllTagAssetsSelected = useCallback((tagId: string, tagAssets: Asset[], selectedAssets: Set<string>): boolean => {
		return tagAssets.every((asset) => selectedAssets.has(createTagAssetId(tagId, asset.id)));
	}, []);

	// Add cleanup effect
	useEffect(() => {
		isInitialized.current = true;

		return () => {
			if (batchUpdateRef.current) {
				clearTimeout(batchUpdateRef.current);
			}
		};
	}, []);

	// finds the first tag ID associated with a given asset, if any
	const findAssetTag = useCallback(
		(assetId: string) => {
			const asset = assets.find((a) => a.id === assetId);
			if (asset && asset.groupIds.length > 0) {
				return asset.groupIds[0]; // use the first tag the asset belongs to
			}
			return null;
		},
		[assets],
	);

	// initial states being populated
	useEffect(() => {
		if (!initialSelectionSet.current && assets.length > 0 && (initialSelectedAssets.length > 0 || initialSelectedTags.length > 0)) {
			const tagScopedAssetIds = new Set<string>();

			// First, handle selected tags and their assets
			initialSelectedTags.forEach((selectedTagId) => {
				const tagAssets = assets.filter((asset) => asset.groupIds.includes(selectedTagId));
				tagAssets.forEach((asset) => {
					tagScopedAssetIds.add(createTagAssetId(selectedTagId, asset.id));
				});
			});

			// Then, handle individually selected assets
			initialSelectedAssets.forEach((assetId) => {
				const asset = assets.find((a) => a.id === assetId);
				if (asset) {
					// For selected assets, we need at least one tag-asset pair
					// If the asset belongs to any selected tags, those pairs were already added above
					// If not, we'll add a pair with the first available tag
					const hasSelectedTagPair = asset.groupIds.some(
						(tagId) => initialSelectedTags.includes(tagId) && tagScopedAssetIds.has(createTagAssetId(tagId, assetId)),
					);

					if (!hasSelectedTagPair && asset.groupIds.length > 0) {
						// Add the asset with its first available tag
						tagScopedAssetIds.add(createTagAssetId(asset.groupIds[0], assetId));
					}
				}
			});

			setSelectedAssets(tagScopedAssetIds);
			setSelectedTags(new Set(initialSelectedTags));
			initialSelectionSet.current = true;
		}
	}, [assets, initialSelectedAssets, initialSelectedTags]);

	// groups assets by their associated tags, mapping each tag to its corresponding assets
	const assetsByTag = useMemo(() => {
		return tags.reduce((acc: Record<string, { tag: Tag; assets: Asset[] }>, tag) => {
			const tagAssets = assets.filter((asset) => asset.groupIds.some((t) => t === tag.id));
			if (tagAssets.length > 0) {
				acc[tag.id] = { tag, assets: tagAssets };
			}
			return acc;
		}, {});
	}, [tags, assets]);

	// updates selected tags based on whether all assets for a tag are selected or not
	useEffect(() => {
		setSelectedTags((prev) => {
			const newSelectedTags = new Set(prev);

			Object.entries(assetsByTag).forEach(([tagId, data]) => {
				const allSelected = areAllTagAssetsSelected(tagId, data.assets, selectedAssets);

				if (allSelected && !newSelectedTags.has(tagId)) {
					newSelectedTags.add(tagId);
				} else if (!allSelected && newSelectedTags.has(tagId) && !prev.has(tagId)) {
					newSelectedTags.delete(tagId);
				}
			});

			return newSelectedTags;
		});
	}, [selectedAssets, assetsByTag, areAllTagAssetsSelected]);

	// shows the error message based on time, to prevent passing the error state via props
	useEffect(() => {
		if (!assets?.length || !tags?.length) {
			const timer = setTimeout(() => setHasError(true), 45000);
			return () => clearTimeout(timer);
		}
		setHasError(false);
	}, [assets, tags]);

	// filters assets by tag or asset name based on the search query and view mode.
	const filteredAssetsByTag = useMemo(() => {
		if (!searchQuery) return assetsByTag;

		return Object.entries(assetsByTag).reduce((acc: any, [tagId, data]: any) => {
			const tag = data.tag;
			const assets = data.assets;

			if (viewMode === "tags") {
				if (tag.name.toLowerCase().includes(searchQuery)) {
					acc[tagId] = { tag, assets };
				}
			} else {
				const matchingAssets = assets.filter((asset: Asset) => asset.chassis?.toLowerCase().includes(searchQuery));
				if (matchingAssets.length > 0) {
					acc[tagId] = { tag, assets: matchingAssets };
				}
			}

			return acc;
		}, {});
	}, [assetsByTag, searchQuery, viewMode]);

	const calculateTagIndeterminateState = useCallback(
		(tagId: string, tagAssets: Asset[], selectedAssets: Set<string>, selectedTags: Set<string>): boolean => {
			// Don't show indeterminate if tag is fully selected
			if (selectedTags.has(tagId)) return false;

			const selectedCount = tagAssets.filter((asset) => selectedAssets.has(createTagAssetId(tagId, asset.id))).length;

			return selectedCount > 0 && selectedCount < tagAssets.length;
		},
		[],
	);

	const rowData = useMemo(() => {
		if (viewMode === "assets" && searchQuery) {
			const matchingAssets = assets.filter((asset: Asset) => {
				return asset.chassis?.toLowerCase().includes(searchQuery.toLowerCase());
			});

			return [
				{
					tagId: "search-results",
					tagName: "search-results-sidebar",
					assetsCount: matchingAssets.length,
					isExpanded: true,
					isChecked: false,
					isIndeterminate: false,
					assets: matchingAssets,
					isTagSelected: false,
					isAssetSelected: (assetId: string) => {
						// Check if the asset is selected under any tag
						return Array.from(selectedAssets).some((tagAssetId) => {
							const { assetId: selectedAssetId } = parseTagAssetId(tagAssetId);
							return selectedAssetId === assetId;
						});
					},
					onTagSelect: () => {},
					onTagToggle: () => {},
					onAssetSelect: (assetId: string) => selectAsset("search-results", assetId),
				},
			];
		}

		return Object.entries(filteredAssetsByTag).map(([tagId, data]: any) => ({
			tagId: data.tag.id,
			tagName: data.tag.name,
			assetsCount: data.assets.length,
			isExpanded: !!expandedTags[tagId],
			isChecked: selectedTags.has(data.tag.id),
			isIndeterminate: indeterminateTags[tagId],
			assets: data.assets,
			isTagSelected: selectedTags.has(data.tag.id),
			isAssetSelected: (assetId: string) => selectedAssets.has(createTagAssetId(data.tag.id, assetId)),
			onTagSelect: (e: ChangeEvent<HTMLInputElement>) => {
				e.stopPropagation();
				selectTag(data.tag.id);
			},
			onTagToggle: () => toggleTag(tagId),
			onAssetSelect: (assetId: string) => selectAsset(data.tag.id, assetId),
		}));
	}, [filteredAssetsByTag, expandedTags, selectedTags, indeterminateTags, selectedAssets, searchQuery, viewMode, assets]);

	// Modified header indeterminate calculation
	const calculateHeaderIndeterminate = useCallback(() => {
		// Get total number of unique assets that are selected
		const uniqueSelectedAssets = new Set(
			Array.from(selectedAssets).map((tagAssetId) => {
				const { assetId } = parseTagAssetId(tagAssetId);
				return assetId;
			}),
		);

		if (searchQuery) {
			const filteredAssets = rowData[0]?.assets || [];
			if (filteredAssets.length === 0) return false;

			// Count how many of the filtered assets are in our selected set
			const selectedFilteredCount = filteredAssets.filter((asset: { id: string }) => uniqueSelectedAssets.has(asset.id)).length;

			// If there are any selected assets outside our search results,
			// maintain the indeterminate state
			const hasSelectedAssetsOutsideSearch = uniqueSelectedAssets.size > selectedFilteredCount;

			// Return true if:
			// 1. We have partial selection in search results, OR
			// 2. We have any selected assets outside search results
			return (selectedFilteredCount > 0 && selectedFilteredCount < filteredAssets.length) || hasSelectedAssetsOutsideSearch;
		}

		const totalPossibleSelections = Object.values(assetsByTag).reduce((total, { assets }) => total + assets.length, 0);

		// Keep indeterminate state when:
		// 1. We have some selections but not all
		// 2. We have any selected tags
		// 3. We have all assets selected but some are through tag selection
		return uniqueSelectedAssets.size > 0 && (uniqueSelectedAssets.size < totalPossibleSelections || selectedTags.size > 0);
	}, [searchQuery, selectedAssets, assetsByTag, selectedTags, rowData]);

	// Update header indeterminate state
	useEffect(() => {
		setHeaderIndeterminate(calculateHeaderIndeterminate());
	}, [calculateHeaderIndeterminate, selectedAssets, selectedTags]);

	// Modified selectTag to use batch updates
	const selectTag = useCallback(
		(tagId: string) => {
			const relatedAssets = filteredAssetsByTag[tagId]?.assets || [];
			const isSelected = selectedTags.has(tagId);
			const isIndeterminate = indeterminateTags[tagId];

			const updatedTags = new Set(selectedTags);
			let updatedAssets = new Set(selectedAssets);

			if (isSelected || isIndeterminate) {
				// Deselect tag and its assets
				updatedTags.delete(tagId);
				updatedAssets = new Set(
					Array.from(updatedAssets).filter((tagAssetId) => {
						const { tagId: currentTagId } = parseTagAssetId(tagAssetId);
						return currentTagId !== tagId;
					}),
				);
			} else {
				// Select tag and all its assets
				updatedTags.add(tagId);
				relatedAssets.forEach((asset: { id: string }) => {
					updatedAssets.add(createTagAssetId(tagId, asset.id));
				});
			}

			setSelectedTags(updatedTags);
			setSelectedAssets(updatedAssets);

			// Update indeterminate states
			const newIndeterminateTags: Record<string, boolean> = {};
			Object.entries(assetsByTag).forEach(([currentTagId, data]) => {
				const isIndeterminate = calculateTagIndeterminateState(currentTagId, data.assets, updatedAssets, updatedTags);
				if (isIndeterminate) {
					newIndeterminateTags[currentTagId] = true;
				}
			});
			setIndeterminateTags(newIndeterminateTags);

			if (isInitialized.current) {
				const uniqueAssetIds = new Set<string>();
				updatedAssets.forEach((tagAssetId) => {
					const { assetId } = parseTagAssetId(tagAssetId);
					uniqueAssetIds.add(assetId);
				});

				onSelectionChange({
					groups: Array.from(updatedTags),
					items: Array.from(uniqueAssetIds),
				});
			}
		},
		[filteredAssetsByTag, selectedTags, selectedAssets, indeterminateTags, assetsByTag, calculateTagIndeterminateState],
	);

	// Modified selectAsset to use batch updates
	const selectAsset = useCallback(
		(tagId: string, assetId: string) => {
			let actualTagId = tagId;

			// If selecting from search results, find the actual tag
			if (tagId === "search-results") {
				const realTagId = findAssetTag(assetId);
				if (realTagId) {
					actualTagId = realTagId;
				}
			}

			const tagAssetId = createTagAssetId(actualTagId, assetId);

			setSelectedAssets((prev) => {
				const updatedAssets = new Set(prev);
				if (updatedAssets.has(tagAssetId)) {
					updatedAssets.delete(tagAssetId);
				} else {
					updatedAssets.add(tagAssetId);
				}

				// Recalculate indeterminate states immediately
				const newIndeterminateTags: Record<string, boolean> = {};
				Object.entries(assetsByTag).forEach(([currentTagId, data]) => {
					const isIndeterminate = calculateTagIndeterminateState(currentTagId, data.assets, updatedAssets, selectedTags);
					if (isIndeterminate) {
						newIndeterminateTags[currentTagId] = true;
					}
				});
				setIndeterminateTags(newIndeterminateTags);

				// Only notify parent if initialized
				if (isInitialized.current) {
					const uniqueAssetIds = new Set<string>();
					updatedAssets.forEach((tagAssetId) => {
						const { assetId } = parseTagAssetId(tagAssetId);
						uniqueAssetIds.add(assetId);
					});

					// sends back the state changed
					onSelectionChange({
						groups: Array.from(selectedTags),
						items: Array.from(uniqueAssetIds),
					});
				}

				return updatedAssets;
			});
		},
		[selectedTags, findAssetTag, assetsByTag, calculateTagIndeterminateState],
	);

	const performSearch = useMemo(
		() =>
			debounce((query: string) => {
				setSearchQuery(query.toLowerCase());
			}, 300),
		[],
	);

	const handleSearchChange = useCallback(
		(value: string) => {
			// Store current indeterminate state before search
			const currentIndeterminate = calculateHeaderIndeterminate();

			if (!value) {
				// Recalculate indeterminate states for all tags
				const newIndeterminateTags: Record<string, boolean> = {};
				Object.entries(assetsByTag).forEach(([tagId, data]) => {
					const isIndeterminate = calculateTagIndeterminateState(tagId, data.assets, selectedAssets, selectedTags);
					if (isIndeterminate) {
						newIndeterminateTags[tagId] = true;
					}
				});
				setIndeterminateTags(newIndeterminateTags);
			}

			// Perform search (assets, tags, etc)
			performSearch(value);

			// Preserve indeterminate state if it was set
			if (currentIndeterminate) {
				setHeaderIndeterminate(true);
			}
		},
		[performSearch, assetsByTag, selectedAssets, selectedTags, calculateTagIndeterminateState, calculateHeaderIndeterminate],
	);

	// toggle the tag to be expanded or not
	const toggleTag = useCallback(
		(tagId: string) => {
			setExpandedTags((prev) => {
				const newState = { ...prev, [tagId]: !prev[tagId] };
				return newState;
			});
		},
		[filteredAssetsByTag],
	);

	// Modified select/deselect all to use batch updates
	const selectAllTags = () => {
		if (searchQuery) {
			const filteredAssets = rowData[0]?.assets || [];

			setSelectedAssets((prev) => {
				const newSelectedAssets = new Set(prev);

				filteredAssets.forEach((asset: { id: string }) => {
					const realTagId = findAssetTag(asset.id);
					if (realTagId) {
						newSelectedAssets.add(createTagAssetId(realTagId, asset.id));
					}
				});

				// Set to indeterminate after selection
				setHeaderIndeterminate(true);

				if (isInitialized.current) {
					const uniqueAssetIds = new Set(Array.from(newSelectedAssets).map((tagAssetId) => parseTagAssetId(tagAssetId).assetId));

					onSelectionChange({
						groups: Array.from(selectedTags),
						items: Array.from(uniqueAssetIds),
					});
				}

				return newSelectedAssets;
			});

			return;
		}

		const allTags = Object.keys(assetsByTag);
		const allTagAssetPairs = allTags.flatMap((tagId) => assetsByTag[tagId].assets.map((asset) => createTagAssetId(tagId, asset.id)));

		setSelectedTags(new Set(allTags));
		setSelectedAssets(new Set(allTagAssetPairs));

		// Set to indeterminate after selection
		setHeaderIndeterminate(true);

		if (batchUpdateRef.current) {
			clearTimeout(batchUpdateRef.current);
		}

		batchUpdateRef.current = setTimeout(() => {
			if (isInitialized.current) {
				onSelectionChange({
					groups: allTags,
					items: Array.from(new Set(allTagAssetPairs.map((tagAssetId) => parseTagAssetId(tagAssetId).assetId))),
				});
			}
		}, 0);
	};

	// deselect all tags or assets that are currently selected/showing in the sidebar
	const deselectAll = () => {
		if (searchQuery) {
			const filteredAssets = rowData[0]?.assets || [];

			// Clear all selected tags when deselecting during search
			setSelectedTags(new Set());

			setSelectedAssets((prev) => {
				const newSelectedAssets = new Set(prev);

				// Remove all filtered assets from selection
				filteredAssets.forEach((asset: { id: string }) => {
					Array.from(newSelectedAssets).forEach((tagAssetId) => {
						const { assetId: selectedAssetId } = parseTagAssetId(tagAssetId);
						if (selectedAssetId === asset.id) {
							newSelectedAssets.delete(tagAssetId);
						}
					});
				});

				// Reset to unchecked after deselection
				setHeaderIndeterminate(false);

				if (isInitialized.current) {
					const uniqueAssetIds = new Set(Array.from(newSelectedAssets).map((tagAssetId) => parseTagAssetId(tagAssetId).assetId));

					onSelectionChange({
						groups: [], // Clear groups since we're deselecting all tags
						items: Array.from(uniqueAssetIds),
					});
				}

				return newSelectedAssets;
			});

			return;
		}

		setSelectedTags(new Set());
		setSelectedAssets(new Set());

		// Reset to unchecked after deselection
		setHeaderIndeterminate(false);

		if (batchUpdateRef.current) {
			clearTimeout(batchUpdateRef.current);
		}

		batchUpdateRef.current = setTimeout(() => {
			if (isInitialized.current) {
				onSelectionChange({
					groups: [],
					items: [],
				});
			}
		}, 0);
	};

	// initialize selected tags and assets on first render using tag-scoped asset IDs
	useEffect(() => {
		if (!initialized.current) {
			const allTags = Object.keys(assetsByTag);
			const allTagAssetPairs = allTags.flatMap((tagId) => assetsByTag[tagId].assets.map((asset) => createTagAssetId(tagId, asset.id)));
			setSelectedTags(new Set(allTags));
			setSelectedAssets(new Set(allTagAssetPairs));
			initialized.current = true;
		}
	}, [assetsByTag]);

	// countings of truck and buses in the sidebar
	useEffect(() => {
		let filteredAssets = assets.filter((item) => !item.groupIds.some((group) => group === "empty"));

		if (searchQuery) {
			filteredAssets = assets.filter((asset: Asset) => {
				return asset.chassis?.toLowerCase().includes(searchQuery.toLowerCase());
			});
		}

		let truckCountTemp = 0;
		let busCountTemp = 0;

		filteredAssets.forEach((vehicle) => {
			if (vehicle.type === "truck") {
				truckCountTemp += 1;
			} else if (vehicle.type === "bus") {
				busCountTemp += 1;
			}
		});

		setTruckCount(truckCountTemp);
		setBusCount(busCountTemp);
	}, [searchQuery, viewMode, assets]);

	const shouldShowHeader = useMemo(() => {
		if (!searchQuery) return true; // Show when search is empty
		return rowData.length > 0 && rowData[0].assets.length > 0; // Show when there are results
	}, [searchQuery, rowData]);

	const options5 = [
		{
			id: "1",
			label: "Veículos",
			short: "assets",
			icon: <span className="rioglyph text-size-16 rioglyph-status-driving text-color-primary" />,
			selected: viewMode == "assets",
		},
		{
			id: "2",
			label: "Grupos",
			short: "tags",
			icon: <span className="rioglyph text-size-16 rioglyph-tag text-color-primary" />,
			selected: viewMode == "tags",
		},
		// {
		// 	id: "3",
		// 	label: "Placa",
		// 	short: "license",
		// 	icon: <span className="rioglyph text-size-16 rioglyph-detail-view text-color-primary" />,
		// 	selected: viewMode == "license",
		// },
	];

	// Modified rowData to handle tag-scoped asset selection

	const isCheckedAll = useMemo(() => {
		if (searchQuery) {
			const filteredAssets = rowData[0]?.assets || [];
			return (
				filteredAssets.length > 0 &&
				filteredAssets.every((asset: { id: string }) => {
					// Check if the asset is selected under any tag
					return Array.from(selectedAssets).some((tagAssetId) => {
						const { assetId: selectedAssetId } = parseTagAssetId(tagAssetId);
						return selectedAssetId === asset.id;
					});
				})
			);
		}

		const hasSelectedTags = selectedTags.size > 0;
		const totalAssets = Object.values(assetsByTag).reduce((total, { assets }) => total + assets.length, 0);
		const allAssetsSelected = selectedAssets.size === totalAssets;

		return !hasSelectedTags && allAssetsSelected && totalAssets > 0;
	}, [assetsByTag, selectedAssets, selectedTags, searchQuery, rowData]);

	const getSearchLabel = (searchType: string) => {
		switch (searchType) {
			case "assets":
				return "Buscar veículos";
			case "tags":
				return "Buscar grupos";
			// case "license":
			// 	return "Buscar placas";
		}
	};

	if (!assets?.length || !tags?.length) {
		if (hasError) {
			return (
				<div className="height-100pct display-flex align-items-center justify-content-center">
					<span>Something went wrong. Please try again.</span>
				</div>
			);
		}
		return (
			<div className="height-100pct display-flex align-items-center justify-content-center">
				<Spinner />
			</div>
		);
	}

	return (
		<div className="Tree" style={{ height: "100%" }}>
			<div className="TreeHeader">
				<div className="TreeSearch width-100pct">
					<div className="input-group width-100pct">
						<div className="input-group width-100pct">
							<span className="input-group-addon">
								<span className="rioglyph rioglyph-search" aria-hidden="true" />
							</span>{" "}
							<ClearableInput placeholder={getSearchLabel(viewMode)} onChange={handleSearchChange} />
							<SimpleTooltip content={"Tipo de busca"}>
								<span className="input-group-btn">
									<Select
										pullRight={true}
										showSelectedItemIcon={true}
										dropdownClassName="width-auto"
										onChange={(item) => setViewMode(item?.short!)}
										options={options5}
										placeholder="Tipo de busca"
									/>
								</span>
							</SimpleTooltip>
						</div>
					</div>
				</div>
				{shouldShowHeader && ( // Show header when search is empty OR there are results
					<div className="TreeHead display-flex gap-5 padding-15">
						<div className="border border-right-only hidden-empty padding-right-10 margin-right-2">
							<div className="TreeSelectAll display-flex align-items-center">
								<label className="checkbox margin-top--1" id="checkbox-all">
									<Checkbox
										indeterminate={headerIndeterminate}
										checked={headerIndeterminate}
										onChange={() => {
											if (headerIndeterminate) {
												deselectAll();
											} else {
												selectAllTags();
											}
										}}
									/>
								</label>
							</div>
						</div>
						<div className="display-flex justify-content-between align-items-start width-100pct">
							<div className="TreeSummary display-flex flex-wrap align-items-center gap-10 padding-left-3">
								{truckCount >= 1 && (
									<div className="TypeCounter display-flex align-items-center user-select-none margin-right-2 cursor-pointer">
										<span className="rioglyph rioglyph-truck text-size-16 margin-right-2"></span>
										<span className="TreeLabelCount label label-condensed label-muted label-filled">{truckCount}</span>
									</div>
								)}
								{busCount >= 1 && (
									<div className="TypeCounter display-flex align-items-center user-select-none margin-right-2 cursor-pointer">
										<span className="rioglyph rioglyph-bus text-size-16 margin-right-2"></span>
										<span className="TreeLabelCount label label-condensed label-muted label-filled">{busCount}</span>
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
			<div
				className="TreeRoot user-select-none overflow-hidden"
				style={{
					height: `calc(100% - ${viewMode === "assets" && searchQuery ? "85px" : "100px"})`,
					transform: viewMode !== "tags" && searchQuery ? "translateY(-41px)" : undefined,
				}}
			>
				<div style={{ height: "100%" }}>
					<Virtuoso style={{ height: "100%" }} totalCount={rowData.length} itemContent={(index) => <TagRow {...rowData[index]} />} />
				</div>
			</div>
		</div>
	);
};

export default memo(CustomSidebar);
