import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Asset } from "../../../../models/asset"

import ToScheduleDialog from "../dialogs/toScheduleDialog";

interface IToScheduleProps {
    asset: Asset;
    hasMetrics?: boolean;
}
const ToSchedule = ({asset, hasMetrics}: IToScheduleProps) => {
    
    const navigate = useNavigate();
    const [showScheduleModal, setShowScheduleModal] = useState(false);

    return (
        <>
            <button 
                type="button" 
                disabled={!!asset.nextCheckup?.date || !hasMetrics || asset.chassis === '-'}
                onClick={() => navigate(`/dealership/new-appointment?asset=${asset.id}`)}
                // Hide the button if the asset has no volksTotalPlan (back after the volkstotal info is available)
                // onClick={() => {
                //     if(asset?.volksTotalPlan) navigate(`/dealership/new-appointment?asset=${asset.id}`)
                //     else setShowScheduleModal(true)
                // }}
            >
                <span className={`rioglyph rioglyph-calendar ${!asset.nextCheckup?.date && hasMetrics ? 'active' : ''}`} />
            </button>

            <ToScheduleDialog
                asset={asset}
                isOpen={showScheduleModal}
                onClose={() => setShowScheduleModal(false)}
            />
        </>
    )
}

export default ToSchedule;