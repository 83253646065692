import { Button, Dialog, Divider } from "@rio-cloud/rio-uikit";

interface IConfirmDialogProps {
    show: boolean;
    title: string;
    body: string;
    onClose: () => void;
    onConfirm: () => void;
}

const ConfirmDialog = ({ show, title, body, onClose, onConfirm }: IConfirmDialogProps) => {
    return (
        <Dialog
            show={show}
            title={(
                <>
                    <span className="rioglyph rioglyph-remove-circle margin-right-10 text-color-danger" />
                    <span>{title}</span>
                </>
            )}
            footer={
                <>
                    <div className='display-flex justify-content-end'>
                        <Button bsStyle='default' onClick={onClose} className="margin-right-10">
                            Cancelar
                        </Button>
                        <Button bsStyle='primary' onClick={onConfirm}>
                            Confirmar
                        </Button>
                    </div>
                </>
            }
            body={body}
            bsSize={Dialog.SIZE_XS}
            onClose={onClose}
        />
    );
}

export default ConfirmDialog;