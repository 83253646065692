import { useEffect, useState } from "react";
import { BigNumberFilterEnum } from "../../../../enums/bigNumberFilter";
import {
  fetchAttentionScores,
  fetchAvailableToSchedule,
  fetchInProgress,
  fetchPastDueMaintenance,
} from "../../../../api/fetchBigNumbers";
import { fetchAssets } from "../../../../api/fetchAssets";
import { AssetSummary } from "../../../../models/asset";

export interface IBigNumberProps {
  assetIds: string[];
  setAssetIds: (e: string[]) => void;
}

export const BigNumbers = ({ assetIds, setAssetIds }: IBigNumberProps) => {
  const [initialAssetIds, setInitialAssetIds] = useState<AssetSummary[]>([]);

  const [previousStateFlag, setPreviousStateFlag] = useState<boolean>(false);
  const [previousAssetIds, setPreviousAssetIds] = useState<string[]>([]);
  const [
    previousCriticalAtentionScoresAssetIds,
    setPreviousCriticalAtentionScoresAssetIds,
  ] = useState<string[]>([]);
  const [
    previousPrimaryAtentionScoresAssetIds,
    setPreviousPrimaryAtentionScoresAssetIds,
  ] = useState<string[]>([]);
  const [previousInProgressAssetIds, setPreviousInProgressAssetIds] = useState<
    string[]
  >([]);
  const [previousDueMaintenanceAssetIds, setPreviousDueMaintenanceAssetIds] =
    useState<string[]>([]);
  const [previousToScheduleAssetIds, setPreviousToScheduleAssetIds] = useState<
    string[]
  >([]);

  const [bigNumberFilter, setBigNumberFilter] = useState<
    BigNumberFilterEnum | undefined
  >();

  const [criticalAttencionScoresLoading, setCriticalAttentionScoresLoading] =
    useState<boolean>(true);
  const [criticalAttencionScoresAssetIds, setCriticalAttentionScoresAssetIds] =
    useState<string[]>([]);

  const [primaryAttencionScoresLoading, setPrimaryAttentionScoresLoading] =
    useState<boolean>(true);
  const [primaryAttencionScoresAssetIds, setPrimaryAttentionScoresAssetIds] =
    useState<string[]>([]);

  const [inProgressLoading, setInProgressLoading] = useState<boolean>(true);
  const [inProgressAssetIds, setInProgressAssetIds] = useState<string[]>([]);

  const [dueMaintenanceLoading, setDueMaintenanceLoading] =
    useState<boolean>(true);
  const [dueMaintenanceAssetIds, setDueMaintenanceAssetIds] = useState<
    string[]
  >([]);

  const [toScheduleLoading, setToScheduleLoading] = useState<boolean>(true);
  const [toScheduleAssetIds, setToScheduleAssetIds] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (bigNumberFilter !== BigNumberFilterEnum.CRITICAL)
        setCriticalAttentionScoresLoading(true);
      if (bigNumberFilter !== BigNumberFilterEnum.PRIMARY)
        setPrimaryAttentionScoresLoading(true);
      if (bigNumberFilter !== BigNumberFilterEnum.MAINTENANCE)
        setInProgressLoading(true);
      if (bigNumberFilter !== BigNumberFilterEnum.MAINTENANCE_DUE)
        setDueMaintenanceLoading(false);
      if (bigNumberFilter !== BigNumberFilterEnum.SCHEDULING)
        setToScheduleLoading(true);

      const assets = await fetchAssets();
      setInitialAssetIds(assets.filter((asset) => assetIds.includes(asset.id)));

      const fetchAttentionScoresPromise = fetchAttentionScores(assetIds);
      const fetchInProgressPromise = fetchInProgress(assetIds, assets);
      const fetchPastDueMaintenancePromise = fetchPastDueMaintenance(
        assetIds,
        assets
      );
      const fetchAvailableToSchedulePromise = fetchAvailableToSchedule(
        assetIds,
        assets
      );

      fetchAttentionScoresPromise.then(([primaryScores, criticalScores]) => {
        setCriticalAttentionScoresAssetIds(criticalScores[1]);
        setCriticalAttentionScoresLoading(false);

        setPrimaryAttentionScoresAssetIds(primaryScores[1]);
        setPrimaryAttentionScoresLoading(false);
      });

      fetchInProgressPromise.then(([, inProgressAssetIds]) => {
        setInProgressAssetIds(inProgressAssetIds);
        setInProgressLoading(false);
      });

      fetchPastDueMaintenancePromise.then(([, pastDueMaintenanceAssetIds]) => {
        setDueMaintenanceAssetIds(pastDueMaintenanceAssetIds);
        setDueMaintenanceLoading(false);
      });

      fetchAvailableToSchedulePromise.then(
        ([, availableToScheduleAssetIds]) => {
          setToScheduleAssetIds(availableToScheduleAssetIds);
          setToScheduleLoading(false);
        }
      );
    };

    if (assetIds.length && !previousStateFlag) fetchData();

    setPreviousStateFlag(false);
  }, [assetIds]);

  const setPreviousData = () => {
    setPreviousAssetIds(initialAssetIds.map((el) => el.id));
    setPreviousCriticalAtentionScoresAssetIds(criticalAttencionScoresAssetIds);
    setPreviousPrimaryAtentionScoresAssetIds(primaryAttencionScoresAssetIds);
    setPreviousInProgressAssetIds(inProgressAssetIds);
    setPreviousDueMaintenanceAssetIds(dueMaintenanceAssetIds);
    setPreviousToScheduleAssetIds(toScheduleAssetIds);
  };

  const setToPreviousData = () => {
    setPreviousStateFlag(true);
    setAssetIds(previousAssetIds);
    setCriticalAttentionScoresAssetIds(previousCriticalAtentionScoresAssetIds);
    setPrimaryAttentionScoresAssetIds(previousPrimaryAtentionScoresAssetIds);
    setInProgressAssetIds(previousInProgressAssetIds);
    setDueMaintenanceAssetIds(previousDueMaintenanceAssetIds);
    setToScheduleAssetIds(previousToScheduleAssetIds);
  };

  return (
    <div className="overflow-x-auto padding-20 w-full flex justify-center">
      <div className="flex gap-25">
        {[
          {
            title: "VEÍCULOS EM ATENÇÃO CRÍTICA",
            value: criticalAttencionScoresAssetIds.length,
            color: "danger",
            choosed: bigNumberFilter === BigNumberFilterEnum.CRITICAL,
            isLoading: criticalAttencionScoresLoading,
            enableToFilter: true,
            disabled: false,
            onFilter: () => {
              if (
                bigNumberFilter !== BigNumberFilterEnum.CRITICAL &&
                bigNumberFilter !== undefined
              )
                return;
              if (bigNumberFilter !== BigNumberFilterEnum.CRITICAL)
                [
                  setBigNumberFilter(BigNumberFilterEnum.CRITICAL),
                  setPreviousData(),
                  setAssetIds(criticalAttencionScoresAssetIds),
                ];
              else setToPreviousData(), setBigNumberFilter(undefined);
            },
          },
          {
            title: "VEÍCULOS EM ATENÇÃO BÁSICA",
            value: primaryAttencionScoresAssetIds.length,
            color: "warning",
            choosed: bigNumberFilter === BigNumberFilterEnum.PRIMARY,
            isLoading: primaryAttencionScoresLoading,
            enableToFilter: true,
            disabled: false,
            onFilter: () => {
              if (
                bigNumberFilter !== BigNumberFilterEnum.PRIMARY &&
                bigNumberFilter !== undefined
              )
                return;
              if (bigNumberFilter !== BigNumberFilterEnum.PRIMARY)
                [
                  setBigNumberFilter(BigNumberFilterEnum.PRIMARY),
                  setPreviousData(),
                  setAssetIds(primaryAttencionScoresAssetIds),
                ];
              else setToPreviousData(), setBigNumberFilter(undefined);
            },
          },
          {
            title: "MANUTENÇÕES INICIADAS",
            value: inProgressAssetIds.length,
            color: "highlight",
            choosed: bigNumberFilter === BigNumberFilterEnum.MAINTENANCE,
            isLoading: inProgressLoading,
            enableToFilter: true,
            disabled: false,
            onFilter: () => {
              if (
                bigNumberFilter !== BigNumberFilterEnum.MAINTENANCE &&
                bigNumberFilter !== undefined
              )
                return;
              if (bigNumberFilter !== BigNumberFilterEnum.MAINTENANCE)
                [
                  setBigNumberFilter(BigNumberFilterEnum.MAINTENANCE),
                  setPreviousData(),
                  setAssetIds(inProgressAssetIds),
                ];
              else setToPreviousData(), setBigNumberFilter(undefined);
            },
          },
          // {
          //   title: "VEÍCULOS COM MANUTENÇÃO VENCIDA",
          //   value: 0, // dueMaintenanceAssetIds.length,
          //   color: "danger",
          //   choosed: bigNumberFilter === BigNumberFilterEnum.MAINTENANCE_DUE,
          //   isLoading: dueMaintenanceLoading,
          //   enableToFilter: true,
          //   disabled: true,
          //   onFilter: () => {
          //     // if (bigNumberFilter !== BigNumberFilterEnum.MAINTENANCE_DUE && bigNumberFilter !== undefined) return;
          //     // if (bigNumberFilter !== BigNumberFilterEnum.MAINTENANCE_DUE) [setBigNumberFilter(BigNumberFilterEnum.MAINTENANCE_DUE), setPreviousData(), setAssetIds(dueMaintenanceAssetIds)];
          //     // else setToPreviousData(), setBigNumberFilter(undefined);
          //   },
          // },
          // {
          //   title: "VEÍCULOS EM SOS",
          //   value: 0,
          //   color: "highlight",
          //   choosed: bigNumberFilter === BigNumberFilterEnum.SOS,
          //   isLoading: false,
          //   enableToFilter: false,
          //   disabled: true,
          //   onFilter: () => {
          //     // if (bigNumberFilter === BigNumberFilterEnum.SOS) {
          //     //     setFilteredAssets(assets);
          //     //     setBigNumberFilter(undefined);
          //     // } else {
          //     //     setBigNumberFilter(BigNumberFilterEnum.SOS);
          //     //     setFilteredAssets([]);
          //     // }
          //   },
          // },
          {
            title: "VEÍCULOS COM PRÓXIMAS REVISÕES",
            value: toScheduleAssetIds.length,
            color: "highlight",
            choosed: bigNumberFilter === BigNumberFilterEnum.SCHEDULING,
            isLoading: toScheduleLoading,
            enableToFilter: true,
            disabled: false,
            onFilter: () => {
              if (
                bigNumberFilter !== BigNumberFilterEnum.SCHEDULING &&
                bigNumberFilter !== undefined
              )
                return;
              if (bigNumberFilter !== BigNumberFilterEnum.SCHEDULING)
                [
                  setBigNumberFilter(BigNumberFilterEnum.SCHEDULING),
                  setPreviousData(),
                  setAssetIds(toScheduleAssetIds),
                ];
              else setToPreviousData(), setBigNumberFilter(undefined);
            },
          },
        ].map((bigNumber, index) =>
          bigNumber.isLoading ? (
            <div
              key={index}
              style={{ width: "280px", height: "120px", paddingTop: "35px" }}
              className={`items-center justify-center shadow-smooth rounded ${
                bigNumber.disabled || (bigNumberFilter && !bigNumber.choosed)
                  ? "bg-lighter"
                  : `bg-white`
              }`}
            >
              <div
                className={`padding-left-15  flex flex-col animate-pulse border-color-${
                  bigNumber.color
                } ${
                  bigNumber.disabled || bigNumber.choosed === true
                    ? "border-l-8"
                    : "border-l-4"
                } `}
              >
                <div className="grid grid-cols-3">
                  <div className="col-span-2">
                    <div className="w-3/4 h-8 bg-gray-300 mb-2 rounded"></div>
                    <div className="w-1/2 h-8 bg-gray-300 rounded"></div>
                  </div>
                  <div className="w-3/4 h-16 bg-gray-300 mb-2 rounded"></div>
                </div>
              </div>
            </div>
          ) : (
            <div
              key={index}
              style={{ width: "280px", height: "120px" }}
              className={`flex items-center justify-center shadow-smooth rounded ${
                bigNumber.disabled || (bigNumberFilter && !bigNumber.choosed)
                  ? "bg-lighter"
                  : `bg-white ${[4].includes(index) ? "" : "cursor-pointer"}`
              }`}
              onClick={bigNumber.onFilter}
            >
              <div
                className="flex w-full justify-between"
                style={{ height: "80px" }}
              >
                <div
                  className={`border-color-${bigNumber.color} ${
                    bigNumber.disabled || bigNumber.choosed === true
                      ? "border-l-8"
                      : "border-l-4"
                  } text-size-h5 padding-left-25 text-color-dark flex items-center`}
                >
                  {bigNumber.title}
                </div>
                <div className="text-size-h2 text-bold padding-right-25 text-color-highlight flex items-center">
                  {bigNumber.value}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};
