import { useEffect, useState } from 'react';
import RadioButton from '@rio-cloud/rio-uikit/RadioButton';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import Map from '@rio-cloud/rio-uikit/Map';
import Marker from '@rio-cloud/rio-uikit/Marker';
import { Select } from '@rio-cloud/rio-uikit';
import SingleMapMarker from '@rio-cloud/rio-uikit/SingleMapMarker';

import { cities, getDistanceBetweenTwoLocations, states } from '../../../../utils/locationUtils';
import { fetchDealershipsByGeolocation, fetchDealershipsByStateAndCity, fetchFavoritesDealerships } from '../../../../api/fetchDealerships';
import { favoriteDealership, removeFavoriteDealership } from '../../../../api/favoriteDealership';
import { Dealership } from '../../../../models/dealership';
import { Asset } from '../../../../models/asset';
import { getAddressByGeoLocation, getGeoLocationByAddress } from '../../../../services/assetService';

import '../styles.scss';

interface IDealershipProps {
    asset?: Asset;
    setAsset: (asset: Asset) => void;
    setStep: (step: number) => void;
    dealership?: Dealership;
    setDealership: (dealership: Dealership) => void;
}

const DealershipForm = ({ setStep, asset, setAsset, dealership, setDealership }: IDealershipProps) => {

    const [activeTab, setActiveTab] = useState<"favorites" | "newDealership">("favorites");
    const [showSearchConcessionaria, setShowSearchConcessionaria] = useState<boolean>(false);
    const [enableLocation, setEnableLocation] = useState<boolean>(false);

    const [favorites, setFavorites] = useState<Dealership[]>([]);
    const [dealerships, setDealerships] = useState<Dealership[]>([]);
    const [searchDealershipTerm, setSearchDealershipTerm] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const [addressForm, setAddressForm] = useState<Record<'address' | 'neighborhood' | 'state' | 'city', string>>({
        address: dealership?.address ?? '',
        neighborhood: dealership?.neighborhood ?? '',
        state: dealership?.state ?? '',
        city: dealership?.city ?? '',
    });


    useEffect(() => {
        const fetchData = async () => {
            const favorites = await fetchFavoritesDealerships();
            setFavorites(favorites);
        }

        fetchData();

        if (asset?.geoLocation?.lat && asset?.geoLocation?.lng) {
            updateAddress();
        } else {
            setEnableLocation(true);
        }

    }, []);

    const getFavoritesDealerships = async () => {
        const favorites = await fetchFavoritesDealerships();
        setFavorites(favorites);
    }

    const getDealershipsByGeolocation = async () => {
        const dealerships = await fetchDealershipsByGeolocation(asset?.geoLocation?.lat || 0, asset?.geoLocation?.lng || 0);
        setDealerships(dealerships);
    }

    const getDealershipsByStateAndCity = async () => {
        const dealerships = await fetchDealershipsByStateAndCity(addressForm.state.split('_')[1], addressForm.city);
        setDealerships(dealerships);
    }

    const updateGeoLocation = async (city: string) => {
        const geoLocation = await getGeoLocationByAddress(`${addressForm.address}, ${addressForm.neighborhood}, ${city}, ${addressForm.state.split('_')[1]}`);
        if (asset?.id) {
            setAsset({
                ...asset,
                geoLocation
            });
        }
    }

    const updateAddress = async () => {
        const address = await getAddressByGeoLocation(asset?.geoLocation?.lat, asset?.geoLocation?.lng);
        setAddressForm({
            address: address.address,
            neighborhood: address.neighborhood,
            city: address.city,
            state: address.state
        });
        if(asset?.geoLocation?.lat && asset?.geoLocation?.lng) await getDealershipsByGeolocation();
        else await getDealershipsByStateAndCity();
    }

    return (
        <div className="flex chooseConcessionaria h-[85vh]">
            <div className="w-1/3 overflow-auto p-10 padding-right-20 h-[85vh] pb-[200px]">
                <h2 className="text-size-h2 text-color-black font-semibold text-uppercase">
                    2. ESCOLHA SUA CONCESSIONÁRIA
                </h2>
                <p className="text-color-status-resting text-size-16 padding-top-10">
                    Escolha sua concessionária favorita ou selecione outra da
                    lista para realizar os serviços.
                </p>

                <div>Localização do veículo</div>
                <div className='padding-x-10 padding-y-20'>
                    <Checkbox
                        checked={enableLocation}
                        onChange={() => {
                            setEnableLocation(!enableLocation)
                            setAddressForm({ address: '', neighborhood: '', state: '', city: '' });
                            setShowSearchConcessionaria(false);
                        }}
                    >
                        Inserir local manualmente
                    </Checkbox>
                </div>

                <div>Geolocalização</div>
                <div className="flex justify-between gap-6">
                    <div className="form-group w-1/2">
                        <label htmlFor="lat">Latitude:</label>
                        <input
                            id="lat"
                            placeholder="Latitude"
                            value={asset?.geoLocation?.lat || '-'}
                            className="form-control"
                            disabled
                        />
                    </div>

                    <div className="form-group w-1/2">
                        <label htmlFor="lng">Longitude:</label>
                        <input
                            id="lng"
                            placeholder="Longitude"
                            value={asset?.geoLocation?.lng || '-'}
                            className="form-control"
                            disabled
                        />
                    </div>
                </div>

                <div>
                    <div>Endereço</div>

                    {[
                        { label: 'Local', value: 'address' as const },
                        { label: 'Bairro', value: 'neighborhood' as const },
                    ].map((item, index) => (
                        <div key={index} className="form-group">
                            <label htmlFor={item.value}>{item.label}:</label>
                            <input
                                id={item.value}
                                placeholder={item.label}
                                onChange={(e: any) => {
                                    setAddressForm({ ...addressForm, [item.value]: e.target.value });
                                    setShowSearchConcessionaria(false);
                                }}
                                value={addressForm[item.value]}
                                className="form-control"
                                disabled={!enableLocation}
                            />
                        </div>
                    ))}
                    
                    {asset?.geoLocation?.lat && asset?.geoLocation?.lng && !enableLocation ?
                        <>
                            <div className="form-group">
                                <label htmlFor='state'>Estado:</label>
                                <input
                                    id='state'
                                    placeholder='Estado'
                                    value={addressForm['state']}
                                    className="form-control"
                                    disabled
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor='city'>Cidade:</label>
                                <input
                                    id='city'
                                    placeholder='Cidade'
                                    value={addressForm['city']}
                                    className="form-control"
                                    disabled
                                />
                            </div>
                        </>
                        :
                        <>
                            <div className="form-group select-group">
                                <label htmlFor='state'>Estado:</label>
                                <Select
                                    id="state"
                                    options={states
                                        .map((state) => ({ id: `${state.id}_${state.state}`, label: state.state, selected: `${state.id}_${state.state}` === addressForm.state }))}
                                    onChange={(selected) => {
                                        setAddressForm({ ...addressForm, state: selected?.id! });
                                        setShowSearchConcessionaria(false);
                                    }}
                                    disabled={!enableLocation}
                                />
                            </div>
                            
                            <div className="form-group select-group">
                                <label htmlFor='city'>Cidade:</label>
                                <Select
                                    id="city"
                                    disabled={!addressForm.state || !enableLocation}
                                    useFilter
                                    options={cities
                                        .filter((city) => city.state === addressForm.state.split('_')[0])
                                        .map((city) => ({ id: city.name, label: city.name, selected: city.name === addressForm.city }))}
                                    onChange={(selected) => {
                                        setAddressForm({ ...addressForm, city: selected?.id! });
                                        setShowSearchConcessionaria(false);
                                        updateGeoLocation(selected?.id!);
                                    }}
                                />
                            </div>
                        </>
                    }
                </div>

                <div className="flex border-b mb-10 mt-10">
                    <button
                        onClick={() => setActiveTab("favorites")}
                        className={`px-4 py-2 font-medium ${activeTab === "favorites"
                            ? "text-color-primary border-b-2 border-color-primary"
                            : "text-gray-600 hover-text-color-primary"
                            }`}
                    >
                        Favoritas
                    </button>
                    <button
                        onClick={() => setActiveTab("newDealership")}
                        className={`px-4 py-2 font-medium ${activeTab === "newDealership"
                            ? "text-color-primary border-b-2 border-color-primary"
                            : "text-gray-600 hover-text-color-primary"
                            }`}
                    >
                        Nova concessionária
                    </button>
                </div>

                {activeTab === "favorites" ? (
                    <ul className="flex flex-column gap-6">
                        {favorites.map((favoriteDealership, index) => (
                            <li
                                key={index}
                                className={`flex items-center justify-between border card-concessionaria ${dealership?.id === favoriteDealership.id
                                    ? "selected"
                                    : "border-gray-300"
                                    }`}
                            >
                                <div className="flex flex-column w-full">
                                    <div className="flex items-center gap-6 w-full border-top-none border-right-none border-left-none border-width-2">
                                        <h3 className="flex items-center gap-6 font-semibold text-size-18 p-5">
                                            <div className='border border-gray-300 rounded p-2'>
                                                <span className="rioglyph rioglyph-workshop" />
                                            </div>
                                            {favoriteDealership.name}
                                        </h3>

                                        <button
                                            className={`text-red-500 hover:text-red-600 text-red-500`}
                                            onClick={async () => {
                                                await removeFavoriteDealership(favoriteDealership.id);
                                                await getFavoritesDealerships();
                                            }}
                                        >
                                            <span className="rioglyph rioglyph-heart text-size-16 text-color-danger"></span>
                                        </button>
                                        <RadioButton
                                            checked={dealership?.id === favoriteDealership.id}
                                            onChange={() => setDealership(favoriteDealership)}
                                        />
                                    </div>
                                    <p className="text-size-14 padding-top-10 p-5">
                                        {favoriteDealership.address}
                                    </p>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="new-concessionaria">
                        <div className="flex">
                            <div className="form-group w-full">
                                <label htmlFor="1">Selecionar concessionária</label>
                                <div
                                    className="select-concessionaria cursor-pointer flex justify-between items-center gap-6 padding-left-10 padding-right-10"
                                    onClick={async () => {
                                        setLoading(true);
                                        if (!showSearchConcessionaria){
                                            if(asset?.geoLocation?.lat && asset?.geoLocation?.lng) await getDealershipsByGeolocation();
                                            else await getDealershipsByStateAndCity();
                                        }
                                        
                                        setShowSearchConcessionaria(!showSearchConcessionaria)
                                        setLoading(false);
                                    }}
                                >
                                    <div className="flex items-center gap-6">
                                        <img src="assets/images/building.svg" alt="" />
                                        <span className="text-color-gray">
                                            Selecionar concessionária
                                        </span>
                                    </div>
                                    {
                                        loading ?
                                            <div className="spinner"><div /><div /><div /><div /></div> :
                                            <span className="rioglyph rioglyph-chevron-down text-size-10"></span>
                                    }
                                </div>

                                {showSearchConcessionaria && (
                                    <div className="search-concessionaria">
                                        <div className="form-group form-group-lg">
                                            <div className="input-group">
                                                <span className="input-group-addon">
                                                    <span className="rioglyph rioglyph-search"></span>
                                                </span>
                                                <input
                                                    id="input7"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Procurar concessionária"
                                                    onChange={(e) => setSearchDealershipTerm(e.target.value)}
                                                    value={searchDealershipTerm}
                                                />
                                            </div>
                                        </div>

                                        {dealerships.length === 0 && (
                                            <div className="flex justify-center padding-top-10">
                                                <span className="text-color-gray">
                                                    Nenhuma concessionária encontrada
                                                </span>
                                            </div>
                                        )}

                                        {
                                            dealerships
                                                .filter((foundDealership: Dealership) => foundDealership.name.toLowerCase().includes(searchDealershipTerm.toLowerCase()))
                                                .map((foundDealership: Dealership) => {
                                                    foundDealership.distance = getDistanceBetweenTwoLocations(asset?.geoLocation, foundDealership?.geoLocation);
                                                    return foundDealership;
                                                })
                                                .sort((a, b) => (a.distance ?? 0) - (b.distance ?? 0))
                                                .map((foundDealership: Dealership) =>
                                                    <div className={`flex concessionaria-options w-full ${foundDealership.id === dealership?.id ? "selected" : ""}`}>
                                                        <RadioButton
                                                            name="radios"
                                                            id="optionsRadios1"
                                                            value="option1"
                                                            checked={foundDealership.id === dealership?.id}
                                                            onChange={() => setDealership(foundDealership)}
                                                        >
                                                        </RadioButton>
                                                        <span className="text-size-14 w-[90%] text-ellipsis whitespace-nowrap overflow-hidden">
                                                            <strong className="text-size-14">
                                                                {foundDealership.name}
                                                            </strong>
                                                            <span className="card-km text-size-14 font-semibold">
                                                                {foundDealership.distance} Km
                                                            </span>
                                                            {foundDealership.address}
                                                        </span>
                                                        <button
                                                            className='align-right'
                                                            onClick={async () => {
                                                                if (favorites.some((favDealership: Dealership) => favDealership.id === foundDealership.id))
                                                                    await removeFavoriteDealership(foundDealership.id);
                                                                else
                                                                    await favoriteDealership(foundDealership.id);
                                                                await getFavoritesDealerships();
                                                            }}
                                                        >
                                                            <span
                                                                className={
                                                                    `rioglyph rioglyph-heart 
                                                                        ${favorites.some((favDealership: Dealership) => favDealership.id === foundDealership.id) ?
                                                                        `text-color-danger` :
                                                                        `text-color-light`
                                                                    }
                                                                    `}
                                                            />
                                                        </button>
                                                    </div>
                                                )
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="w-1/3 info-and-nav padding-bottom-20">
                <div className="page-info flex justify-between padding-15">
                    <span className="text-color-status-resting font-size-14">
                        Passo <strong className="text-color-danger">2</strong> de 4
                    </span>
                    <span className="text-color-status-resting font-size-14">
                        Escolha sua concessionária
                    </span>
                </div>
                <div className="flex justify-between navigation-buttons">
                    <button onClick={() => setStep(1)}>
                        <span className="rioglyph rioglyph-arrow-left text-size-16"></span>{" "}
                        Voltar
                    </button>
                    <button
                        onClick={() => setStep(3)}
                        className="px-4 py-2 btn btn-primary"
                        disabled={!dealership}
                    >
                        Próxima etapa
                    </button>
                </div>
            </div>

            <div className="w-2/3">
                <div className="w-full h-full">
                    <Map
                        credentials={{
                            apikey: import.meta.env.VITE_HERE_MAPS_API_KEY,
                        }}
                        center={{ lat: asset?.geoLocation?.lat || -23.550573750495325, lng: asset?.geoLocation?.lng || -46.64411772565403 }}
                        zoom={10}
                        hideMapSettings
                    >
                        <Marker
                            position={{
                                lat: asset?.geoLocation?.lat || -23.550573750495325,
                                lng: asset?.geoLocation?.lng || -46.64411772565403
                            }}
                            icon={
                                <SingleMapMarker iconNames={['truck']} markerColor='bg-map-marker-asset' active />
                            }
                        />
                        {
                            favorites
                                .filter((favoriteDealership: Dealership) => favoriteDealership.geoLocation.lat && favoriteDealership.geoLocation.lng)
                                .map((favoriteDealership: Dealership) => (
                                    <Marker
                                        key={favoriteDealership.id}
                                        position={{ lat: favoriteDealership.geoLocation.lat, lng: favoriteDealership.geoLocation.lng }}
                                        icon={
                                            <SingleMapMarker iconNames={['factory']} markerColor='bg-map-marker-poi' active name={favoriteDealership.name} />
                                        }
                                    />
                                ))
                        }
                        {
                            dealerships
                                .filter((dealership: Dealership) => dealership.geoLocation.lat && dealership.geoLocation.lng && favorites.every((favDealership: Dealership) => favDealership.id !== dealership.id))
                                .map((dealership: Dealership) => (
                                    <Marker
                                        key={dealership.id}
                                        position={{ lat: dealership.geoLocation.lat, lng: dealership.geoLocation.lng }}
                                        icon={
                                            <SingleMapMarker iconNames={['factory']} markerColor='bg-map-marker-poi' active name={dealership.name} />
                                        }
                                    />
                                ))
                        }
                    </Map>
                </div>
            </div>
        </div>
    )
}

export default DealershipForm;