import { ReactNode, useEffect, useRef, useState } from "react";
import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import AppHeader from "../features/header/AppHeader";
import { AppContext } from "./AppContext";
import SubnavigationHeader from "../features/header/SubnavigationHeader";

import "./App.css";
import LeftSidebar from "../features/header/LeftSidebar";
import React from "react";
import { checkRole } from "../services/roleService";
import { UserRoleEnum } from "../enums/role";
import { Loading } from "../utils/loading";

interface IHeaderProps {
  children: ReactNode;
}

const AppLayout = ({ children }: IHeaderProps) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUserRole = async () => {
      await checkRole(UserRoleEnum.MANAGER)
      setLoading(false);
    }
    
    checkUserRole();
  }, []);

  const sidebarRef = useRef<HTMLDivElement>(null);
  const [assetIds, setAssetIds] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);

  return loading ?
    <Loading label="Carregando..." /> :
    <AppContext.Provider value={{ sidebarRef }}>
      <ApplicationLayout data-testid="app-layout">
        <ApplicationLayout.Header>
          <AppHeader />
        </ApplicationLayout.Header>
        <LeftSidebar
          assetIds={assetIds}
          setAssetIds={setAssetIds}
          groupIds={groupIds}
          setGroupIds={setGroupIds}
        />
        <ApplicationLayout.Sidebar className="right" ref={sidebarRef} />
        <SubnavigationHeader>{React.cloneElement(
          children as React.ReactElement,
          { assetIds, setAssetIds, groupIds }
        )}</SubnavigationHeader>
      </ApplicationLayout>
    </AppContext.Provider>;
};

export default AppLayout;
