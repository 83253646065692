import { useEffect, useState } from "react";
import { RadioButton } from "@rio-cloud/rio-uikit";
import FilePicker from "@rio-cloud/rio-uikit/FilePicker";
import { Asset } from "../../../../../models/asset";
import { InspectionChecklistDialog } from "../dialogs/inspectionChecklistDialog";
import { TicketInspection, TicketRequest } from "../../../../../models/ticket";
import {
  downloadTicketImage,
  fetchTicketImages,
  ITicketFile,
  removeTicketImage,
  uploadTicketImage,
} from "../../../../../api/ticket";
import { Scheduling } from "../../../../../models/schedule";
import ErrorDialog from "../../../../../components/ErrorDialog";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import Tooltip from "@rio-cloud/rio-uikit/Tooltip";
import OverlayTrigger from "@rio-cloud/rio-uikit/OverlayTrigger";
import moment from "moment";
import { formatFileSize } from "../../../../../utils/imageSizeUtils";

const accept = {
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
  "image/svg": [],
};

interface IInspectionProps {
  ticketId?: string | undefined;
  inspection?: TicketInspection | undefined;
  setInspection: (inspection: TicketInspection) => void;
  scheduling?: Scheduling | undefined;
  request: TicketRequest | undefined;
}

const Inspection = ({
  ticketId,
  inspection,
  setInspection,
  scheduling,
  request,
}: IInspectionProps) => {
  const [files, setFiles] = useState<ITicketFile[]>([]);
  const [uploadErrorMessage, setUploadErrorMessage] = useState<
    string | undefined
  >();

  useEffect(() => {
    const fetchData = async () => {
      if (inspection && ticketId) {
        const images = await fetchTicketImages({
          ticketId: ticketId,
          step: "INSPECTION",
        });
        setFiles(images ?? []);
      }
    };
    fetchData();
  }, [ticketId]);

  const [checklistDialog, setChecklistDialog] = useState(false);

  return (
    <div>
      <div className="display-grid gap-20 grid-cols-4">
        <div className="form-group">
          <label htmlFor="checkinDate">Check-in</label>
          <input
            id="checkin"
            className="form-control"
            type="text"
            placeholder="--/--/----"
            disabled
            value={inspection?.checkIn?.toLocaleDateString("pt-BR")}
          />
        </div>
        <div className="form-group">
          <label htmlFor="checkinHour">Hora</label>
          <input
            id="hora-checkin"
            className="form-control"
            type="text"
            placeholder="--:--"
            disabled
            value={inspection?.checkIn?.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="checkoutDate">Check-out</label>
          <input
            id="checkout"
            className="form-control"
            type="text"
            placeholder="--/--/----"
            disabled
            value={inspection?.checkOut?.toLocaleDateString("pt-BR")}
          />
        </div>
        <div className="form-group">
          <label htmlFor="checkoutHour">Hora</label>
          <input
            id="hora-checkout"
            className="form-control"
            type="text"
            placeholder="--:--"
            disabled
            value={inspection?.checkOut?.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          />
        </div>
      </div>

      <div className="display-grid gap-20 grid-cols-4">
        <div className="display-grid gap-20 grid-cols-2">
          <div>
            <label>Validação do Serviço está OK?</label>
            <div>
              <RadioButton
                name="isServiceValid"
                inline
                checked={inspection?.serviceValidation}
                onChange={() =>
                  setInspection({ ...inspection, serviceValidation: true })
                }
                disabled={!inspection?.checkIn || !!inspection?.checkOut}
              >
                Sim
              </RadioButton>
              <RadioButton
                name="isServiceValid"
                inline
                checked={!inspection?.serviceValidation}
                onChange={() =>
                  setInspection({ ...inspection, serviceValidation: false })
                }
                disabled={!inspection?.checkIn || !!inspection?.checkOut}
              >
                Não
              </RadioButton>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-secondary margin-top-25"
            onClick={() => setChecklistDialog(true)}
          >
            <span
              className="rioglyph rioglyph-time-alt"
              aria-hidden="true"
            ></span>
            <span className="text-bold">Checklist</span>
          </button>
        </div>

        <div className="form-group">
          <label htmlFor="fanceInHour">Tempo Estimado</label>
          <div className="input-group">
            <span className="input-group-addon">
              <span className="rioglyph rioglyph-time"></span>
            </span>
            <input
              id="fanceInHour"
              className="form-control"
              type="text"
              value={inspection?.estimatedTime}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, "").slice(0, 4);
                const formattedValue = value.replace(/(\d{2})(\d{2})/, "$1:$2");
                setInspection({ ...inspection, estimatedTime: formattedValue });
              }}
              disabled={!inspection?.checkIn || !!inspection?.checkOut}
            />
          </div>
        </div>
      </div>

      <div className="form-group ">
        <label htmlFor="textArea">Relato</label>
        <textarea
          className="form-control"
          rows={3}
          id="textArea"
          placeholder="Descritivo"
          value={inspection?.report}
          onChange={(e) =>
            setInspection({ ...inspection, report: e.target.value })
          }
          disabled={!inspection?.checkIn || !!inspection?.checkOut}
        />
      </div>

      <div>
        <div className="infoFiles">
          <label htmlFor="relato">Inspeção visual</label>
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip id="tooltip" allowOnTouch>
                <div className="flex flex-col padding-10">
                  <p>
                    Arquivos permitidos: .jpg, .jpeg e .png
                    <br />
                    Tamanho máximo permitido: 2MB
                  </p>
                </div>
              </Tooltip>
            }
          >
            <span className="rioglyph rioglyph-question-sign margin-left-10 cursor-pointer text-color-gray" />
          </OverlayTrigger>
        </div>
        <div>
          {!!!inspection?.checkOut &&
            (files?.length === 0 || !files) &&
            !(!inspection?.checkIn || !!inspection?.checkOut) && (
              <FilePicker
                displayMode="dropzone"
                className="margin-bottom-20"
                onPick={async (acceptedFiles: FileList | null) => {
                  const maxSize = 5 * 1024 * 1024;
                  const filePicked = acceptedFiles?.[0];

                  if (!filePicked) return;

                  if (filePicked.size > maxSize) {
                    return setUploadErrorMessage(
                      "O arquivo selecionado excede o limite de 5MB. Escolha um arquivo menor."
                    );
                  }

                  if (acceptedFiles?.[0] && ticketId) {
                    const uploaded = await uploadTicketImage({
                      ticketId: ticketId,
                      imageFile: Array.from(acceptedFiles),
                      step: "INSPECTION",
                    });
                    if (!uploaded)
                      return setUploadErrorMessage(
                        "Arquivo invalido. Erro ao realizar o upload, verifique o arquivo e tente novamente."
                      );
                    setFiles(
                      filePicked
                        ? [
                            {
                              name: filePicked.name,
                              updatedAt: new Date(
                                filePicked.lastModified
                              ).toLocaleDateString("pt-BR"),
                              contentType: filePicked.type,
                              contentSize: filePicked.size,
                              link: URL.createObjectURL(filePicked),
                            },
                          ]
                        : []
                    );
                  }
                }}
                multiple={true}
                accept={accept}
              >
                {dropArea}
              </FilePicker>
            )}
          <div className="display-grid grid-cols-1 grid-cols-3-sm gap-25">
            {(files ?? []).map((file) => (
              <FilePreview
                key={file.name}
                file={file}
                ticketId={ticketId}
                removeFile={() =>
                  setFiles((prevFiles) =>
                    (prevFiles ?? []).filter((f) => f.name !== file.name)
                  )
                }
                disabled={!inspection?.checkIn || !!inspection?.checkOut}
              />
            ))}
          </div>
        </div>

        <ErrorDialog
          show={!!uploadErrorMessage}
          title={"Erro ao fazer upload"}
          body={uploadErrorMessage ?? ""}
          onClose={() => setUploadErrorMessage(undefined)}
        />
      </div>

      <InspectionChecklistDialog
        showDialog={checklistDialog}
        onClose={() => setChecklistDialog(false)}
        services={request?.checkupChecklist ?? []}
        setServices={(servicesUpdated) =>
          (request!.checkupChecklist = servicesUpdated)
        }
        asset={scheduling?.asset ?? ({} as Asset)}
      />
    </div>
  );
};

const dropArea = ({ isDragActive }: { isDragActive: boolean }) => (
  <div
    className={`
            rounded border border-style-dashed border-color-gray bg-lightest text-size-14
            text-center text-color-darker opacity-50 padding-25 cursor-pointer
        `}
  >
    <span className="rioglyph rioglyph-files text-size-200pct opacity-50 margin-top-20" />
    <div className="margin-top-10">
      {isDragActive
        ? "Jogue os arquivos aqui ..."
        : "Arraste arquivos de imagem para cá ou clique para selecionar no computador"}
    </div>
  </div>
);

const FilePreview = ({
  file,
  ticketId,
  removeFile,
  disabled,
}: {
  file: ITicketFile | undefined;
  ticketId: string | undefined;
  removeFile: () => void;
  disabled: boolean;
}) => {
  const [deleteFileDialog, setDeleteFileDialog] = useState<boolean>(false);

  return (
    <>
      <div
        className="panel panel-default margin-top-20 cursor-pointer"
        onClick={async () => {
          if (ticketId && file) {
            const response = await downloadTicketImage({
              ticketId: ticketId,
              step: "INSPECTION",
              filename: file.name,
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file.name);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
          }
        }}
      >
        <div className="panel-body">
          {file && (
            <div className="flex">
              {file.link && (
                <div className="thumbnail margin-right-10">
                  <img
                    src={file.link}
                    alt={file.name}
                    style={{
                      width: "200px",
                      height: "120px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              )}
              <div className="flex justify-between w-full">
                <div>
                  <div>
                    <b>{`${file?.name}`}</b>
                  </div>
                  <div>tipo: {`${file?.contentType}`}</div>
                  <div>tamanho: {formatFileSize(file?.contentSize)}</div>
                  <div>
                    data da última modificação:{" "}
                    {file?.updatedAt
                      ? moment(file.updatedAt).format("DD/MM/YYYY")
                      : "Data não disponível"}
                  </div>{" "}
                  <div>caminho: {`${file?.name}`}</div>
                </div>
                {!disabled && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteFileDialog(true);
                    }}
                  >
                    <span className="rioglyph rioglyph-remove" />
                  </div>
                )}
              </div>
            </div>
          )}
          {!file && (
            <div className="text-color-dark text-italic">Nenhum arquivo</div>
          )}
        </div>
      </div>
      <ConfirmDialog
        show={deleteFileDialog}
        title="Remover arquivo"
        body="Deseja realmente remover o arquivo?"
        onClose={() => setDeleteFileDialog(false)}
        onConfirm={async () => {
          if (ticketId && file) {
            const response = await removeTicketImage({
              ticketId: ticketId,
              step: "INSPECTION",
              filename: file.name,
            });
            if (response) {
              removeFile();
              setDeleteFileDialog(false);
            }
          }
        }}
      />
    </>
  );
};

export default Inspection;
