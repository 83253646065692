import { FormattedMessage } from "react-intl";
import { Link, NavLink } from "react-router-dom";
import { DefaultUserMenu } from "@rio-cloud/rio-user-menu-component";
import { RioNotifications } from "@rio-cloud/rio-notifications-component";
import ApplicationHeader from "@rio-cloud/rio-uikit/ApplicationHeader";
import IframeResizer from "iframe-resizer-react";
import { config } from "../../config";
import ServiceInfo from "./ServiceInfo";

const currentLocation = window?.location.hash.replace("#", "/");
const navItems = [
  {
    key: "1",
    route: (
      <NavLink to="https://latam-maintenance.rio.cloud/#/dashboard">
        DASHBOARD
      </NavLink>
    ),
  },
  {
    key: "2",
    route: (
      <NavLink to="https://latam-maintenance.rio.cloud/#/alerts">
        RELATÓRIO DE ALERTAS
      </NavLink>
    ),
  },
  {
    key: "3",
    route: (
      <NavLink to="https://latam-maintenance.rio.cloud/#/notifications">
        NOTIFICAÇÕES
      </NavLink>
    ),
  },
  {
    key: "4",
    route: (
      <NavLink to="https://latam-maintenance.rio.cloud/#/recurrence">
        RECORRÊNCIA
      </NavLink>
    ),
  },
  {
    key: "5",
    route: (
      <NavLink to={currentLocation} onClick={(event) => event.preventDefault()}>
        MANUTENÇÃO
      </NavLink>
    ),
  },
];

const AppHeader = () => {
  const environment = import.meta.env.PROD ? "production" : "local";

  const serviceInfoItem = <ServiceInfo />;
  const notifications = <RioNotifications />;
  const userMenuItem = <DefaultUserMenu environment={environment} />;

  return (
    <ApplicationHeader
      label={<FormattedMessage id="intl-msg:starterTemplate.moduleName" />}
      appNavigator={
        <IframeResizer
          className="iFrameResizer"
          src={config.backend.MENU_SERVICE}
        />
      }
      homeRoute={<Link to={config.homeRoute || ""} />}
      navItems={navItems}
      actionBarItems={[serviceInfoItem, notifications, userMenuItem]}
    />
  );
};

export default AppHeader;
