import AppLayout from "../../../../layout/AppLayout";
import Submenu from "./components/submenu";

const Report = () => {
    return (
        <AppLayout>
            <>
                <Submenu />
                <h3>Reportar</h3>
            </>
        </AppLayout>
    );
}

export default Report;