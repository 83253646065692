import { Scheduling } from "../../../../../models/schedule";
// import { Divider } from "@rio-cloud/rio-uikit";
// import Map from '@rio-cloud/rio-uikit/Map';
// import DtcAlertsTable from "./dtcAlertsTable";

interface IFanceData {
  fanceIn?: Date | undefined;
  fanceOut?: Date | undefined;
}

interface IMaintenanceRequestProps {
  scheduling: Scheduling | undefined;
  fanceData?: IFanceData | undefined;
}

const MaintenanceRequest = ({ scheduling }: IMaintenanceRequestProps) => {
  return (
    <div>
      <div className="resume-section">
        <div className="resume-container padding-0">
          <div className="row schedule-data">
            <div className="col-4">
              <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
                Motorista
              </label>
              <div className="mt-1 p-2 border schedule-data__info">
                <div className="truncate">{scheduling?.driver?.name}</div>
              </div>
            </div>
            <div className="col-2">
              <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
                CNH
              </label>
              <div className="mt-1 p-2 border schedule-data__info">
                <div className="truncate">{scheduling?.driver?.license}</div>
              </div>
            </div>
            <div className="col-3">
              <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
                Telefone
              </label>
              <div className="mt-1 p-2 border schedule-data__info">
                <div className="truncate">{scheduling?.driver?.phone}</div>
              </div>
            </div>
            <div className="col-3">
              <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
                E-mail
              </label>
              <div className="mt-1 p-2 border schedule-data__info">
                <div className="truncate">{scheduling?.driver?.email}</div>
              </div>
            </div>
          </div>

          <div className="p-4 bg-lighter margin-top-20 vehicle-detais">
            <div className="flex items-start gap-4">
              <img
                src="/assets/images/vehicle-thumb.png"
                alt="Vehicle"
                className="w-20 h-20 rounded"
              />

              <div className="flex-1 padding-left-10">
                <p className="text-color-black text-size-16 font-medium m-0">
                  {scheduling?.asset?.name || scheduling?.asset?.model} | Placa:{" "}
                  {scheduling?.asset?.licensePlate}
                </p>
                <p className="text-color-status-resting text-size-14 m-0">
                  Chassi - {scheduling?.asset?.chassis}
                  <br />
                  Odômetro: {scheduling?.asset?.odometer} km | Horímetro:{" "}
                  {scheduling?.asset?.hourmeter}
                </p>
              </div>

              {(scheduling?.asset?.plans?.length ?? 0) > 0 && (
                <div className="flex gap-6 margin-bottom-20">
                  <span className="px-2 py-1 green-tag">RIO</span>
                  <span className="px-2 py-1 green-tag">Em Garantia</span>
                  {scheduling?.asset?.volksTotalPlan && (
                    <span className="px-2 py-1 bg-white white-tag">
                      Volkstotal {scheduling?.asset?.volksTotalPlan}
                    </span>
                  )}
                </div>
              )}
            </div>

            <div className="padding-top-10">
              <div className="car-workshop">
                <ul className="flex flex-column gap-5 m-0">
                  <li className="flex justify-between">
                    <div className="text-color-black">Campanha de campo</div>
                    <div className="text-color-black font-bold">Grátis</div>
                  </li>

                  <li className="flex justify-between">
                    <div className="text-color-black">
                      {scheduling?.shortDescription}
                    </div>
                    <div className="text-color-darkest font-bold">Grátis</div>
                  </li>
                  <li className="flex justify-between border border-bottom-none border-left-none border-right-none padding-top-10 margin-top-10">
                    <div className="text-color-black">Total</div>
                    <div className="text-color-black font-bold">Grátis</div>
                  </li>

                  <li className="flex justify-between">
                    <div className="text-color-black">Total em serviços</div>
                    <div className="text-color-black">
                      {scheduling?.totalPrice}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="revision-itens">
            <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
              Itens cobertos na revisão
            </h5>
            <div className="p-4 bg-white border">
              <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
                {scheduling?.services.length === 0 && <p>-</p>}
                {scheduling?.services.map((item, index) => (
                  <div key={index} className="padding-bottom-10">
                    <p className="text-bold">{item.name}</p>
                    {item.items.map((subItem, subIndex) => (
                      <p key={subIndex} className="padding-left-20">
                        {subItem}
                      </p>
                    ))}
                  </div>
                ))}
              </ul>
            </div>
          </div>

          <div className="revision-itens observation">
            <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
              Observações
            </h5>
            <div className="p-4 bg-white border">
              <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
                <li className="flex justify-between">
                  <span>{scheduling?.observation || "-"}</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="revision-itens flex justify-between gap-6">
            <div className="w-1/3">
              <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
                Concessionária
              </h5>
              <div className="p-4 bg-white border card">
                <p className="m-0 text-size-h5">
                  {scheduling?.dealership?.name}
                </p>
                <p className="m-0">{scheduling?.dealership?.address}</p>
              </div>
            </div>

            <div className="w-1/3">
              <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
                Data e horário:
              </h5>
              <div className="p-4 bg-white border card">
                <p className="m-0 text-size-h5">
                  {scheduling?.scheduleTime?.toLocaleDateString("pt-BR", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </p>
                <p className="m-0">
                  {scheduling?.scheduleTime?.toLocaleTimeString("pt-BR", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
              </div>
            </div>

            <div className="w-1/3">
              <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
                Consultor:
              </h5>
              <div className="p-4 bg-white border card">
                <p className="m-0 text-size-h5">{scheduling?.consultant}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* TODO: Relato anterior */}
      {/* <div className="alert alert-dismissible alert-warning margin-y-20">
                <div>
                    <strong className="text-size-16">Relato anterior:</strong>
                    <div className="margin-y-5">Durante a inspeção realizada no caminhão, foram identificadas falhas críticas no sistema de freios e desgaste excessivo nos componentes da suspensão, comprometendo a segurança e o desempenho do veículo. Além disso, foi detectado vazamento de óleo no motor e irregularidades no sistema elétrico. Diante disso, é imprescindível a realização de uma revisão corretiva imediata para corrigir os problemas e evitar danos mais graves ou riscos operacionais.</div>
                </div>
            </div> 
            <Divider /> */}

      {/* TODO: Cerca eletrônica */}
      {/* <div className="display-grid gap-20 grid-cols-4">
                <div className="form-group">
                    <label htmlFor="fanceInDate">Data de entrada na cerca</label>
                    <div className="input-group">
                        <span className="input-group-addon">
                            <span className="rioglyph rioglyph-calendar">
                            </span>
                        </span>
                        <input id="fanceInDate" className="form-control" type="text" disabled value={fanceData?.fanceIn ? fanceData.fanceIn.toLocaleDateString('pt-BR') : ''} />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="fanceInHour">Hora de entrada na cerca</label>
                    <div className="input-group">
                        <span className="input-group-addon">
                            <span className="rioglyph rioglyph-time">
                            </span>
                        </span>
                        <input id="fanceInHour" className="form-control" type="text" disabled value={fanceData?.fanceIn ? fanceData.fanceIn.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }) : ''} />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="fanceOutDate">Data de saída na cerca</label>
                    <div className="input-group">
                        <span className="input-group-addon">
                            <span className="rioglyph rioglyph-calendar">
                            </span>
                        </span>
                        <input id="fanceOutDate" className="form-control" type="text" disabled value={fanceData?.fanceOut ? fanceData.fanceOut.toLocaleDateString('pt-BR') : ''} />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="fanceOutHour">Hora de saída na cerca</label>
                    <div className="input-group">
                        <span className="input-group-addon">
                            <span className="rioglyph rioglyph-time">
                            </span>
                        </span>
                        <input id="fanceOutHour" className="form-control" type="text" disabled value={fanceData?.fanceOut ? fanceData.fanceOut.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }) : ''} />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="fanceTime">Tempo em cerca eletrônica</label>
                <div className="input-group">
                    <span className="input-group-addon">
                        <span className="rioglyph rioglyph-time">
                        </span>
                    </span>
                    <input
                        id="fanceTime" className="form-control" type="text" disabled
                        value={fanceData?.fanceIn && fanceData?.fanceOut ?
                            `${Math.floor((fanceData.fanceOut.getTime() - fanceData.fanceIn.getTime()) / (1000 * 60 * 60))}:${Math.floor(((fanceData.fanceOut.getTime() - fanceData.fanceIn.getTime()) % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0')}`
                            : ''
                        }
                    />
                </div>
            </div>
            <div className="w-full height-300">
                <Map
                    credentials={{ apikey: import.meta.env.VITE_HERE_MAPS_API_KEY }}
                    center={{ lat: -23.550573750495325, lng: -46.64411772565403 }}
                    zoom={10}
                    hideMapSettings
                />
            </div>
            <Divider /> */}

      {/* TODO: Alertas de DTC */}
      {/* <span className="text-bold">Alertas de DTC Crítico nas últimas 24 horas</span>
            <DtcAlertsTable viewType={"TABLE"} />
            <Divider /> */}
    </div>
  );
};

export default MaintenanceRequest;
