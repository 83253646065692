export const dealershipMockData = [
    {
        id: '1930',
        name: 'Dibracam',
        address: 'Avenida Corifeu de Azevedo Marques, 345, Butanta, São Paulo - SP',
        consultants: [
            'b79d16f6-9213-43f2-971e-59de52b15956'// Josué
        ]
    },
    {
        id: '4873',
        name: 'Tiete',
        address: 'Avenida Marginal Direita do Rio Tiete, 700, Vila Jaguara, São Paulo - SP',
        consultants: [
            'ec6e5df5-b523-4428-bacd-48d04b90a234'// Alexis
        ]
    },
    {
        id: '182739',
        name: 'Concessionaria VWCO',
        address: 'Av. Dr. Luis Rocha Miranda 341, Jabaquara, São Paulo - SP',
        consultants: [
            '38193adf-ab52-441d-a240-c2275f500d30'// Tiano
        ]
    }
];