import { useEffect, useState } from "react";
import StatsWidget from "@rio-cloud/rio-uikit/StatsWidget";
import StatsWidgetNumber from "@rio-cloud/rio-uikit/StatsWidgetNumber";
import StatsWidgetSpacer from "@rio-cloud/rio-uikit/StatsWidgetSpacer";
import { fetchAssetsByRioPlans, fetchRioAssets } from "../../../../../api/fetchBigNumbers";
import { AssetSummary } from "../../../../../models/asset";

const RioPlanBigNumbers = (
    { 
        initialAssetIds, 
        assetIds, 
        setAssetIds, 
        filter, 
        setFilter,
        previousStateFlag,
        setPreviousStateFlag,
        withRioPlanAssetIds,
        setWithRioPlanAssetIds,
        withoutRioPlanAssetIds,
        setWithoutRioPlanAssetIds,
        byRioPlan,
        setByRioPlan,
        setPreviousData,
        setToPreviousData,
    }: 
    { 
        initialAssetIds: AssetSummary[], 
        assetIds: string[], 
        setAssetIds: (e: string[]) => void, 
        filter: string, 
        setFilter: (e: string) => void,
        previousStateFlag: boolean,
        setPreviousStateFlag: (e: boolean) => void,
        withRioPlanAssetIds: string[],
        setWithRioPlanAssetIds: (e: string[]) => void,
        withoutRioPlanAssetIds: string[],
        setWithoutRioPlanAssetIds: (e: string[]) => void,
        byRioPlan: { [key: string]: string[] },
        setByRioPlan: (e: { [key: string]: string[] }) => void,
        setPreviousData: () => void,
        setToPreviousData: () => void,
    }
) => {

    const [withRioPlanLoading, setWithRioPlanLoading] = useState<boolean>(true);
    const [withoutRioPlanLoading, setWithoutRioPlanLoading] = useState<boolean>(true);
    const [byRioPlanLoading, setByRioPlanLoading] = useState<boolean>(true);
    
    useEffect(() => {
        const fetchData = async () => {
            setWithRioPlanLoading(true);
            setWithoutRioPlanLoading(true);
            setByRioPlanLoading(true);

            const fetchWithRioPlanPromise = fetchRioAssets(assetIds);
            const fetchByRioPlanPromise = fetchAssetsByRioPlans(assetIds);

            fetchWithRioPlanPromise.then(([, rioAssetIds]) => {
                setWithRioPlanAssetIds(rioAssetIds);
                setWithoutRioPlanAssetIds(assetIds.filter(id => !rioAssetIds.includes(id)));

                setWithRioPlanLoading(false);
                setWithoutRioPlanLoading(false);
            });

            fetchByRioPlanPromise.then((byRioPlan) => {
                setByRioPlan(byRioPlan);
                setByRioPlanLoading(false);
            })
        }

        if(assetIds.length && !previousStateFlag) fetchData();

        setPreviousStateFlag(false);
    }, [initialAssetIds]);

    return (
        <StatsWidget className="card">
            <StatsWidget.Header>RIO</StatsWidget.Header>
            <StatsWidget.Body className="mt-4">
                <StatsWidgetNumber
                    className={`${withRioPlanLoading ? `animate-pulse` : ``} ${filter !== '' && filter !== 'withRioPlan' ? `` : 'cursor-pointer'}`}
                    value={withRioPlanLoading ?
                        <div className="width-30 height-40 bg-gray-300 rounded mb-8"></div> :
                        <span className={filter !== '' && filter !== 'withRioPlan' ? `text-light` : 'text-color-status-driving'}>{withRioPlanAssetIds.length}</span>
                    }
                    label={withRioPlanLoading ?
                        <div className="width-70 h-8 bg-gray-300 rounded"></div> :
                        <span className={filter !== '' && filter !== 'withRioPlan' ? `text-light` : ''}>Com RIO</span>
                    }
                    onClick={() => {
                        if(filter !== 'withRioPlan' && filter !== '') return;
                        if(filter !== 'withRioPlan') [setFilter('withRioPlan'), setPreviousData(), setAssetIds(withRioPlanAssetIds)];
                        else setToPreviousData(), setFilter('');
                    }}
                />
                <StatsWidgetSpacer />
                <StatsWidgetNumber
                    className={`${withoutRioPlanLoading ? `animate-pulse` : ``} ${filter !== '' && filter !== 'withoutRioPlan' ? `` : 'cursor-pointer'}`}
                    value={withoutRioPlanLoading ?
                        <div className="width-30 height-40 bg-gray-300 rounded mb-8"></div> :
                        <span className={filter !== '' && filter !== 'withoutRioPlan' ? `text-light` : 'text-color-danger'}>{withoutRioPlanAssetIds.length}</span>
                    }
                    label={withoutRioPlanLoading ?
                        <div className="width-70 h-8 bg-gray-300 rounded"></div> :
                        <span className={filter !== '' && filter !== 'withoutRioPlan' ? `text-light` : ''}>Sem RIO</span>
                    }
                    onClick={() => {
                        if(filter !== 'withoutRioPlan' && filter !== '') return;
                        if(filter !== 'withoutRioPlan') [setFilter('withoutRioPlan'), setPreviousData(), setAssetIds(withoutRioPlanAssetIds)];
                        else setToPreviousData(), setFilter('');
                    }}
                />
            </StatsWidget.Body>
            <StatsWidget.Body>
                {
                    Object.keys(byRioPlan).map((key, index) => (
                        <>
                            <StatsWidgetNumber
                                className={`${byRioPlanLoading ? `animate-pulse` : ``} ${filter !== '' && filter !== `rioPlan_${key}` ? `` : 'cursor-pointer'} margin-top-10`}
                                value={byRioPlanLoading ?
                                    <div className="width-30 height-40 bg-gray-300 rounded mb-8"></div> :
                                    <span className={filter !== '' && filter !== `rioPlan_${key}` ? `text-light` : ''}>{byRioPlan[key].length}</span>
                                }
                                label={byRioPlanLoading ?
                                    <div className="width-70 h-8 bg-gray-300 rounded"></div> :
                                    <span className={filter !== '' && filter !== `rioPlan_${key}` ? `text-light` : ''}>{key}</span>
                                }
                                onClick={() => {
                                    if(filter !== `rioPlan_${key}` && filter !== '') return;
                                    if(filter !== `rioPlan_${key}`) [setFilter(`rioPlan_${key}`), setPreviousData(), setAssetIds(byRioPlan[key])];
                                    else setToPreviousData(), setFilter('');
                                }}
                            />
                            {index !== (Object.keys(byRioPlan).length - 1) && <StatsWidgetSpacer />}
                        </>
                    ))
                }
            </StatsWidget.Body>
        </StatsWidget>
    )
}

export default RioPlanBigNumbers;