import { useEffect, useState } from "react";
import {
  Dealership,
  DealershipConsultant,
} from "../../../../models/dealership";
import {
  addDays,
  getFirstDayOfWeek,
  getLastDayOfWeek,
  getStringWeekDaysStartingFromDate,
} from "../../../../utils/dateUtils";
import { fetchConsultants } from "../../../../api/fetchConsultants";
import RadioButton from "@rio-cloud/rio-uikit/RadioButton";
import { Loading } from "../../../../utils/loading";
import { OverlayTrigger, Tooltip } from "@rio-cloud/rio-uikit";

export interface IDateAndConsultantForm {
  scheduleDate?: Date;
  consultant?: DealershipConsultant;
}

export interface IDateAndConsultantFormProps {
  dealership?: Dealership;
  setStep: (step: number) => void;
  form?: IDateAndConsultantForm;
  setAppointmentForm: (form: IDateAndConsultantForm) => void;
  isReschedule?: boolean;
}

const DateAndConsultantForm = ({
  dealership,
  setStep,
  form,
  setAppointmentForm,
  isReschedule,
}: IDateAndConsultantFormProps) => {
  const [consultantloading, setConsultantLoading] = useState<boolean>(false);
  const [consultants, setConsultants] = useState<DealershipConsultant[]>([]);
  const [date, setDate] = useState<Date | undefined>();

  const [firstWeekDay, setFirstWeekDay] = useState<Date>(
    getFirstDayOfWeek(new Date())
  );
  const [weekDays, setWeekDays] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setConsultantLoading(true);
      const consultants = await fetchConsultants(
        addDays(new Date(), 1),
        dealership?.id ?? ""
      );
      setConsultants(consultants);
      setDate(form?.scheduleDate ?? addDays(new Date(), 1));
      setConsultantLoading(false);
    };

    setWeekDays(
      getStringWeekDaysStartingFromDate(firstWeekDay).map((date, index) => ({
        date: addDays(firstWeekDay, index),
        weekDay: date.split(" ")[0],
        day: date.split(" ")[1],
      }))
    );

    fetchData();
  }, []);

  const previousWeek = () => {
    const lastDayOfPreviousWeek = addDays(firstWeekDay, -1);
    const firstWeekDayOfPreviousWeek = getFirstDayOfWeek(lastDayOfPreviousWeek);
    setWeekDays(
      getStringWeekDaysStartingFromDate(firstWeekDayOfPreviousWeek).map(
        (date, index) => ({
          date: addDays(firstWeekDayOfPreviousWeek, index),
          weekDay: date.split(" ")[0],
          day: date.split(" ")[1],
        })
      )
    );
    setFirstWeekDay(firstWeekDayOfPreviousWeek);
  };

  const nextWeek = () => {
    const lastWeekDay = getLastDayOfWeek(firstWeekDay);
    const firstDayOfNextWeek = addDays(lastWeekDay, +1);
    setWeekDays(
      getStringWeekDaysStartingFromDate(firstDayOfNextWeek).map(
        (date, index) => ({
          date: addDays(firstDayOfNextWeek, index),
          weekDay: date.split(" ")[0],
          day: date.split(" ")[1],
        })
      )
    );
    setFirstWeekDay(firstDayOfNextWeek);
  };

  const getAvailableHours = (selectedDate: Date) => {
    const dayOfWeek = selectedDate.getDay();
    if (dayOfWeek === 0) {
      return [];
    } else if (dayOfWeek === 6) {
      return Array.from({ length: 5 }, (_, i) => i + 8);
    } else {
      return Array.from({ length: 11 }, (_, i) => i + 8);
    }
  };

  return (
    <>
      <div className="scheduling-page calendar-section p-10">
        <div className="scheduling-page__header">
          <h2 className="text-size-h2 text-color-black font-semibold text-uppercase">
            3. Agendamento
          </h2>
          <p className="text-color-status-resting text-size-16 padding-top-10 font-semibold">
            Concessionária:
          </p>
        </div>

        <div className="flex items-center justify-between border card-concessionaria selected margin-top-10 margin-bottom-20">
          <div className="flex flex-column w-full">
            <div className="flex justify-between items-center gap-6 w-full border-top-none border-right-none border-left-none border-width-2">
              <h3 className="flex items-center gap-6 font-semibold text-size-18 p-5">
                <div className="border border-gray-300 rounded p-2">
                  <span className="rioglyph rioglyph-workshop" />
                </div>
                {dealership?.name}
              </h3>
            </div>
            <p className="text-size-14 p-6">{dealership?.address}</p>
          </div>
        </div>

        <div className="mb-4">
          <p className="text-color-status-resting text-size-16 padding-top-10 font-semibold">
            Selecionar a data: *
          </p>
          <div className="calendar-row margin-top-10">
            <div className="flex items-center justify-between mb-2 calendar-row__month">
              <button onClick={previousWeek}>
                <span className="rioglyph rioglyph-chevron-left text-color-status-available"></span>
              </button>
              <span className="text-size-16 text-color-status-resting font-semibold capitalize">
                {firstWeekDay
                  .toLocaleDateString("pt-BR", {
                    month: "long",
                    year: "numeric",
                  })
                  .replace(" de ", "/")}
              </span>
              <button onClick={nextWeek}>
                <span className="rioglyph rioglyph-chevron-right text-color-status-available"></span>
              </button>
            </div>
            <div className="flex justify-between text-center p-2 calendar-row__days">
              {(weekDays ?? []).map((weekDay: any) => (
                <button
                  key={`${weekDay.weekDay}_${weekDay.day}`}
                  className={`flex flex-col items-center gap-5 p-2 w-12 h-16 rounded-lg text-sm transition-all 
                                        ${
                                          weekDay.day ===
                                          (form?.scheduleDate || date)
                                            ?.getDate()
                                            .toString()
                                            ? "active-date text-white"
                                            : "hover:bg-gray-200"
                                        }`}
                  onClick={async () => {
                    setConsultantLoading(true);
                    setAppointmentForm({
                      consultant: undefined,
                      scheduleDate: undefined,
                    });
                    setDate(weekDay.date);
                    const consultants = await fetchConsultants(
                      weekDay.date,
                      dealership?.id ?? ""
                    );
                    setConsultants(consultants);
                    setConsultantLoading(false);
                  }}
                  disabled={weekDay.date < new Date()}
                >
                  <span className="text-size-12 text-gray-600 text-lowercase">
                    {weekDay.weekDay}
                  </span>
                  <span
                    className={`
                                            font-medium flex items-center justify-center text-size-14 day-card
                                            ${
                                              weekDay.date < new Date()
                                                ? "bg-lighter"
                                                : ""
                                            }
                                        `}
                  >
                    {weekDay.day}
                  </span>
                </button>
              ))}
            </div>
          </div>
        </div>

        {consultantloading && <Loading label="Carregando consultores..." />}

        {!consultantloading && consultants.length !== 0 && (
          <div className="mb-6">
            <p className="text-color-status-resting text-size-16 padding-top-10 font-semibold">
              Selecionar o consultor: *
            </p>
            <div className="overflow-x-auto margin-top-20 consult-table">
              <table className="w-full table-fixed border-collapse">
                <thead>
                  <tr>
                    <th className="w-1/6 text-left p-2 border-b text-size-14">
                      Consultor(a)
                    </th>
                    {getAvailableHours(date || new Date()).map((hour) => (
                      <th
                        key={hour}
                        className="w-1/24 text-center p-2 border-b text-size-14"
                      >
                        {hour}:00
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {consultants.map((consultant) => (
                    <tr key={consultant.id}>
                      <td className="p-2 border-b text-size-14 font-medium">
                        {consultant.name}
                      </td>
                      {getAvailableHours(date || new Date()).map((hour) => (
                        <td key={hour} className="p-1 border-b text-center">
                          {!date ||
                          consultant.scheduleDates
                            ?.map((scheduleDate) =>
                              scheduleDate.toLocaleString("pt-Br")
                            )
                            ?.includes(
                              new Date(
                                date.setHours(hour, 0, 0, 0)
                              ).toLocaleString("pt-Br")
                            ) ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip" allowOnTouch>
                                  Horário indisponível
                                </Tooltip>
                              }
                            >
                              <div className="ml-[-7px]">
                                <div className="rounded-circle w-[14px] h-[14px] bg-light margin-auto" />
                              </div>
                            </OverlayTrigger>
                          ) : (
                            <RadioButton
                              className="form-radio"
                              name="time-slot"
                              onChange={() => {
                                if (date) {
                                  setAppointmentForm({
                                    consultant: consultant.id
                                      ? consultant
                                      : undefined,
                                    scheduleDate: new Date(
                                      date.getFullYear(),
                                      date.getMonth(),
                                      date.getDate(),
                                      hour,
                                      0,
                                      0,
                                      0
                                    ),
                                  });
                                }
                              }}
                            />
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <div className="page-info flex justify-between padding-15">
        {!isReschedule ? (
          <span className="text-color-status-resting font-size-14">
            Passo <strong className="text-color-danger">3</strong> de 4
          </span>
        ) : (
          <span />
        )}
        <span className="text-color-status-resting font-size-14">
          Agendamento
        </span>
      </div>
      <div className="flex justify-between navigation-buttons step-1">
        {!isReschedule ? (
          <button onClick={() => setStep(2)}>
            <span className="rioglyph rioglyph-arrow-left text-size-16"></span>{" "}
            Voltar
          </button>
        ) : (
          <span />
        )}
        <button
          onClick={() => setStep(4)}
          className="px-4 py-2 btn btn-primary"
          disabled={!form?.scheduleDate}
        >
          Finalizar {isReschedule ? "reagendamento" : "agendamento"}
        </button>
      </div>
    </>
  );
};

export default DateAndConsultantForm;
