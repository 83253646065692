import { useEffect, useState } from "react";
import StatsWidget from "@rio-cloud/rio-uikit/StatsWidget";
import StatsWidgetNumber from "@rio-cloud/rio-uikit/StatsWidgetNumber";
import StatsWidgetSpacer from "@rio-cloud/rio-uikit/StatsWidgetSpacer";
import { fetchVolksTotalAssets } from "../../../../../api/fetchBigNumbers";
import { AssetSummary } from "../../../../../models/asset";

const disabled = true;

const VolksTotalPlanBigNumbers = (
    { assets, assetIds, setAssetIds, filter, setFilter }: 
    { assets: AssetSummary[], assetIds: string[], setAssetIds: (e: string[]) => void, filter: string, setFilter: (e: string) => void }
) => {

    const [withVolksTotalPlan, setWithVolksTotalPlan] = useState<number>(0);
    const [withVolksTotalPlanLoading, setWithVolksTotalPlanLoading] = useState<boolean>(true);
    const [withVolksTotalPlanAssetIds, setWithVolksTotalPlanAssetIds] = useState<string[]>([]);

    const [withoutVolksTotalPlan, setWithoutVolksTotalPlan] = useState<number>(0);
    const [withoutVolksTotalPlanLoading, setWithoutVolksTotalPlanLoading] = useState<boolean>(true);
    const [withoutVolksTotalPlanAssetIds, setWithoutVolksTotalPlanAssetIds] = useState<string[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            setWithVolksTotalPlanLoading(true);
            setWithoutVolksTotalPlanLoading(true);

            const fetchWithVolksTotalPlanPromise = fetchVolksTotalAssets(assetIds);

            fetchWithVolksTotalPlanPromise.then(([withRioPlan, volksTotalAssetIds]) => {
                setWithVolksTotalPlan(withRioPlan);
                setWithVolksTotalPlanAssetIds(volksTotalAssetIds);
                setWithoutVolksTotalPlan(assetIds.length - withRioPlan);
                setWithoutVolksTotalPlanAssetIds(assetIds.filter(id => !volksTotalAssetIds.includes(id)));

                setWithVolksTotalPlanLoading(false);
                setWithoutVolksTotalPlanLoading(false);
            });
        }

        if (assetIds.length) fetchData();
    }, [assetIds]);

    return (
        <StatsWidget className="card bg-lightest">
            <StatsWidget.Header>VolksTotal</StatsWidget.Header>
            <StatsWidget.Body className="mt-4">
                <StatsWidgetNumber
                    className={`${withVolksTotalPlanLoading ? `animate-pulse` : ``} ${disabled || (filter !== '' && filter !== 'withVolksTotalPlan') ? `` : 'cursor-pointer'}`}
                    value={withVolksTotalPlanLoading ?
                        <div className="width-30 height-40 bg-gray-300 rounded mb-8"></div> :
                        <span className={disabled || (filter !== '' && filter !== 'withVolksTotalPlan') ? `text-light` : 'text-color-status-driving'}>{withVolksTotalPlan}</span>
                    }
                    label={withVolksTotalPlanLoading ?
                        <div className="width-70 h-8 bg-gray-300 rounded"></div> :
                        <span className={disabled || (filter !== '' && filter !== 'withVolksTotalPlan') ? `text-light` : ''}>Com VolksTotal</span>
                    }
                    onClick={() => {
                        if(disabled) return;
                        if(filter !== 'withVolksTotalPlan' && filter !== '') return;
                        if(filter !== 'withVolksTotalPlan') [setFilter('withVolksTotalPlan'), setAssetIds(withVolksTotalPlanAssetIds)];
                        else setAssetIds(assets.map(el => el.id)), setFilter('');
                    }}
                />
                <StatsWidgetSpacer />
                <StatsWidgetNumber
                    className={`${withoutVolksTotalPlanLoading ? `animate-pulse` : ``} ${disabled || (filter !== '' && filter !== 'withoutVolksTotalPlan') ? `` : 'cursor-pointer'}`}
                    value={withoutVolksTotalPlanLoading ?
                        <div className="width-30 height-40 bg-gray-300 rounded mb-8"></div> :
                        <span className={disabled || (filter !== '' && filter !== 'withoutVolksTotalPlan') ? `text-light` : 'text-color-status-driving'}>{withoutVolksTotalPlan}</span>
                    }
                    label={withoutVolksTotalPlanLoading ?
                        <div className="width-70 h-8 bg-gray-300 rounded"></div> :
                        <span className={disabled || (filter !== '' && filter !== 'withoutVolksTotalPlan') ? `text-light` : ''}>Sem VolksTotal</span>
                    }
                    onClick={() => {
                        if(disabled) return;
                        if(filter !== 'withoutVolksTotalPlan' && filter !== '') return;
                        if(filter !== 'withoutVolksTotalPlan') [setFilter('withoutVolksTotalPlan'), setAssetIds(withoutVolksTotalPlanAssetIds)];
                        else setAssetIds(assets.map(el => el.id)), setFilter('');
                    }}
                />
            </StatsWidget.Body>
            {/* <StatsWidget.Body>
                <StatsWidgetNumber
                    className={`
                        ${getDisabledColor(
                                (assetFilter.hasVolksTotalPlanFilter === false || assetFilter.volksTotalPlan !== 'Prev') && !isRioPlanFilterDisabled(assetFilter.hasVolksTotalPlanFilter, assetFilter.volksTotalPlan),
                                ''
                            )} 
                        cursor-pointer
                        `}
                    value={bigNumberVolksTotal?.prev}
                    label="Prev"
                    onClick={() =>
                        assetFilter.hasVolksTotalPlanFilter && setAssetFilter({
                            ...assetFilter,
                            volksTotalPlan: assetFilter.volksTotalPlan === 'Prev' ? undefined : "Prev",
                        })
                    }
                />
                <StatsWidgetSpacer />
                <StatsWidgetNumber
                    className={`
                        ${getDisabledColor(
                                (assetFilter.hasVolksTotalPlanFilter === false || assetFilter.volksTotalPlan !== 'Max') && !isRioPlanFilterDisabled(assetFilter.hasVolksTotalPlanFilter, assetFilter.volksTotalPlan),
                                ''
                            )} 
                        cursor-pointer
                        `}
                    value={bigNumberVolksTotal?.max}
                    label="Max"
                    onClick={() =>
                        assetFilter.hasVolksTotalPlanFilter && setAssetFilter({
                            ...assetFilter,
                            volksTotalPlan: assetFilter.volksTotalPlan === 'Max' ? undefined : "Max",
                        })
                    }
                />
                <StatsWidgetSpacer />
                <StatsWidgetNumber
                    className={`
                        ${getDisabledColor(
                                (assetFilter.hasVolksTotalPlanFilter === false || assetFilter.volksTotalPlan !== 'Plus') && !isRioPlanFilterDisabled(assetFilter.hasVolksTotalPlanFilter, assetFilter.volksTotalPlan),
                                ''
                            )} 
                        cursor-pointer
                        `}
                    value={bigNumberVolksTotal?.plus}
                    label="Plus"
                    onClick={() =>
                        assetFilter.hasVolksTotalPlanFilter && setAssetFilter({
                            ...assetFilter,
                            volksTotalPlan: assetFilter.volksTotalPlan === 'Plus' ? undefined : "Plus",
                        })
                    }
                />
            </StatsWidget.Body> */}
        </StatsWidget>
    )
}

export default VolksTotalPlanBigNumbers;