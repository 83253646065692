import { Scheduling } from "../models/schedule";
import { Driver } from "../models/driver";
import { IAppointmentForm } from "../pages/fleetManager/newAppointment";
import { cities, states } from "../utils/locationUtils";

export const createAppointmentFormFromScheduling = (scheduling: Scheduling, drivers: Driver[]): IAppointmentForm => {
    const dealershipState = scheduling.dealership.state ? states.find((state: any) => state.state === scheduling.dealership.state) : null;

    return {
        assetsAndDrivers: {
            asset: scheduling.asset,
            scheduleByDriver: false,
            checkup: scheduling.asset.nextCheckup?.checkup,
            driver: {
                ...scheduling.driver,
                id: (drivers.find((driver: Driver) => driver.email === scheduling.driver.email))?.id ?? '',
            },
            schedulingTypes: ['preventive'],
            aditionalServices: scheduling.asset.nextCheckup?.checkup.aditionalServices,
            comments: scheduling.comments,
            obs: scheduling.observation,
        },
        dealership: {
            id: scheduling.dealership.id,
            name: scheduling.dealership.name,
            address: scheduling.dealership.address,
            city: scheduling.dealership.city ?
                cities.find((city: any) => city.name === scheduling.dealership.city && city.state === dealershipState?.id)?.id : 
                '',
            state: dealershipState ? `${dealershipState.id}_${dealershipState.state}` : '',
            neighborhood: scheduling.dealership.neighborhood,
            distance: scheduling.dealership.distance,
            geoLocation: scheduling.dealership.geoLocation,
        },
        dateAndConsultant: {
            scheduleDate: undefined,
            consultant: undefined,
        }
    };
}