import { fetchUserRole } from "../api/fetchUser";
import { UserRoleEnum } from "../enums/role";

export const checkRole = async (roleToCheck: UserRoleEnum) => {
    const role = await fetchUserRole();
    if (role === roleToCheck) return;

    if (role === UserRoleEnum.CONSULTANT) {
        return window.location.href = "/dealership";
    }

    if (role === UserRoleEnum.MANAGER) {
        return window.location.href = "/fleet-manager";
    }

    if (role === UserRoleEnum.TOWER) {
        return window.location.href = "/control-tower";
    }
}