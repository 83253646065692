import Checkbox from "@rio-cloud/rio-uikit/Checkbox";
import { IAppointmentForm } from "..";
import {
  makeAnAppointment,
  rescheduleAnAppointment,
} from "../../../../api/appointments";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getUserProfile } from "../../../../configuration/login/loginSlice";
import SuccessDialog from "../dialogs/successDialog";
import ErrorDialog from "../../../../components/ErrorDialog";
import { AppointmentCreatedByEnum } from "../../../../enums/appointmentCreatedBy";

interface IAppointmentSummaryProps {
  appointmentForm?: IAppointmentForm;
  setStep: (step: number) => void;
  schedulingId: string | null;
}

const AppointmentSummary = ({
  appointmentForm,
  setStep,
  schedulingId,
}: IAppointmentSummaryProps) => {
  const userProfile = useSelector(getUserProfile);

  const [loading, setLoading] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialog, setErrorDialog] = useState({ show: false, title: '', body: '' });

  return (
    <div className="scheduling-page resume-section p-10">
      <h2 className="text-size-h2 text-center text-color-black font-semibold padding-top-20 padding-bottom-20">
        Resumo do agendamento
      </h2>
      <div className="resume-container">
        <h5 className="text-size-20 text-color-black font-bold margin-bottom-20 text-color-gray">
          AGD 001574
        </h5>

        <div className="row schedule-data">
          <div className="col-4">
            <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
              Motorista
            </label>
            <div className="mt-1 p-2 border schedule-data__info">
              <div className="truncate">
                {appointmentForm?.assetsAndDrivers?.driver?.name}
              </div>
            </div>
          </div>
          <div className="col-2">
            <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
              CNH
            </label>
            <div className="mt-1 p-2 border schedule-data__info">
              <div className="truncate">
                {appointmentForm?.assetsAndDrivers?.driver?.license}
              </div>
            </div>
          </div>
          <div className="col-3">
            <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
              Telefone
            </label>
            <div className="mt-1 p-2 border schedule-data__info">
              <div className="truncate">
                {appointmentForm?.assetsAndDrivers?.driver?.phone}
              </div>
            </div>
          </div>
          <div className="col-3">
            <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
              E-mail
            </label>
            <div className="mt-1 p-2 border schedule-data__info">
              <div className="truncate">
                {appointmentForm?.assetsAndDrivers?.driver?.email}
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 bg-lighter margin-top-20 vehicle-detais">
          <div className="flex items-start gap-4">
            <img
              src="/assets/images/vehicle-thumb.png"
              alt="Vehicle"
              className="w-20 h-20 rounded"
            />

            <div className="flex-1 padding-left-10">
              <p className="text-color-black text-size-16 font-medium m-0">
                {appointmentForm?.assetsAndDrivers?.asset?.name} | Placa:{" "}
                {appointmentForm?.assetsAndDrivers?.asset?.licensePlate}
              </p>
              <p className="text-color-status-resting text-size-14 m-0">
                Chassi - {appointmentForm?.assetsAndDrivers?.asset?.chassis}
                <br />
                Odômetro: {
                  appointmentForm?.assetsAndDrivers?.asset?.odometer
                }{" "}
                km | Horímetro:{" "}
                {appointmentForm?.assetsAndDrivers?.asset?.hourmeter}
              </p>
            </div>

            {(appointmentForm?.assetsAndDrivers?.asset?.plans?.length ?? 0) >
              0 && (
                <div className="flex gap-6 margin-bottom-20">
                  <span className="px-2 py-1 green-tag">RIO</span>
                  <span className="px-2 py-1 green-tag">Em Garantia</span>
                  {appointmentForm?.assetsAndDrivers?.asset?.volksTotalPlan && (
                    <span className="px-2 py-1 bg-white white-tag">
                      Volkstotal{" "}
                      {appointmentForm?.assetsAndDrivers?.asset?.volksTotalPlan}
                    </span>
                  )}
                </div>
              )}
          </div>

          <div className="padding-top-10">
            <div className="car-workshop">
              <ul className="flex flex-column gap-5 m-0">
                {
                  (appointmentForm?.assetsAndDrivers?.schedulingTypes ?? []).includes('workshopActions') &&
                  <li className="flex justify-between">
                    <div className="text-color-black">Campanhas de campo</div>
                    <div className="text-color-black font-bold">Grátis</div>
                  </li>
                }

                <li className="flex justify-between">
                  <div className="text-color-black">
                    {appointmentForm?.assetsAndDrivers?.checkup?.description}
                  </div>
                  <div className="text-color-black font-bold">Grátis</div>
                </li>

                {/* <li className="flex justify-between">
                                    <div className="text-color-black">
                                        Total em peças
                                    </div>
                                    <div className="text-color-black">R$4.628,00</div>
                                </li> */}

                <li className="flex justify-between">
                  <div className="text-color-black">Total em serviços</div>
                  <div className="text-color-black">
                    {(
                      appointmentForm?.assetsAndDrivers?.aditionalServices ?? []
                    )
                      .reduce((acc, service) => acc + service.price, 0)
                      .toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="revision-itens">
          <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
            Itens cobertos na revisão
          </h5>
          <div className="p-4 bg-white border">
            <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
              {appointmentForm?.assetsAndDrivers?.checkup?.services.length === 0 && <p>-</p>}
              {appointmentForm?.assetsAndDrivers?.checkup?.services.map(
                (item, index) => (
                  <div key={index} className="padding-bottom-10">
                    <p className="text-bold">{item.name}</p>
                    {item.items.map((subItem, subIndex) => (
                      <p key={subIndex} className="padding-left-20">
                        {subItem}
                      </p>
                    ))}
                  </div>
                )
              )}

              {appointmentForm?.assetsAndDrivers?.checkup?.aditionalServices?.length ?
                <div className="padding-bottom-10">
                  <p className="text-bold">Serviços complementares</p>
                  {appointmentForm?.assetsAndDrivers?.checkup?.aditionalServices.map(
                    (item, index) => (
                      <p key={index} className="padding-left-20">
                        {item.name}
                      </p>
                    )
                  )}
                </div> : null}

              {(appointmentForm?.assetsAndDrivers?.schedulingTypes ?? []).includes('workshopActions') &&
                <div className="padding-bottom-10">
                  <p className="text-bold">Campanhas de Campo</p>
                  {
                    (appointmentForm?.assetsAndDrivers?.asset?.campaigns?.list ?? [])
                      .map((subItem, subIndex) => (
                        <p key={subIndex} className="padding-left-20">
                          {subItem.name}
                        </p>
                      ))}
                </div>}
            </ul>
          </div>
        </div>

        <div className="revision-itens">
          <h5 className="text-size-20 text-bold margin-bottom-0">
            Descrição dos Serviços Contratados
          </h5>
          <div className="text-size-14 margin-bottom-10">
            Espaço para descrever informações importantes sobre “Serviços
            Contratados”, adicionados no agendamento.
          </div>
          <div className="p-4 bg-white border">
            <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
              {appointmentForm?.assetsAndDrivers?.aditionalServices?.map(
                (item, index) => (
                  <div key={index}>
                    <p>{item.name}</p>
                  </div>
                )
              )}
              {(appointmentForm?.assetsAndDrivers?.aditionalServices ?? []).length === 0 && <p>-</p>}
            </ul>
          </div>
        </div>

        <div className="revision-itens observation">
          <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
            Observações
          </h5>
          <div className="p-4 bg-white border">
            <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
              <li className="flex justify-between">
                <span>{appointmentForm?.assetsAndDrivers?.obs || '-'}</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="revision-itens flex justify-between gap-6">
          <div className="w-1/3">
            <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
              Concessionária
            </h5>
            <div className="p-4 bg-white border card">
              <p className="m-0 text-size-h5">
                {appointmentForm?.dealership?.name}
              </p>
              <p className="m-0">{appointmentForm?.dealership?.address}</p>
            </div>
          </div>

          <div className="w-1/3">
            <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
              Data e horário:
            </h5>
            <div className="p-4 bg-white border card">
              <p className="m-0 text-size-h5">
                {appointmentForm?.dateAndConsultant?.scheduleDate?.toLocaleDateString(
                  "pt-BR",
                  { year: "numeric", month: "long", day: "numeric" }
                )}
              </p>
              <p className="m-0">
                {appointmentForm?.dateAndConsultant?.scheduleDate?.toLocaleTimeString(
                  "pt-BR",
                  { hour: "2-digit", minute: "2-digit" }
                )}
              </p>
            </div>
          </div>

          <div className="w-1/3">
            <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
              Consultor:
            </h5>
            <div className="p-4 bg-white border card">
              <p className="m-0 text-size-h5">
                {appointmentForm?.dateAndConsultant?.consultant?.name}
              </p>
            </div>
          </div>
        </div>

        <div className="warning-row">
          <h5 className="text-color-status-resting">Atenção!</h5>
          <p className="text-color-warning text-size-18 font-semibold">
            Os itens de cobertura do seu plano de contrato de manutenção estão
            sujeitas a análise técnica de garantia, podendo a garantia ser
            indeferida por violação e ou mau uso.
          </p>
          <p className="margin-top-20">
            <Checkbox
              checked={acceptedTerms}
              onChange={() => setAcceptedTerms(!acceptedTerms)}
            >
              Estou ciente sobre as regras da garantia
            </Checkbox>
          </p>
        </div>
        <div className="flex justify-between navigation-buttons step-4">
          <button className="back-btn" onClick={() => setStep(3)}>
            Voltar
          </button>
          <button
            className={`px-4 py-2 btn btn-primary ${loading ? "btn-loading disabled" : ""
              }`}
            disabled={!acceptedTerms || loading}
            onClick={async () => {
              setLoading(true);

              try {
                schedulingId
                  ? await rescheduleAnAppointment(appointmentForm!, schedulingId, AppointmentCreatedByEnum.MANAGER)
                  : await makeAnAppointment(appointmentForm!, userProfile, AppointmentCreatedByEnum.MANAGER);
                setSuccessDialogOpen(true);
                setLoading(false);
              } catch (error: any) {
                setLoading(false);
                setErrorDialog({ show: true, title: 'Erro ao agendar', body: error.message });
              }
            }}
          >
            Confirmar
          </button>
        </div>
      </div>

      <SuccessDialog
        open={successDialogOpen}
        appointmentForm={appointmentForm}
      />

      <ErrorDialog
        show={errorDialog.show}
        title={errorDialog.title}
        body={errorDialog.body}
        onClose={() => setErrorDialog({ show: false, title: '', body: '' })}
      />
    </div>
  );
};

export default AppointmentSummary;
