import { useEffect, useState } from "react";
import "./styles.scss";
import Page from "../../Page";
import { fetchAllScheduleList } from "../../../../api/fetchScheduleList";
import { MainSchedule } from "../../../../models/scheduleList";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../../utils/loading";
import Appointment from "./appointment";
import { SchedulingDto } from "../../../../dtos/schedulingDto";
import { fetchTickets } from "../../../../api/fetchTickets";
import { MaintenanceMonitoringStepsEnum } from "../../../../enums/maintenanceMonitoring";

export const MonitoringList = () => {
  const navigate = useNavigate();
  const [scheduleList, setScheduleList] = useState<MainSchedule[]>([]);
  const [loading, setLoading] = useState(true);
  const [sortedList, setSortedList] = useState<MainSchedule[]>([]);
  const [selectedOption, setSelectedOption] = useState("1");
  const [appointment, setAppointment] = useState<MainSchedule | null>(null);
  const [allTickets, setAllTickets] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);
    const fetchAllSchedules = async () => {
      const schedules = await fetchAllScheduleList();
      setScheduleList(schedules);
      setLoading(false);
    };
    fetchAllSchedules();
  }, []);

  useEffect(() => {
    const sorted = [...scheduleList].sort((a, b) => {
      if (selectedOption === "1") {
        return (
          new Date(b.schedule.scheduledDate).getTime() -
          new Date(a.schedule.scheduledDate).getTime()
        );
      } else if (selectedOption === "2") {
        return (
          new Date(a.schedule.scheduledDate).getTime() -
          new Date(b.schedule.scheduledDate).getTime()
        );
      }
      return 0;
    });

    setSortedList(sorted);
  }, [scheduleList, selectedOption]);

  useEffect(() => {
    const fetchAllTickets = async () => {
      const consultantUser = true;
      try {
        const tickets = await fetchTickets({ consultantUser });
        setAllTickets(tickets);
      } catch (error) {
        console.error("Erro ao buscar tickets:", error);
      }
    };

    fetchAllTickets();
  }, []);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    const months = [
      "janeiro",
      "fevereiro",
      "março",
      "abril",
      "maio",
      "junho",
      "julho",
      "agosto",
      "setembro",
      "outubro",
      "novembro",
      "dezembro",
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} de ${month} de ${year}`;
  };

  const extractTime = (dateTimeString: string): string => {
    const date = new Date(dateTimeString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  const filteredSchedules =
    allTickets.length > 0
      ? sortedList.filter((schedule) =>
          allTickets.some(
            (ticket) =>
              String(ticket.maintenanceScheduleId) === String(schedule.id)
          )
        )
      : sortedList;

  return (
    <div className="mySchedule">
      <Page>
        {loading ? (
          <Loading label="Carregando..." />
        ) : appointment ? (
          <Appointment
            appointment={SchedulingDto(appointment)}
            goBack={() => setAppointment(null)}
          />
        ) : (
          <div className="min-h-screen w-full flex flex-column">
            <h1 className="font-semibold">Acompanhamento da manutenção</h1>

            {filteredSchedules.length > 0 ? (
              filteredSchedules.map((schedule) => (
                <div
                  key={schedule?.id}
                  className="bg-white p-6 w-full margin-top-25 full-schedule"
                >
                  <div className="flex padding-top-10 justify-between items-center mb-4">
                    <div className="flex items-center space-x-2 w-9/12">
                      <span className="text-size-18 font-semibold text-color-map-marker-asset flex items-center w-3/12 truncate">
                        <span className="rioglyph rioglyph-calendar text-color-map-marker-asset"></span>
                        {formatDate(schedule?.schedule?.scheduledDate)}
                      </span>
                      <div className="flex gap-6 schedule-tags w-9/12 media_leftRow">
                        <div className="flex items-center justify-center gap-5 schedule-tags__card">
                          <span className="rioglyph rioglyph-time-alt"></span>
                          <span>
                            {extractTime(schedule?.schedule?.scheduledDate)}
                          </span>
                        </div>

                        <div className="flex items-center justify-center gap-5 schedule-tags__card truncate">
                          <span className="rioglyph rioglyph-workshop"></span>
                          <span>
                            {schedule?.vehicleSchedule?.dealership?.fantasyName}
                          </span>
                        </div>

                        <div className="flex items-center justify-center gap-5 schedule-tags__card truncate">
                          <span className="rioglyph rioglyph-pin"></span>
                          <span>
                            {schedule?.vehicleSchedule?.dealership?.city},{" "}
                            {schedule?.vehicleSchedule?.dealership?.state}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="w-3/12 flex items-center gap-6 justify-end">
                      <span className="flex items-center justify-center rounded-full payment-info text-center">
                        Pagamento na concessionária
                      </span>
                    </div>
                  </div>

                  <hr className="border-gray-200 my-2" />

                  <div className="flex items-start justify-between mb-4 padding-top-25 w-full">
                    <div className="flex space-x-2 w-9/12">
                      <div className="flex flex-col w-3/12">
                        <div className="flex items-center justify-center gap-6 payment-method">
                          <span className="text-size-16 font-bold text-color-map-marker-asset">
                            Valor a ser pago
                          </span>
                          <span className="text-size-14 font-bold flex items-center justify-around price-value">
                            {schedule?.vehicleSchedule?.otherServices?.total}
                          </span>
                        </div>

                        <div className="flex flex-col padding-top-25">
                          {schedule?.vehicleSchedule?.comments ? (
                            <>
                              <span className="text-size-14 font-semibold text-color-highlight-darkest">
                                Importante!
                              </span>
                              <span className="text-size-14 font-normal text-color-highlight-darkest">
                                {schedule?.vehicleSchedule?.comments}
                              </span>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="flex flex-col padding-right-10 media_leftRow">
                        <span className="text-size-14 font-semibold text-color-highlight-darkest">
                          Solicitante
                        </span>
                        <span className="text-size-14 font-normal text-color-highlight-darkest">
                          {schedule?.scheduledBy || schedule?.createdBy}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-size-14 font-semibold text-color-highlight-darkest">
                          Status da Manutenção
                        </span>
                        <span className="text-size-14 font-normal text-color-highlight-darkest">
                          {MaintenanceMonitoringStepsEnum[
                            allTickets.find(
                              (ticket) =>
                                ticket.maintenanceScheduleId === schedule.id
                            )
                              ?.step as keyof typeof MaintenanceMonitoringStepsEnum
                          ] || "Status desconhecido"}
                        </span>
                      </div>
                    </div>
                    <div className="w-3/12 flex items-center justify-end">
                      <div className="flex gap-6 items-center">
                        <img
                          src="/assets/images/vehicle-thumb.png"
                          alt="Veículo"
                          className="w-16 h-16 rounded-md"
                        />
                        <div className="flex flex-col">
                          <span className="text-size-14 font-semibold text-color-highlight-darkest">
                            {schedule?.vehicleSchedule?.vehicle?.vehicleModel}
                          </span>
                          <span className="text-size-14 font-normal text-text-color-darker">
                            Chassi - {schedule?.vehicleSchedule?.vehicle?.chassis}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="display-flex gap-20 margin-top-25 margin-bottom-15">
                    <button
                      type="button"
                      className="btn btn-secondary width-250"
                      onClick={() =>
                        navigate(
                          `/dealership/maintenance-monitoring/monitoring?scheduling-id=${schedule?.id}`
                        )
                      }
                    >
                      Acompanhar manutenção
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex justify-center padding-top-10">
                <span className="text-color-gray">
                  Sem manutenções no momento
                </span>
              </div>
            )}
          </div>
        )}
      </Page>
    </div>
  );
};

export default MonitoringList;
