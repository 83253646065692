import axios from "axios";
import { Asset, AssetGeoLocation, UptimeAsset } from "../models/asset";
import { DealershipAddress } from "../models/dealership";

export const getGeoLocationByAddress = async (
  address: string
): Promise<AssetGeoLocation> => {
  const apiKey = import.meta.env.VITE_HERE_MAPS_API_KEY;
  const url = `https://geocode.search.hereapi.com/v1/geocode?q=${encodeURIComponent(
    address
  )}&apiKey=${apiKey}`;

  try {
    const response = await axios(url);
    const data = response.data;

    if (data.items && data.items.length > 0) {
      const location = data.items[0].position;

      return { lat: location.lat, lng: location.lng };
    } else {
      console.log("Nenhuma localização encontrada para o endereço.");
      return { lat: 0, lng: 0 };
    }
  } catch (error) {
    console.error("Erro ao buscar geolocalização:", error);
    return { lat: 0, lng: 0 };
  }
};

export const getAddressByGeoLocation = async (
  lat?: number,
  lng?: number
): Promise<DealershipAddress> => {
  if (!lat || !lng)
    return { address: "", neighborhood: "", city: "", state: "" };

  const apiKey = import.meta.env.VITE_HERE_MAPS_API_KEY;
  const url = `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${lat},${lng}&apiKey=${apiKey}`;

  try {
    const response = await axios(url);
    const data = response.data;

    if (data.items && data.items.length > 0) {
      const address = data.items[0].address;

      return {
        address: address.street,
        neighborhood: address.district,
        city: address.city,
        state: address.stateCode,
      };
    } else {
      console.log("Nenhum endereço encontrado para a localização.");
      return { address: "", neighborhood: "", city: "", state: "" };
    }
  } catch (error) {
    console.error("Erro ao buscar endereço:", error);
    return { address: "", neighborhood: "", city: "", state: "" };
  }
};

export const getUptimeAsset = (asset: Asset, uptimeAssest: UptimeAsset[]): UptimeAsset | undefined => {
  const found = uptimeAssest.find((u) => u.chassis === asset.chassis);
  return found;
}

export const assetHasMetrics = (asset: Asset, uptimeAssests: UptimeAsset[]): boolean => {
  if (asset.odometer && asset.hourmeter) return true;

  const foundAsset = getUptimeAsset(asset, uptimeAssests);
  if(
    (foundAsset?.odometer && foundAsset?.hourmeter) ||
    (foundAsset?.odometer && asset.hourmeter) ||
    (asset.odometer && foundAsset?.hourmeter)
  ) return true;
  
  return false;
}