import { CheckupDto } from "../dtos/checkupDto";
import { Checkup } from "../models/checkup";
import ApiService from "../services/api";

export const fetchCheckup = async (checkupId: string): Promise<Checkup | undefined> => {
    const apiService = new ApiService();
    
    try {
        const response = await apiService.get<any>(`/checkups/${checkupId}`);
        if (response.status === 200) {
            return CheckupDto(response.data);
        } else return undefined;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}

export const fetchCheckupByMetricAndModel = async (odometer: number, hourmeter: number, chassis: string, group: string): Promise<Checkup | undefined> => {
    const apiService = new ApiService();
    
    try {
        const response = await apiService.get<any>(`/checkups/by-model-group?odometer=${odometer}&hourMeter=${hourmeter}&chassis=${chassis}&maintenanceGroup=${group}`);
        if (response.status === 200) {
            return CheckupDto(response.data);
        } else return undefined;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}