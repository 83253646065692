/**
 * Gets an array of days representing the current month, including the days from the previous and next months
 * to fill the first and last weeks of the calendar view.
 *
 * @returns {number[]} An array of days for the current month, including padding days from the previous and next months.
 */
export const getCurrentMonthDays = (currentDate: Date) => {
	const year = currentDate.getFullYear();
	const month = currentDate.getMonth();
	const firstDayOfMonth = (new Date(year, month, 1).getDay() + 6) % 7;

	const daysInMonth = new Date(year, month + 1, 0).getDate();
	const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

	return [
		...previousMonthDays(month, year, firstDayOfMonth),
		...days,
		...nextMonthDays(firstDayOfMonth, daysInMonth)
	];
};

/**
 * Generates an array representing the days of the previous month that appear in the calendar view of the current month.
 *
 * @param month - The current month (0-based, where 0 = January, 11 = December).
 * @param year - The current year.
 * @param firstDayOfMonth - The day of the week the first day of the current month falls on (0 = Sunday, 6 = Saturday).
 * @returns {number[]} An array of negative numbers representing the days of the previous month that appear in the calendar view.
 */
export const previousMonthDays = (month: number, year: number, firstDayOfMonth: number): number[] => {
	const lastDayOfPreviousMonth = new Date(year, month, 0).getDate();
	return Array.from({ length: firstDayOfMonth }, (_, i) => -1 * (lastDayOfPreviousMonth - firstDayOfMonth + i + 1));
};

/**
 * Calculates the days of the next month that should be displayed in the current month's calendar view.
 *
 * @param firstDayOfMonth - The day of the week the current month starts on (0 for Sunday, 1 for Monday, ..., 6 for Saturday).
 * @param daysInMonth - The number of days in the current month.
 * @returns {number[]} An array of negative numbers representing the days of the next month to be displayed.
 */
export const nextMonthDays = (firstDayOfMonth: number, daysInMonth: number): number[] => {
	const nextMonthStartDay = (firstDayOfMonth + daysInMonth) % 7;
	const emptyDays = 7 - nextMonthStartDay;
	return Array.from({ length: emptyDays }, (_, i) => -1 * (i + 1));
};

/**
 * Checks if the provided day is a valid day in a month.
 *
 * @param day - The day to check, which can be a number or a string.
 * @returns {boolean} `true` if the day is a number greater than 0, otherwise `false`.
 */
export const isDayInMonth = (day: number | string): boolean => typeof day === 'number' && day > 0;

export const formatDate = (date: Date) => {
	const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'long', year: 'numeric' };
	return date.toLocaleDateString('pt-BR', options);
};

/**
 * Returns the first day of the week for the given date.
 * The week starts on Monday. If the given date is a Sunday,
 * it will return the previous Monday.
 *
 * @param date - The date for which to find the first day of the week.
 * @returns {Date} The first day of the week as a Date object.
 */
export const getFirstDayOfWeek = (date: Date): Date => {
	const day = date.getDay();
	const diff = (day === 0 ? -6 : 1) - day;
	const firstDay = new Date(date);
	firstDay.setDate(date.getDate() + diff);
	return firstDay;
};

/**
 * Returns the last day of the week for the given date.
 *
 * @param date - The date for which to find the last day of the week.
 * @returns {Date} The last day of the week as a Date object.
 */
export const getLastDayOfWeek = (date: Date): Date => {
	const firstDay = getFirstDayOfWeek(date);
	const lastDay = new Date(firstDay);
	lastDay.setDate(firstDay.getDate() + 6);
	return lastDay;
};

/**
 * Generates an array of strings representing the days of the week starting from a given date.
 * Each string contains the abbreviated name of the day and the day number.
 *
 * @param {Date} firstDayOfWeek - The date representing the first day of the week.
 * @returns {string[]} An array of strings where each string is in the format "DayName DayNumber".
 */
export const getStringWeekDaysStartingFromDate = (firstDayOfWeek: Date): string[] => {
	const daysOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
	const weekDays = [];

	for (let i = 0; i < 7; i++) {
		const currentDay = new Date(firstDayOfWeek);
		currentDay.setDate(firstDayOfWeek.getDate() + i);
		const dayName = daysOfWeek[currentDay.getDay()];
		const dayNumber = currentDay.getDate();
		weekDays.push(`${dayName} ${dayNumber}`);
	}

	return weekDays;
};

/**
 * Adds a specified number of days to a given date.
 *
 * @param date - The date to which to add days.
 * @param days - The number of days to add.
 * @returns {Date} A new Date object with the specified number of days added.
 */
export const addDays = (date: Date, days: number): Date => {
	const newDate = new Date(date);
	newDate.setDate(date.getDate() + days);
	return newDate;
}

/**
 * Subtracts a specified number of days from a given date.
 *
 * @param date - The date from which to subtract days.
 * @param days - The number of days to subtract.
 * @returns {Date} A new Date object with the specified number of days subtracted.
 */
export const timeDifference = (past: Date): string => {
	const now = new Date();
	const diffInMs = now.getTime() - past.getTime();
	const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
	const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
	const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

	if (diffInMinutes < 60) {
		return `${diffInMinutes} min`;
	} else if (diffInHours < 24) {
		return `${diffInHours} h`;
	} else {
		return `${diffInDays} d`;
	}
};

/**
 * Converts a time string in the format "HH:mm" to the equivalent number of minutes.
 *
 * @param time - The time string to convert.
 * @returns {number} The equivalent number of minutes.
 */
export const convertTimeToMinutes = (time: string): number => {
	const [hours, minutes] = time.split(":").map(Number);
	return hours * 60 + minutes;
};