import { useEffect, useState } from "react";
import { Checkbox, Spinner } from "@rio-cloud/rio-uikit";
import { createChannel, getMembers, IChannel, IMember } from "../../../services/amityService";
import { Loading } from "../../../utils/loading";

interface INewChatProps {
    handleBackToList: () => void;
    handleOpenMessages: (channel: IChannel) => void;
    userId: string;
    channels: IChannel[];
}

const NewChat = ({ handleBackToList, handleOpenMessages, userId, channels }: INewChatProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [users, setUsers] = useState<IMember[]>([]);
    const [selectedUser, setSelectedUser] = useState<IMember | null>(null);

    useEffect(() => {
        setLoading(true);
        getMembers((members) => {
            setUsers(members);
            setLoading(false);
        });
    }, []);
    
    return (
        <div className="chat-view new-chat flex flex-col h-full">
            <div className="chat-header flex justify-between items-center bg-black text-white px-4 py-2">
                <button
                    onClick={handleBackToList}
                    className="text-white focus:outline-none"
                >
                    <span className="rioglyph rioglyph-arrow-left text-color-primary"></span>
                </button>
                <h3 className="text-size-h4 font-bold insert-title">
                    Nova Mensagem
                </h3>
            </div>

            <div className="search-field mt-10">
                <div className="form-group margin-bottom-0">
                    <label className="width-100pct text-color-black text-medium">
                        Convidar pessoas
                        <input
                            className="form-control margin-y-5"
                            type="text"
                            placeholder="Pesquisar"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <button className="btn btn-action padding-0">
                            {loading ? <Spinner /> : <span className="rioglyph rioglyph-search text-color-primary"></span>}
                        </button>
                    </label>
                </div>
            </div>

            <div className="new-chat-body p-4 overflow-y-auto">
                {
                    users
                        .filter((user) => 
                            user.userId !== userId
                            && user.displayName.toLowerCase().includes(searchTerm.toLowerCase())
                        )
                        .map((user) => (
                            <div 
                                className="flex items-center justify-between card-info"
                                onClick={() => {
                                    if(user.userId === selectedUser?.userId) setSelectedUser(null);
                                    else setSelectedUser(user);
                                }}
                            >
                                <div className="w-1/6 padding-5">
                                    <img src={user.profileImage ?? "/assets/images/profile.png"} alt="Profile image" className="size-16 rounded-full object-cover" />
                                </div>
                                <div className="flex flex-column w-4/6 card-info__name">
                                    <div className="text-color-black text-size-18">
                                        {user.displayName.split('__')[0]}
                                    </div>
                                    <div className="text-color-gray text-size-14 truncate">
                                        {user.displayName.split('__')[1]}
                                        {/* Consultor */}
                                    </div>
                                </div>
                                <div className="w-1/6 flex justify-end">
                                    <Checkbox
                                        iconSize={10}
                                        checked={selectedUser?.userId === user.userId}
                                        onChange={(e) => {
                                            if(e.target.checked) setSelectedUser(null);
                                            else setSelectedUser(user);
                                        }}
                                    />
                                </div>
                            </div>
                        ))
                }
            </div>

            {
                loading && <Loading label="Carregando pessoas..." />
            }

            {
                selectedUser && (
                    <div className="new-chat-footer flex justify-center gap-5">
                        <button 
                            type="button" className="btn text-color-primary"
                            onClick={() => setSelectedUser(null)}
                        >
                            Cancelar
                        </button>
                        <button 
                            type="button" className="btn btn-primary"
                            onClick={async () => {
                                // Check if the channel already exists
                                const existingChannel = channels.find((channel) => channel.members.find((member) => member.userId === selectedUser.userId));
                                if(existingChannel) {
                                    handleOpenMessages(existingChannel);
                                    return;
                                }

                                const newChannel = await createChannel([selectedUser], userId);
                                handleOpenMessages(newChannel);
                            }}
                        >
                            Criar conversa
                        </button>
                    </div>
                )
            }
        </div>
    )
}

export default NewChat;