import SimpleTooltip from "@rio-cloud/rio-uikit/SimpleTooltip";
import { CheckupService, ScheduleCheckup } from "../../models/checkup";
import { isOverdueCheckup } from "../../services/periodicReviewsService";

interface ICardProps {
  scheduleCheckup: ScheduleCheckup;
  isOilChange?: boolean;
  index?: number;
}

const Card = ({ scheduleCheckup, isOilChange, index }: ICardProps) => {
  return (
    <div className="border rounded-lg revision-card">
      <div className="flex justify-between items-center mb-2 px-4 border-b border-color-light">
        <p className="p-0 m-0 revision-card__title">
          {scheduleCheckup.checkup.description
            ? scheduleCheckup.checkup.description
            : `Revisão ${String(index).padStart(2, "0")}`}{" "}
        </p>
        <SimpleTooltip
          placement="top"
          textAlignment="left"
          content={scheduleCheckup.checkup.services.map(
            (service: CheckupService, serviceIndex: number) => (
              <div className="padding-5" key={serviceIndex}>
                {service.name}
              </div>
            )
          )}
        >
          <span className="rioglyph rioglyph-eye-option"></span>
        </SimpleTooltip>
      </div>

      {/* <div className="flex justify-between mb-2 text-lg p-4 border-b border-color-light">
        <span className="text-size-14 text-color-dark">
          {scheduleCheckup.checkup.odometer.toLocaleString('pt-Br')} Km
        </span>
        <span className="text-size-14 text-color-dark">{scheduleCheckup.checkup.hourmeter} H</span>
      </div> */}

      <div className="form-group flex flex-wrap justify-between p-4 border-b border-color-light field-section">
        <label className="block w-24 text-size-12 text-color-dark">
          OS nº:
          <input
            type="text"
            readOnly
            value={scheduleCheckup.os}
            className="form-control mt-1 p-2 border border-gray-300 rounded-md text-gray-700"
          />
        </label>
        <div
          className={`form-group ${
            isOverdueCheckup(scheduleCheckup) ? "has-error" : ""
          }`}
        >
          <label className="block w-24 text-size-12 text-color-dark">
            Data:
            <input
              type="text"
              readOnly
              value={
                scheduleCheckup.done
                  ? ""
                  : scheduleCheckup.date?.toLocaleDateString("pt-BR")
              }
              className="form-control mt-1 p-2 border border-gray-300 rounded-md text-gray-700"
            />
          </label>
        </div>
      </div>
      <div className="form-group flex flex-wrap justify-between p-4 border-b border-color-light field-section">
        <label className="block w-full text-size-12 text-color-dark">
          Horímetro:
          <input
            type="text"
            readOnly
            value={scheduleCheckup.done ? scheduleCheckup.hourmeter : "-"}
            className="form-control mt-1 p-2 border border-gray-300 rounded-md text-gray-700"
          />
        </label>
        <label className="block w-full text-size-12 text-color-dark">
          Km:
          <input
            type="text"
            readOnly
            value={
              scheduleCheckup.done
                ? scheduleCheckup.odometer.toLocaleString("pt-Br")
                : "-"
            }
            className="form-control mt-1 p-2 border border-gray-300 rounded-md text-gray-700"
          />
        </label>
      </div>

      {scheduleCheckup.isSigned && (
        <div className="flex justify-between items-center font-semibold text-color-primary text-teal-600 footer-card">
          <span
            className={`w-3/4 text-size-12 ${
              !scheduleCheckup.done ? "text-color-gray" : ""
            }`}
          >
            Carimbo Digital{" "}
            <span
              style={{
                display: scheduleCheckup.done ? "block" : "none",
              }}
            >
              Marcado
            </span>
          </span>
          <span
            className={`w-1/4 text-size-20 rioglyph rioglyph-ok-circle ${
              !scheduleCheckup.done ? "text-color-gray" : ""
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default Card;
