import { useState, SetStateAction } from "react";
import Card from "./card";
import { ScheduleCheckup } from "../../models/checkup";
import { Asset } from "../../models/asset";

interface ICheckupsProps {
  asset: Asset | null;
}

const Checkups = ({ asset }: ICheckupsProps) => {
  const [order, setOrder] = useState<string>("down");
  const [activeTab, setActiveTab] = useState("tab1");

  const handleOrderChange = () => setOrder(order === "down" ? "up" : "down");

  const handleTabClick = (tab: SetStateAction<string>) => {
    setActiveTab(tab);
  };

  return (
    <div className="revision-content">
      <div className="flex justify-between items-center actions-row">
        <button type="button" className="btn btn-default btn-icon-only">
          <span className="rioglyph rioglyph-tachograph-download"></span>
        </button>
        <div className="form-group">
          <div className="display-flex gap-10 align-items-end">
            <div className="form-group">
              <button
                type="button"
                className="btn btn-default"
                onClick={handleOrderChange}
              >
                Por ordem
                <span
                  className={`rioglyph rioglyph-chevron-${order} padding-left-10`}
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center margin-top-20 margin-bottom-20 alert-tabs">
        <button
          className={`p-4 w-1/2 ${
            activeTab === "tab1" ? "active-tab" : "text-gray-500"
          }`}
          onClick={() => handleTabClick("tab1")}
        >
          Próximas
        </button>
        <button
          className={`p-4 w-1/2 ${
            activeTab === "tab2" ? "active-tab" : "text-gray-500"
          }`}
          onClick={() => handleTabClick("tab2")}
        >
          Realizadas
        </button>
        {/* <button
          className={`p-4 w-1/3 ${
            activeTab === "tab3" ? "active-tab" : "text-gray-500"
          }`}
          onClick={() => handleTabClick("tab3")}
        >
          Vencidas
        </button> */}
      </div>

      {activeTab === "tab1" && (
        <>
          <div
            className="grid grid-cols-2 gap-6 scrollable"
            key={asset?.nextCheckup?.id}
          >
            {asset?.nextCheckups
              .sort((a, b) =>
                order === "up"
                  ? b.checkup.odometer - a.checkup.odometer
                  : a.checkup.odometer - b.checkup.odometer
              )
              .map((scheduleCheckup: ScheduleCheckup) => (
                <Card scheduleCheckup={scheduleCheckup} isOilChange={false} />
              ))}
          </div>
        </>
      )}

      {activeTab === "tab2" && (
        <>
          <div
            className="grid grid-cols-2 gap-6 scrollable"
            key={asset?.nextCheckup?.id}
          >
            {Array.isArray(asset?.previousCheckups) &&
              asset?.previousCheckups
                .sort((a, b) =>
                  order === "up"
                    ? b.checkup.odometer - a.checkup.odometer
                    : a.checkup.odometer - b.checkup.odometer
                )
                .map((scheduleCheckup: ScheduleCheckup, index) => (
                  <Card
                    scheduleCheckup={{ ...scheduleCheckup, done: true }}
                    isOilChange={false}
                    index={index + 1}
                  />
                ))}
          </div>
        </>
      )}

      {activeTab === "tab3" && (
        <>
          <div
            className="grid grid-cols-2 gap-6 scrollable"
            key={asset?.nextCheckup?.id}
          >
            {Array.isArray(asset?.previousCheckups) &&
              asset?.previousCheckups
                .sort((a, b) =>
                  order === "up"
                    ? b.checkup.odometer - a.checkup.odometer
                    : a.checkup.odometer - b.checkup.odometer
                )
                .map((scheduleCheckup: ScheduleCheckup) => (
                  <Card scheduleCheckup={scheduleCheckup} isOilChange={false} />
                ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Checkups;
