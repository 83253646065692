import { useState } from "react";
import OverlayTrigger from "@rio-cloud/rio-uikit/OverlayTrigger";
import Tooltip from "@rio-cloud/rio-uikit/Tooltip";

import CheckupDetailDialog from "../dialogs/checkupDetailDialog";
import { ScheduleCheckup } from "../../../../models/checkup";

interface ICheckupInfoProps {
    scheduling?: ScheduleCheckup;
}

const CheckupInfo = ({ scheduling }: ICheckupInfoProps) => {
    const [checkupDetailDialog, setCheckupDetailDialog] = useState<boolean>(false);

    return (
        scheduling ?
            <div>
                <OverlayTrigger
                    key={crypto.randomUUID()}
                    trigger="click"
                    placement="left"
                    overlay={
                        <Tooltip id="tooltip" allowOnTouch>
                            <div className="tooltip-card flex flex-column padding-10 gap-6">
                                <h3 className="flex flex-column text-size-18 font-bold items-start text-left">
                                    {scheduling?.checkup?.name}
                                    <span className="text-size-12 font-normal">
                                        {[scheduling?.date?.toLocaleDateString('pt-BR'), `${scheduling?.odometer?.toLocaleString('pt-Br')} km`].filter(Boolean).join(' ou ')}
                                    </span>
                                </h3>
                                <ul className="text-left">
                                    {
                                        scheduling?.checkup?.services
                                            .slice(0, 4)
                                            .map((service, index) => (
                                                <li key={index}>{service.name}</li>
                                            ))
                                    }
                                </ul>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => setCheckupDetailDialog(true)}
                                >
                                    <span>Ver lista completa</span>
                                </button>
                            </div>
                        </Tooltip>
                    }
                >
                    <span className="cursor-pointer rioglyph rioglyph-exclamation text-size-12 text-color-white icon-revision"></span>
                </OverlayTrigger>

                <CheckupDetailDialog
                    scheduling={scheduling}
                    showCheckupDetailDialog={checkupDetailDialog} 
                    setShowCheckupDetailDialog={(e:boolean) => setCheckupDetailDialog(e)} 
                />
            </div>
            : null
    );
}

export default CheckupInfo;