import { useEffect, useState } from "react";
import AppLayout from "../../../../layout/AppLayout";
import Timeline from "./components/timeline";
import Submenu from "./components/submenu";
import { Divider } from "@rio-cloud/rio-uikit";
import MaintenanceRequest from "./components/maintenanceRequest";
import Inspection from "./components/inspection";
import { fetchScheduling } from "../../../../api/fetchScheduling";
import { useSearchParams } from "react-router-dom";
import { Scheduling } from "../../../../models/schedule";
import { Loading } from "../../../../utils/loading";
import ScreeningDiagnosis from "./components/screeningDiagnosis";
import { MaintenanceMonitoringTimelineStep, Ticket } from "../../../../models/ticket";
import { fetchTicketBySchedulingId } from "../../../../api/ticket";
import { initialTimeline } from "../../../../dtos/ticketDto";
import Repair from "./components/repair";
import { MaintenanceMonitoringStatusEnum, MaintenanceMonitoringStepsEnum } from "../../../../enums/maintenanceMonitoring";
import Release from "./components/release";
import { convertTimeToMinutes } from "../../../../utils/dateUtils";

const Monitoring = () => {
  const [searchParams] = useSearchParams();

  const [step, setStep] = useState<number>(0);
  const [currentStatusStep, setCurrentStatusStep] = useState<number | undefined>(undefined);

  const [scheduling, setScheduling] = useState<Scheduling | undefined>(undefined);
  const [ticket, setTicket] = useState<Ticket | undefined>(undefined);
  const [timeline, setTimeline] = useState<MaintenanceMonitoringTimelineStep[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      const schedulingId = searchParams.get("scheduling-id");
      if (!schedulingId) return setStep(0), setCurrentStatusStep(0);

      const scheduling = await fetchScheduling(schedulingId);
      if (!scheduling) return setStep(0), setCurrentStatusStep(0);
      setScheduling(scheduling);

      const ticket = await fetchTicketBySchedulingId(schedulingId);
      setTicket({
        ...ticket,
        request: { ...ticket?.request, schedulingId },
        timeline: ticket?.timeline ?? initialTimeline,
      });

      setTimeline(ticket?.timeline ?? initialTimeline);
      const currentStep = (ticket?.timeline ?? initialTimeline)?.findIndex(step => !step.time);
      setStep(currentStep < 0 ? 0 : currentStep), setCurrentStatusStep(currentStep < 0 ? 0 : currentStep);
    }

    fetchData();
  }, [searchParams]);

  const [doneTime, setDoneTime] = useState<number>(0);
  const [progressTime, setProgressTime] = useState<number>(0);
  const [totalTime, setTotalTime] = useState<number>(0);
  const [overTime, setOverTime] = useState<number>(0);
  useEffect(() => {
    const interval = setInterval(() => {
      const done = timeline
        .filter(step => step.status === MaintenanceMonitoringStatusEnum.DONE)
        .reduce((acc, step) => acc + (step.time ?? 0), 0);
      setDoneTime(done);

      const progress = timeline
        .filter(step => step.status === MaintenanceMonitoringStatusEnum.IN_PROGRESS)
        .reduce((acc, step) => acc + (Math.floor((new Date().getTime() - step?.date!.getTime()) / 60000)), 0);
      setProgressTime(progress);

      const findEstimatedTime = (step: MaintenanceMonitoringStepsEnum): number => {
        return timeline.find(el => el.step === MaintenanceMonitoringStepsEnum.REPAIR)?.estimatedTime ?? 0;
      }

      const estimatedTime = 
        (convertTimeToMinutes(ticket?.repair?.estimatedTime ?? '0:0') ?? findEstimatedTime(MaintenanceMonitoringStepsEnum.REPAIR)) +
        (convertTimeToMinutes(ticket?.inspection?.estimatedTime ?? '0:0') ?? findEstimatedTime(MaintenanceMonitoringStepsEnum.INSPECTION)) +
        (convertTimeToMinutes(ticket?.screening?.estimatedTimeTroubleshooting ?? '0:0') ?? findEstimatedTime(MaintenanceMonitoringStepsEnum.SCREENING)) +
        (convertTimeToMinutes(ticket?.release?.estimatedTime ?? '0:0') ?? findEstimatedTime(MaintenanceMonitoringStepsEnum.RELEASE));
      setTotalTime((done + progress) > estimatedTime ? (done + progress) : estimatedTime);
      setOverTime((done + progress) - estimatedTime < 0 ? 0 : (done + progress) - estimatedTime);
    }, 1000);
    return () => clearInterval(interval);
  }, [
    step, timeline,
    ticket?.repair?.estimatedTime,
    ticket?.inspection?.estimatedTime,
    ticket?.screening?.estimatedTimeTroubleshooting,
    ticket?.release?.estimatedTime,
  ]);

  return (
    <AppLayout>
      <>
        {currentStatusStep === undefined && <Loading label="Carregando dados..." />}

        {ticket && (
          <>
            <Submenu />
            <div className="container bg-white padding-20 width-100pct margin-top-20">
              <h3>Progresso da manutenção</h3>
              <Timeline
                ticket={ticket}
                setStep={(step: number) => setStep(step)}
                timeline={timeline}
                doneTime={doneTime}
                progressTime={progressTime}
                overTime={overTime}
                totalTime={totalTime}
              />

              <Divider className="margin-top-20" />

              {step === 0 && <MaintenanceRequest scheduling={scheduling} fanceData={undefined} ticket={ticket} />}
              {step === 1 && <ScreeningDiagnosis screening={ticket?.screening} ticketId={ticket?.id} scheduling={scheduling} />}
              {step === 2 && <Repair repair={ticket?.repair} ticketId={ticket?.id} />}
              {step === 3 && <Inspection scheduling={scheduling} inspection={ticket?.inspection} ticketId={ticket?.id} request={ticket?.request} />}
              {step === 4 && <Release release={ticket?.release} ticketId={ticket?.id} />}
            </div>
          </>
        )}
      </>
    </AppLayout>
  );
};

export default Monitoring;
