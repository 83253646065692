import { SchedulingType } from '../enums/taskType';
import { Scheduling, DaySchedule, WeekSchedule } from '../models/schedule';
import { addDays } from '../utils/dateUtils';

export const getMaintenanceDaySchedule = async (date: Date, monthSchedulings: Scheduling[]): Promise<DaySchedule | undefined> => {
	return {
		date,
		schedulings: monthSchedulings
			.filter((scheduling: Scheduling) => 
				scheduling.scheduleTime.getDate() === date.getDate()
			)
	};
};

export const getMaintenanceWeekSchedule = async (date: Date, monthSchedulings: Scheduling[]): Promise<WeekSchedule> => {
	const weekSchedule: WeekSchedule = {
		firstWeekDay: date,
		lastWeekDay: addDays(date, 6),
		days: []
	};
	
	for (let i = 0; i < 7; i++) {
		const currentDate = new Date(date);
		currentDate.setDate(date.getDate() + i);
		const schedule = await getMaintenanceDaySchedule(currentDate, monthSchedulings);
		if (schedule) {
			weekSchedule.days.push(schedule);
		}
	}
	return weekSchedule;
}

export const getDailyOccupancyRate = (daySchedule: DaySchedule): number => {
	const totalHoursInDay = 12;
	const occupiedHours = daySchedule.schedulings.length;

	return parseFloat(((occupiedHours / totalHoursInDay) * 100).toFixed(1));
};

export const getWeeklyOccupancyRate = (weekSchedule: WeekSchedule): number => {
	const totalHoursInDay = 12;
	const totalDaysInWeek = 7;
	const totalAvailableHours = totalHoursInDay * totalDaysInWeek;

	const occupiedHours =  weekSchedule
		.days
		.reduce((prev, daySchedule) => prev += daySchedule.schedulings.length, 0);

	return parseFloat(((occupiedHours / totalAvailableHours) * 100).toFixed(1));
};

export const getMonthlyOccupancyRate = (monthSchedulings: Scheduling[], date: Date): number => {
	const totalHoursInDay = 12;
	const daysInMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
	const totalAvailableHours = totalHoursInDay * daysInMonth;

	const occupiedHours = monthSchedulings.length;

	return parseFloat(((occupiedHours / totalAvailableHours) * 100).toFixed(1));
};

export const getDailySchedulingCounts = (daySchedule: DaySchedule): number[] => {
	let preventiveCount = 0;
	let correctiveCount = 0;

	daySchedule.schedulings.forEach(scheduling => {
		if (scheduling.type === SchedulingType.PREVENTIVA) {
			preventiveCount++;
		} else if (scheduling.type === SchedulingType.CORRETIVA) {
			correctiveCount++;
		}
	});

	return [preventiveCount, correctiveCount];
};

export const getWeeklySchedulingCounts = (weekSchedule: WeekSchedule): number[] => {
	let preventiveCount = 0;
	let correctiveCount = 0;

	weekSchedule.days.forEach(daySchedule => {
		daySchedule.schedulings.forEach(scheduling => {
			if (scheduling.type === SchedulingType.PREVENTIVA) {
				preventiveCount++;
			} else if (scheduling.type === SchedulingType.CORRETIVA) {
				correctiveCount++;
			}
		});
	});

	return [preventiveCount, correctiveCount];
};

export const getMonthlySchedulingCounts = (monthSchedulings: Scheduling[]): number[] => {
	let preventiveCount = 0;
	let correctiveCount = 0;

	monthSchedulings.forEach(scheduling => {
		if (scheduling.type === SchedulingType.PREVENTIVA) {
			preventiveCount++;
		} else if (scheduling.type === SchedulingType.CORRETIVA) {
			correctiveCount++;
		}
	});

	return [preventiveCount, correctiveCount];
};