import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Page from "../Page";

import { Loading } from "../../../utils/loading";
import { Asset, AssetSummary } from "../../../models/asset";
import { Dealership } from "../../../models/dealership";

import { fetchScheduling } from "../../../api/fetchScheduling";

import AssetsAndDriversForm, { IAssetAndDriverForm } from "./components/assetsAndDriversForm";
import DealershipForm from "./components/dealershipForm";
import DateAndConsultantForm, { IDateAndConsultantForm } from "./components/dateAndConsultantForm";
import AppointmentSummary from "./components/appointmentSummary";

import { fetchAssets } from "../../../api/fetchAssets";
import { fetchScheduleList } from "../../../api/fetchScheduleList";

import { createAppointmentFormFromScheduling } from "../../../services/schedulingService";

import "./styles.scss";

export interface IAppointmentForm {
  assetsAndDrivers?: IAssetAndDriverForm;
  dealership?: Dealership;
  dateAndConsultant?: IDateAndConsultantForm;
}

export const NewAppointments = () => {
  const [loading, setLoading] = useState(true);

  const [step, setStep] = useState(1);
  const [appointmentForm, setAppointmentForm] = useState<IAppointmentForm>({
    assetsAndDrivers: {
      driver: { id: "", name: "-", license: "", phone: "", email: "" }
    },
    dealership: { id: "", name: "", address: "", geoLocation: { lat: 0, lng: 0 } },
    dateAndConsultant: {},
  });
  const [appointmentId, setAppointmentId] = useState<string | null>(null);

  const [assets, setAssets] = useState<AssetSummary[]>([]);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      
      const schedulings = await fetchScheduleList();
      const assets = await fetchAssets();
      setAssets(assets.filter(asset => !schedulings.find(scheduling => scheduling.vehicleSchedule.vehicle.assetId === asset.id)));

      const schedulingId = searchParams.get("scheduling-id");
      if (schedulingId) {
        const appointment = await fetchScheduling(schedulingId);
        if(appointment) {
          setAppointmentForm(createAppointmentFormFromScheduling(appointment, []));
          setStep(3);
          setAppointmentId(schedulingId);
        }
      }

      setLoading(false);
    }

    fetchData()
  }, []);

  return (
    <div className="mySchedule">
      <Page>
        <div className="min-h-screen w-full flex justify-center">
          {
            loading ?
              <Loading label="Carregando dados..." /> :
              <div className="w-full h-full bg-white">
                <div className="stepsSchedule">

                  {step === 1 &&
                    <AssetsAndDriversForm
                      setStep={setStep}
                      assets={assets}
                      form={appointmentForm?.assetsAndDrivers}
                      setForm={(form: IAssetAndDriverForm) => setAppointmentForm({ ...appointmentForm, assetsAndDrivers: form })}
                    />
                  }

                  {step === 2 &&
                    <DealershipForm
                      asset={appointmentForm?.assetsAndDrivers?.asset}
                      setAsset={(asset: Asset) => {
                          setAppointmentForm({ 
                            ...appointmentForm, 
                            assetsAndDrivers: { ...appointmentForm?.assetsAndDrivers, asset }
                          })
                      }}
                      dealership={appointmentForm?.dealership}
                      setDealership={(dealership: Dealership) =>  setAppointmentForm({ ...appointmentForm, dealership })}
                      setStep={setStep}
                    />
                  }

                  {step === 3 && (
                    <DateAndConsultantForm 
                      dealership={appointmentForm?.dealership}
                      setStep={setStep}
                      form={appointmentForm?.dateAndConsultant}
                      setForm={(form: IDateAndConsultantForm) => setAppointmentForm({ ...appointmentForm, dateAndConsultant: form })}
                      isReschedule={!!appointmentId}
                    />
                  )}

                  {step === 4 && (
                    <AppointmentSummary
                      appointmentForm={appointmentForm}
                      setStep={setStep}
                      schedulingId={appointmentId}
                    />
                  )}
                </div>
              </div>
          }
        </div>
      </Page>
    </div>
  );
};
