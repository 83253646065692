import { SchedulingType } from "../enums/taskType";
import { Scheduling } from "../models/schedule";
import { AssetDto } from "./assetDto";
import { dealershipDto } from "./dealershipDto";
import { driverDto } from "./driverDto";

export const SchedulingDto = (data: any): Scheduling => {
  return {
    id: data.id,
    type: SchedulingType.PREVENTIVA, //data.checkup.checkupType.name,
    description: data?.checkup ? `Revisão de ${data.checkup.range?.start.toLocaleString("pt-Br")} km` : `Revisão realizada com ${data.schedule?.odometer.toLocaleString("pt-Br")} km`, //`${data.checkup.desc ?? ''}  ${data.checkup?.range?.end || data.checkup?.range?.start ? `(${data.checkup?.range?.end || data.checkup?.range?.start} km)` : ''}`,
    shortDescription: data?.checkup ? `Revisão de ${data.checkup.range?.start.toLocaleString("pt-Br")} km` : `Revisão realizada com ${data.schedule?.odometer.toLocaleString("pt-Br")} km`, //data.checkup.desc ?? '',
    asset: AssetDto(data.vehicleSchedule),
    driver: driverDto(data.vehicleSchedule.driver),
    observation: data.vehicleSchedule.generalObservations,
    comments: data.vehicleSchedule.comments,
    consultant: `${data.consultant?.firstName ?? ""} ${
      data.consultant?.lastName ?? ""
    }`,
    dealership: dealershipDto(data.vehicleSchedule.dealership),
    scheduleTime: new Date(data.schedule.scheduledDate),
    services: (data.checkup?.checkupType.services ?? []).map((service: any) => ({
      name: service.name,
      items: service.items,
    })),
    otherServices: (data.vehicleSchedule.otherServices?.services ?? []).map(
      (service: any) => service.name
    ),
    totalPrice: data.vehicleSchedule.otherServices?.total ?? 0,
    campaigns: data.campaigns,
    scheduledBy: data.scheduledBy,
    maintenanceStatus: data.vehicleSchedule.maintenance?.statusId,
    parts: data.checkup?.parts,
  };
};
