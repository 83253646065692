import { useState } from "react";
import { OverlayTrigger, Popover } from "@rio-cloud/rio-uikit";
import { Scheduling } from "../../../../models/schedule";
import { useNavigate } from "react-router-dom";
import DeleteDialog from "./deleteDialog";

import "../styles.scss";

interface IAppointmentCardProps {
  appointment: Scheduling;
  chooseScheduling: (scheduling: Scheduling) => void;
  fetchAppointmens: () => void;
}

const AppointmentCard = ({
  appointment,
  chooseScheduling,
  fetchAppointmens,
}: IAppointmentCardProps) => {
  const navigate = useNavigate();

  const [selectedScheduleId, setSelectedScheduleId] = useState<string | null>(
    null
  );

  return (
    <>
      <div
        key={appointment.id}
        className="bg-white p-6 w-full margin-top-25 full-schedule"
      >
        <div className="flex padding-top-10 justify-between items-center mb-4">
          <div className="flex items-center space-x-2 w-9/12">
            <span className="text-size-18 font-semibold text-color-map-marker-asset flex items-center w-3/12 truncate">
              <span className="rioglyph rioglyph-calendar text-color-map-marker-asset"></span>
              {appointment.scheduleTime.toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              })}
            </span>
            <div className="flex gap-6 schedule-tags w-9/12 media_leftRow">
              <div className="flex items-center justify-center gap-5 schedule-tags__card">
                <span className="rioglyph rioglyph-time-alt"></span>
                <span>
                  {appointment.scheduleTime.toLocaleTimeString("pt-BR", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              </div>

              <div className="flex items-center justify-center gap-5 schedule-tags__card truncate">
                <span className="rioglyph rioglyph-workshop"></span>
                <span>{appointment.dealership.name}</span>
              </div>

              <div className="flex items-center justify-center gap-5 schedule-tags__card truncate">
                <span className="rioglyph rioglyph-pin"></span>
                <span>{appointment.dealership.address}</span>
              </div>
            </div>
          </div>
          <div className="w-3/12 flex items-center gap-6 justify-end">
            <span className="flex items-center justify-center rounded-full payment-info text-center">
              Pagamento na concessionária
            </span>
            <OverlayTrigger
              trigger="click"
              key="left"
              placement="left"
              overlay={
                <Popover id={`popover-positioned-left`}>
                  <div
                    className="popover-item flex items-center cursor-pointer"
                    onClick={() =>
                      navigate(
                        `/control-tower/new-appointment?scheduling-id=${appointment.id}`
                      )
                    }
                  >
                    <span className="text-color-darkest rioglyph rioglyph-calendar"></span>
                    <span className="text-color-darkest text-size-14 font-semibold">
                      Reagendar
                    </span>
                  </div>

                  <div
                    className="popover-item flex items-center cursor-pointer"
                    onClick={() => chooseScheduling(appointment)}
                  >
                    <span className="text-color-darkest text-size-h4 rioglyph rioglyph-eye-option"></span>
                    <span className="text-color-darkest text-size-14 font-semibold">
                      Ver detalhes
                    </span>
                  </div>

                  <div
                    className="popover-item flex items-center cursor-pointer"
                    onClick={() => setSelectedScheduleId(appointment.id)}
                  >
                    <span className="text-color-darkest text-size-h4 rioglyph rioglyph-remove-circle"></span>
                    <span className="text-color-darkest text-size-14 font-semibold">
                      Cancelar
                    </span>
                  </div>
                </Popover>
              }
            >
              <div>
                <span className="rioglyph rioglyph-option-vertical cursor-pointer"></span>
              </div>
            </OverlayTrigger>
          </div>
        </div>

        <hr className="border-gray-200 my-2" />

        <div className="flex items-start justify-between mb-4 padding-top-25">
          <div className="flex space-x-2 w-9/12">
            <div className="flex flex-col w-3/12">
              <div className="flex items-center justify-center gap-6 payment-method">
                <span className="text-size-16 font-bold text-color-map-marker-asset">
                  Valor a ser pago
                </span>
                <span className="text-size-14 font-bold flex items-center justify-around price-value">
                  {appointment.totalPrice}
                </span>
              </div>

              <div className="flex flex-col padding-top-25">
                {appointment.comments ? (
                  <>
                    <span className="text-size-14 font-semibold text-color-highlight-darkest">
                      Importante!
                    </span>
                    <span className="text-size-14 font-normal text-color-highlight-darkest">
                      {appointment.comments}
                    </span>
                  </>
                ) : null}
              </div>
            </div>
            <div className="flex flex-col padding-right-10 media_leftRow">
              <span className="text-size-14 font-semibold text-color-highlight-darkest">
                Solicitante
              </span>
              <span className="text-size-14 font-normal text-color-highlight-darkest">
                {appointment.scheduledBy || appointment.consultant}
              </span>
            </div>
          </div>
          <div className="w-3/12 flex items-center justify-end">
            <div className="flex gap-6 items-center">
              <img
                src="/assets/images/vehicle-thumb.png"
                alt="Veículo"
                className="w-16 h-16 rounded-md"
              />
              <div className="flex flex-col">
                <span className="text-size-14 font-semibold text-color-highlight-darkest">
                  {appointment.asset.name}
                </span>
                <span className="text-size-14 font-normal text-text-color-darker">
                  Chassi - {appointment.asset.chassis}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="display-flex gap-20 margin-top-25 margin-bottom-15">
          <button
            type="button"
            className="btn btn-secondary width-250"
            onClick={() =>
              navigate(
                `/control-tower/maintenance-monitoring/monitoring?scheduling-id=${appointment?.id}`
              )
            }
          >
            Acompanhar manutenção
          </button>
        </div>
      </div>

      <DeleteDialog
        schedulingId={selectedScheduleId}
        handleClose={() => setSelectedScheduleId(null)}
        fetchAppointmens={fetchAppointmens}
      />
    </>
  );
};

export default AppointmentCard;
