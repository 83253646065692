import ApiService from "../services/api";

export const fetchManuals = async (chassis: string): Promise<any> => {
  const apiService = new ApiService();

  try {
    const response = await apiService.get<any>(`/manual?chassi=${chassis}`);
    if (response.status === 200) {
      return response.data.result || [];
    } else {
      console.warn("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching manuals:", error);
    return [];
  }
};
