import { ticketDto } from "../dtos/ticketDto";
import {
  ICheckupChecklist,
  Ticket,
  TicketInspection,
  TicketRelease,
  TicketRepair,
  TicketScreening,
} from "../models/ticket";
import ApiService from "../services/api";

export const fetchTickets = async ({
  consultantUser,
}: {
  consultantUser: boolean;
}): Promise<Ticket[]> => {
  const apiService = new ApiService();
  try {
    const route = consultantUser ? "consultant-user" : "manager-account";
    const response = await apiService.get<any[]>(
      `/maintenance/ticket/${route}`
    );
    if (response.status < 400) {
      return response.data.map(ticketDto);
    } else {
      console.warn("Unexpected status code:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching tickets:", error);
    return [];
  }
};

export const fetchTicketsByChassisList = async ({
  chassisList,
}: {
  chassisList: string[];
}): Promise<Ticket[]> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.post<any[]>(
      `/maintenance/ticket/by-chassis-list`,
      { chassisList }
    );
    if (response.status < 400) {
      return response.data.map(ticketDto);
    } else {
      console.warn("Unexpected status code:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching tickets:", error);
    return [];
  }
};

export const fetchTicket = async (
  ticketId: string
): Promise<Ticket | undefined> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.get<any>(
      `/maintenance/ticket/${ticketId}`
    );
    if (response.status < 400) {
      return ticketDto(response.data);
    } else {
      console.warn("Unexpected status code:", response.status);
      return undefined;
    }
  } catch (error) {
    console.error("Error fetching ticket:", error);
    return undefined;
  }
};

export const fetchTicketBySchedulingId = async (
  schedulingId: string
): Promise<Ticket | undefined> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.get<any>(
      `/maintenance/ticket/${schedulingId}/by-schedule-id`
    );
    if (response.status < 400) {
      return ticketDto(response.data);
    } else {
      console.warn("Unexpected status code:", response.status);
      return undefined;
    }
  } catch (error) {
    console.error("Error fetching ticket:", error);
    return undefined;
  }
};

export const openTicket = async ({
  schedulingId,
  date,
}: {
  schedulingId: string;
  date: Date;
}): Promise<string> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.post<void>(`/maintenance/ticket`, {
      maintenanceScheduleId: schedulingId,
      checkInDate: date.toISOString().split("T")[0],
      checkInHour:
        date.getHours().toString().padStart(2, "0") +
        ":" +
        date.getMinutes().toString().padStart(2, "0"),
    });
    if (response.status < 400) {
      return (response.data as any).id;
    } else {
      console.warn("Unexpected status code:", response.status);
      return "";
    }
  } catch (error) {
    console.error("Error post ticket:", error);
    return "";
  }
};

export const postCheckoutTicket = async ({
  ticketId,
  date,
}: {
  ticketId: string;
  date: Date;
}): Promise<void> => {
  const apiService = new ApiService();
  try {
    await apiService.post<void>(
      `/maintenance/ticket/${ticketId}/ticket/checkout`,
      {
        checkOutDate: date.toISOString().split("T")[0],
        checkOutHour:
          date.getHours().toString().padStart(2, "0") +
          ":" +
          date.getMinutes().toString().padStart(2, "0"),
      }
    );
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message ?? "Verifique os dados e tente novamente"
    );
  }
};

export const putScreening = async ({
  ticketId,
  screening,
}: {
  ticketId: string;
  screening: TicketScreening;
}): Promise<void> => {
  const apiService = new ApiService();
  try {
    await apiService.post<void>(`/maintenance/ticket/${ticketId}/screening`, {
      ...screening,
      serviceOrderDate: screening.serviceOrderDate
        ? screening.serviceOrderDate.toISOString()
        : undefined,
      checkInDate: screening.checkIn
        ? screening.checkIn.toISOString().split("T")[0]
        : undefined,
      checkInHour: screening.checkIn
        ? screening.checkIn.getHours().toString().padStart(2, "0") +
          ":" +
          screening.checkIn.getMinutes().toString().padStart(2, "0")
        : undefined,
      checkOutDate: screening.checkOut
        ? screening.checkOut.toISOString().split("T")[0]
        : undefined,
      checkOutHour: screening.checkOut
        ? screening.checkOut.getHours().toString().padStart(2, "0") +
          ":" +
          screening.checkOut.getMinutes().toString().padStart(2, "0")
        : undefined,
    });
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message ?? "Verifique os dados e tente novamente"
    );
  }
};

export const putRepair = async ({
  ticketId,
  repair,
}: {
  ticketId: string;
  repair: TicketRepair;
}): Promise<void> => {
  const apiService = new ApiService();
  try {
    await apiService.post<void>(`/maintenance/ticket/${ticketId}/repair`, {
      ...repair,
      repairDate: repair.repairDate
        ? repair.repairDate.toISOString().split("T")[0]
        : undefined,
      checkInDate: repair.checkIn
        ? repair.checkIn.toISOString().split("T")[0]
        : undefined,
      checkInHour: repair.checkIn
        ? repair.checkIn.getHours().toString().padStart(2, "0") +
          ":" +
          repair.checkIn.getMinutes().toString().padStart(2, "0")
        : undefined,
      checkOutDate: repair.checkOut
        ? repair.checkOut.toISOString().split("T")[0]
        : undefined,
      checkOutHour: repair.checkOut
        ? repair.checkOut.getHours().toString().padStart(2, "0") +
          ":" +
          repair.checkOut.getMinutes().toString().padStart(2, "0")
        : undefined,
    });
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message ?? "Verifique os dados e tente novamente"
    );
  }
};

export const putInspection = async ({
  ticketId,
  inspection,
  checklist,
}: {
  ticketId: string;
  inspection: TicketInspection;
  checklist?: ICheckupChecklist[] | undefined;
}): Promise<void> => {
  const apiService = new ApiService();
  try {
    await Promise.all([
      apiService.post<void>(`/maintenance/ticket/${ticketId}/inspection`, {
        ...inspection,
        checkInDate: inspection.checkIn
          ? inspection.checkIn.toISOString().split("T")[0]
          : undefined,
        checkInHour: inspection.checkIn
          ? inspection.checkIn.getHours().toString().padStart(2, "0") +
            ":" +
            inspection.checkIn.getMinutes().toString().padStart(2, "0")
          : undefined,
      }),
      apiService.post<void>(
        `/maintenance/ticket/${ticketId}/checklist/checkup`,
        (checklist ?? [])
          .map((service) =>
            service.items.map((item) => ({ id: item.id, ok: item.ok }))
          )
          .flat()
      ),
    ]);

    if (inspection.checkOut) {
      await apiService.post<void>(
        `/maintenance/ticket/${ticketId}/inspection/checkout`,
        {
          checkOutDate: inspection.checkOut
            ? inspection.checkOut.toISOString().split("T")[0]
            : undefined,
          checkOutHour: inspection.checkOut
            ? inspection.checkOut.getHours().toString().padStart(2, "0") +
              ":" +
              inspection.checkOut.getMinutes().toString().padStart(2, "0")
            : undefined,
        }
      );
    }
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message ?? "Verifique os dados e tente novamente"
    );
  }
};

export const putRelease = async ({
  ticketId,
  release,
}: {
  ticketId: string;
  release: TicketRelease;
}): Promise<void> => {
  const apiService = new ApiService();
  try {
    await apiService.post<void>(`/maintenance/ticket/${ticketId}/release`, {
      ...release,
      checkInDate: release.checkIn
        ? release.checkIn.toISOString().split("T")[0]
        : undefined,
      checkInHour: release.checkIn
        ? release.checkIn.getHours().toString().padStart(2, "0") +
          ":" +
          release.checkIn.getMinutes().toString().padStart(2, "0")
        : undefined,
    });

    if (release.checkOut) {
      await apiService.post<void>(
        `/maintenance/ticket/${ticketId}/release/checkout`,
        {
          checkOutDate: release.checkOut
            ? release.checkOut.toISOString().split("T")[0]
            : undefined,
          checkOutHour: release.checkOut
            ? release.checkOut.getHours().toString().padStart(2, "0") +
              ":" +
              release.checkOut.getMinutes().toString().padStart(2, "0")
            : undefined,
        }
      );
    }
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message ?? "Verifique os dados e tente novamente"
    );
  }
};

export interface ITicketFile {
  name: string;
  updatedAt: string;
  contentType: string;
  contentSize: number;
  link: string;
}

export const uploadTicketImage = async ({
  ticketId,
  imageFile,
  step,
}: {
  ticketId: string;
  imageFile: File[];
  step: string;
}): Promise<boolean> => {
  const apiService = new ApiService();
  const formData = new FormData();
  imageFile.forEach((file) => formData.append("file", file));

  try {
    const response = await apiService.post<void>(
      `/attachment/ticket/${ticketId}/${step}/upload`,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    if (response.status < 400) {
      return true;
    } else {
      console.warn("Unexpected status code:", response.status);
      return false;
    }
  } catch (error) {
    console.error("Error uploading ticket image:", error);
    return false;
  }
};

export const fetchTicketImages = async ({
  ticketId,
  step,
}: {
  ticketId: string;
  step: string;
}): Promise<ITicketFile[]> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.get<ITicketFile[]>(
      `/attachment/ticket/${ticketId}/${step}`
    );
    if (response.status < 400) {
      return response.data;
    } else {
      console.warn("Unexpected status code:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching ticket images:", error);
    return [];
  }
};

export const downloadTicketImage = async ({
  ticketId,
  step,
  filename,
}: {
  ticketId?: string;
  step: string;
  filename?: string;
}): Promise<any> => {
  if (!filename || !ticketId) return;
  const apiService = new ApiService();
  try {
    return await apiService.getAndDownloadFile(
      `/attachment/ticket/${ticketId}/${step}/${filename}/download`
    );
  } catch (error) {
    console.error("Error fetching ticket images:", error);
  }
};

export const removeTicketImage = async ({
  ticketId,
  step,
  filename,
}: {
  ticketId: string;
  step: string;
  filename: string;
}): Promise<boolean> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.delete<void>(
      `/attachment/ticket/${ticketId}/${step}/${filename}`
    );
    if (response.status < 400) {
      return true;
    } else {
      console.warn("Unexpected status code:", response.status);
      return false;
    }
  } catch (error) {
    console.error("Error removing ticket image:", error);
    return false;
  }
};
