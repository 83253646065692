import { NotFoundState } from "@rio-cloud/rio-uikit";

const EmptyTable = () => {
    return (
        <table className="margin-top-10 w-full">
            <tr>
                <td colSpan={5}>
                    <NotFoundState
                        headline="Não encontramos nada que corresponda à sua pesquisa"
                        message='Por favor, refine sua pesquisa e tente novamente.'
                        outerClassName='border-none'
                    />
                </td>
            </tr>
        </table>
    )
}

export default EmptyTable;