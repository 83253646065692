import { useEffect, useState } from "react";
import { TicketScreening } from "../../../../../models/ticket";
import {
  downloadTicketImage,
  fetchTicketImages,
  ITicketFile,
} from "../../../../../api/ticket";
import { Scheduling } from "../../../../../models/schedule";
import { DatePicker, Select } from "@rio-cloud/rio-uikit";
import moment, { Moment } from "moment";
import { ScreeningChecklistDialog } from "../dialogs/screeningChecklistDialog";
import { Asset } from "../../../../../models/asset";
import { formatFileSize } from "../../../../../utils/imageSizeUtils";
// import 'moment/dist/locale/pt-BR';

interface IScreeningDiagnosisProps {
  ticketId: string | undefined;
  screening: TicketScreening | undefined;
  scheduling: Scheduling | undefined;
}

const ScreeningDiagnosis = ({
  ticketId,
  screening,
  scheduling,
}: IScreeningDiagnosisProps) => {
  const [files, setFiles] = useState<ITicketFile[]>([]);
  const [, setState] = useState<number>(0);

  const [checklistDialog, setChecklistDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (screening && ticketId) {
        const images = await fetchTicketImages({
          ticketId: ticketId,
          step: "SCREENING",
        });
        setFiles(images);
      }
    };

    fetchData();
  }, [screening]);

  return (
    <div>
      <div className="resume-section">
        <div className="resume-container padding-0">
          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="Criticality">Criticidade de atendimento</label>
              <Select
                id="Criticality"
                disabled
                value={
                  screening?.criticality
                    ? [screening.criticality.toString()]
                    : undefined
                }
                options={[
                  {
                    id: "5",
                    label: "Emergência",
                    icon: (
                      <span className="rioglyph rioglyph-sphere text-color-danger" />
                    ),
                  },
                  {
                    id: "4",
                    label: "Muito urgente",
                    icon: (
                      <span className="rioglyph rioglyph-sphere text-color-warning" />
                    ),
                  },
                  {
                    id: "3",
                    label: "Urgente",
                    icon: (
                      <span className="rioglyph rioglyph-sphere text-color-rating-3" />
                    ),
                  },
                  {
                    id: "2",
                    label: "Pouco urgente",
                    icon: (
                      <span className="rioglyph rioglyph-sphere text-color-rating-4" />
                    ),
                  },
                  {
                    id: "1",
                    label: "Não urgente",
                    icon: (
                      <span className="rioglyph rioglyph-sphere text-color-map-marker-route" />
                    ),
                  },
                ]}
              />
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="concessionaria">Nome da Concessionária</label>
              <input
                id="concessionaria"
                className="form-control"
                type="text"
                // value={screening?.dealership}
                value={scheduling?.dealership.name}
                onChange={(e) => (screening!.dealership = e.target.value)}
                disabled
              />
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="cnpj">CNPJ</label>
              <div className="input-group">
                <input
                  id="cnpj"
                  className="form-control"
                  type="text"
                  placeholder="CNPJ"
                  value={screening?.corporateTaxId}
                  onChange={(e) => (screening!.corporateTaxId = e.target.value)}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="local">Local</label>
              <input
                id="local"
                className="form-control"
                type="text"
                // value={screening?.location}
                value={`${scheduling?.dealership.address} ${scheduling?.dealership.city} - ${scheduling?.dealership.state}`}
                onChange={(e) => (screening!.location = e.target.value)}
                disabled
              />
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="representante">Representante</label>
              <div className="input-group">
                <input
                  id="representante"
                  className="form-control"
                  type="text"
                  // value={screening?.agentName}
                  value={scheduling?.consultant}
                  onChange={(e) => (screening!.agentName = e.target.value)}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="checkin">Check-in</label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-calendar"></span>
                </span>
                <input
                  id="checkin"
                  className="form-control"
                  type="text"
                  placeholder="--/--/----"
                  disabled
                  value={screening?.checkIn?.toLocaleDateString("pt-BR")}
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="hora-checkin" className="opacity-0">
                Hora
              </label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="hora-checkin"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  disabled
                  value={screening?.checkIn?.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })}
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="checkout">Check-out</label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-calendar"></span>
                </span>
                <input
                  id="checkout"
                  className="form-control"
                  type="text"
                  placeholder="--/--/----"
                  disabled
                  value={screening?.checkOut?.toLocaleDateString("pt-BR")}
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="hora-checkout" className="opacity-0">
                Hora
              </label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="hora-checkout"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  disabled
                  value={screening?.checkOut?.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/3">
              <label htmlFor="checkinMotorista">Check-in do Motorista</label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-pin"></span>
                </span>
                <input
                  id="hora-checkout"
                  className="form-control"
                  type="text"
                  placeholder="Localização"
                  value={screening?.checkInDriverLocation}
                  onChange={(e) =>
                    (screening!.checkInDriverLocation = e.target.value)
                  }
                  disabled
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/3">
              <label htmlFor="aberturaOs">Abertura da OS</label>
              <input
                id="aberturaOs"
                className="form-control"
                type="text"
                placeholder="Número"
                value={screening?.serviceOrder}
                onChange={(e) => (screening!.serviceOrder = e.target.value)}
                disabled
              />
            </div>

            <div className="form-group form-group-lg w-1/3">
              <label htmlFor="dataHoraOs">Data e Hora de abertura da OS</label>
              <DatePicker
                id="dataHoraOs"
                dateFormat="DD-MM-YYYY"
                timeFormat="HH:mm"
                value={
                  screening?.serviceOrderDate
                    ? moment(screening.serviceOrderDate)
                        .subtract(3, "hours")
                        .format("DD/MM/YYYY HH:mm")
                    : ""
                }
                onChange={(result: Moment | string) => {
                  screening!.serviceOrderDate = new Date(result.toString());
                  setState((prevState) => prevState + 1);
                }}
              />
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="indicaFalha">Indicação de Falha</label>
              <input
                id="indicaFalha"
                className="form-control"
                type="text"
                value={screening?.failIndication}
                onChange={(e) => (screening!.failIndication = e.target.value)}
                disabled
              />
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="codeFalha">Código de Falha</label>
              <div className="input-group">
                <input
                  id="codeFalha"
                  className="form-control"
                  type="text"
                  value={screening?.failCode}
                  onChange={(e) => (screening!.failCode = e.target.value)}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="ferramentaDiagnose">Ferramenta de Diagnose</label>
              <input
                id="ferramentaDiagnose"
                className="form-control"
                type="text"
                value={screening?.diagnosisTool}
                onChange={(e) => (screening!.diagnosisTool = e.target.value)}
                disabled
              />
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="lerDiagnose">Leitura de Diagnose</label>
              <div className="input-group">
                <input
                  id="lerDiagnose"
                  className="form-control"
                  type="text"
                  value={screening?.diagnosisReport}
                  onChange={(e) =>
                    (screening!.diagnosisReport = e.target.value)
                  }
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="troubleshooting">
                Literatura para Troubleshooting
              </label>
              <input
                id="troubleshooting"
                className="form-control"
                type="text"
                value={screening?.troubleshootingDoc}
                onChange={(e) =>
                  (screening!.troubleshootingDoc = e.target.value)
                }
                disabled
              />
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="tempoEstimado">Tempo Estimado</label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="tempoEstimado"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  value={screening?.estimatedTimeTroubleshooting}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "").slice(0, 4);
                    const formattedValue = value.replace(
                      /(\d{2})(\d{2})/,
                      "$1:$2"
                    );
                    screening!.estimatedTimeTroubleshooting = formattedValue;
                    setState((prevState) => prevState + 1);
                  }}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="tempoEstimadoBox">
                Tempo Estimado para Entrada no Box
              </label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="tempoEstimadoBox"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  value={screening?.estimatedTimeBoxEntry}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "").slice(0, 4);
                    const formattedValue = value.replace(
                      /(\d{2})(\d{2})/,
                      "$1:$2"
                    );
                    screening!.estimatedTimeBoxEntry = formattedValue;
                    setState((prevState) => prevState + 1);
                  }}
                  disabled
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="tempoEstimadoReparo">
                Tempo Estimado de Reparo
              </label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="tempoEstimadoReparo"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  value={screening?.estimatedTimeRepair}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "").slice(0, 4);
                    const formattedValue = value.replace(
                      /(\d{2})(\d{2})/,
                      "$1:$2"
                    );
                    screening!.estimatedTimeRepair = formattedValue;
                    setState((prevState) => prevState + 1);
                  }}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="flex justify-center w-full">
            <button
              type="button"
              className="btn btn-primary btn-lg padding-left-25 padding-right-25 margin-top-10"
              onClick={() => setChecklistDialog(true)}
            >
              <span
                className="rioglyph rioglyph-ok-circle"
                aria-hidden="true"
              ></span>
              <span className="text-capitalize">Checklist</span>
            </button>
          </div>

          <div className="flex justify-between w-full gap-6 margin-top-25">
            <div className="form-group form-group-lg w-full">
              <label htmlFor="relato">Relato</label>
              <textarea
                className="form-control height-100"
                id="relato"
                placeholder="Descritivo"
                value={screening?.report}
                onChange={(e) => (screening!.report = e.target.value)}
                disabled
              ></textarea>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-full">
              <label htmlFor="relato">Inspeção visual</label>
              <div>
                <div className="display-grid grid-cols-1 grid-cols-3-sm gap-25">
                  {files.length > 0 ? (
                    files.map((file, index) => (
                      <FilePreview
                        key={index}
                        file={file}
                        ticketId={ticketId}
                      />
                    ))
                  ) : (
                    <div className="text-color-dark text-italic">
                      Nenhum arquivo
                    </div>
                  )}
                </div>
                ;
              </div>
            </div>
          </div>
        </div>
      </div>

      <ScreeningChecklistDialog
        showDialog={checklistDialog}
        onClose={() => setChecklistDialog(false)}
        checkupParts={scheduling?.parts}
        asset={scheduling?.asset}
        partsChecked={[]}
      />
    </div>
  );
};

const FilePreview = ({
  file,
  ticketId,
}: {
  file: ITicketFile | undefined;
  ticketId: string | undefined;
}) => (
  <div
    className="panel panel-default margin-top-20 cursor-pointer"
    onClick={async () => {
      if (ticketId && file) {
        const response = await downloadTicketImage({
          ticketId: ticketId,
          step: "SCREENING",
          filename: file.name,
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.name);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      }
    }}
  >
    <div className="panel-body">
      {file && (
        <div className="flex">
          {file.link && (
            <div className="thumbnail margin-right-10">
              <img
                src={file.link}
                alt={file.name}
                style={{
                  width: "200px",
                  height: "120px",
                  objectFit: "cover",
                }}
              />
            </div>
          )}
          <div className="flex justify-between w-full">
            <div>
              <div>
                <b>{`${file?.name}`}</b>
              </div>
              <div>tipo: {`${file?.contentType}`}</div>
              <div>tamanho: {formatFileSize(file?.contentSize)}</div>
              <div>
                data da última modificação:{" "}
                {file?.updatedAt
                  ? moment(file.updatedAt).format("DD/MM/YYYY")
                  : "Data não disponível"}
              </div>{" "}
              <div>caminho: {`${file?.name}`}</div>
            </div>
          </div>
        </div>
      )}
      {!file && (
        <div className="text-color-dark text-italic">Nenhum arquivo</div>
      )}
    </div>
  </div>
);

export default ScreeningDiagnosis;
