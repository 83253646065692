import { useEffect, useState } from "react";
import { MessageRepository } from "@amityco/ts-sdk";
import { createTextMessage, IChannel, markAllMessageAsRead } from "../../../services/amityService";

interface IChatProps {
    handleBackToList: () => void;
    channel?: IChannel | null;
    userId: string;
}

interface IMessage {
    id: string;
    text: string;
    sender: string;
    createdAt: Date;
}

const Chat = ({ handleBackToList, channel, userId }: IChatProps) => {
    const [newMessage, setNewMessage] = useState('');
    const [chatMessages, setChatMessages] = useState<IMessage[]>([]);
    
    const subscribeToChannelMessages = (channelId: string) => {
        const unsub = MessageRepository.getMessages(
            {subChannelId: channelId, limit: 100},
            ({data: messages, error}) => {
                if (error) return;
                
                if(messages){
                    setChatMessages(
                        messages.map((message) => ({
                            id: message.messageId,
                            text: (message.data as Amity.ContentDataText).text,
                            sender: message.creatorId,
                            createdAt: new Date(message.createdAt),
                        }))
                    );
                }
            },
        );

        return unsub;
    }

    useEffect(() => {
        if (channel) {
            subscribeToChannelMessages(channel.id);
            channel.hasUnreadMessage = false;
            markAllMessageAsRead(channel.id);
        }
    }, [channel]);

    return (
        <div className="chat-view flex flex-col h-full">
            <div className="chat-header flex justify-between items-center bg-black text-white px-4 py-2">
                <button
                    onClick={handleBackToList}
                    className="text-white focus:outline-none"
                >
                    <span className="rioglyph rioglyph-arrow-left text-color-primary"></span>
                </button>
                <h3 className="text-size-h4 font-bold insert-title">Mensagens</h3>
            </div>

            <div className="chat-body">
                {/* <div className="flex items-center open-call">
                    <span className="rioglyph rioglyph-pencil-square text-color-primary text-size-h4"></span>
                    <span className="text-size-14 text-medium open-call__text">
                        Abertura de chamado para Meteor
                    </span>
                </div> */}

                <div className="chat-details flex items-center gap-6 px-4 py-3">
                    <img
                        src={channel?.profileImage ?? "/assets/images/profile.png"}
                        alt="Foto de Usuário"
                        className="size-16 rounded-full object-cover"
                    />
                    <div className="flex flex-col w-4/6">
                        {/* <span className="text-size-12 text-color-status-resting">
                            Consultora
                        </span> */}
                        <span className="text-size-18 text-color-black font-medium">
                            {channel?.displayName?.split('__')[0]}
                        </span>
                    </div>
                    {/* <span className="flex justify-end w-1/6">
                        <img src="assets/images/more.svg" alt="" />
                    </span> */}
                </div>

                <div className="chat-messages flex flex-col-reverse gap-4 p-4 overflow-y-auto">
                    {
                        chatMessages.map((message, index) => {
                            return (
                                <>
                                    <p
                                        key={message.id}
                                        className={`message ${message.sender === userId ? 'sent' : 'received'} mb-4`}
                                        >
                                        <span className={`block message-text text-white px-3 py-2 max-w-max ${message.sender === userId ? 'ml-auto' : ''}`}>
                                            {message.text}
                                            <span className="block text-xs mt-1">
                                                {message.createdAt.toLocaleTimeString('pt-Br', {hour: '2-digit', minute: '2-digit'})}
                                            </span>
                                        </span>
                                    </p>
                                    {(index === (chatMessages.length - 1) || message.createdAt.toDateString() !== chatMessages[index + 1]?.createdAt.toDateString()) && (
                                        <div className="text-center text-size-12 text-gray-500 my-2 mt-6">
                                            {message.createdAt.toLocaleDateString('pt-Br', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                        </div>
                                    )}
                                </>
                            )
                        })
                    }
                </div>

                <div className="chat-input flex items-center gap-5 p-4 border-t bg-white">
                    <input
                        type="text"
                        className="flex-grow px-4 py-2 border rounded-full text-size-14 focus:outline-none focus:ring-2 focus:ring-teal-600"
                        placeholder="Mensagem"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                (() => [createTextMessage(newMessage, channel?.id ?? ''), setNewMessage('')])();
                            }
                        }}
                    />

                    <button type="button" className="btn btn-action padding-0">
                        <img src="assets/images/button-voice.svg" alt="" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Chat;