import { useEffect, useState } from "react";
import { Divider } from "@rio-cloud/rio-uikit";
import Page from "../../Page";
import Submenu from "./components/submenu";
import Timeline from "./components/timeline";
import MaintenanceRequest from "./components/maintenanceRequest";
import Inspection from "./components/inspection";
import { Scheduling } from "../../../../models/schedule";
import { fetchScheduling } from "../../../../api/fetchScheduling";
import { useSearchParams } from "react-router-dom";
import { Loading } from "../../../../utils/loading";
import {
  MaintenanceMonitoringTimelineStep,
  Ticket,
  TicketInspection,
  TicketRelease,
  TicketRepair,
  TicketScreening,
} from "../../../../models/ticket";
import {
  fetchTicket,
  fetchTicketBySchedulingId,
  openTicket,
  postCheckoutTicket,
  putInspection,
  putRelease,
  putRepair,
  putScreening,
} from "../../../../api/ticket";
import { initialTimeline } from "../../../../dtos/ticketDto";
import ScreeningDiagnosis from "./components/screeningDiagnosis";
import {
  MaintenanceMonitoringStatusEnum,
  MaintenanceMonitoringStepsEnum,
} from "../../../../enums/maintenanceMonitoring";
import { FinishStepDialog } from "./dialogs/finishStepDialog";
import Repair from "./components/repair";
import ErrorDialog from "../../../../components/ErrorDialog";
import Release from "./components/release";
import { fetchAllScheduleList } from "../../../../api/fetchScheduleList";
import { MainSchedule } from "../../../../models/scheduleList";
import { convertTimeToMinutes } from "../../../../utils/dateUtils";

const MaintenanceMonitoring = () => {
  const [searchParams] = useSearchParams();

  const [finishStepDialog, setFinishStepDialog] = useState<boolean>(false);
  const [finishStepDialogLoading, setFinishStepDialogLoading] =
    useState<boolean>(false);
  const [errorDialog, setErrorDialog] = useState<{
    show: boolean;
    title: string;
    body: string;
  }>({ show: false, title: "", body: "" });

  const [step, setStep] = useState<number>(0);
  const [currentStatusStep, setCurrentStatusStep] = useState<
    number | undefined
  >(undefined);

  const [scheduling, setScheduling] = useState<Scheduling | undefined>(
    undefined
  );
  const [ticket, setTicket] = useState<Ticket | undefined>(undefined);
  const [timeline, setTimeline] = useState<MaintenanceMonitoringTimelineStep[]>(
    []
  );
  useEffect(() => {
    const fetchData = async () => {
      const schedulingId = searchParams.get("scheduling-id");
      if (!schedulingId) return setStep(0), setCurrentStatusStep(0);

      const scheduling = await fetchScheduling(schedulingId);
      if (!scheduling) return setStep(0), setCurrentStatusStep(0);
      setScheduling(scheduling);

      const ticket = await fetchTicketBySchedulingId(schedulingId);

      setTicket({
        ...ticket,
        request: { ...ticket?.request, schedulingId },
        timeline: ticket?.timeline ?? initialTimeline,
      });

      setTimeline(ticket?.timeline ?? initialTimeline);
      const currentStep = (ticket?.timeline ?? initialTimeline)?.findIndex(
        (step) => !step.time && step.time !== 0
      );
      setStep(currentStep < 0 ? 0 : currentStep),
        setCurrentStatusStep(currentStep < 0 ? 0 : currentStep);
    };

    fetchData();
  }, [searchParams]);

  const [doneTime, setDoneTime] = useState<number>(0);
  const [progressTime, setProgressTime] = useState<number>(0);
  const [totalTime, setTotalTime] = useState<number>(0);
  const [overTime, setOverTime] = useState<number>(0);
  const [scheduleList, setScheduleList] = useState<MainSchedule[]>([]);

  useEffect(() => {
    const interval = setInterval(() => {
      const done = timeline
        .filter((step) => step.status === MaintenanceMonitoringStatusEnum.DONE)
        .reduce((acc, step) => acc + (step.time ?? 0), 0);
      setDoneTime(done);

      const progress = timeline
        .filter(
          (step) => step.status === MaintenanceMonitoringStatusEnum.IN_PROGRESS
        )
        .reduce(
          (acc, step) =>
            acc +
            Math.floor((new Date().getTime() - step?.date!.getTime()) / 60000),
          0
        );
      setProgressTime(progress);

      const findEstimatedTime = (
        step: MaintenanceMonitoringStepsEnum
      ): number => {
        return (
          timeline.find(
            (el) => el.step === MaintenanceMonitoringStepsEnum.REPAIR
          )?.estimatedTime ?? 0
        );
      };

      const estimatedTime =
        (convertTimeToMinutes(ticket?.repair?.estimatedTime ?? "0:0") ??
          findEstimatedTime(MaintenanceMonitoringStepsEnum.REPAIR)) +
        (convertTimeToMinutes(ticket?.inspection?.estimatedTime ?? "0:0") ??
          findEstimatedTime(MaintenanceMonitoringStepsEnum.INSPECTION)) +
        (convertTimeToMinutes(
          ticket?.screening?.estimatedTimeTroubleshooting ?? "0:0"
        ) ?? findEstimatedTime(MaintenanceMonitoringStepsEnum.SCREENING)) +
        (convertTimeToMinutes(ticket?.release?.estimatedTime ?? "0:0") ??
          findEstimatedTime(MaintenanceMonitoringStepsEnum.RELEASE));

      setTotalTime(
        done + progress > estimatedTime ? done + progress : estimatedTime
      );
      setOverTime(
        done + progress - estimatedTime < 0
          ? 0
          : done + progress - estimatedTime
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [
    step,
    timeline,
    ticket?.repair?.estimatedTime,
    ticket?.inspection?.estimatedTime,
    ticket?.screening?.estimatedTimeTroubleshooting,
    ticket?.release?.estimatedTime,
  ]);

  const startStep = async (
    index: number,
    updatedTimeline?: MaintenanceMonitoringTimelineStep[] | undefined
  ) => {
    const startDateTime = new Date();
    setTimeline(
      (updatedTimeline ?? timeline).map((step, i) => {
        if (i === index) {
          return {
            ...step,
            status: MaintenanceMonitoringStatusEnum.IN_PROGRESS,
            date: startDateTime,
            time: 0,
          };
        }
        return step;
      })
    );

    if (index === 0) {
      const id = await openTicket({
        date: startDateTime,
        schedulingId: ticket?.request?.schedulingId ?? "",
      });
      const createdTicket = await fetchTicket(id);
      setTicket(createdTicket);
    }
    if (index === 1)
      putScreening({
        screening: { checkIn: startDateTime },
        ticketId: ticket?.id ?? "",
      }),
        (ticket!.screening = {
          ...(ticket?.screening ?? {}),
          checkIn: startDateTime,
        });
    if (index === 2)
      putRepair({
        repair: { checkIn: startDateTime },
        ticketId: ticket?.id ?? "",
      }),
        (ticket!.repair = {
          ...(ticket?.repair ?? {}),
          checkIn: startDateTime,
        });
    if (index === 3)
      putInspection({
        inspection: { checkIn: startDateTime },
        ticketId: ticket?.id ?? "",
        checklist: ticket?.request?.checkupChecklist,
      }),
        (ticket!.inspection = {
          ...(ticket?.inspection ?? {}),
          checkIn: startDateTime,
        });
    if (index === 4)
      putRelease({
        release: { checkIn: startDateTime },
        ticketId: ticket?.id ?? "",
      }),
        (ticket!.release = {
          ...(ticket?.release ?? {}),
          checkIn: startDateTime,
        });
  };

  // const finishStep = async (index: number): Promise<boolean> => {
  //   const finishDateTime = new Date();

  //   try {
  //     if (index === 0) {
  //       await postCheckoutTicket({
  //         date: finishDateTime,
  //         ticketId: ticket?.id ?? "",
  //       });
  //       ticket!.request!.checkOut = finishDateTime;
  //     }

  //     if (index === 1) {
  //       await putScreening({
  //         screening: { ...ticket?.screening, checkOut: finishDateTime },
  //         ticketId: ticket?.id ?? "",
  //       });
  //       ticket!.screening!.checkOut = finishDateTime;
  //     }

  //     if (index === 2) {
  //       await putRepair({
  //         repair: { ...ticket?.repair, checkOut: finishDateTime },
  //         ticketId: ticket?.id ?? "",
  //       });
  //       ticket!.repair!.checkOut = finishDateTime;
  //     }

  //     if (index === 3) {
  //       await putInspection({
  //         inspection: { ...ticket?.inspection, checkOut: finishDateTime },
  //         ticketId: ticket?.id ?? "",
  //         checklist: ticket?.request?.checkupChecklist,
  //       });
  //       ticket!.inspection!.checkOut = finishDateTime;
  //     }

  //     if (index === 4) {
  //       await putRelease({
  //         release: { ...ticket?.release, checkOut: finishDateTime },
  //         ticketId: ticket?.id ?? "",
  //       });
  //       ticket!.release!.checkOut = finishDateTime;
  //     }
  //   } catch (error: any) {
  //     setErrorDialog({
  //       show: true,
  //       title: "Erro ao finalizar etapa",
  //       body: error.message,
  //     });
  //     return false;
  //   }

  //   const updatedTimeline = timeline.map((step, i) => {
  //     if (i === index) {
  //       return {
  //         ...step,
  //         status: MaintenanceMonitoringStatusEnum.DONE,
  //         time: Math.floor(
  //           (finishDateTime.getTime() - step.date!.getTime()) / 60000
  //         ),
  //       };
  //     }
  //     return step;
  //   });
  //   setTimeline(updatedTimeline);

  //   if (index < 4) {
  //     startStep(index + 1, updatedTimeline);
  //     setCurrentStatusStep(index + 1);
  //     setStep(index + 1);
  //   }

  //   return true;
  // };

  const finishStep = async (index: number): Promise<boolean> => {
    const finishDateTime = new Date();

    try {
      if (index === 4) {
        console.log(
          "Validando campos da etapa de Liberação...",
          ticket?.release
        );

        if (!ticket?.release) {
          console.error("Erro: `ticket.release` está indefinido.");
          setErrorDialog({
            show: true,
            title: "Erro ao finalizar a etapa",
            body: "Os dados da Liberação não foram carregados corretamente.",
          });
          return false;
        }

        if (!ticket.release.serviceOrder) {
          console.warn("Erro: `serviceOrder` está vazio.");
          setErrorDialog({
            show: true,
            title: "Erro ao finalizar a etapa",
            body: "O campo 'Fechamento OS' é obrigatório.",
          });
          return false;
        }

        if (!ticket.release.estimatedTime) {
          console.warn("Erro: `estimatedTime` está vazio.");
          setErrorDialog({
            show: true,
            title: "Erro ao finalizar a etapa",
            body: "O campo 'Tempo Estimado' é obrigatório.",
          });
          return false;
        }

        if (
          ticket.release.estimatedTime.length !== 5 ||
          !/^([01]\d|2[0-3]):([0-5]\d)$/.test(ticket.release.estimatedTime)
        ) {
          console.warn(
            "Erro: `estimatedTime` tem um formato inválido.",
            ticket.release.estimatedTime
          );
          setErrorDialog({
            show: true,
            title: "Erro ao finalizar a etapa",
            body: "O campo 'Tempo Estimado' deve estar no formato hh:mm.",
          });
          return false;
        }
      }

      console.log("Campos validados com sucesso, finalizando etapa...");

      if (index === 0) {
        await postCheckoutTicket({
          date: finishDateTime,
          ticketId: ticket?.id ?? "",
        });
        ticket!.request!.checkOut = finishDateTime;
      }

      if (index === 1) {
        await putScreening({
          screening: { ...ticket?.screening, checkOut: finishDateTime },
          ticketId: ticket?.id ?? "",
        });
        ticket!.screening!.checkOut = finishDateTime;
      }

      if (index === 2) {
        await putRepair({
          repair: { ...ticket?.repair, checkOut: finishDateTime },
          ticketId: ticket?.id ?? "",
        });
        ticket!.repair!.checkOut = finishDateTime;
      }

      if (index === 3) {
        await putInspection({
          inspection: { ...ticket?.inspection, checkOut: finishDateTime },
          ticketId: ticket?.id ?? "",
          checklist: ticket?.request?.checkupChecklist,
        });
        ticket!.inspection!.checkOut = finishDateTime;
      }

      if (index === 4) {
        await putRelease({
          release: { ...ticket?.release, checkOut: finishDateTime },
          ticketId: ticket?.id ?? "",
        });
        ticket!.release!.checkOut = finishDateTime;
      }
    } catch (error: any) {
      console.error("Erro ao finalizar etapa:", error);
      setErrorDialog({
        show: true,
        title: "Erro ao finalizar etapa",
        body: error.message,
      });
      return false;
    }

    console.log("Etapa finalizada com sucesso!");

    const updatedTimeline = timeline.map((step, i) => {
      if (i === index) {
        return {
          ...step,
          status: MaintenanceMonitoringStatusEnum.DONE,
          time: Math.floor(
            (finishDateTime.getTime() - step.date!.getTime()) / 60000
          ),
        };
      }
      return step;
    });
    setTimeline(updatedTimeline);

    if (index < 4) {
      startStep(index + 1, updatedTimeline);
      setCurrentStatusStep(index + 1);
      setStep(index + 1);
    }

    return true;
  };

  useEffect(() => {
    const fetchAllSchedules = async () => {
      const schedules = await fetchAllScheduleList();
      setScheduleList(schedules);
    };
    fetchAllSchedules();
  }, []);

  const formatDate = (dateString: string | undefined): string => {
    if (!dateString) return "Data não disponível";

    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Data inválida";

    return date.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  return (
    <Page>
      <>
        {currentStatusStep === undefined && (
          <Loading label="Carregando dados..." />
        )}

        {ticket && (
          <>
            <Submenu />
            <div className="container bg-white padding-20 width-100pct margin-top-20">
              <h3>Progresso da manutenção</h3>
              <p>
                Data do agendamento:{" "}
                {formatDate(
                  scheduleList.find(
                    (schedule) =>
                      schedule.id === searchParams.get("scheduling-id")
                  )?.schedule.scheduledDate
                )}
              </p>

              <Timeline
                ticket={ticket}
                setStep={(step: number) => setStep(step)}
                currentStatusStep={currentStatusStep}
                timeline={timeline}
                doneTime={doneTime}
                progressTime={progressTime}
                overTime={overTime}
                totalTime={totalTime}
                startStep={startStep}
                finishStep={() => setFinishStepDialog(true)}
              />

              <Divider className="margin-top-20" />

              {step === 0 && (
                <MaintenanceRequest
                  scheduling={scheduling}
                  fanceData={undefined}
                />
              )}
              {step === 1 && (
                <ScreeningDiagnosis
                  screening={ticket?.screening}
                  setScreening={(screening: TicketScreening) =>
                    setTicket({ ...ticket, screening })
                  }
                  ticketId={ticket?.id}
                  setCheckOut={() => setFinishStepDialog(true)}
                  scheduling={scheduling}
                />
              )}
              {step === 2 && (
                <Repair
                  repair={ticket?.repair}
                  setRepair={(repair: TicketRepair) =>
                    setTicket({ ...ticket, repair })
                  }
                  ticketId={ticket?.id}
                />
              )}
              {step === 3 && (
                <Inspection
                  inspection={ticket?.inspection}
                  setInspection={(inspection: TicketInspection) =>
                    setTicket({ ...ticket, inspection })
                  }
                  ticketId={ticket?.id}
                  request={ticket?.request}
                  scheduling={scheduling}
                />
              )}
              {step === 4 && (
                <Release
                  release={ticket?.release}
                  setRelease={(release: TicketRelease) =>
                    setTicket({ ...ticket, release })
                  }
                  ticketId={ticket?.id}
                  setCheckOut={() => setFinishStepDialog(true)}
                />
              )}
            </div>
          </>
        )}

        <FinishStepDialog
          open={finishStepDialog}
          onClose={() => setFinishStepDialog(false)}
          loading={finishStepDialogLoading}
          onFinish={async () => {
            setFinishStepDialogLoading(true);
            await finishStep(currentStatusStep!);
            setFinishStepDialogLoading(false);
            setFinishStepDialog(false);
          }}
        />

        <ErrorDialog
          show={errorDialog.show}
          title={errorDialog.title}
          body={errorDialog.body}
          onClose={() => setErrorDialog({ show: false, title: "", body: "" })}
        />
      </>
    </Page>
  );
};

export default MaintenanceMonitoring;
