import { AssetDto } from "../dtos/assetDto";
import { Asset } from "../models/asset";
import { BigNumber } from "../models/bigNumbers";
import ApiV2Service from "../services/apiV2";

export const fetchMaintenance = async ({
  ids,
  groupIds,
  limit,
  lastAssetId,
  chassis,
}: {
  ids?: string[];
  groupIds?: string[];
  limit?: number;
  lastAssetId?: string;
  chassis?: string;
}): Promise<[Asset[], BigNumber?]> => {
  const apiService = new ApiV2Service();

  try {
    const query: string[] = [
      ids?.length ? `assetIds=${ids.join(",")}` : "",
      groupIds?.length ? `groupIds=${groupIds.join(",")}` : "",
      limit ? `limit=${limit}` : "",
      lastAssetId ? `after=${lastAssetId}` : "",
      chassis ? `chassis=${chassis}` : "",
    ].filter(Boolean);

    const response = await apiService.get<any>(
      `/maintenance?${query.join("&")}`
    );
    if (response.status === 200) {
      const assets = response.data.list.map(AssetDto);
      return [
        assets,
        //BigNumberDto(response.data.bigNumbers, assets)
      ];
    } else return [[]];
  } catch (error) {
    console.error(error);
    return [[]];
  }
};
