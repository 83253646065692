import { useEffect, useState } from "react";
import StatsWidgets from "@rio-cloud/rio-uikit/StatsWidgets";

import MaintenanceBigNumbers from "./maintenanceBigNumbers";
import RioPlanBigNumbers from "./rioPlanBigNumbers";
import VolksTotalPlanBigNumbers from "./volksTotalPlanBigNumbers";
import { fetchAssets } from "../../../../../api/fetchAssets";
import { AssetSummary } from "../../../../../models/asset";

import "./styles.scss";

interface IBigNumbersProps {
  assetIds: string[];
  setAssetIds: (e: string[]) => void;
}

const BigNumbers = ({ assetIds, setAssetIds }: IBigNumbersProps) => {
  const [filter, setFilter] = useState<string>("");
  const [assets, setAssets] = useState<AssetSummary[]>([]);
  const [initialAssetIds, setInitialAssetIds] = useState<AssetSummary[]>([]);

  const [previousStateFlag, setPreviousStateFlag] = useState<boolean>(false);
  const [previousAssetIds, setPreviousAssetIds] = useState<string[]>([]);

  // Rio big numbers 
  const [withRioPlanAssetIds, setWithRioPlanAssetIds] = useState<string[]>([]);
  const [withoutRioPlanAssetIds, setWithoutRioPlanAssetIds] = useState<string[]>([]);
  const [byRioPlan, setByRioPlan] = useState<{ [key: string]: string[] }>({});
  const [previousWithRioPlanAssetIds, setPreviousWithRioPlanAssetIds] = useState<string[]>([]);
  const [previousWithoutRioPlanAssetIds, setPreviousWithoutRioPlanAssetIds] = useState<string[]>([]);
  const [previousByRioPlan, setPreviousByRioPlan] = useState<{ [key: string]: string[] }>({});

  // Maintenance big numbers
  const [toScheduleAssetIds, setToScheduleAssetIds] = useState<string[]>([]);
  const [delayedMaintenanceAssetIds, setDelayedMaintenanceAssetIds] = useState<string[]>([]);
  const [scheduledAssetIds, setScheduledAssetIds] = useState<string[]>([]);
  const [underMaintenanceAssetIds, setUnderMaintenanceAssetIds] = useState<string[]>([]);
  const [previousToScheduleAssetIds, setPreviousToScheduleAssetIds] = useState<string[]>([]);
  const [previousDelayedMaintenanceAssetIds, setPreviousDelayedMaintenanceAssetIds] = useState<string[]>([]);
  const [previousScheduledAssetIds, setPreviousScheduledAssetIds] = useState<string[]>([]);
  const [previousUnderMaintenanceAssetIds, setPreviousUnderMaintenanceAssetIds] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const assets = await fetchAssets();
      setInitialAssetIds(assets.filter(asset => assetIds.includes(asset.id)));
      setAssets(assets);
    };

    if (assetIds.length) fetchData();
  }, [assetIds]);

  const setPreviousData = () => {
    setPreviousAssetIds(initialAssetIds.map(el => el.id));

    setPreviousWithRioPlanAssetIds(withRioPlanAssetIds);
    setPreviousWithoutRioPlanAssetIds(withoutRioPlanAssetIds);
    setPreviousByRioPlan(byRioPlan);

    setPreviousToScheduleAssetIds(toScheduleAssetIds);
    setPreviousDelayedMaintenanceAssetIds(delayedMaintenanceAssetIds);
    setPreviousScheduledAssetIds(scheduledAssetIds);
    setPreviousUnderMaintenanceAssetIds(underMaintenanceAssetIds);
  }

  const setToPreviousData = () => {
    setPreviousStateFlag(true);
    setAssetIds(previousAssetIds);

    setWithRioPlanAssetIds(previousWithRioPlanAssetIds);
    setWithoutRioPlanAssetIds(previousWithoutRioPlanAssetIds);
    setByRioPlan(previousByRioPlan);

    setToScheduleAssetIds(previousToScheduleAssetIds);
    setDelayedMaintenanceAssetIds(previousDelayedMaintenanceAssetIds);
    setScheduledAssetIds(previousScheduledAssetIds);
    setUnderMaintenanceAssetIds(previousUnderMaintenanceAssetIds);
  };

  return (
    <>
      <StatsWidgets className="big-numbers">

        <MaintenanceBigNumbers
          initialAssetIds={initialAssetIds}
          assets={assets}
          assetIds={assetIds}
          setAssetIds={setAssetIds}
          filter={filter}
          setFilter={setFilter}
          previousStateFlag={previousStateFlag}
          setPreviousStateFlag={setPreviousStateFlag}
          toScheduleAssetIds={toScheduleAssetIds}
          setToScheduleAssetIds={setToScheduleAssetIds}
          delayedMaintenanceAssetIds={delayedMaintenanceAssetIds}
          setDelayedMaintenanceAssetIds={setDelayedMaintenanceAssetIds}
          scheduledAssetIds={scheduledAssetIds}
          setScheduledAssetIds={setScheduledAssetIds}
          underMaintenanceAssetIds={underMaintenanceAssetIds}
          setUnderMaintenanceAssetIds={setUnderMaintenanceAssetIds}
          setPreviousData={setPreviousData}
          setToPreviousData={setToPreviousData}
        />

        <RioPlanBigNumbers
          initialAssetIds={initialAssetIds}
          assetIds={assetIds}
          setAssetIds={setAssetIds}
          filter={filter}
          setFilter={setFilter}
          previousStateFlag={previousStateFlag}
          setPreviousStateFlag={setPreviousStateFlag}
          withRioPlanAssetIds={withRioPlanAssetIds}
          setWithRioPlanAssetIds={setWithRioPlanAssetIds}
          withoutRioPlanAssetIds={withoutRioPlanAssetIds}
          setWithoutRioPlanAssetIds={setWithoutRioPlanAssetIds}
          byRioPlan={byRioPlan}
          setByRioPlan={setByRioPlan}
          setPreviousData={setPreviousData}
          setToPreviousData={setToPreviousData}
        />

        <VolksTotalPlanBigNumbers
          assets={assets}
          assetIds={assetIds}
          setAssetIds={setAssetIds}
          filter={filter}
          setFilter={setFilter}
        />

      </StatsWidgets>
    </>
  );
};

export default BigNumbers;
