import { UserRoleEnum } from "../enums/role";
import ApiService from "../services/api";

export interface IUserProfile {
    name: string;
    firstName?: string;
    lastName?: string;
    email: string;
    group?: string;
    accountId?: string;
}

export const fetchUser = async (userId: string): Promise<IUserProfile | undefined> => {
    const apiService = new ApiService();
    
    try {
        const response = await apiService.get<any>(`/users/${userId}`);
        if (response.status === 200) {
            return {
                name: response.data.firstName + " " + response.data.lastName,
                firstName: response.data.firstName,
                lastName: response.data.lastName,
                email: response.data.email,
                group: response.data.group,
                accountId: response.data.accountId,
            };
        } else return undefined;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}

export const fetchUserRole = async (): Promise<UserRoleEnum | undefined> => {
    const apiService = new ApiService();
    
    try {
        const response = await apiService.get<any>(`/users/role`);
        if (response.status === 200) {
            return response.data.role;
        } else return undefined;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}