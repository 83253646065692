import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import { useEffect, useState } from "react";
import ChatMessages from "../../components/chatMessages";
import Calendar from "../../components/calendar";
import { Sidebar } from "@rio-cloud/rio-uikit";
import AssetTreeSidebar from "../../components/assetsTree";
import { useLocation } from "react-router-dom";

export type SidebarDemoKey = "showSidebarLeft";

export type SidebarProps = Partial<Record<SidebarDemoKey, boolean>> & {
  toggleSidebar?: (id: SidebarDemoKey) => void;
  assetIds: string[];
  groupIds: string[];
  setAssetIds: (assetIds: string[]) => void;
  setGroupIds: (groupIds: string[]) => void;
};

const LeftSidebar = (props: SidebarProps) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [showSidebarLeft, setShowSidebarLeft] = useState(false);

  const [selectAssetIds, setSelectAssetIds] = useState<string[]>([]);
  const [selectGroupIds, setSelectGroupIds] = useState<string[]>([]);

  const hideAssetTreeFromRoutes = [
    '/dealership/maintenance-control',
    '/dealership/maintenance-monitoring',
    '/dealership/my-appointments',
  ];
  const location = useLocation();
  const currentRoute = location.pathname;
  const hideAssetTree = hideAssetTreeFromRoutes.some(route => currentRoute.includes(route));

  const handleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const toggleSidebarLeft = () => {
    if (showSidebarLeft) {
      setShowSidebarLeft(false);
      props.setAssetIds(selectAssetIds);
      props.setGroupIds(selectGroupIds);
    } else setShowSidebarLeft(true);
  };

  useEffect(() => {
    setSelectAssetIds(props.assetIds);
    setSelectGroupIds(props.groupIds);
  }, [props.assetIds, props.groupIds]);

  return (
    <ApplicationLayout.Sidebar>
      <div className="icons-col">
        {
          !hideAssetTree ?
          <span
            className="rioglyph rioglyph-truck"
            onClick={toggleSidebarLeft}
          ></span> :
          <span />
        }

        <div className="display-flex flex-column align-items-center icons-col__bottom">
          <ChatMessages />
          <span
            onClick={handleCalendar}
            className="rioglyph rioglyph-calendar"
          ></span>
          {
            !hideAssetTree &&
            <span
              className={
                showSidebarLeft
                  ? "rioglyph rioglyph-chevron-left"
                  : "rioglyph rioglyph-chevron-right"
              }
              onClick={toggleSidebarLeft}
            ></span>
          }
        </div>
      </div>

      {showCalendar && (
        <div className="calendar">
          <Calendar closeSidebar={() => handleCalendar()} />
        </div>
      )}

      <Sidebar
        closed={!showSidebarLeft}
        minWidth={300}
        fly
        className="sidebar-left"
        hasBackdrop={true}
        makeBackdropVisible={true}
        onBackdropClick={toggleSidebarLeft}
      >
        <div className="w-full">
          <AssetTreeSidebar
            assetIds={props.assetIds}
            setAssetIds={(e: string[]) => props.assetIds.length ? setSelectAssetIds(e) : props.setAssetIds(e)}
            groupIds={props.groupIds}
            setGroupIds={(e: string[]) =>  props.assetIds.length ? setSelectGroupIds(e) : props.setGroupIds(e)}
          />
        </div>
      </Sidebar>
    </ApplicationLayout.Sidebar>
  );
};

export default LeftSidebar;
