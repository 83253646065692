import { useEffect, useState } from "react";
import StatsWidget from "@rio-cloud/rio-uikit/StatsWidget";
import StatsWidgetNumber from "@rio-cloud/rio-uikit/StatsWidgetNumber";
import StatsWidgetSpacer from "@rio-cloud/rio-uikit/StatsWidgetSpacer";
import { fetchAvailableToSchedule, fetchInProgress, fetchDelayedMaintenance, fetchScheduled } from "../../../../../api/fetchBigNumbers";
import { AssetSummary } from "../../../../../models/asset";

const MaintenanceBigNumbers = (
    { 
        initialAssetIds, 
        assets, 
        assetIds, 
        setAssetIds, 
        filter, 
        setFilter,
        previousStateFlag,
        setPreviousStateFlag,
        toScheduleAssetIds,
        setToScheduleAssetIds,
        delayedMaintenanceAssetIds,
        setDelayedMaintenanceAssetIds,
        scheduledAssetIds,
        setScheduledAssetIds,
        underMaintenanceAssetIds,
        setUnderMaintenanceAssetIds,
        setPreviousData,
        setToPreviousData,
    }: 
    { 
        initialAssetIds: AssetSummary[], 
        assets: AssetSummary[], 
        assetIds: string[], 
        setAssetIds: (e: string[]) => void, 
        filter: string, 
        setFilter: (e: string) => void,
        previousStateFlag: boolean,
        setPreviousStateFlag: (e: boolean) => void,
        toScheduleAssetIds: string[],
        setToScheduleAssetIds: (e: string[]) => void,
        delayedMaintenanceAssetIds: string[],
        setDelayedMaintenanceAssetIds: (e: string[]) => void,
        scheduledAssetIds: string[],
        setScheduledAssetIds: (e: string[]) => void,
        underMaintenanceAssetIds: string[],
        setUnderMaintenanceAssetIds: (e: string[]) => void,
        setPreviousData: () => void,
        setToPreviousData: () => void,
    }
) => {
    const [toScheduleLoading, setToScheduleLoading] = useState<boolean>(true);
    const [delayedMaintenanceLoading, setDelayedMaintenanceLoading] = useState<boolean>(true);
    const [scheduledLoading, setScheduledLoading] = useState<boolean>(true);
    const [underMaintenanceLoading, setUnderMaintenanceLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            if(filter !== 'toSchedule') setToScheduleLoading(true);
            if(filter !== 'delayedMaintenance') setDelayedMaintenanceLoading(true);
            if(filter !== 'scheduled') setScheduledLoading(true);
            if(filter !== 'underMaintenance') setUnderMaintenanceLoading(true);
            
            const fetchScheduledPromise = fetchScheduled(assetIds, assets);
            const fetchToSchedulePromise = fetchAvailableToSchedule(assetIds, assets);
            const fetchUndeMaintenancePromise = fetchInProgress(assetIds, assets);
            const fetchDelayedMaintenancePromise = fetchDelayedMaintenance(assetIds, assets);

            if(filter !== 'toSchedule')
                fetchToSchedulePromise.then(([, toScheduleassetIds]) => {
                    setToScheduleAssetIds(toScheduleassetIds);
                    setToScheduleLoading(false);
                });

            if(filter !== 'underMaintenance')
            fetchUndeMaintenancePromise.then(([, assetIds]) => {
                setUnderMaintenanceAssetIds(assetIds);
                setUnderMaintenanceLoading(false);
            });

            if(filter !== 'delayedMaintenance')
                fetchDelayedMaintenancePromise.then(([, assetIds]) => {
                    setDelayedMaintenanceAssetIds(assetIds);
                    setDelayedMaintenanceLoading(false);
                });
            
            if(filter !== 'scheduled')
                fetchScheduledPromise.then(([, assetIds]) => {
                    setScheduledAssetIds(assetIds);
                    setScheduledLoading(false);
                });
        }
        
        if(assetIds.length && !previousStateFlag) fetchData();

        setPreviousStateFlag(false);
    }, [initialAssetIds]);

    return (
        <StatsWidget className="card">
            <StatsWidget.Header>Manutenções</StatsWidget.Header>
            <StatsWidget.Body className="mt-4">
                <StatsWidgetNumber
                    className={`${toScheduleLoading ? `animate-pulse` : ``} ${filter !== '' && filter !== 'toSchedule' ? `` : 'cursor-pointer'}`}
                    value={toScheduleLoading ?
                        <div className="width-30 height-40 bg-gray-300 rounded mb-8"></div> :
                        <span className={filter !== '' && filter !== 'toSchedule' ? `text-light` : 'text-color-status-driving'}>{toScheduleAssetIds.length}</span>
                    }
                    label={toScheduleLoading ?
                        <div className="width-70 h-8 bg-gray-300 rounded"></div> :
                        <span className={filter !== '' && filter !== 'toSchedule' ? `text-light` : ''}>Agendar</span>
                    }
                    onClick={() => {
                        if(filter !== 'toSchedule' && filter !== '') return;
                        if(filter !== 'toSchedule') [setFilter('toSchedule'), setPreviousData(), setAssetIds(toScheduleAssetIds)];
                        else setToPreviousData(), setFilter('');
                    }}
                />
                <StatsWidgetSpacer />
                <StatsWidgetNumber
                    className={`${delayedMaintenanceLoading ? `animate-pulse` : ``} ${filter !== '' && filter !== 'delayedMaintenance' ? `` : 'cursor-pointer'}`}
                    value={delayedMaintenanceLoading ?
                        <div className="width-30 height-40 bg-gray-300 rounded mb-8"></div> :
                        <span className={filter !== '' && filter !== 'delayedMaintenance' ? `text-light` : 'text-color-danger'}>{delayedMaintenanceAssetIds.length}</span>
                    }
                    label={delayedMaintenanceLoading ?
                        <div className="width-70 h-8 bg-gray-300 rounded"></div> :
                        <span className={filter !== '' && filter !== 'delayedMaintenance' ? `text-light` : ''}>Atrasadas</span>
                    }
                    onClick={() => {
                        if(filter !== 'delayedMaintenance' && filter !== '') return;
                        if(filter !== 'delayedMaintenance') [setFilter('delayedMaintenance'), setPreviousData(), setAssetIds(delayedMaintenanceAssetIds)]
                        else setToPreviousData(), setFilter('');
                    }}
                />
            </StatsWidget.Body>
            <StatsWidget.Body>
                <StatsWidgetNumber
                    className={`${scheduledLoading ? `animate-pulse` : ``} ${filter !== '' && filter !== 'scheduled' ? `` : 'cursor-pointer'}`}
                    value={scheduledLoading ?
                        <div className="width-30 height-40 bg-gray-300 rounded mb-8"></div> :
                        <span className={filter !== '' && filter !== 'scheduled' ? `text-light` : ''}>{scheduledAssetIds.length}</span>
                    }
                    label={scheduledLoading ?
                        <div className="width-100 h-8 bg-gray-300 rounded"></div> :
                        <span className={filter !== '' && filter !== 'scheduled' ? `text-light` : ''}>Agendadas</span>
                    }
                    onClick={() => {
                        if(filter !== 'scheduled' && filter !== '') return;
                        if(filter !== 'scheduled') [setFilter('scheduled'), setPreviousData(), setAssetIds(scheduledAssetIds)]
                        else setToPreviousData(), setFilter('');
                    }}
                />
                <StatsWidgetSpacer />
                <StatsWidgetNumber
                    className={`${underMaintenanceLoading ? `animate-pulse` : ``} ${filter !== '' && filter !== 'underMaintenance' ? `` : 'cursor-pointer'}`}
                    value={underMaintenanceLoading ?
                        <div className="width-30 height-40 bg-gray-300 rounded mb-8"></div> :
                        <span className={filter !== '' && filter !== 'underMaintenance' ? `text-light` : ''}>{underMaintenanceAssetIds.length}</span>
                    }
                    label={underMaintenanceLoading ?
                        <div className="width-100 h-8 bg-gray-300 rounded"></div> :
                        <span className={filter !== '' && filter !== 'underMaintenance' ? `text-light` : ''}>Em manutenção</span>
                    }
                    onClick={() => {
                        if(filter !== 'underMaintenance' && filter !== '') return;
                        if(filter !== 'underMaintenance') [setFilter('underMaintenance'), setPreviousData(), setAssetIds(underMaintenanceAssetIds)]
                        else setToPreviousData(), setFilter('');
                    }}
                />
            </StatsWidget.Body>
        </StatsWidget>
    )
}

export default MaintenanceBigNumbers;