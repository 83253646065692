import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Select, Spinner } from "@rio-cloud/rio-uikit";
import Checkbox from "@rio-cloud/rio-uikit/Checkbox";
import Page from "../../Page";
import Dialog from "@rio-cloud/rio-uikit/Dialog";
import { addHours } from "date-fns";

import {
  addDays,
  getFirstDayOfWeek,
  getLastDayOfWeek,
  getStringWeekDaysStartingFromDate,
} from "../../../../utils/dateUtils";

import { IAppointmentForm as BaseIAppointmentForm } from "../../../fleetManager/newAppointment";

import {
  AssetSummary,
  Asset,
} from "../../../../models/asset";
import { Driver } from "../../../../models/driver";
import {
  Checkup,
  CheckupAdicionalService,
  CheckupService,
} from "../../../../models/checkup";
import { DealershipConsultant } from "../../../../models/dealership";

import { fetchCheckupByMetricAndModel } from "../../../../api/fetchCheckup";
import { fetchMaintenance } from "../../../../api/fetchMaintenance";
import { fetchConsultants } from "../../../../api/fetchConsultants";
import { fetchUptimeAssets } from "../../../../api/fetchAssets";

import "../styles.scss";
import { debounce } from "lodash";

export interface IAssetAndDriverForm {
  asset?: Asset;
  scheduleByDriver?: boolean;
  checkup?: Checkup;
  driver?: Driver;
  schedulingTypes?: string[];
  aditionalServices?: CheckupAdicionalService[];
  comments?: string | null;
  obs?: string;
}

export interface IDateAndConsultantForm {
  scheduleDate?: Date;
  consultant?: DealershipConsultant;
}

interface IAppointmentFormProps {
  goToSummary: () => void;
  form: IAppointmentForm | undefined;
  setForm: (form: IAppointmentForm) => void;
  assets: AssetSummary[];
  appointmentId?: string;

}

export interface CampaignsDetails {
  number: string;
  name: string;
  validityFrom: string;
  validityUntil: string;
  classification: string;
  averagePrice: number;
  campaignYear: string;
  campaignStatus: string;
  dn: string;
  vehicle: string;
  repairDate: any;
  repairStatus: string;
}

interface IAppointmentForm extends BaseIAppointmentForm {
  checkup?: Checkup;
}

const AppointmentForm = ({
  goToSummary,
  form,
  setForm,
  assets,
  appointmentId,
}: IAppointmentFormProps) => {
  const [searchParams] = useSearchParams();

  const [assetId, setAssetId] = useState<string>('');

  const [firstWeekDay, setFirstWeekDay] = useState<Date>(
    getFirstDayOfWeek(new Date())
  );
  const [weekDays, setWeekDays] = useState<any[]>([]);
  const [consultantScheduleDates, setConsultantScheduleDates] = useState<
    Date[]
  >([]);
  const [choosedDate, setChoosedDate] = useState<Date | null>(null);

  const [showDialog, setShowDialog] = useState(false);

  const [loading, setLoading] = useState(false);

  // const [disableAssetModel, setDisableAssetModel] = useState(false);
  const [disableAssetGroup, setDisableAssetGroup] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const assetId = searchParams.get("asset");
      if (assetId) {
        setLoading(true);
        setAssetId(assetId);
        await chooseAsset(assetId);
        setLoading(false);
      }
    };

    const date = searchParams.get("date");
    if (date) {
      const localeDate = addHours(new Date(date), 3);
      setChoosedDate(localeDate);
      selectDate(localeDate);
      getConsultantAvailability(localeDate);
    }

    fetchData();
  }, [assets]);

  useEffect(() => {
    setWeekDays(
      getStringWeekDaysStartingFromDate(firstWeekDay).map((date, index) => ({
        date: addDays(firstWeekDay, index),
        weekDay: date.split(" ")[0],
        day: date.split(" ")[1],
      }))
    );
    if (form?.dateAndConsultant?.scheduleDate)
      setChoosedDate(form?.dateAndConsultant?.scheduleDate);
  }, [assets]);

  const previousWeek = () => {
    const lastDayOfPreviousWeek = addDays(firstWeekDay, -1);
    const firstWeekDayOfPreviousWeek = getFirstDayOfWeek(lastDayOfPreviousWeek);
    setWeekDays(
      getStringWeekDaysStartingFromDate(firstWeekDayOfPreviousWeek).map(
        (date, index) => ({
          date: addDays(firstWeekDayOfPreviousWeek, index),
          weekDay: date.split(" ")[0],
          day: date.split(" ")[1],
        })
      )
    );
    setFirstWeekDay(firstWeekDayOfPreviousWeek);
  };

  const nextWeek = () => {
    const lastWeekDay = getLastDayOfWeek(firstWeekDay);
    const firstDayOfNextWeek = addDays(lastWeekDay, +1);
    setWeekDays(
      getStringWeekDaysStartingFromDate(firstDayOfNextWeek).map(
        (date, index) => ({
          date: addDays(firstDayOfNextWeek, index),
          weekDay: date.split(" ")[0],
          day: date.split(" ")[1],
        })
      )
    );
    setFirstWeekDay(firstDayOfNextWeek);
  };

  const selectDate = (selectedDate: Date | undefined) => {
    setForm({
      ...form,
      dateAndConsultant: {
        ...form?.dateAndConsultant,
        scheduleDate: selectedDate,
      },
    });
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const chooseAsset = async (assetId: string) => {
    const [assetsFetched] = await fetchMaintenance({ ids: [assetId] });
    if (!assetsFetched.length) return;
    let asset = assetsFetched[0];

    if (!asset.odometer || !asset.hourmeter) {
      const uptimeAssets = await fetchUptimeAssets([asset.chassis]);
      const uptimeAsset = uptimeAssets[0];
      if (!asset.odometer && uptimeAsset?.odometer) {
        asset.odometer = uptimeAsset?.odometer;
        asset.lastOdometerUpdate = uptimeAsset?.lastOdometerUpdate;
      }

      if (!asset.hourmeter && uptimeAsset?.hourmeter) {
        asset.hourmeter = uptimeAsset?.hourmeter;
        asset.lastHourmeterUpdate = uptimeAsset?.lastHourmeterUpdate;
      }
    }

    setForm({
      ...form,
      assetsAndDrivers: {
        ...form?.assetsAndDrivers,
        asset,
      },
    });
    setDisableAssetGroup(!!asset.group);
    chooseCheckup(asset);
  };

  const chooseCheckup = async (asset: Asset | undefined) => {
    const checkup = asset
      ? await fetchCheckupByMetricAndModel(
        asset?.odometer ?? "",
        asset?.hourmeter ?? "",
        asset?.chassis ?? "",
        asset?.group ?? ""
      )
      : undefined;
    setForm({
      ...form,
      assetsAndDrivers: {
        ...form?.assetsAndDrivers,
        checkup,
        asset,
      },
    });
  };

  const getCheckupByMetric = async (
    odometer: number,
    hourmeter: number,
    chassis: string,
    group: string
  ) => {
    return await fetchCheckupByMetricAndModel(
      odometer,
      hourmeter,
      chassis,
      group
    );
  };

  const formIsValid = (): boolean => {
    return !!(
      form?.assetsAndDrivers?.asset &&
      form?.assetsAndDrivers?.checkup &&
      form?.schedulingTypes &&
      form?.dateAndConsultant?.scheduleDate
    );
  };

  const getConsultantAvailability = async (date: Date) => {
    if (form?.dealership?.id) {
      const consultants = await fetchConsultants(date, form.dealership.id);
      const consultant = consultants.find(
        (el) => el.id === form?.dateAndConsultant?.consultant?.id
      );
      if (consultant && consultant.scheduleDates)
        setConsultantScheduleDates(consultant.scheduleDates);
    }
  };

  const setCheckup = useMemo(
    () => debounce(async (metricValue: number, metricType: string, asset?: Asset) => {
      setLoading(true);
      const checkup = await getCheckupByMetric(
        metricType === 'odometer' ? metricValue : asset?.odometer ?? 0,
        metricType === 'hourmeter' ? metricValue : asset?.hourmeter ?? 0,
        asset?.chassis ?? "",
        asset?.group ?? ""
      );
      setForm({
        ...form,
        assetsAndDrivers: {
          ...form?.assetsAndDrivers,
          checkup,
          asset: asset
            ? {
              ...asset,
              [metricType]: metricValue,
            }
            : undefined,
        },
      });
      setLoading(false);
    }, 500), [],
  );

  return (
    <Page>
      <>
        <div className="schedule-consultant">
          <div className="schedule-consultant overflow-auto bg-white flex">
            <div className="w-2/3">
              <div className="schedule-consultant__header">
                <h2 className="text-color-black font-semibold">
                  Agendamento de revisão preventiva
                </h2>
                <p className="text-color-status-resting text-size-16">
                  Preencha abaixo os dados para criar o agendamento
                </p>
              </div>

              <div className="border rounded padding-15 margin-top-20 scheduling-page__fieldset">
                <h6 className="text-bold text-size-16">
                  Informações do veículo
                </h6>

                {!assetId &&
                  <div className="flex justify-between w-full gap-6 mt-5">
                    <div className="form-group form-group-lg w-full">
                      <label htmlFor="placa">
                        Buscar veículo<span className="asterisk-color">*</span>
                      </label>
                      <Select
                        placeholder="Buscar veículo pelo chassi..."
                        options={assets.map((asset: AssetSummary) => ({
                          id: asset.id,
                          label: asset.name,
                        }))}
                        value={
                          form?.assetsAndDrivers?.asset
                            ? [form?.assetsAndDrivers?.asset.id]
                            : []
                        }
                        useFilter
                        noItemMessage="Nenhum veículo encontrado"
                        onChange={(e) => chooseAsset(e?.id ?? "")}
                        inputAddon="rioglyph rioglyph-search"
                        disabled={!!appointmentId}
                      />
                    </div>
                  </div>
                }

                {form?.assetsAndDrivers?.asset && assetId && (
                  <div className="border rounded padding-15 margin-top-20 margin-bottom-20 scheduling-page__fieldset">
                    <div className="flex items-center gap-4">
                      <img
                        src="/assets/images/vehicle-thumb.png"
                        alt="Vehicle"
                        className="w-20 h-20 rounded"
                      />

                      <div className="flex-1 padding-left-10">
                        <p className="text-color-black text-size-16 font-medium m-0">
                          {form?.assetsAndDrivers?.asset.name}
                        </p>
                        <p className="text-color-status-resting text-size-14 m-0">
                          Chassi - {form?.assetsAndDrivers?.asset.chassis}
                        </p>
                      </div>

                      {(form?.assetsAndDrivers?.asset?.plans?.length ?? 0) > 0 && (
                        <div className="flex gap-6 margin-bottom-20">
                          <span className="px-2 py-1 green-tag">RIO</span>
                          <span className="px-2 py-1 green-tag">Em Garantia</span>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="flex justify-between w-full gap-6">
                  <div className="form-group form-group-lg w-1/3">
                    <label htmlFor="placa">Placa do veículo</label>
                    <input
                      id="placa"
                      className="form-control"
                      type="text"
                      placeholder="Placa"
                      value={form?.assetsAndDrivers?.asset?.licensePlate}
                      disabled
                    />
                  </div>

                  <div className="form-group form-group-lg w-1/3">
                    <label htmlFor="model">Modelo do veículo</label>
                    <input
                      id="model"
                      className="form-control"
                      type="text"
                      value={form?.assetsAndDrivers?.asset?.model}
                      disabled
                    />
                  </div>

                  <div className="form-group form-group-lg w-1/3">
                    <label htmlFor="group">Grupo de manutenção*</label>
                    <Select
                      id="group"
                      placeholder="Buscar grupo de manutenção..."
                      options={[
                        { id: '1', label: "Rodoviário" },
                        { id: '2', label: "Misto" },
                        { id: '3', label: "Severo" },
                        { id: '4', label: "Especial" },
                      ].map((group: any) => ({ id: group.id, label: group.label }))}
                      value={form?.assetsAndDrivers?.asset?.group ? [form?.assetsAndDrivers?.asset?.group] : []}
                      useFilter
                      noItemMessage="Nenhum grupo encontrado"
                      onChange={async (e) => {
                        setLoading(true);
                        const checkup = await getCheckupByMetric(
                          form?.assetsAndDrivers?.asset?.odometer ?? 0,
                          form?.assetsAndDrivers?.asset?.hourmeter ?? 0,
                          form?.assetsAndDrivers?.asset?.chassis ?? "",
                          e?.id ?? ""
                        );
                        setLoading(false);
                        setForm({
                          ...form,
                          assetsAndDrivers: {
                            ...form?.assetsAndDrivers,
                            checkup,
                            asset: form?.assetsAndDrivers?.asset
                              ? {
                                ...form?.assetsAndDrivers?.asset,
                                group: e?.id ?? '',
                              }
                              : undefined,
                          },
                        })
                      }}
                      disabled={disableAssetGroup}
                    />
                  </div>
                </div>

                <div className="flex justify-between w-full gap-6">
                  <div className="form-group form-group-lg w-1/3">
                    <label htmlFor="placa">Odômetro</label>
                    <div className="input-group">
                      <input
                        id="placa"
                        className="form-control"
                        type="text"
                        placeholder="Odômetro"
                        value={form?.assetsAndDrivers?.asset?.odometer}
                        disabled={loading || !!appointmentId}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            assetsAndDrivers: {
                              ...form?.assetsAndDrivers,
                              asset: form?.assetsAndDrivers?.asset
                                ? {
                                  ...form?.assetsAndDrivers?.asset,
                                  odometer: Number(e.target.value),
                                  group: form?.assetsAndDrivers?.asset?.group,
                                }
                                : undefined,
                            },
                          });
                          setCheckup(Number(e.target.value), 'odometer', form?.assetsAndDrivers?.asset);
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group form-group-lg w-1/3">
                    <label htmlFor="placa">Horímetro</label>
                    <input
                      id="placa"
                      className="form-control"
                      type="text"
                      placeholder="Horímetro"
                      disabled={loading || !!appointmentId}
                      value={form?.assetsAndDrivers?.asset?.hourmeter}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          assetsAndDrivers: {
                            ...form?.assetsAndDrivers,
                            asset: form?.assetsAndDrivers?.asset
                              ? {
                                ...form?.assetsAndDrivers?.asset,
                                hourmeter: Number(e.target.value),
                                group: form?.assetsAndDrivers?.asset?.group,
                              }
                              : undefined,
                          },
                        });
                        setCheckup(Number(e.target.value), 'hourmeter', form?.assetsAndDrivers?.asset);
                      }}
                    />
                  </div>

                  <div className="form-group form-group-lg w-1/3">
                    <label htmlFor="cnh">CNH do motorista</label>
                    <div className="input-group">
                      <input
                        id="cnh"
                        className="form-control"
                        type="text"
                        placeholder="00000000000"
                        maxLength={12}
                        disabled={loading || !!appointmentId}
                        value={form?.assetsAndDrivers?.driver?.license}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            assetsAndDrivers: {
                              ...form?.assetsAndDrivers,
                              driver: form?.assetsAndDrivers?.driver
                                ? {
                                  ...form?.assetsAndDrivers?.driver,
                                  license: e.target.value,
                                }
                                : undefined,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="border rounded padding-15 margin-top-20 scheduling-page__fieldset">
                <h6 className="text-bold text-size-16">
                  Revisões com base no intervalo de km e/ou tempo do veículo e
                  ações de oficina (se houver)
                </h6>
                {!appointmentId ?
                  <div className="info-group">
                    <p className="text-color-status-resting text-size-14 padding-top-10 padding-bottom-5">
                      Revisões disponíveis
                      <span className="asterisk-color">*</span>
                      <span className="rioglyph rioglyph-question question-icon"></span>
                    </p>
                    {/* <Select
                      placeholder="Escolha a revisão"
                      options={
                        form?.assetsAndDrivers?.checkup
                          ? [
                              {
                                id: form?.assetsAndDrivers?.checkup?.id,
                                label:
                                  form?.assetsAndDrivers?.checkup?.description,
                              },
                            ]
                          : []
                      }
                      value={
                        form?.assetsAndDrivers?.checkup
                          ? [form?.assetsAndDrivers?.checkup?.id]
                          : []
                      }
                    /> */}
                    <div className="display-flex gap-10">
                      {[
                        { id: "preventive", label: "Preventiva" },
                        { id: "workshopActions", label: "Campanhas de Campo" },
                      ].map((option) => {
                        if (
                          option.id === "preventive" &&
                          !form?.assetsAndDrivers?.checkup
                        )
                          return null;
                        if (
                          option.id === "workshopActions" &&
                          !form?.assetsAndDrivers?.asset?.campaigns?.list?.length
                        )
                          return null;
                        return (
                          <div className="avalilable-revisions border rounded">
                            <Checkbox
                              onChange={() => {
                                setForm({
                                  ...form,
                                  schedulingTypes:
                                    form?.schedulingTypes?.includes(option.id)
                                      ? form?.schedulingTypes.filter(
                                        (type) => type !== option.id
                                      )
                                      : [
                                        ...(form?.schedulingTypes ?? []),
                                        option.id,
                                      ],
                                });
                              }}
                              checked={form?.schedulingTypes?.includes(option.id)}
                              custom
                              className="padding-y-5 padding-x-10"
                            >
                              <div className="checkbox-text-wrapper display-flex gap-10">
                                <div className="checkbox-text" />
                                <div className="flex-1-1">{option.label}</div>
                              </div>
                            </Checkbox>
                          </div>
                        );
                      })}
                    </div>
                  </div> : null}

                {form?.assetsAndDrivers?.asset &&
                  form?.schedulingTypes?.length ? (
                  <div
                    style={{
                      backgroundColor: "#ECFDF3",
                      color: "#067647",
                      borderColor: "#ABEFC6",
                    }}
                    className="border rounded padding-15 margin-top-20"
                  >
                    {form?.schedulingTypes?.includes("preventive") &&
                      form?.assetsAndDrivers?.checkup && (
                        <div className="margin-bottom-20">
                          <div className="text-bold">
                            {form?.assetsAndDrivers?.checkup.description}
                          </div>
                          {(
                            form?.assetsAndDrivers?.checkup.services ?? []
                          ).map((service: CheckupService) => (
                            <div className="padding-left-10 padding-top-10">
                              {service.name}
                            </div>
                          ))}
                          {form?.assetsAndDrivers?.checkup?.parts?.length ?
                            <>
                              <div className="text-bold padding-top-10">
                                Lista de peças a serem trocadas
                              </div>
                              {(form?.assetsAndDrivers?.checkup?.parts ?? []).map((part) => (
                                <div className="padding-left-10 padding-top-10">
                                  {part.qtd}x Cód.: {part.code} - {part.description}
                                </div>
                              ))}
                            </>
                            : null}
                        </div>
                      )}

                    {form?.schedulingTypes?.includes("workshopActions") && (
                      <>
                        <div className="text-bold">Campanhas de campo</div>
                        {(
                          form?.assetsAndDrivers?.asset?.campaigns?.list ?? []
                        ).map((campaign: CampaignsDetails) => (
                          <div className="padding-left-10 padding-top-10">
                            {campaign.name}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                ) : null}

                {/* {form?.assetsAndDrivers?.checkup && (
                  <div
                    style={{
                      backgroundColor: "#ECFDF3",
                      color: "#067647",
                      borderColor: "#ABEFC6",
                    }}
                    className="border rounded padding-15 margin-top-20"
                  >
                    <div className="text-bold">
                      Revisão {form?.assetsAndDrivers?.checkup?.description}{" "}
                      {form?.assetsAndDrivers?.checkup?.odometer
                        ? `(${form?.assetsAndDrivers?.checkup?.odometer} km)`
                        : ""}
                    </div>
                    {(form?.assetsAndDrivers?.checkup?.services ?? []).map(
                      (service: CheckupService) => (
                        <div className="padding-left-10 padding-top-10">
                          {service.name}
                        </div>
                      )
                    )}
                  </div>
                )} */}
              </div>

              <div className="border rounded padding-15 margin-top-20 scheduling-page__fieldset">
                <div className="flex justify-between">
                  <h6 className="text-bold text-size-16">Observações gerais</h6>
                </div>

                <div className="info-group">
                  <div className="add-services">
                    <div className="text-comments margin-top-10">
                      <textarea
                        style={{ height: "100px" }}
                        className="form-control"
                        placeholder="Detalhe o que está acontecendo com o veículo..."
                        value={form?.assetsAndDrivers?.obs}
                        disabled={!!appointmentId}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            assetsAndDrivers: {
                              ...form?.assetsAndDrivers,
                              obs: e.target.value,
                            },
                          })
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/3 calendar-col">
              <h3>Calendário</h3>

              <div className="mb-4">
                <div className="calendar-row margin-top-10">
                  <div className="flex items-center justify-between mb-2 calendar-row__month">
                    <button onClick={previousWeek}>
                      <span className="rioglyph rioglyph-chevron-left text-color-status-available"></span>
                    </button>
                    <span className="text-size-16 text-color-status-resting font-semibold capitalize">
                      {firstWeekDay
                        .toLocaleDateString("pt-BR", {
                          month: "long",
                          year: "numeric",
                        })
                        .replace(" de ", "/")}
                    </span>
                    <button onClick={nextWeek}>
                      <span className="rioglyph rioglyph-chevron-right text-color-status-available"></span>
                    </button>
                  </div>
                  <div className="flex justify-between text-center p-2 calendar-row__days">
                    {(weekDays ?? []).map((weekDay: any) => (
                      <button
                        key={`${weekDay.weekDay}_${weekDay.day}`}
                        className={`
                                                flex flex-col items-center gap-5 p-2 w-12 h-16 rounded-lg text-sm transition-all 
                                                ${choosedDate?.getDay() ===
                            weekDay.date.getDay() &&
                            choosedDate?.getMonth() ===
                            weekDay.date.getMonth() &&
                            choosedDate?.getFullYear() ===
                            weekDay.date.getFullYear()
                            ? "active-date text-white"
                            : "hover:bg-gray-200"
                          }
                                            `}
                        onClick={() => [
                          setChoosedDate(weekDay.date),
                          getConsultantAvailability(weekDay.date),
                        ]}
                        disabled={
                          weekDay.date.setHours(0, 0, 0, 0) < new Date()
                        }
                      >
                        <span className="text-size-12 text-gray-600 text-lowercase">
                          {weekDay.weekDay}
                        </span>
                        <span
                          className={`
                                                    font-medium flex items-center justify-center text-size-14 day-card
                                                    ${weekDay.date.setHours(
                            0,
                            0,
                            0,
                            0
                          ) < new Date()
                              ? "bg-lighter"
                              : "bg-white"
                            }
                                                `}
                        >
                          {weekDay.day}
                        </span>
                      </button>
                    ))}
                  </div>
                </div>

                {choosedDate && (
                  <div className="select-date-row">
                    <h5>Selecione uma hora</h5>
                    <div className="hour-slots flex flex-col">
                      {(() => {
                        if (!choosedDate) return null;

                        const dayOfWeek = choosedDate.getDay(); // 0 = Domingo, 1 = Segunda, ..., 6 = Sábado
                        let availableHours: number[] = [];

                        if (dayOfWeek >= 1 && dayOfWeek <= 5) {
                          // Segunda a Sexta (08h - 18h)
                          availableHours = Array.from(
                            { length: 11 },
                            (_, i) => i + 8
                          );
                        } else if (dayOfWeek === 6) {
                          // Sábado (08h - 12h)
                          availableHours = Array.from(
                            { length: 5 },
                            (_, i) => i + 8
                          );
                        }

                        return availableHours.length === 0 ? (
                          <p className="text-center text-gray-500">
                            Indisponível para agendamento
                          </p>
                        ) : (
                          availableHours.map((hour) =>
                            consultantScheduleDates
                              .map((scheduleDate) =>
                                scheduleDate.toLocaleString("pt-Br")
                              )
                              .includes(
                                new Date(
                                  new Date(choosedDate).setHours(hour, 0, 0, 0)
                                ).toLocaleString("pt-Br")
                              ) ? (
                              <div key={hour} className={`hour-row flex gap-6`}>
                                <span className="hour-slot">{hour}:00</span>
                                <span className="hour-select bg-lighter">
                                  Horário indisponível
                                </span>
                              </div>
                            ) : (
                              <div
                                key={hour}
                                className={`hour-row flex gap-6 ${form?.dateAndConsultant?.scheduleDate?.getTime() ===
                                  new Date(choosedDate).setHours(hour, 0, 0, 0)
                                  ? "active"
                                  : ""
                                  }`}
                                onClick={() =>
                                  selectDate(
                                    new Date(
                                      choosedDate.setHours(hour, 0, 0, 0)
                                    )
                                  )
                                }
                              >
                                <span className="hour-slot">{hour}:00</span>
                                <span className="hour-select">
                                  {form?.dateAndConsultant?.scheduleDate?.getTime() ===
                                    new Date(choosedDate).setHours(hour, 0, 0, 0)
                                    ? "Selecionado"
                                    : "Selecionar"}
                                </span>
                              </div>
                            )
                          )
                        );
                      })()}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="fixed-footer">
              <div className="page-info flex justify-end">
                <span className="text-color-status-resting text-size-16 text-right">
                  Informações do veículo e serviços
                </span>
              </div>
              <div className="flex justify-between navigation-buttons step-1">
                <button
                  className="px-4 py-2 rounded-lg font-semibold button-color"
                  onClick={() => setShowDialog(true)}
                >
                  <span className="rioglyph rioglyph-arrow-left text-size-16"></span>{" "}
                  Voltar
                </button>
                <button
                  className="px-4 py-2 btn btn-primary"
                  disabled={!formIsValid()}
                  onClick={goToSummary}
                >
                  Finalizar agendamento
                </button>
              </div>
            </div>
          </div>
        </div>
        <Spinner show={loading} text="Buscando revisão..." isFullSized />
        <Dialog
          show={showDialog}
          onClose={handleClose}
          showCloseButton
          bsSize={Dialog.SIZE_SM}
        >
          <Dialog.Title title={"Sair do agendamento"} />
          <Dialog.Body>
            <div className="cancel-content flex flex-col">
              <span className="text-color-darker text-size-16 text-normal">
                Você está prestes a sair do agendamento, se continuar, todos os
                dados serão perdidos.
                <br />
                <br />
                Deseja continuar mesmo assim?
              </span>
            </div>
          </Dialog.Body>
          <Dialog.Footer>
            <div className="flex items-center justify-end">
              <div className="flex items-center gap-6">
                <span
                  className="text-medium text-size-14 cursor-pointer text-color-primary"
                  onClick={handleClose}
                >
                  Voltar
                </span>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setShowDialog(false);
                    window.history.back();
                  }}
                >
                  Continuar
                </button>
              </div>
            </div>
          </Dialog.Footer>
        </Dialog>
      </>
    </Page>
  );
};

export default AppointmentForm;
