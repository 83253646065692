import { useState } from "react";
import { useSelector } from "react-redux";
import Page from "../../Page";
import { getUserProfile } from "../../../../configuration/login/loginSlice";
import { makeAnAppointment } from "../../../../api/appointments";
import { IAppointmentForm } from "../../../dealership/newAppointment";
import SuccessDialog from "../dialogs/successDialog";
import ErrorDialog from "../../../../components/ErrorDialog";
import { AppointmentCreatedByEnum } from "../../../../enums/appointmentCreatedBy";
import { rescheduleAnAppointment } from "../../../../api/appointments";

interface IAppointmentSummaryProps {
  goBackToForm: () => void;
  appointment: IAppointmentForm | undefined;
  appointmentId?: string;
}

const AppointmentSummary = ({
  goBackToForm,
  appointment,
  appointmentId,
}: IAppointmentSummaryProps) => {
  const userProfile = useSelector(getUserProfile);
  const [loading, setLoading] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const [errorDialog, setErrorDialog] = useState({
    show: false,
    title: "",
    body: "",
  });

  return (
    <Page>
      <>
        <div className="scheduling-page resume-section p-10">
          <h2 className="text-size-h2 text-center text-color-black font-semibold padding-top-20 padding-bottom-20">
            Resumo do agendamento
          </h2>
          <div className="resume-container">
            <h5 className="text-size-20 text-color-black font-bold margin-bottom-20 text-color-gray">
              AGD 001574
            </h5>

            <div className="p-4 bg-lighter margin-top-20 vehicle-detais">
              <div className="flex items-start gap-4">
                <img
                  src="/assets/images/vehicle-thumb.png"
                  alt="Vehicle"
                  className="w-20 h-20 rounded"
                />

                <div className="flex-1 padding-left-10">
                  <p className="text-color-black text-size-16 font-medium m-0">
                    {appointment?.assetsAndDrivers?.asset?.name ||
                      appointment?.assetsAndDrivers?.asset?.model}{" "}
                    | Placa:{" "}
                    {appointment?.assetsAndDrivers?.asset?.licensePlate}
                  </p>
                  <p className="text-color-status-resting text-size-14 m-0">
                    Chassi - {appointment?.assetsAndDrivers?.asset?.chassis}
                    <br />
                    Odômetro: {
                      appointment?.assetsAndDrivers?.asset?.odometer
                    }{" "}
                    km | Horímetro:{" "}
                    {appointment?.assetsAndDrivers?.asset?.hourmeter}
                  </p>
                </div>

                {(appointment?.assetsAndDrivers?.asset?.plans?.length ?? 0) >
                  0 && (
                    <div className="flex gap-6 margin-bottom-20">
                      <span className="px-2 py-1 green-tag">RIO</span>
                      <span className="px-2 py-1 green-tag">Em Garantia</span>
                      {appointment?.assetsAndDrivers?.asset?.volksTotalPlan && (
                        <span className="px-2 py-1 bg-white white-tag">
                          Volkstotal{" "}
                          {appointment?.assetsAndDrivers?.asset?.volksTotalPlan}
                        </span>
                      )}
                    </div>
                  )}
              </div>

              <div className="padding-top-10">
                <div className="car-workshop">
                  <ul className="flex flex-column gap-5 m-0">
                    {appointment?.assetsAndDrivers?.asset?.campaigns && (
                      <li className="flex justify-between">
                        <div className="text-color-black">
                          Campanhas de campo
                        </div>
                        <div className="text-color-black font-bold">Grátis</div>
                      </li>
                    )}

                    <li className="flex justify-between">
                      <div className="text-color-black">
                        {appointment?.assetsAndDrivers?.checkup?.description}
                      </div>
                      <div className="text-color-darkest font-bold">Grátis</div>
                    </li>
                    <li className="flex justify-between border border-bottom-none border-left-none border-right-none padding-top-10 margin-top-10">
                      <div className="text-color-black">Total</div>
                      <div className="text-color-black font-bold">Grátis</div>
                    </li>

                    <li className="flex justify-between">
                      <div className="text-color-black">Total em serviços</div>
                      <div className="text-color-black">
                        {(
                          appointment?.assetsAndDrivers?.aditionalServices ?? []
                        )
                          .reduce((acc, service) => acc + service.price, 0)
                          .toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="revision-itens">
              <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
                Gestor
              </h5>
              <div className="p-4 bg-white border">
                <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
                  <div className="padding-top-10 padding-bottom-10">
                    <span>-</span>
                  </div>
                </ul>
              </div>
            </div>

            <div className="revision-itens">
              <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
                Itens cobertos na revisão
              </h5>
              <div className="p-4 bg-white border">
                <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
                  {appointment?.assetsAndDrivers?.checkup?.services.length === 0 && <p>-</p>}
                  {appointment?.assetsAndDrivers?.checkup?.services.map(
                    (item, index) => (
                      <div key={index} className="padding-bottom-10">
                        <p className="text-bold">{item.name}</p>
                        {item.items.map((subItem, subIndex) => (
                          <p key={subIndex} className="padding-left-20">
                            {subItem}
                          </p>
                        ))}
                      </div>
                    )
                  )}

                  {appointment?.assetsAndDrivers?.checkup?.aditionalServices?.length ?
                    <div className="padding-bottom-10">
                      <p className="text-bold">Serviços complementares</p>
                      {appointment?.assetsAndDrivers?.checkup?.aditionalServices.map(
                        (item, index) => (
                          <p key={index} className="padding-left-20">
                            {item.name}
                          </p>
                        )
                      )}
                    </div> : null}
                </ul>
              </div>
            </div>

            <div className="revision-itens observation">
              <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
                Observações
              </h5>
              <div className="p-4 bg-white border">
                <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
                  <li className="flex justify-between">
                    <span>{appointment?.assetsAndDrivers?.obs ?? "-"}</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="revision-itens flex justify-between gap-6">
              <div className="w-2/3">
                <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
                  Concessionária
                </h5>
                <div className="p-4 bg-white border card">
                  <p className="m-0 text-size-h5">
                    {appointment?.dealership?.name}
                  </p>
                  <p className="m-0">{appointment?.dealership?.address}</p>
                </div>
              </div>

              <div className="w-1/3">
                <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
                  Data e horário:
                </h5>
                <div className="p-4 bg-white border card">
                  <p className="m-0 text-size-h5">
                    {appointment?.dateAndConsultant?.scheduleDate?.toLocaleDateString(
                      "pt-BR",
                      { year: "numeric", month: "long", day: "numeric" }
                    )}
                  </p>
                  <p className="m-0">
                    {appointment?.dateAndConsultant?.scheduleDate?.toLocaleTimeString(
                      "pt-BR",
                      { hour: "2-digit", minute: "2-digit" }
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex justify-between navigation-buttons step-4">
              <button className="back-btn" onClick={goBackToForm}>
                Voltar
              </button>
              <button
                className={`px-4 py-2 btn btn-primary ${loading ? "btn-loading disabled" : ""
                  }`}
                disabled={loading}
                onClick={async () => {
                  setLoading(true);
                  try {
                    if(appointmentId) {
                      await rescheduleAnAppointment(appointment!, appointmentId, AppointmentCreatedByEnum.CONSULTANT);
                    } else {
                      await makeAnAppointment(appointment!, userProfile, AppointmentCreatedByEnum.CONSULTANT);
                    }
                    setSuccessDialogOpen(true);
                    setLoading(false);
                  } catch (error: any) {
                    setLoading(false);
                    setErrorDialog({
                      show: true,
                      title: "Erro ao agendar",
                      body: error.message,
                    });
                  }
                }}
              >
                Confirmar
              </button>
            </div>
          </div>

          <SuccessDialog
            open={successDialogOpen}
            appointmentForm={appointment}
            routeWhenClose="/dealership"
          />
        </div>

        <ErrorDialog
          show={errorDialog.show}
          title={errorDialog.title}
          body={errorDialog.body}
          onClose={() => setErrorDialog({ show: false, title: "", body: "" })}
        />
      </>
    </Page>
  );
};

export default AppointmentSummary;
