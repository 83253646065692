import { AssetSummaryDto, UptimeAssetDto } from "../dtos/assetDto";
import { AssetSummary, UptimeAsset } from "../models/asset";
import ApiService from "../services/api";

export const fetchAssets = async (): Promise<AssetSummary[]> => {
    const apiService = new ApiService();
    
    try {
        const response = await apiService.get<any>(`/assets/group/all`);
        if (response.status === 200) {
            return response.data.map(AssetSummaryDto);
        } else return [];
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const fetchUptimeAssets = async (chassisList: string[]): Promise<UptimeAsset[]> => {
    const apiService = new ApiService();
    
    try {
        const response = await apiService.post<any>(
            `/assets/uptime`,
            { chassisList }
        );
        if (response.status === 200) {
            return response.data.map(UptimeAssetDto);
        } else return [];
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const updateUptimeAsset = async (asset: UptimeAsset): Promise<boolean> => {
    const apiService = new ApiService();
    
    try {
        const response = await apiService.put<any>(`/assets/uptime/${asset.chassis}`, asset);
        return response.status === 200;
    } catch (error) {
        console.error(error);
        return false;
    }
}