import { cloneElement, ReactNode, useEffect, useRef, useState } from "react";
import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import ApplicationLayoutBodyNavigation from "@rio-cloud/rio-uikit/ApplicationLayoutBodyNavigation";
import SubNavigation from "@rio-cloud/rio-uikit/SubNavigation";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../layout/AppContext";
import AppHeader from "../../features/header/AppHeader";
import LeftSidebar from "./LeftSidebar";
import { UserRoleEnum } from "../../enums/role";
import { checkRole } from "../../services/roleService";
import { Loading } from "../../utils/loading";

interface ISubnavitationHeaderProps {
  children: ReactNode;
}

const SubNavigationHeader = ({ children }: ISubnavitationHeaderProps) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUserRole = async () => {
      await checkRole(UserRoleEnum.TOWER);
      setLoading(false);
    };

    checkUserRole();
  }, []);

  const navItems = [
    { key: "control-tower/maintenance-control", value: "Monitoramento" },
    // { key: "dealership/maintenance-control", value: "Controle de manutenções" },
    {
      key: "control-tower/maintenance-monitoring",
      value: "Acompanhamento da manutenção",
    },
    { key: "control-tower/my-appointments", value: "Agendamentos" },
  ];

  return loading ? (
    <Loading label="Carregando..." />
  ) : (
    <ApplicationLayout.Body
      className="uikitDemo"
      navigation={
        <ApplicationLayoutBodyNavigation>
          <SubNavigation
            navItems={navItems.map((item) => ({
              key: item.key,
              route: <NavLink to={`/${item.key}`}>{item.value}</NavLink>,
            }))}
          />
        </ApplicationLayoutBodyNavigation>
      }
    >
      {children}
    </ApplicationLayout.Body>
  );
};

interface IHeaderProps {
  children: ReactNode;
}

const Page = ({ children }: IHeaderProps) => {
  const sidebarRef = useRef<HTMLDivElement>(null);

  const [assetIds, setAssetIds] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);

  return (
    <AppContext.Provider value={{ sidebarRef }}>
      <ApplicationLayout className="MyServiceLayout bg-lighter height-500">
        <ApplicationLayout.Header>
          <AppHeader />
        </ApplicationLayout.Header>
        <LeftSidebar
          assetIds={assetIds}
          setAssetIds={setAssetIds}
          groupIds={groupIds}
          setGroupIds={setGroupIds}
        />
        <ApplicationLayout.Sidebar className="right" ref={sidebarRef} />
        <SubNavigationHeader>
          {cloneElement(children as React.ReactElement, {
            assetIds,
            setAssetIds,
            groupIds,
          })}
        </SubNavigationHeader>
      </ApplicationLayout>
    </AppContext.Provider>
  );
};

export default Page;
