import { Asset, AssetSummary, UptimeAsset } from "../models/asset";
import { getGroupIdByName } from "../services/groupService";
import { AssetPlanDto } from "./assetPlanDto";
import { ScheduleCheckupDto } from "./scheduleCheckupDto";

export const AssetDto = (data: any): Asset => {
  return {
    id: data.vehicle.assetId,
    name: data.vehicle.name,
    model: data.vehicle.vehicleModel,
    chassis: data.vehicle.chassis,
    odometer: data.vehicle.odometer,
    lastOdometerUpdate: data.vehicle.odometer ? new Date() : undefined,
    hourmeter: data.vehicle.hourMeter,
    lastHourmeterUpdate: data.vehicle.hourMeter ? new Date() : undefined,
    year: data.vehicle.vehicleYear,
    licensePlate: data.vehicle.plate,
    plans: (data.plans || data.vehiclePlan)?.map(AssetPlanDto),
    volksTotalPlan: data.volksTotal,
    madeCheckups: data.madeCheckups?.map(ScheduleCheckupDto) ?? [],
    madeOilChangeCheckups: data.madeOilCheckups?.map(ScheduleCheckupDto) ?? [],
    lastCheckup: data.lastVehicleCheckup
      ? ScheduleCheckupDto(data.lastVehicleCheckup)
      : undefined,
    nextCheckups: data.nextCheckups?.map(ScheduleCheckupDto) ?? [],
    nextOilChangeCheckups: data.nextOilCheckups?.map(ScheduleCheckupDto) ?? [],
    previousCheckups: data.previousCheckups?.map(ScheduleCheckupDto) ?? [],
    nextCheckup: data.nextVehicleCheckup
      ? ScheduleCheckupDto({
          ...data.nextVehicleCheckup,
          schedule: data.nextVehicleCheckup.schedule,
        })
      : undefined,
    checkupActions: data.autoWorkshopActivitiesAmount ?? 0,
    score: data.vehicleScore ?? "-",
    groupName: data.vehicle.vehicleGroup,
    group: data.vehicle.vehicleGroupId ?? getGroupIdByName(data.vehicle.vehicleGroup),
    groups: data.groups,
    warrantyStatus: data.warrantyStatus,
    status: data.status,
    geoLocation: data.geoLocation,
    campaigns: data.campaigns,
    vehicleMetric: data.vehicle,
  };
};

export const AssetSummaryDto = (data: any): AssetSummary => {
  return {
    id: data.id,
    name: data.name,
    type: data.type,
    groupIds: data.groupIds,
    chassis: data.identification,
    assetSummary: true,
  };
};

export const UptimeAssetDto = (data: any): UptimeAsset => {
  return {
    chassis: data.chassis,
    odometer: data.odometer,
    hourmeter: data.hourmeter,
    lastOdometerUpdate: data.lastOdometerUpdate
      ? new Date(data.lastOdometerUpdate)
      : undefined,
    lastHourmeterUpdate: data.lastHourmeterUpdate
      ? new Date(data.lastHourmeterUpdate)
      : undefined,
  };
};
