import { useEffect, useState } from "react";

import inspectionsService, {
  IInspection,
} from "../../services/inspectionsService";

import "./styles.scss";
import InspectionList from "./inspection-list";
import Inspection from "./inspection";

interface IInspectionProps {
  closeSidebar: () => void;
  inspectionsData: any;
  assetId: string;
  userRole: string;
}

export default ({
  closeSidebar,
  inspectionsData = { list: [] },
  assetId,
  userRole,
}: IInspectionProps) => {
  const [inspections, setInspections] = useState<IInspection[]>(
    inspectionsData.list || []
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredInspections, setFilteredInspections] = useState<IInspection[]>(
    []
  );
  const [inspection, setInspection] = useState<IInspection | null>(null);

  useEffect(() => {
    setInspections(inspectionsData.list || []);
  }, [inspectionsData]);

  useEffect(() => {
    const initializeInspections = async () => {
      if (inspectionsData && inspectionsData.list?.length > 0) {
        setInspections(inspectionsData.list);
        setFilteredInspections(inspectionsData.list);
      } else {
        try {
          const fetchedInspections =
            await inspectionsService.getUserInspections("1");
          setInspections(fetchedInspections);
          setFilteredInspections(fetchedInspections);
        } catch (error) {
          console.error("Failed to fetch inspections", error);
        }
      }
    };

    initializeInspections();
  }, [inspectionsData]);

  useEffect(() => {
    setFilteredInspections(
      inspections.filter((inspection) =>
        inspection.vehicle?.toLowerCase().includes(searchTerm?.toLowerCase())
      )
    );
  }, [searchTerm, inspections]);

  const markInspectionAsViewed = async (inspectionId: string) => {
    setInspections(
      inspections.map((inspection) =>
        inspection.number === inspectionId
          ? { ...inspection, viewed: true }
          : inspection
      )
    );
  };

  const markAllInspectionsAsViewed = async () => {
    setInspections(
      inspections.map((inspection) => ({ ...inspection, viewed: true }))
    );
  };

  return (
    <>
      <div className="bg-right-sidebar" onClick={() => closeSidebar()} />
      <div className="right-sidebar">
        <h3 className="flex gap-6 margin-bottom-20">
          <span className="rioglyph rioglyph-calendar-today"></span>
          Campanhas de Campo
        </h3>
        <div className="close-sidebar cursor-pointer" onClick={closeSidebar}>
          <span className="rioglyph rioglyph-remove"></span>
        </div>
        {!inspection && (
          <InspectionList
            inspections={filteredInspections}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            markInspectionAsViewed={markInspectionAsViewed}
            markAllInspectionsAsViewed={markAllInspectionsAsViewed}
            chooseInspection={setInspection}
          />
        )}

        {inspection && (
          <Inspection
            inspection={inspection}
            setInspection={() => setInspection(null)}
            assetId={assetId}
            userRole={userRole}
          />
        )}
      </div>
    </>
  );
};
