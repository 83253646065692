import ApiService from "../services/api";

export const favoriteDealership = async (favoriteId: string): Promise<void> => {
    const apiService = new ApiService();
    
    try {
        await apiService.post<any>(
            `/dealerships/favorites`,
            {
                id: favoriteId,
                favorite: true,
            }
        );
    } catch (error) {
        console.error(error);
    }
}

export const removeFavoriteDealership = async (favoriteId: string): Promise<void> => {
    const apiService = new ApiService();
    
    try {
        await apiService.post<any>(
            `/dealerships/favorites`,
            {
                id: favoriteId,
                favorite: false,
            }
        );
    } catch (error) {
        console.error(error);
    }
}