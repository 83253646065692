import { Dialog } from "@rio-cloud/rio-uikit";
import { IAppointmentForm } from "..";
import { useEffect, useState } from "react";
import { fetchUser, IUserProfile } from "../../../../api/fetchUser";
import { useSelector } from "react-redux";
import { getUserProfile } from "../../../../configuration/login/loginSlice";

interface ISuccessDialogProps {
  open: boolean;
  appointmentForm: IAppointmentForm | undefined;
  routeWhenClose?: string;
}

const SuccessDialog = ({
  open,
  appointmentForm,
  routeWhenClose,
}: ISuccessDialogProps) => {
  const userProfile = useSelector(getUserProfile);
  const [scheduleDate, setScheduleDate] = useState<Date>();
  const [user, setUser] = useState<IUserProfile>();

  useEffect(() => {
    const fetchData = async () => {
      const userId = userProfile?.sub?.split(":")[1];
      if (userId) {
        const user = await fetchUser(userId);
        setUser(user);
      }
    };

    if (appointmentForm?.dateAndConsultant?.scheduleDate)
      setScheduleDate(
        new Date(appointmentForm?.dateAndConsultant?.scheduleDate?.getTime())
      );

    fetchData();
  }, [open]);

  return (
    <Dialog
      show={open}
      title="Agendamento realizado"
      onClose={() => (window.location.href = routeWhenClose ?? "/dealership")}
      bsSize="sm"
      body={
        <div>
          <div
            style={{ backgroundColor: "#ECFDF3", borderColor: "#ABEFC6" }}
            className="border rounded padding-15"
          >
            <div className="flex">
              <div className="text-center w-14">
                <span className="rioglyph rioglyph-ok bg-success text-color-white rounded-circle padding-2" />
              </div>
              <div className="flex-grow">
                <div className="font-bold">
                  Agendamento realizado com sucesso!
                </div>
                <div>
                  O gestor da frota desse veículo também foi notificado.
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="flex items-start gap-4 padding-top-10 padding-bottom-10">
            <img
              src="/assets/images/vehicle-thumb.png"
              alt="Vehicle"
              className="w-20 h-20 rounded"
            />

            <div className="flex-1 padding-left-10">
              <p className="text-color-black text-bold text-size-16 m-0">
                {appointmentForm?.assetsAndDrivers?.asset?.name}
              </p>
              <p className="text-color-dark text-size-16 m-0">
                Chassi - {appointmentForm?.assetsAndDrivers?.asset?.chassis}
              </p>
            </div>
          </div>

          <hr />

          <div className="flex items-start gap-4">
            <div className="padding-right-25 border border-right-only">
              <div className="text-size-16 text-bold">Solicitante</div>
              <div
                className="text-size-16 text-color-dark"
                style={{ lineHeight: "34px" }}
              >
                {user?.name ?? "-"}
              </div>
            </div>

            <div className="padding-left-10 flex-grow border border-right-only">
              <div className="text-size-16 text-bold">Data</div>
              <div className="flex" style={{ lineHeight: "34px" }}>
                <span className="rioglyph rioglyph-calendar" />{" "}
                {scheduleDate?.toLocaleDateString("pt-BR", {
                  day: "2-digit",
                  month: "long",
                })}
              </div>
            </div>

            <div className="width-100 padding-left-15">
              <div className="text-size-16 text-bold">Hora</div>
              <div className="text-size-16" style={{ lineHeight: "34px" }}>
                {scheduleDate?.toLocaleTimeString("pt-BR", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
                h
              </div>
            </div>
          </div>
        </div>
      }
      footer={
        <button
          type="button"
          className="btn btn-primary btn-link"
          onClick={() =>
            (window.location.href = routeWhenClose ?? "/dealership")
          }
        >
          Fechar
        </button>
      }
    />
  );
};

export default SuccessDialog;
