import { Button, Dialog } from "@rio-cloud/rio-uikit";
import { useState } from "react";
import { Asset } from "../../../../models/asset";
import { updateUptimeAsset } from "../../../../api/fetchAssets";

interface IEditOrdometerHourmeterDialogProps {
    type: string;
    asset: Asset;
    onClose: () => void;
}

export const EditOrdometerHourmeterDialog = (props: IEditOrdometerHourmeterDialogProps) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<number | undefined>();
    const [loading, setLoading] = useState(false);

    return (
        <>
            <span
                className="rioglyph rioglyph-pencil-square text-size-18 cursor-pointer"
                onClick={() => setOpen(true)}
            />

            <Dialog
                show={open}
                title={`Atualizar ${props.type === 'odometer' ? 'Odômetro' : 'Horímetro'}`}
                subtitle="Informe abaixo o valor desejado."
                bsSize="xs"
                footer={
                    <>
                        <button
                            onClick={async () => {
                                if(!value) return;
                                
                                setLoading(true);
                                try {
                                    const now = new Date();

                                    const response = await updateUptimeAsset({
                                        chassis: props.asset.chassis,
                                        [props.type]: value,
                                        [props.type === 'odometer' ? 'lastOdometerUpdate' : 'lastHourmeterUpdate']: now
                                    });

                                    if(!response) throw new Error();

                                    if(props.type === 'odometer') {
                                        props.asset.odometer = value;
                                        props.asset.lastOdometerUpdate = now;
                                    } else {
                                        props.asset.hourmeter = value;
                                        props.asset.lastHourmeterUpdate = now;
                                    }

                                    setOpen(false);
                                    props.onClose();
                                } catch (error) {
                                    console.error(error);
                                    setLoading(false);
                                }

                            }}
                            className={`btn btn-primary ${loading ? 'btn-loading' : ''} margin-right-10`}
                            disabled={!value || loading}
                        >
                            Atualizar
                        </button>
                        <button
                            onClick={() => setOpen(false)}
                            className="btn btn-default"
                        >
                            Fechar
                        </button>
                    </>
                }
                body={
                    <div className="w-full mx-auto bg-white rounded-lg p-6">
                        <div className="form-group">
                            <label htmlFor="value">{props.type === 'odometer' ? 'Odômetro' : 'Horímetro'}</label>
                            <input
                                id="value"
                                className="form-control"
                                type="number"
                                placeholder="Valor"
                                onChange={(e) => setValue(parseFloat(e.target.value))}
                            />
                        </div>
                    </div>
                }
            />
        </>
    )
}