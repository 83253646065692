import React, { useState } from "react";
import RadioButton from "@rio-cloud/rio-uikit/RadioButton";
import Dialog from "@rio-cloud/rio-uikit/Dialog";
import {
  MaintenanceMonitoringStepsEnum,
  MaintenanceMonitoringStatusEnum,
} from "../../../../../enums/maintenanceMonitoring";
import { MaintenanceMonitoringTimelineStep } from "../../../../../models/ticket";
import { sendAssessment } from "../../../../../api/assessment";
import { useNavigate } from "react-router-dom";
interface ITimelineProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  doneTime: number;
  progressTime: number;
  totalTime: number;
  overTime: number;
  timeline: MaintenanceMonitoringTimelineStep[];
  ticketId?: string;
}

const Rate = ({
  setStep,
  timeline: initialTimeline,
  ticketId,
}: ITimelineProps) => {
  const [timeline, setTimeline] = useState<MaintenanceMonitoringTimelineStep[]>(
    () =>
      initialTimeline.map((step, index) => ({
        ...step,
        status:
          index === 0
            ? MaintenanceMonitoringStatusEnum.VALIDATION
            : MaintenanceMonitoringStatusEnum.PENDING,
      }))
  );
  const [rating, setRating] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const stepNames: Record<MaintenanceMonitoringStepsEnum, string> = {
    [MaintenanceMonitoringStepsEnum.TICKET]: "Chamado",
    [MaintenanceMonitoringStepsEnum.SCREENING]: "Triagem",
    [MaintenanceMonitoringStepsEnum.REPAIR]: "Reparo",
    [MaintenanceMonitoringStepsEnum.INSPECTION]: "Inspeção",
    [MaintenanceMonitoringStepsEnum.RELEASE]: "Liberação",
    [MaintenanceMonitoringStepsEnum.FINISHED]: "Finalizado",
  };

  const originalStepNames: Record<MaintenanceMonitoringStepsEnum, string> = {
    [MaintenanceMonitoringStepsEnum.TICKET]: "TICKET",
    [MaintenanceMonitoringStepsEnum.SCREENING]: "SCREENING",
    [MaintenanceMonitoringStepsEnum.REPAIR]: "REPAIR",
    [MaintenanceMonitoringStepsEnum.INSPECTION]: "INSPECTION",
    [MaintenanceMonitoringStepsEnum.RELEASE]: "RELEASE",
    [MaintenanceMonitoringStepsEnum.FINISHED]: "FINISHED",
  };

  const handleNextStep = async () => {
    if (!ticketId) {
      console.error("Erro: ticketId não encontrado.");
      return;
    }

    const currentStep = timeline.find(
      (step) => step.status === MaintenanceMonitoringStatusEnum.VALIDATION
    );

    if (!currentStep) {
      console.error("Nenhuma etapa atual encontrada.");
      return;
    }

    const body = {
      step:
        originalStepNames[currentStep.step as keyof typeof originalStepNames] ??
        currentStep.step,
      description,
      rating,
    };

    try {
      setIsLoading(true);

      await sendAssessment(ticketId, body);
      setTimeline((prevTimeline) => {
        const newTimeline = [...prevTimeline];
        const currentStepIndex = newTimeline.findIndex(
          (step) => step.step === currentStep.step
        );

        if (currentStepIndex !== -1) {
          newTimeline[currentStepIndex].status =
            MaintenanceMonitoringStatusEnum.DONE;
          if (
            currentStepIndex + 1 < newTimeline.length &&
            newTimeline[currentStepIndex + 1].status ===
              MaintenanceMonitoringStatusEnum.PENDING
          ) {
            newTimeline[currentStepIndex + 1].status =
              MaintenanceMonitoringStatusEnum.VALIDATION;
          }
        }
        return newTimeline;
      });

      setStep((prevStep) => prevStep + 1);
      setRating(0);
      setDescription("");

      if (
        timeline.every(
          (step) =>
            step.status === MaintenanceMonitoringStatusEnum.DONE ||
            step.status === MaintenanceMonitoringStatusEnum.VALIDATION
        )
      ) {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Erro ao enviar avaliação:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getStepIcon = (step: MaintenanceMonitoringStepsEnum) => {
    switch (step) {
      case MaintenanceMonitoringStepsEnum.TICKET:
        return "rioglyph-warning-sign";
      case MaintenanceMonitoringStepsEnum.SCREENING:
        return "rioglyph-transfer";
      case MaintenanceMonitoringStepsEnum.REPAIR:
        return "rioglyph-workshop";
      case MaintenanceMonitoringStepsEnum.INSPECTION:
        return "rioglyph-maintenance-components";
      case MaintenanceMonitoringStepsEnum.RELEASE:
        return "rioglyph-area-out";
      default:
        return "";
    }
  };

  return (
    <div>
      <div className="display-grid grid-cols-5 margin-auto margin-top-20 width-100pct">
        {timeline.map((step, index) => (
          <div
            key={`step_${index}`}
            className={`
              padding-15 border-width-1 rounded text-color-gray margin-x-15
              ${
                step.status === MaintenanceMonitoringStatusEnum.VALIDATION
                  ? "border-color-primary bg-white"
                  : step.status === MaintenanceMonitoringStatusEnum.DONE
                  ? "border-color-success bg-white"
                  : "border-color-gray bg-lightest"
              }
            `}
          >
            <div className="flex items-center">
              <div>
                <i
                  className={`
                    rioglyph 
                    ${
                      step.status === MaintenanceMonitoringStatusEnum.DONE
                        ? "rioglyph-ok"
                        : getStepIcon(step.step)
                    } 
                    ${
                      step.status === MaintenanceMonitoringStatusEnum.VALIDATION
                        ? "text-color-primary"
                        : step.status === MaintenanceMonitoringStatusEnum.DONE
                        ? "text-color-success"
                        : "text-color-gray"
                    }
                  `}
                  style={{ fontSize: "25px" }}
                />
              </div>
              <div className="flex flex-col margin-left-20 width-100pct">
                <div className="border border-bottom-only flex justify-between padding-y-5">
                  <span
                    className={`
                      font-bold 
                      ${
                        step.status ===
                        MaintenanceMonitoringStatusEnum.VALIDATION
                          ? "text-color-primary"
                          : step.status === MaintenanceMonitoringStatusEnum.DONE
                          ? "text-color-success"
                          : "text-color-gray"
                      }
                    `}
                  >
                    {step.step}
                  </span>
                  <span>
                    <i className="rioglyph rioglyph-time-alt"></i>{" "}
                    {Math.floor(step.estimatedTime / 60)
                      .toString()
                      .padStart(2, "0")}
                    :{(step.estimatedTime % 60).toString().padStart(2, "0")}h
                  </span>
                </div>
                <div
                  className={`
                    flex justify-between padding-y-5
                    ${
                      step.status === MaintenanceMonitoringStatusEnum.DONE
                        ? "text-color-success"
                        : ""
                    }
                  `}
                >
                  <span>
                    {step.date
                      ? `${step.date.getDate().toString().padStart(2, "0")}/${(
                          step.date.getMonth() + 1
                        )
                          .toString()
                          .padStart(2, "0")}/${step.date.getFullYear()}`
                      : "--/--/----"}
                  </span>
                  <span>
                    <i className="rioglyph rioglyph-time-alt"></i>{" "}
                    {step.time
                      ? `${Math.floor(step.time / 60)
                          .toString()
                          .padStart(2, "0")}:${(step.time % 60)
                          .toString()
                          .padStart(2, "0")}h`
                      : "--:--h"}
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`
                margin-top-10 width-100pct rounded-circle border-width-1 text-center padding-2
                ${
                  step.status === MaintenanceMonitoringStatusEnum.VALIDATION
                    ? "bg-highlight-lightest border-color-highlight-light text-color-highlight-dark text-bold"
                    : step.status === MaintenanceMonitoringStatusEnum.DONE
                    ? "bg-emerald-50 border-emerald-300 text-color-success text-bold"
                    : "bg-decent border-color-gray"
                }
              `}
            >
              {step.status}
            </div>
          </div>
        ))}
      </div>

      <div className="margin-top-20 margin-bottom-10">
        <span style={{ color: "#707F8F" }} className="text-size-16 font-bold">
          Avalie como foi a etapa de{" "}
          {
            stepNames[
              timeline.find(
                (item) =>
                  item.status === MaintenanceMonitoringStatusEnum.VALIDATION
              )?.step ?? MaintenanceMonitoringStepsEnum.TICKET
            ]
          }{" "}
          do seu veículo
        </span>
        <div className="height-10 w-full margin-top-20 rate-bar"></div>
        <div className="margin-top-20 w-full flex space-between">
          {[...Array(10)].map((_, index) => (
            <RadioButton
              key={index + 1}
              name="radiosInline"
              id={`rating-${index + 1}`}
              inline
              checked={rating === index + 1} // Adicionado para refletir a seleção corretamente
              onChange={() => setRating(index + 1)}
            >
              {index + 1}
            </RadioButton>
          ))}
        </div>

        <div className="form-group margin-top-20">
          <label htmlFor="textArea">Comentários</label>
          <textarea
            className="form-control"
            id="textArea"
            placeholder="Descritivo"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{ height: 173 }}
          ></textarea>
        </div>

        <div className="margin-top-20 flex justify-center">
          <button
            type="button"
            className="btn btn-lg btn-primary"
            onClick={handleNextStep}
            disabled={isLoading || rating === 0}
          >
            {isLoading ? "Enviando..." : "Avançar"}
          </button>
        </div>
      </div>
      <Dialog
        show={isModalOpen}
        title="Progresso de avaliação"
        body={<ModalContent />}
        bsSize={Dialog.SIZE_SM}
        onClose={() => setIsModalOpen(false)}
        className="exampleDialog"
        showCloseButton
      />
    </div>
  );
};

export default Rate;

const ModalContent = () => {
  const navigate = useNavigate();
  return (
    <div>
      <p className="text-size-16">Avaliação concluída com sucesso</p>
      <div className="flex justify-end padding-top-10">
        <div className="modal-footer padding-top-20 margin-top-20 w-full">
          <button
            type="button"
            className="btn btn-primary margin-top-15 padding-left-20 padding-right-20"
            onClick={() => navigate(`/fleet-manager/my-appointments`)}
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  );
};
