import { useEffect, useState } from "react";
import { ClearableInput, Select } from "@rio-cloud/rio-uikit";
import DatePicker from "@rio-cloud/rio-uikit/DatePicker";
import { Scheduling } from "../../../../models/schedule";
import AppointmentCard from "./appointmentCard";
import { fetchSchedulingByChassisList } from "../../../../api/fetchScheduleList";
import { fetchAssets } from "../../../../api/fetchAssets";
import { Loading } from "../../../../utils/loading";
import Appointment from "./appointment";
import moment from "../../../../utils/momentConfig";

import "../styles.scss";

const Appointmens = (props: any) => {
  const [sortType, setSortType] = useState<string | undefined>("asc");
  const [appointmens, setAppointmens] = useState<Scheduling[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [scheduling, setScheduling] = useState<Scheduling | null>(null);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [datePickerKey, setDatePickerKey] = useState<number>(0);

  const fetchAppointmens = async () => {
    setLoading(true);
    const assets = await fetchAssets();
    let appointments: Scheduling[] = [];

    const chunkSize = 5000;
    const chunks = [];
    for (let i = 0; i < props.assetIds.length; i += chunkSize) {
      const chunk = props.assetIds.slice(i, i + chunkSize);
      chunks.push(chunk);
    }

    const promisesLength = chunks.length / 10;
    let promises = [];
    for (let i = 0; i < promisesLength; i++) {
      promises.push([]);
      promises[i] = chunks
        .slice(i * 10, (i + 1) * 10)
        .map((chunk) =>
          fetchSchedulingByChassisList(
            assets
              .filter((asset) => chunk.includes(asset.id))
              .map((asset) => asset.chassis)
          )
        );
    }

    for (const promise of promises) {
      const responses = await Promise.all(promise);
      if(responses.length === 0) setLoading(false);
      responses.forEach((response, index) => {
        appointments = [...appointments, ...response];
        if(index === responses.length - 1) setLoading(false);
      });
    }

    setAppointmens(appointments);
  };

  const handleDateChange = (date: moment.Moment | string) => {
    if (moment.isMoment(date)) {
      setSelectedDate(date);
    }
  };

  useEffect(() => {
    fetchAppointmens();
  }, [props.assetIds]);

  const clearDateFilter = () => {
    setSelectedDate(null);
    setDatePickerKey((prevKey) => prevKey + 1);
  };

  return loading ? (
    <Loading label="Carregando agendamentos..." />
  ) : scheduling ? (
    <Appointment appointment={scheduling} goBack={() => setScheduling(null)} />
  ) : (
    <>
      <h1>Agendamentos</h1>
      <div className="flex gap-6 margin-top-15 search-filter">
        <div className="form-group search-group">
          <div className="input-group">
            <span className="input-group-addon">
              <span className="rioglyph rioglyph-search" aria-hidden="true" />
            </span>
            <ClearableInput
              id="5"
              placeholder="Busque por chassi"
              onChange={(value: string) => setSearchTerm(value)}
            />
          </div>
        </div>

        <div className="form-group search-group relative">
          <DatePicker
            key={datePickerKey}
            id="date-picker"
            inputProps={{ placeholder: "Escolha a data" }}
            dateFormat="DD-MM-YYYY"
            timeFormat={false}
            locale="pt-br"
            onChange={handleDateChange}
            value={selectedDate || undefined}
          />

          {selectedDate && (
            <span
              className="rioglyph rioglyph-remove-sign absolute right-10 top-10 cursor-pointer text-color-gray"
              onClick={clearDateFilter}
            />
          )}
        </div>

        <div className="form-group select-group">
          <Select
            id="sort-type"
            options={[
              { id: "desc", label: <span>Decrescente</span>, selected: true },
              { id: "asc", label: <span>Crescente</span>, selected: false },
            ]}
            onChange={(selected) => setSortType(selected?.id)}
          />
        </div>
      </div>

      {appointmens.length === 0 && (
        <div className="flex justify-center padding-top-10">
          <span className="text-color-gray">
            Não existem agendamentos realizados.
          </span>
        </div>
      )}

      {appointmens
        .sort((a, b) =>
          sortType === "asc"
            ? a.scheduleTime.getTime() - b.scheduleTime.getTime()
            : b.scheduleTime.getTime() - a.scheduleTime.getTime()
        )
        .filter((appointment) =>
          appointment.asset.chassis
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
        .filter((appointment) => {
          if (!selectedDate) return true;
          const appointmentDate = moment(appointment.scheduleTime).format("DD-MM-YYYY");
          const selectedFormattedDate = selectedDate.format("DD-MM-YYYY");
          return appointmentDate === selectedFormattedDate;
        })
        .map((appointment) => (
          <AppointmentCard
            key={crypto.randomUUID()}
            appointment={appointment}
            chooseScheduling={(schedulingChoosed) =>
              setScheduling(schedulingChoosed)
            }
            fetchAppointmens={fetchAppointmens}
          />
        ))}
    </>
  );
};

export default Appointmens;
