import { useEffect, useState } from "react";
import { TicketRelease } from "../../../../../models/ticket";
import {
  downloadTicketImage,
  fetchTicketImages,
  ITicketFile,
} from "../../../../../api/ticket";
import moment from "moment";
import { formatFileSize } from "../../../../../utils/imageSizeUtils";

interface IReleaseProps {
  ticketId?: string | undefined;
  release?: TicketRelease | undefined;
}

const Release = ({ ticketId, release }: IReleaseProps) => {
  const [files, setFiles] = useState<ITicketFile[]>([]);
  const [, setState] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      if (release && ticketId) {
        const images = await fetchTicketImages({
          ticketId: ticketId,
          step: "RELEASE",
        });
        setFiles(images);
      }
    };

    fetchData();
  }, [release]);

  return (
    <div>
      <div className="display-grid gap-20 grid-cols-2">
        <div className="form-group">
          <label htmlFor="serviceOrder">Fechamento da OS</label>
          <input
            id="serviceOrder"
            className="form-control"
            type="text"
            placeholder="Número"
            disabled
            onChange={(e) => (release!.serviceOrder = e.target.value)}
            value={release?.serviceOrder}
          />
        </div>
        <div className="form-group">
          <label htmlFor="osHour">Tempo Estimado</label>
          <div className="input-group">
            <span className="input-group-addon">
              <span className="rioglyph rioglyph-time"></span>
            </span>
            <input
              id="osHour"
              className="form-control"
              type="text"
              value={release?.estimatedTime}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, "").slice(0, 4);
                const formattedValue = value.replace(/(\d{2})(\d{2})/, "$1:$2");
                release!.estimatedTime = formattedValue;
                setState((prevState) => prevState + 1);
              }}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="form-group ">
        <label htmlFor="textArea">Relato</label>
        <textarea
          className="form-control"
          rows={3}
          id="textArea"
          placeholder="Descritivo"
          value={release?.report}
          onChange={(e) => (release!.report = e.target.value)}
          disabled
        />
      </div>

      <div>
        <label>Inspeção Visual</label>
        <div className="display-grid grid-cols-1 grid-cols-3-sm gap-25">
          {files.length > 0 ? (
            files.map((file, index) => (
              <FilePreview key={index} file={file} ticketId={ticketId} />
            ))
          ) : (
            <div className="text-color-dark text-italic">Nenhum arquivo</div>
          )}
        </div>
      </div>

      <div className="display-grid gap-20 grid-cols-3 padding-y-50">
        <div className="alert alert-dismissible alert-info">
          <div className="display-flex gap-10">
            <span className="text-color-info text-size-h4 rioglyph rioglyph-info-sign"></span>
            <div>
              <strong className="text-size-16">Adquirir Serviço</strong>
              <div className="margin-y-5">
                Nenhum serviço contratado, clique para selecionar.
              </div>
              <div className="text-medium text-uppercase text-size-12">
                <a href="#" className="alert-link">
                  IR PARA MARKETPLACE
                </a>
                <span className="text-color-info margin-left-3 rioglyph rioglyph-new-window"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FilePreview = ({
  file,
  ticketId,
}: {
  file: ITicketFile | undefined;
  ticketId: string | undefined;
}) => (
  <div
    className="panel panel-default margin-top-20 cursor-pointer"
    onClick={async () => {
      if (ticketId && file) {
        const response = await downloadTicketImage({
          ticketId: ticketId,
          step: "RELEASE",
          filename: file.name,
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.name);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      }
    }}
  >
    <div className="panel-body">
      {file && (
        <div className="flex">
          {file.link && (
            <div className="thumbnail margin-right-10">
              <img
                src={file.link}
                alt={file.name}
                style={{
                  width: "200px",
                  height: "120px",
                  objectFit: "cover",
                }}
              />
            </div>
          )}
          <div className="flex justify-between w-full">
            <div>
              <div>
                <b>{`${file?.name}`}</b>
              </div>
              <div>tipo: {`${file?.contentType}`}</div>
              <div>tamanho: {formatFileSize(file?.contentSize)}</div>
              <div>
                data da última modificação:{" "}
                {file?.updatedAt
                  ? moment(file.updatedAt).format("DD/MM/YYYY")
                  : "Data não disponível"}
              </div>{" "}
              <div>caminho: {`${file?.name}`}</div>
            </div>
          </div>
        </div>
      )}
      {!file && (
        <div className="text-color-dark text-italic">Nenhum arquivo</div>
      )}
    </div>
  </div>
);

export default Release;
