import { useState } from "react";
import { IInspection } from "../../services/inspectionsService";

interface IInspectionListProps {
  inspections: IInspection[];
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  markInspectionAsViewed: (inspectionId: string) => void;
  markAllInspectionsAsViewed: () => void;
  chooseInspection: (inspection: IInspection) => void;
}

export default ({
  inspections,
  searchTerm,
  setSearchTerm,
  markInspectionAsViewed,
  markAllInspectionsAsViewed,
  chooseInspection,
}: IInspectionListProps) => {
  const [order, setOrder] = useState<string>('down');
  const handleOrderChange = () => setOrder(order === 'down' ? 'up' : 'down');

  return (
    <div>
      {/* <div className="form-group">
        <div className="input-group">
          <span className="input-group-addon">
            <span className="rioglyph rioglyph-search"></span>
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Pesquisar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div> */}
      <div className="form-group">
        <div className="display-flex gap-10 align-items-end">
          <div className="form-group width-100pct">
            <button type="button" className="btn btn-default width-100pct" onClick={handleOrderChange}>
              {order === 'down' ? 'Antigo para o atual' : 'Atual para o antigo'}
              <span className={`rioglyph rioglyph-chevron-${order} padding-left-10`} aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>

      {/* <button
        type="button"
        className="btn btn-secondary"
        onClick={markAllInspectionsAsViewed}
      >
        <span className="rioglyph rioglyph-ok" aria-hidden="true"></span>
        <span className="text-capitalize">Marcar todos como lidos</span>
      </button> */}

      <div className="margin-top-15">
        {inspections
        ?.sort((a, b) => order === 'down' ? new Date(b.validityFrom).getTime() - new Date(a.validityFrom).getTime() : new Date(a.validityFrom).getTime() - new Date(b.validityFrom).getTime())
        .map((inspection) => (
          <div
            key={inspection.number}
            className="border margin-bottom-15 rounded padding-15 cursor-pointer"
            onClick={() => (
              markInspectionAsViewed(inspection.number),
              chooseInspection(inspection)
            )}
          >
            <div className="display-flex gap-20">
              <div className="flex-basis-10pct margin-auto">
                <span className="indicated-icon">
                  <span
                    className="rioglyph rioglyph-calendar-today"
                    aria-hidden="true"
                  ></span>
                  {!inspection.campaignYear && (
                    <span className="badge badge-indicator badge-indicator-border badge-danger"></span>
                  )}
                </span>
              </div>
              <div className="flex-basis-90pct">
                <div className="text-size-14 text-medium">
                  {inspection?.classification} - {inspection.vehicle}
                </div>
                <div className="text-color-dark">{inspection.validityFrom}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
