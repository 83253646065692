import { useEffect, useState } from "react";
import DatePicker from "@rio-cloud/rio-uikit/DatePicker";
import type { Moment } from "moment";
import { TicketRepair } from "../../../../../models/ticket";
import {
  downloadTicketImage,
  fetchTicketImages,
  ITicketFile,
  removeTicketImage,
  uploadTicketImage,
} from "../../../../../api/ticket";
import { FilePicker } from "@rio-cloud/rio-uikit";
import ErrorDialog from "../../../../../components/ErrorDialog";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import Tooltip from "@rio-cloud/rio-uikit/Tooltip";
import OverlayTrigger from "@rio-cloud/rio-uikit/OverlayTrigger";
import moment from "moment";
import { formatFileSize } from "../../../../../utils/imageSizeUtils";

const accept = {
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
  "image/svg": [],
};

interface IRepairProps {
  ticketId: string | undefined;
  repair: TicketRepair | undefined;
  setRepair: (repair: TicketRepair) => void;
}

const Repair = ({ ticketId, repair, setRepair }: IRepairProps) => {
  const [files, setFiles] = useState<ITicketFile[]>([]);
  const [uploadErrorMessage, setUploadErrorMessage] = useState<
    string | undefined
  >();

  useEffect(() => {
    const fetchData = async () => {
      if (repair && ticketId) {
        const images = await fetchTicketImages({
          ticketId: ticketId,
          step: "REPAIR",
        });
        setFiles(images ?? []);
      }
    };
    fetchData();
  }, [ticketId]);

  return (
    <div>
      <div className="resume-section">
        <div className="resume-container padding-0">
          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-full">
              <label htmlFor="data">Data</label>
              <DatePicker
                id="data"
                inputProps={{ placeholder: "--/--/----" }}
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                value={repair?.repairDate}
                onChange={(result: Moment | string) => {
                  if (typeof result === "string") {
                    setRepair({ ...repair, repairDate: new Date(result) });
                  } else {
                    setRepair({ ...repair, repairDate: result.toDate() });
                  }
                }}
                className="margin-bottom-0"
              />
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="checkin">Check-in</label>
              <input
                id="checkin"
                className="form-control"
                type="text"
                placeholder="--/--/----"
                disabled
                value={repair?.checkIn?.toLocaleDateString("pt-BR")}
              />
            </div>

            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="hora-checkin" className="opacity-0">
                Hora
              </label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="hora-checkin"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  disabled
                  value={repair?.checkIn?.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="checkout">Check-out</label>
              <input
                id="checkout"
                className="form-control"
                type="text"
                placeholder="--/--/----"
                disabled
                value={repair?.checkOut?.toLocaleDateString("pt-BR")}
              />
            </div>

            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="hora-checkout" className="opacity-0">
                Hora
              </label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="hora-checkout"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  disabled
                  value={repair?.checkOut?.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-full">
              <label htmlFor="pecas">Aguardando Peças</label>
              <div className="input-group">
                <input
                  id="pecas"
                  className="form-control"
                  type="text"
                  value={repair?.awaitingParts}
                  onChange={(e) =>
                    setRepair({ ...repair, awaitingParts: e.target.value })
                  }
                  disabled={!repair?.checkIn || !!repair?.checkOut}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="literatura">
                Tempo Estimado Conforme Literatura
              </label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="literatura"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  value={repair?.estimatedTime}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "").slice(0, 4);
                    const formattedValue = value.replace(
                      /(\d{2})(\d{2})/,
                      "$1:$2"
                    );
                    setRepair({ ...repair, estimatedTime: formattedValue });
                  }}
                  disabled={!repair?.checkIn || !!repair?.checkOut}
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="troubleshooting">
                Troubleshoot Estimado em Solução Conforme Literatura
              </label>
              <input
                id="troubleshooting"
                className="form-control"
                type="text"
                value={repair?.estimatedTroubleshooting}
                onChange={(e) =>
                  setRepair({
                    ...repair,
                    estimatedTroubleshooting: e.target.value,
                  })
                }
                disabled={!repair?.checkIn || !!repair?.checkOut}
              />
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-full">
              <label htmlFor="relato">Relato</label>
              <textarea
                className="form-control height-100"
                id="relato"
                placeholder="Descritivo"
                value={repair?.report}
                onChange={(e) =>
                  setRepair({ ...repair, report: e.target.value })
                }
                disabled={!repair?.checkIn || !!repair?.checkOut}
              ></textarea>
            </div>
          </div>

          <div>
            <div className="infoFiles">
              <label htmlFor="relato">Inspeção visual</label>
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id="tooltip" allowOnTouch>
                    <div className="flex flex-col padding-10">
                      <p>
                        Arquivos permitidos: .jpg, .jpeg e .png
                        <br />
                        Tamanho máximo permitido: 2MB
                      </p>
                    </div>
                  </Tooltip>
                }
              >
                <span className="rioglyph rioglyph-question-sign margin-left-10 cursor-pointer text-color-gray" />
              </OverlayTrigger>
            </div>
            <div>
              {!!!repair?.checkOut &&
                (files?.length === 0 || !files) &&
                !(!repair?.checkIn || !!repair?.checkOut) && (
                  <FilePicker
                    displayMode="dropzone"
                    className="margin-bottom-20"
                    onPick={async (acceptedFiles: FileList | null) => {
                      const maxSize = 5 * 1024 * 1024;
                      const filePicked = acceptedFiles?.[0];

                      if (!filePicked) return;

                      if (filePicked.size > maxSize) {
                        return setUploadErrorMessage(
                          "O arquivo selecionado excede o limite de 5MB. Escolha um arquivo menor."
                        );
                      }

                      if (acceptedFiles?.[0] && ticketId) {
                        const uploaded = await uploadTicketImage({
                          ticketId: ticketId,
                          imageFile: Array.from(acceptedFiles),
                          step: "REPAIR",
                        });
                        if (!uploaded)
                          return setUploadErrorMessage(
                            "Arquivo invalido. Erro ao realizar o upload, verifique o arquivo e tente novamente."
                          );
                        setFiles(
                          filePicked
                            ? [
                                {
                                  name: filePicked.name,
                                  updatedAt: new Date(
                                    filePicked.lastModified
                                  ).toLocaleDateString("pt-BR"),
                                  contentType: filePicked.type,
                                  contentSize: filePicked.size,
                                  link: URL.createObjectURL(filePicked),
                                },
                              ]
                            : []
                        );
                      }
                    }}
                    multiple={true}
                    accept={accept}
                  >
                    {dropArea}
                  </FilePicker>
                )}
              <div className="display-grid grid-cols-1 grid-cols-3-sm gap-25">
                {(files ?? []).map((file) => (
                  <FilePreview
                    key={file.name}
                    file={file}
                    ticketId={ticketId}
                    removeFile={() =>
                      setFiles((prevFiles) =>
                        (prevFiles ?? []).filter((f) => f.name !== file.name)
                      )
                    }
                    disabled={!repair?.checkIn || !!repair?.checkOut}
                  />
                ))}
              </div>
            </div>

            <ErrorDialog
              show={!!uploadErrorMessage}
              title={"Erro ao fazer upload"}
              body={uploadErrorMessage ?? ""}
              onClose={() => setUploadErrorMessage(undefined)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const dropArea = ({ isDragActive }: { isDragActive: boolean }) => (
  <div
    className={`
          rounded border border-style-dashed border-color-gray bg-lightest text-size-14
          text-center text-color-darker opacity-50 padding-25 cursor-pointer
      `}
  >
    <span className="rioglyph rioglyph-files text-size-200pct opacity-50 margin-top-20" />
    <div className="margin-top-10">
      {isDragActive
        ? "Jogue os arquivos aqui ..."
        : "Arraste arquivos de imagem para cá ou clique para selecionar no computador"}
    </div>
  </div>
);

const FilePreview = ({
  file,
  ticketId,
  removeFile,
  disabled,
}: {
  file: ITicketFile | undefined;
  ticketId: string | undefined;
  removeFile: () => void;
  disabled: boolean;
}) => {
  const [deleteFileDialog, setDeleteFileDialog] = useState<boolean>(false);

  return (
    <>
      <div
        className="panel panel-default margin-top-20 cursor-pointer"
        onClick={async () => {
          if (ticketId && file) {
            const response = await downloadTicketImage({
              ticketId: ticketId,
              step: "REPAIR",
              filename: file.name,
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file.name);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
          }
        }}
      >
        <div className="panel-body">
          {file && (
            <div className="flex">
              {file.link && (
                <div className="thumbnail margin-right-10">
                  <img
                    src={file.link}
                    alt={file.name}
                    style={{
                      width: "200px",
                      height: "120px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              )}
              <div className="flex justify-between w-full">
                <div>
                  <div>
                    <b>{`${file?.name}`}</b>
                  </div>
                  <div>tipo: {`${file?.contentType}`}</div>
                  <div>tamanho: {formatFileSize(file?.contentSize)}</div>
                  <div>
                    data da última modificação:{" "}
                    {file?.updatedAt
                      ? moment(file.updatedAt).format("DD/MM/YYYY")
                      : "Data não disponível"}
                  </div>{" "}
                  <div>caminho: {`${file?.name}`}</div>
                </div>
                {!disabled && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteFileDialog(true);
                    }}
                  >
                    <span className="rioglyph rioglyph-remove" />
                  </div>
                )}
              </div>
            </div>
          )}
          {!file && (
            <div className="text-color-dark text-italic">Nenhum arquivo</div>
          )}
        </div>
      </div>
      <ConfirmDialog
        show={deleteFileDialog}
        title="Remover arquivo"
        body="Deseja realmente remover o arquivo?"
        onClose={() => setDeleteFileDialog(false)}
        onConfirm={async () => {
          if (ticketId && file) {
            const response = await removeTicketImage({
              ticketId: ticketId,
              step: "REPAIR",
              filename: file.name,
            });
            if (response) {
              removeFile();
              setDeleteFileDialog(false);
            }
          }
        }}
      />
    </>
  );
};

export default Repair;
