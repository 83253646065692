export enum MaintenanceMonitoringStepsEnum {
  TICKET = "Chamado",
  SCREENING = "Triagem",
  REPAIR = "Reparo",
  INSPECTION = "Inspeção",
  RELEASE = "Liberação",
  FINISHED = "Finalizado",
}

export enum MaintenanceMonitoringStatusEnum {
  PENDING = "Não iniciado",
  IN_PROGRESS = "Em progresso",
  DONE = "Concluído",
  VALIDATION = "Avaliar",
}
