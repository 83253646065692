import { Navigate, Route, Routes } from "react-router-dom";

import Monitoring from "./monitoring";
import Assessment from "./monitoring/assessment";
import Report from "./monitoring/report";
import MonitoringList from "./list";

const ControlTowerMaintenanceMonitoringRoutes = () => (
	<Routes>
		<Route path="/" element={<MonitoringList />} />
		<Route path="/monitoring" element={<Monitoring />} />
		<Route path="/report" element={<Report />} />
		<Route path="/assessment" element={<Assessment />} />
	</Routes>
);

export default ControlTowerMaintenanceMonitoringRoutes;
