import { useEffect, useState } from "react";
import DatePicker from "@rio-cloud/rio-uikit/DatePicker";
import "./styles.scss";
import AppLayout from "../../../layout/AppLayout";
import ClearableInput from "@rio-cloud/rio-uikit/ClearableInput";
import Select from "@rio-cloud/rio-uikit/Select";
import Popover from "@rio-cloud/rio-uikit/Popover";
import OverlayTrigger from "@rio-cloud/rio-uikit/OverlayTrigger";
import { fetchDeletedSchedule } from "../../../api/fetchScheduleList";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../utils/loading";
import Dialog from "@rio-cloud/rio-uikit/Dialog";
import Appointment from "./appointment";
import { SchedulingDto } from "../../../dtos/schedulingDto";
import { Scheduling } from "../../../models/schedule";
import { fetchTickets } from "../../../api/fetchTickets";
import { fetchAssets } from "../../../api/fetchAssets";
import { fetchSchedulingByChassisList } from "../../../api/fetchScheduleList";
import moment from "../../../utils/momentConfig";
import { MaintenanceMonitoringStepsEnum } from "../../../enums/maintenanceMonitoring";

const placements = ["left"] as const;

const MyAppointmentsContent = (props: any) => {
  const navigate = useNavigate();
  const [sortType, setSortType] = useState<string | undefined>("asc");
  const [scheduleList, setScheduleList] = useState<Scheduling[]>([]);
  const [filteredAppointmens, setFilteredAppointmens] = useState<Scheduling[]>(
    []
  );
  const [error, setError] = useState<string | null>(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sortedList, setSortedList] = useState<Scheduling[]>([]);
  const [selectedOption, setSelectedOption] = useState("1");
  const [selectedScheduleId, setSelectedScheduleId] = useState<string | null>(
    null
  );
  const [dialogContent, setDialogContent] = useState<
    "confirmCancel" | "reschedule" | "success"
  >("confirmCancel");
  const [appointment, setAppointment] = useState<Scheduling | null>(null);
  const [allTickets, setAllTickets] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [datePickerKey, setDatePickerKey] = useState<number>(0);

  const handleDateChange = (date: moment.Moment | string) => {
    if (moment.isMoment(date)) {
      setSelectedDate(date);
    }
  };

  useEffect(() => {
    const fetchAllSchedules = async () => {
      setLoading(true);
      const assets = await fetchAssets();
      let appointments: Scheduling[] = [];

      const chunkSize = 5000;
      const chunks = [];
      for (let i = 0; i < props.assetIds.length; i += chunkSize) {
        const chunk = props.assetIds.slice(i, i + chunkSize);
        chunks.push(chunk);
      }

      const promisesLength = chunks.length / 10;
      let promises = [];
      for (let i = 0; i < promisesLength; i++) {
        promises.push([]);
        promises[i] = chunks
          .slice(i * 10, (i + 1) * 10)
          .map((chunk) =>
            fetchSchedulingByChassisList(
              assets
                .filter((asset) => chunk.includes(asset.id))
                .map((asset) => asset.chassis)
            )
          );
      }

      for (const promise of promises) {
        const responses = await Promise.all(promise);
        if (responses.length === 0) setLoading(false);
        responses.forEach((response, index) => {
          appointments = [...appointments, ...response];
          if (index === responses.length - 1) setLoading(false);
        });
      }

      setScheduleList(appointments);
      setFilteredAppointmens(appointments);
    };

    fetchAllSchedules();
  }, [props.assetIds]);

  useEffect(() => {
    const sorted = [...filteredAppointmens].sort((a, b) => {
      if (sortType === "desc") {
        return b.scheduleTime.getTime() - a.scheduleTime.getTime();
      } else {
        return a.scheduleTime.getTime() - b.scheduleTime.getTime();
      }
    });

    setFilteredAppointmens(sorted);
  }, [sortType, scheduleList, selectedDate]);

  useEffect(() => {
    const fetchAllTickets = async () => {
      const consultantUser = false;
      try {
        const tickets = await fetchTickets({ consultantUser });
        setAllTickets(tickets);
      } catch (error) {
        console.error("Erro ao buscar tickets:", error);
      }
    };

    fetchAllTickets();
  }, []);

  const handleReschedule = (id: string) => {
    navigate(`/fleet-manager/new-appointment?scheduling-id=${id}`);
  };

  const handleViewDetails = (schedule: Scheduling) => {
    console.log("Visualizando detalhes do agendamento:", schedule);
    setAppointment(schedule);
  };

  const handleOpenCancelDialog = (id: string) => {
    setSelectedScheduleId(id);
    setDialogContent("confirmCancel");
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false);
    setSelectedScheduleId(null);
  };

  const handleConfirmCancel = async () => {
    if (!selectedScheduleId) {
      console.error("ID do agendamento não definido");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      await fetchDeletedSchedule(selectedScheduleId);
      setScheduleList((prev) =>
        prev.filter((schedule) => schedule.id !== selectedScheduleId)
      );
      setDialogContent("success");
    } catch (err) {
      console.error("Erro ao cancelar o agendamento:", err);
      setError("Falha ao cancelar o agendamento.");
    } finally {
      setLoading(false);
    }
  };

  const filterAppointment = (search: string) => {
    if (search === "") {
      setFilteredAppointmens(scheduleList);
      return;
    }

    const filtered = scheduleList.filter((appointment) =>
      appointment.asset.chassis.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredAppointmens(filtered);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      filterAppointment(searchTerm);
    }, 300);

    return () => clearTimeout(timeout);
  }, [searchTerm]);

  useEffect(() => {
    if (!selectedDate) {
      setFilteredAppointmens(scheduleList);
      return;
    }

    const filtered = scheduleList.filter((appointment) => {
      const appointmentDate = moment(appointment.scheduleTime).format(
        "DD-MM-YYYY"
      );
      const selectedFormattedDate = selectedDate.format("DD-MM-YYYY");

      return appointmentDate === selectedFormattedDate;
    });

    setFilteredAppointmens(filtered);
  }, [selectedDate, scheduleList]);

  const clearDateFilter = () => {
    setSelectedDate(null);
    setFilteredAppointmens(scheduleList);
    setDatePickerKey((prevKey) => prevKey + 1);
  };

  return (
    <div className="mySchedule">
      <div>
        {loading ? (
          <Loading label="Carregando agendamentos..." />
        ) : appointment ? (
          <Appointment
            appointment={appointment}
            goBack={() => setAppointment(null)}
          />
        ) : (
          <div className="min-h-screen w-full flex flex-column">
            <h1>Agendamentos</h1>
            <div className="flex gap-6 margin-top-15 search-filter">
              <div className="form-group search-group">
                <div className="input-group">
                  <span className="input-group-addon">
                    <span
                      className="rioglyph rioglyph-search"
                      aria-hidden="true"
                    />
                  </span>
                  <ClearableInput
                    id="5"
                    placeholder="Busque por chassi"
                    onChange={(value: string) => setSearchTerm(value)}
                  />
                </div>
              </div>

              <div className="form-group search-group relative">
                <DatePicker
                  key={datePickerKey}
                  id="date-picker"
                  inputProps={{ placeholder: "Escolha a data" }}
                  dateFormat="DD-MM-YYYY"
                  timeFormat={false}
                  locale="pt-br"
                  onChange={handleDateChange}
                  value={selectedDate || undefined}
                />

                {selectedDate && (
                  <span
                    className="rioglyph rioglyph-remove-sign absolute right-10 top-10 cursor-pointer text-color-gray"
                    onClick={clearDateFilter}
                  />
                )}
              </div>

              <div className="form-group select-group">
                <Select
                  id="sort-type"
                  options={[
                    {
                      id: "desc",
                      label: <span>Decrescente</span>,
                      selected: true,
                    },
                    {
                      id: "asc",
                      label: <span>Crescente</span>,
                      selected: false,
                    },
                  ]}
                  onChange={(selected) => setSortType(selected?.id)}
                />
              </div>
            </div>

            {filteredAppointmens.length === 0 && (
              <div className="flex justify-center padding-top-10">
                <span className="text-color-gray">
                  Não existem agendamentos realizados.
                </span>
              </div>
            )}

            {filteredAppointmens
              .sort((a, b) =>
                sortType === "asc"
                  ? a.scheduleTime.getTime() - b.scheduleTime.getTime()
                  : b.scheduleTime.getTime() - a.scheduleTime.getTime()
              )
              .map((schedule) => (
                <div
                  key={schedule?.id}
                  className="bg-white p-6 w-full margin-top-25 full-schedule"
                >
                  <div className="flex padding-top-10 justify-between items-center mb-4">
                    <div className="flex items-center space-x-2 w-9/12">
                      <span className="text-size-18 font-semibold text-color-map-marker-asset flex items-center w-3/12 truncate">
                        <span className="rioglyph rioglyph-calendar text-color-map-marker-asset"></span>
                        {schedule?.scheduleTime.toLocaleDateString("pt-BR", {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        })}
                      </span>
                      <div className="flex gap-6 schedule-tags w-9/12 media_leftRow">
                        <div className="flex items-center justify-center gap-5 schedule-tags__card">
                          <span className="rioglyph rioglyph-time-alt"></span>
                          <span>
                            {schedule?.scheduleTime.toLocaleTimeString(
                              "pt-BR",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                          </span>
                        </div>

                        <div className="flex items-center justify-center gap-5 schedule-tags__card truncate">
                          <span className="rioglyph rioglyph-workshop"></span>
                          <span>{schedule?.dealership?.name}</span>
                        </div>

                        <div className="flex items-center justify-center gap-5 schedule-tags__card truncate">
                          <span className="rioglyph rioglyph-pin"></span>
                          <span>{schedule?.dealership?.address}</span>
                        </div>
                      </div>
                    </div>
                    <div className="w-3/12 flex items-center gap-6 justify-end">
                      <span className="flex items-center justify-center rounded-full payment-info text-center">
                        Pagamento na concessionária
                      </span>
                      {placements.map((placement) => (
                        <OverlayTrigger
                          trigger="click"
                          key={placement}
                          placement={placement}
                          overlay={
                            <Popover id={`popover-positioned-${placement}`}>
                              <div
                                className="popover-item flex items-center cursor-pointer"
                                onClick={() => handleReschedule(schedule?.id)}
                              >
                                <span className="text-color-darkest rioglyph rioglyph-calendar"></span>
                                <span className="text-color-darkest text-size-14 font-semibold">
                                  Reagendar
                                </span>
                              </div>

                              <div
                                className="popover-item flex items-center cursor-pointer"
                                onClick={() => handleViewDetails(schedule)}
                              >
                                <span className="text-color-darkest text-size-h4 rioglyph rioglyph-eye-option"></span>
                                <span className="text-color-darkest text-size-14 font-semibold">
                                  Ver detalhes
                                </span>
                              </div>

                              <div
                                className="popover-item flex items-center cursor-pointer"
                                onClick={() =>
                                  handleOpenCancelDialog(schedule.id)
                                }
                              >
                                <span className="text-color-darkest text-size-h4 rioglyph rioglyph-remove-circle"></span>
                                <span className="text-color-darkest text-size-14 font-semibold">
                                  Cancelar
                                </span>
                              </div>
                            </Popover>
                          }
                        >
                          {/* Surrounding div is required for proper positioning of the Popover as the button uses a margin */}
                          <div>
                            <span className="rioglyph rioglyph-option-vertical cursor-pointer"></span>
                          </div>
                        </OverlayTrigger>
                      ))}{" "}
                    </div>
                  </div>

                  <hr className="border-gray-200 my-2" />

                  <div className="flex items-start justify-between mb-4 padding-top-25">
                    <div className="flex space-x-2 w-9/12">
                      <div className="flex flex-col w-3/12">
                        <div className="flex items-center justify-center gap-6 payment-method">
                          <span className="text-size-16 font-bold text-color-map-marker-asset">
                            Valor a ser pago
                          </span>
                          <span className="text-size-14 font-bold flex items-center justify-around price-value">
                            {schedule?.totalPrice}
                          </span>
                        </div>

                        <div className="flex flex-col padding-top-25">
                          {schedule?.comments ? (
                            <>
                              <span className="text-size-14 font-semibold text-color-highlight-darkest">
                                Importante!
                              </span>
                              <span className="text-size-14 font-normal text-color-highlight-darkest">
                                {schedule?.comments}
                              </span>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="flex flex-col padding-right-10 media_leftRow">
                        <span className="text-size-14 font-semibold text-color-highlight-darkest">
                          Solicitante
                        </span>
                        <span className="text-size-14 font-normal text-color-highlight-darkest">
                          {schedule?.scheduledBy || schedule?.consultant}
                        </span>
                      </div>
                    </div>
                    <div className="w-3/12 flex items-center justify-end">
                      <div className="flex gap-6 items-center">
                        <img
                          src="/assets/images/vehicle-thumb.png"
                          alt="Veículo"
                          className="w-16 h-16 rounded-md"
                        />
                        <div className="flex flex-col">
                          <span className="text-size-14 font-semibold text-color-highlight-darkest">
                            {schedule.asset.name}
                          </span>
                          <span className="text-size-14 font-normal text-text-color-darker">
                            Chassi - {schedule.asset.chassis}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="display-flex gap-20 margin-top-25 margin-bottom-15">
                    <button
                      type="button"
                      className="btn btn-secondary width-250"
                      onClick={() =>
                        navigate(
                          `/fleet-manager/maintenance-monitoring/monitoring?scheduling-id=${schedule?.id}`
                        )
                      }
                    >
                      Acompanhar manutenção
                    </button>

                    {/* <button
                      type="button"
                      className="btn btn-primary width-250"
                      disabled={
                        allTickets.find(
                          (ticket) =>
                            ticket.maintenanceScheduleId === schedule?.id
                        )?.status !== "FINISHED"
                      }
                      onClick={() =>
                        navigate(
                          `/fleet-manager/maintenance-monitoring/assessment?scheduling-id=${schedule?.id}`
                        )
                      }
                    >
                      {allTickets.find(
                        (ticket) =>
                          ticket.maintenanceScheduleId === schedule?.id
                      )?.hasReview
                        ? "Avaliação Concluída"
                        : "Avaliar Manutenção"}
                    </button> */}
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>

      <Dialog
        show={showDialog}
        onClose={handleClose}
        showCloseButton
        bsSize={Dialog.SIZE_SM}
      >
        {dialogContent === "confirmCancel" && (
          <>
            <Dialog.Title title={"Cancelar agendamento"} />
            <Dialog.Body>
              <div className="cancel-content flex flex-col">
                <span className="text-color-darker text-size-16 text-normal">
                  Você realmente gostaria de cancelar ou reagendar?
                </span>
                <span className="text-color-darker text-bold text-size-18 padding-top-10">
                  Atenção!
                </span>
                <span
                  style={{ color: "#E36E19" }}
                  className="text-size-16 padding-top-5"
                >
                  Após cancelar, essa ação não poderá ser desfeita.
                </span>
              </div>
            </Dialog.Body>
            <Dialog.Footer>
              <div className="flex items-center justify-between">
                <p
                  className="text-bold text-size-16 cursor-pointer text-color-primary"
                  onClick={handleClose}
                >
                  Voltar
                </p>
                <div className="flex gap-6">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={handleConfirmCancel}
                  >
                    Cancelar agendamento
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      handleReschedule;
                    }}
                  >
                    Reagendar
                  </button>
                </div>
              </div>
            </Dialog.Footer>
          </>
        )}

        {dialogContent === "success" && (
          <>
            <Dialog.Title title={"Agendamento Cancelado"} />
            <Dialog.Body>
              <div className="cancel-content flex flex-col">
                <span className="text-size-14 text-normal info-cancel">
                  Cancelamento realizado com sucesso
                </span>
                <span className="text-color-darker text-bold text-size-18 padding-top-10">
                  Atenção!
                </span>
                <span
                  style={{ color: "#B42318" }}
                  className="text-size-16 padding-top-5"
                >
                  Essa ação não pode ser desfeita.
                </span>
              </div>
            </Dialog.Body>
            <Dialog.Footer>
              <p
                onClick={handleClose}
                className="text-bold text-size-16 cursor-pointer text-color-primary"
              >
                Fechar
              </p>
            </Dialog.Footer>
          </>
        )}
      </Dialog>
    </div>
  );
};

const MyAppointments = () => {
  return (
    <AppLayout>
      <MyAppointmentsContent />
    </AppLayout>
  );
};

export default MyAppointments;
