import { ticketDto } from "../dtos/ticketDto";
import { Ticket } from "../models/ticket";
import ApiService from "../services/api";

export const fetchTickets = async ({
  consultantUser,
}: {
  consultantUser: boolean;
}): Promise<Ticket[]> => {
  const apiService = new ApiService();
  try {
    const route = consultantUser ? "consultant-user" : "manager-account";
    const response = await apiService.get<Ticket[]>(
      `/maintenance/ticket/${route}`
    );
    if (response.status < 400) {
      return response.data.map(ticketDto);
    } else {
      console.warn("Unexpected status code:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching tickets:", error);
    return [];
  }
};
