import { AppointmentCreatedByEnum } from "../enums/appointmentCreatedBy";
import { IAppointmentForm } from "../pages/fleetManager/newAppointment";
import ApiService from "../services/api";
import { fetchUser } from "./fetchUser";

export const makeAnAppointment = async (appointmentData: IAppointmentForm, userProfile: any, createdBy: AppointmentCreatedByEnum): Promise<void> => {
    const apiService = new ApiService();

    const scheduleDate = new Date(appointmentData.dateAndConsultant?.scheduleDate?.getTime() ?? 0);
    scheduleDate?.setHours(scheduleDate.getHours() - 3);

    let user;
    const userId = userProfile?.sub?.split(":")[1];
    if (userId) user = await fetchUser(userId);

    try {
        await apiService.post<any>(
            `/schedules/checkups`,
            {
                "consultantId": appointmentData.dateAndConsultant?.consultant?.id ?? 'ec6e5df5-b523-4428-bacd-48d04b90a234',
                "checkupId": appointmentData.assetsAndDrivers?.checkup?.id,
                "checkup": {
                    "value": appointmentData.assetsAndDrivers?.checkup?.odometer,
                    "maintenanceGroupId": appointmentData.assetsAndDrivers?.asset?.group,
                },
                "createdBy": createdBy,
                "scheduledBy": user?.name,
                "schedule": {
                    "booking": "string",
                    "scheduledDate": scheduleDate,
                    "sourceAccountId": userProfile?.account,
                    "sourceUserId": userProfile?.sub?.split(':')[1],
                    // TODO: Change this to the actual destination account
                    "destinationAccountId": "33", 
                    "destinationUserId": "44"
                },
                "fieldCampaignNumbers": (appointmentData.assetsAndDrivers?.schedulingTypes ?? []).includes('workshopActions') ?
                    (appointmentData.assetsAndDrivers?.asset?.campaigns?.list ?? []).map(campaign => campaign.number) : [],
                "vehicleSchedule": {
                    "vehicle": {
                        "assetId": appointmentData.assetsAndDrivers?.asset?.id,
                        "chassis": appointmentData.assetsAndDrivers?.asset?.chassis,
                        "plate": appointmentData.assetsAndDrivers?.asset?.licensePlate ?? '-',
                        "name": appointmentData.assetsAndDrivers?.asset?.name,
                        "vehicleModel": appointmentData.assetsAndDrivers?.asset?.model ?? '-',
                        "vehicleYear": appointmentData.assetsAndDrivers?.asset?.year,
                        "hourMeter": appointmentData.assetsAndDrivers?.asset?.hourmeter,
                        "odometer": appointmentData.assetsAndDrivers?.asset?.odometer,
                        "vehicleGroup": appointmentData.assetsAndDrivers?.asset?.groupName,
                    },
                    "driver": {
                        "name": appointmentData.assetsAndDrivers?.driver?.name || '-',
                        "cnh": appointmentData.assetsAndDrivers?.driver?.license || '-',
                        "email": appointmentData.assetsAndDrivers?.driver?.email || '-',
                        "phone": appointmentData.assetsAndDrivers?.driver?.phone || '-',
                    },
                    "dealerShip": {
                        "id": appointmentData.dealership?.id,
                        "name": appointmentData.dealership?.name,
                    },
                    "dealershipId": appointmentData.dealership?.id,
                    "autoWorkshopActivities": {
                        "id": "103",
                        "name": "Reparo dos cintos"
                    },
                    "otherServices": (appointmentData.assetsAndDrivers?.aditionalServices ?? [])
                        .map(service => ({
                            id: service.id,
                            name: service.name,
                            price: service.price
                        })),
                    "comments": appointmentData.assetsAndDrivers?.comments ?? '',
                    "generalObservations": appointmentData.assetsAndDrivers?.obs ?? '',
                }
            }
        );
    } catch (error: any) {
        throw new Error(error.response?.data?.message ?? 'Verifique os dados e tente novamente');
    }
}

export const rescheduleAnAppointment = async (appointmentData: IAppointmentForm, schedulingId: string, updatedBy: AppointmentCreatedByEnum): Promise<void> => {
    const apiService = new ApiService();

    const scheduleDate = new Date(appointmentData.dateAndConsultant?.scheduleDate?.getTime() ?? 0);
    scheduleDate?.setHours(scheduleDate.getHours() - 3);

    try {
        await apiService.post<any>(
            `/schedules/checkups/reschedule`,
            {
                "id": schedulingId,
                "rescheduleDate": scheduleDate,
                "rescheduledBy": updatedBy,
                "consultantId": appointmentData.dateAndConsultant?.consultant?.id,
                "dealershipId": appointmentData.dealership?.id,
            }
        );
    } catch (error: any) {
        throw new Error(error.response?.data?.message ?? 'Verifique os dados e tente novamente');
    }
}