import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { accessToken } from "../configuration/tokenHandling/accessToken";
import { AccessToken } from "../configuration/tokenHandling/tokenSlice";

class ApiV2Service {
  private axiosInstance: AxiosInstance;

  constructor() {
    const baseURL =
      import.meta.env.VITE_API_URL ||
      "https://api.latam-uptime-gateway.rio.cloud";
    const token = accessToken.getAccessToken() as AccessToken;

    this.axiosInstance = axios.create({
      baseURL: `${baseURL}/v2`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });

    this.axiosInstance.interceptors.response.use(
      this.handleResponse,
      this.handleError
    );
  }

  private handleResponse(response: AxiosResponse) {
    return response;
  }

  private handleError(error: any) {
    return Promise.reject(error);
  }

  public get<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.axiosInstance.get<T>(url, config);
  }

  public post<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.axiosInstance.post<T>(url, data, config);
  }

  public patch<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.axiosInstance.patch<T>(url, data, config);
  }

  public put<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.axiosInstance.put<T>(url, data, config);
  }

  public delete<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.axiosInstance.delete<T>(url, config);
  }

  public getAndDownloadFile(url: string): Promise<any> {
    return this.axiosInstance.get(url, { responseType: "blob" });
  }
}

export default ApiV2Service;
