import { useEffect, useState } from "react";
import Page from "../Page";
import MonthCalendar from "./components/monthCalendar";
import NextSchedulings from "./components/nextSchedulings";
import Schedule from "./components/schedule";
import { getFirstDayOfWeek, getLastDayOfWeek } from "../../../utils/dateUtils";
import { Scheduling } from "../../../models/schedule";
import {
  fetchConsultantSchedule,
  fetchNewConsultantSchedule,
} from "../../../api/fetchConsultantSchedule";
import BigNumbers from "./components/bigNumbers";
import NewSchedulingDetailDialog from "./components/schedule/dialogs/NewSchedulingDetailDialog";
import { Loading } from "../../../utils/loading";

const MaintenanceControl = () => {
  const [loading, setLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [scheduleSidebar, setScheduleSidebar] = useState(true);
  const [consultantMonthSchedule, setConsultantMonthSchedule] = useState<
    Scheduling[]
  >([]);
  const [scheduleViewType, setScheduleViewType] = useState("week");
  const [showNewConsultantSchedule, setShowNewConsultantSchedule] =
    useState(false);
  const [newConsultantScheduleData, setNewConsultantScheduleData] = useState<
    Scheduling[]
  >([]);

  useEffect(() => {
    const fetchNewsSchedules = async () => {
      try {
        const newConsultantSchedule = await fetchNewConsultantSchedule(
          currentDate.getDate(),
          currentDate.getMonth() + 1,
          currentDate.getFullYear()
        );
        const viewedIds = JSON.parse(
          localStorage.getItem("viewedScheduleIds") || "[]"
        );
        const unviewedSchedules = newConsultantSchedule.filter(
          (schedule) => !viewedIds.includes(schedule.id)
        );

        if (unviewedSchedules.length > 0) {
          setShowNewConsultantSchedule(true);
          setNewConsultantScheduleData(unviewedSchedules);
        }
      } catch (error) {
        console.error("Erro ao buscar novos agendamentos:", error);
      }
    };

    const fetchSchedule = async () => {
      try {
        const consultantMonthSchedule = await fetchConsultantSchedule(
          currentDate.getMonth() + 1,
          currentDate.getFullYear()
        );
        setConsultantMonthSchedule(consultantMonthSchedule);
      } catch (error) {
        console.error("Erro ao buscar agendamentos:", error);
      }

      setLoading(false);
    };

    fetchSchedule();
    fetchNewsSchedules();
  }, [currentDate]);

  const handleDialogClose = () => {
    // Atualiza o localStorage com os IDs visualizados
    const viewedIds = JSON.parse(
      localStorage.getItem("viewedScheduleIds") || "[]"
    );
    const newIds = newConsultantScheduleData.map((schedule) => schedule.id);
    localStorage.setItem(
      "viewedScheduleIds",
      JSON.stringify([...viewedIds, ...newIds])
    );

    setShowNewConsultantSchedule(false);
  };

  return (
    <Page>
      {loading ? (
        <Loading label="Carregando agenda..." />
      ) : (
        <>
          <BigNumbers
            consultantMonthSchedule={consultantMonthSchedule}
            firstWeekDay={getFirstDayOfWeek(currentDate)}
            currentDate={currentDate}
            scheduleViewType={scheduleViewType}
          />

          <div className="text-size-18 text-bold padding-y-10">
            Agenda de controle das manutenções
          </div>
          <div className="panel panel-default panel-body shadow-default padding-0">
            <div className="display-flex gap-10">
              {scheduleSidebar && (
                <div className="flex-basis-250">
                  <MonthCalendar
                    dayChoosed={currentDate}
                    chooseDay={(date) => setCurrentDate(date)}
                  />
                  <NextSchedulings
                    now={currentDate}
                    consultantMonthSchedule={consultantMonthSchedule}
                  />
                </div>
              )}

              <div style={{ borderLeftWidth: 1 }} className="width-100pct">
                <Schedule
                  firstWeekDay={getFirstDayOfWeek(currentDate)}
                  lastWeekDay={getLastDayOfWeek(currentDate)}
                  consultantMonthSchedule={consultantMonthSchedule}
                  currentDate={currentDate}
                  setCurrentDate={setCurrentDate}
                  handleScheduleSidebar={() =>
                    setScheduleSidebar(!scheduleSidebar)
                  }
                  scheduleViewType={scheduleViewType}
                  setScheduleViewType={setScheduleViewType}
                />
              </div>
            </div>
          </div>

          {showNewConsultantSchedule && (
            <NewSchedulingDetailDialog
              show={!!showNewConsultantSchedule}
              onClose={handleDialogClose}
              newConsultantSchedule={newConsultantScheduleData}
            />
          )}
        </>
      )}
    </Page>
  );
};

export default MaintenanceControl;
