import { Button, Dialog } from "@rio-cloud/rio-uikit";

interface IErrorDialogProps {
    show: boolean;
    title: string;
    body: string;
    onClose: () => void;
}

const ErrorDialog = ({ show, title, body, onClose }: IErrorDialogProps) => {
    return (
        <Dialog
            show={show}
            title={(
                <>
                    <span className="rioglyph rioglyph-remove-circle margin-right-10 text-color-danger" />
                    <span>{title}</span>
                </>
            )}
            footer={
                <div className='display-flex justify-content-end'>
                    <Button bsStyle='primary' onClick={onClose}>
                        Fechar
                    </Button>
                </div>
            }
            body={body}
            bsSize={Dialog.SIZE_XS}
            onClose={onClose}
        />
    );
}

export default ErrorDialog;