import FilePicker from "@rio-cloud/rio-uikit/FilePicker";
import { useEffect, useState } from "react";
import { TicketScreening } from "../../../../../models/ticket";
import {
  downloadTicketImage,
  fetchTicketImages,
  ITicketFile,
  removeTicketImage,
  uploadTicketImage,
} from "../../../../../api/ticket";
import { Scheduling } from "../../../../../models/schedule";
import { DatePicker, Select } from "@rio-cloud/rio-uikit";
import type { Moment } from "moment";
import ErrorDialog from "../../../../../components/ErrorDialog";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import Tooltip from "@rio-cloud/rio-uikit/Tooltip";
import OverlayTrigger from "@rio-cloud/rio-uikit/OverlayTrigger";
import { ScreeningChecklistDialog } from "../dialogs/screeningChecklistDialog";
import { Asset } from "../../../../../models/asset";
import moment from "moment";
import { formatFileSize } from "../../../../../utils/imageSizeUtils";

const accept = {
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
  "image/svg": [],
};

interface IScreeningDiagnosisProps {
  ticketId: string | undefined;
  screening: TicketScreening | undefined;
  setScreening: (screening: TicketScreening) => void;
  setCheckOut: () => void;
  scheduling: Scheduling | undefined;
}

const ScreeningDiagnosis = ({
  ticketId,
  screening,
  setScreening,
  setCheckOut,
  scheduling,
}: IScreeningDiagnosisProps) => {
  const [files, setFiles] = useState<ITicketFile[]>([]);
  const [uploadErrorMessage, setUploadErrorMessage] = useState<
    string | undefined
  >();

  const [checklistDialog, setChecklistDialog] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      if (screening && ticketId) {
        const images = await fetchTicketImages({
          ticketId: ticketId,
          step: "SCREENING",
        });
        setFiles(images ?? []);
      }
    };
    fetchData();
  }, [ticketId]);

  return (
    <div>
      <div className="resume-section">
        <div className="resume-container padding-0">
          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="Criticality">Criticidade de atendimento</label>
              <Select
                id="Criticality"
                onChange={(value) => {
                  if (value)
                    setScreening({
                      ...screening,
                      criticality: parseInt(value?.id),
                    });
                }}
                disabled={!screening?.checkIn || !!screening?.checkOut}
                value={
                  screening?.criticality
                    ? [screening.criticality.toString()]
                    : undefined
                }
                options={[
                  {
                    id: "5",
                    label: "Emergência",
                    icon: (
                      <span className="rioglyph rioglyph-sphere text-color-danger" />
                    ),
                  },
                  {
                    id: "4",
                    label: "Muito urgente",
                    icon: (
                      <span className="rioglyph rioglyph-sphere text-color-warning" />
                    ),
                  },
                  {
                    id: "3",
                    label: "Urgente",
                    icon: (
                      <span className="rioglyph rioglyph-sphere text-color-rating-3" />
                    ),
                  },
                  {
                    id: "2",
                    label: "Pouco urgente",
                    icon: (
                      <span className="rioglyph rioglyph-sphere text-color-rating-4" />
                    ),
                  },
                  {
                    id: "1",
                    label: "Não urgente",
                    icon: (
                      <span className="rioglyph rioglyph-sphere text-color-map-marker-route" />
                    ),
                  },
                ]}
              />
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="concessionaria">Nome da Concessionária</label>
              <input
                id="concessionaria"
                className="form-control"
                type="text"
                onChange={(e) =>
                  setScreening({ ...screening, dealership: e.target.value })
                }
                value={scheduling?.dealership.name}
                disabled
                // value={screening?.dealership}
                // disabled={!screening?.checkIn || !!screening?.checkOut}
              />
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="cnpj">CNPJ</label>
              <div className="input-group">
                <input
                  id="cnpj"
                  className="form-control"
                  type="text"
                  placeholder="CNPJ"
                  onChange={(e) =>
                    setScreening({
                      ...screening,
                      corporateTaxId: e.target.value,
                    })
                  }
                  value={screening?.corporateTaxId}
                  disabled={!screening?.checkIn || !!screening?.checkOut}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="local">Local</label>
              <input
                id="local"
                className="form-control"
                type="text"
                onChange={(e) =>
                  setScreening({ ...screening, location: e.target.value })
                }
                value={`${scheduling?.dealership.address} ${scheduling?.dealership.city} - ${scheduling?.dealership.state}`}
                disabled
                // value={screening?.location}
                // disabled={!screening?.checkIn || !!screening?.checkOut}
              />
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="representante">Representante</label>
              <div className="input-group">
                <input
                  id="representante"
                  className="form-control"
                  type="text"
                  onChange={(e) =>
                    setScreening({ ...screening, agentName: e.target.value })
                  }
                  value={scheduling?.consultant}
                  disabled
                  // value={screening?.agentName}
                  // disabled={!screening?.checkIn || !!screening?.checkOut}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="checkin">Check-in</label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-calendar"></span>
                </span>
                <input
                  id="checkin"
                  className="form-control"
                  type="text"
                  placeholder="--/--/----"
                  disabled
                  value={screening?.checkIn?.toLocaleDateString("pt-BR")}
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="hora-checkin" className="opacity-0">
                Hora
              </label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="hora-checkin"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  disabled
                  value={screening?.checkIn?.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="checkout">Check-out</label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-calendar"></span>
                </span>
                <input
                  id="checkout"
                  className="form-control"
                  type="text"
                  placeholder="--/--/----"
                  disabled
                  value={screening?.checkOut?.toLocaleDateString("pt-BR")}
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="hora-checkout" className="opacity-0">
                Hora
              </label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="hora-checkout"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  disabled
                  value={screening?.checkOut?.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/3">
              <label htmlFor="checkinMotorista">Check-in do Motorista</label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-pin"></span>
                </span>
                <input
                  id="hora-checkout"
                  className="form-control"
                  type="text"
                  placeholder="Localização"
                  value={screening?.checkInDriverLocation}
                  onChange={(e) =>
                    setScreening({
                      ...screening,
                      checkInDriverLocation: e.target.value,
                    })
                  }
                  disabled={!screening?.checkIn || !!screening?.checkOut}
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/3">
              <label htmlFor="aberturaOs">Abertura da OS</label>
              <input
                id="aberturaOs"
                className="form-control"
                type="text"
                placeholder="Número"
                value={screening?.serviceOrder}
                onChange={(e) =>
                  setScreening({ ...screening, serviceOrder: e.target.value })
                }
                disabled={!screening?.checkIn || !!screening?.checkOut}
              />
            </div>

            <div className="form-group form-group-lg w-1/3">
              <label htmlFor="dataHoraOs">Data e Hora de abertura da OS</label>
              <DatePicker
                id="dataHoraOs"
                dateFormat="DD-MM-YYYY"
                timeFormat="HH:mm"
                // locale="pt-BR"
                value={screening?.serviceOrderDate}
                onChange={(result: Moment | string) =>
                  setScreening({
                    ...screening,
                    serviceOrderDate: new Date(result.toString()),
                  })
                }
              />
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="indicaFalha">Indicação de Falha</label>
              <input
                id="indicaFalha"
                className="form-control"
                type="text"
                value={screening?.failIndication}
                onChange={(e) =>
                  setScreening({ ...screening, failIndication: e.target.value })
                }
                disabled={!screening?.checkIn || !!screening?.checkOut}
              />
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="codeFalha">Código de Falha</label>
              <div className="input-group">
                <input
                  id="codeFalha"
                  className="form-control"
                  type="text"
                  value={screening?.failCode}
                  onChange={(e) =>
                    setScreening({ ...screening, failCode: e.target.value })
                  }
                  disabled={!screening?.checkIn || !!screening?.checkOut}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="ferramentaDiagnose">Ferramenta de Diagnose</label>
              <input
                id="ferramentaDiagnose"
                className="form-control"
                type="text"
                value={screening?.diagnosisTool}
                onChange={(e) =>
                  setScreening({ ...screening, diagnosisTool: e.target.value })
                }
                disabled={!screening?.checkIn || !!screening?.checkOut}
              />
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="lerDiagnose">Leitura de Diagnose</label>
              <div className="input-group">
                <input
                  id="lerDiagnose"
                  className="form-control"
                  type="text"
                  value={screening?.diagnosisReport}
                  onChange={(e) =>
                    setScreening({
                      ...screening,
                      diagnosisReport: e.target.value,
                    })
                  }
                  disabled={!screening?.checkIn || !!screening?.checkOut}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="troubleshooting">
                Literatura para Troubleshooting
              </label>
              <input
                id="troubleshooting"
                className="form-control"
                type="text"
                value={screening?.troubleshootingDoc}
                onChange={(e) =>
                  setScreening({
                    ...screening,
                    troubleshootingDoc: e.target.value,
                  })
                }
                disabled={!screening?.checkIn || !!screening?.checkOut}
              />
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="tempoEstimado">Tempo Estimado</label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="tempoEstimado"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  value={screening?.estimatedTimeTroubleshooting}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "").slice(0, 4);
                    const formattedValue = value.replace(
                      /(\d{2})(\d{2})/,
                      "$1:$2"
                    );
                    setScreening({
                      ...screening,
                      estimatedTimeTroubleshooting: formattedValue,
                    });
                  }}
                  disabled={!screening?.checkIn || !!screening?.checkOut}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="tempoEstimadoBox">
                Tempo Estimado para Entrada no Box
              </label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="tempoEstimadoBox"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  value={screening?.estimatedTimeBoxEntry}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "").slice(0, 4);
                    const formattedValue = value.replace(
                      /(\d{2})(\d{2})/,
                      "$1:$2"
                    );
                    setScreening({
                      ...screening,
                      estimatedTimeBoxEntry: formattedValue,
                    });
                  }}
                  disabled={!screening?.checkIn || !!screening?.checkOut}
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="tempoEstimadoReparo">
                Tempo Estimado de Reparo
              </label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="tempoEstimadoReparo"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  value={screening?.estimatedTimeRepair}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "").slice(0, 4);
                    const formattedValue = value.replace(
                      /(\d{2})(\d{2})/,
                      "$1:$2"
                    );
                    setScreening({
                      ...screening,
                      estimatedTimeRepair: formattedValue,
                    });
                  }}
                  disabled={!screening?.checkIn || !!screening?.checkOut}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-center w-full">
            <button
              type="button"
              className="btn btn-primary btn-lg padding-left-25 padding-right-25 margin-top-10"
              onClick={() => setChecklistDialog(true)}
            >
              <span
                className="rioglyph rioglyph-ok-circle"
                aria-hidden="true"
              ></span>
              <span className="text-capitalize">Checklist</span>
            </button>
          </div>

          <div className="flex justify-between w-full gap-6 margin-top-25">
            <div className="form-group form-group-lg w-full">
              <label htmlFor="relato">Relato</label>
              <textarea
                className="form-control height-100"
                id="relato"
                placeholder="Descritivo"
                value={screening?.report}
                onChange={(e) =>
                  setScreening({ ...screening, report: e.target.value })
                }
                disabled={!screening?.checkIn || !!screening?.checkOut}
              ></textarea>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-full">
              <div className="infoFiles">
                <label htmlFor="relato">Inspeção visual</label>
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id="tooltip" allowOnTouch>
                      <div className="flex flex-col padding-10">
                        <p>
                          Arquivos permitidos: .jpg, .jpeg e .png
                          <br />
                          Tamanho máximo permitido: 2MB
                        </p>
                      </div>
                    </Tooltip>
                  }
                >
                  <span className="rioglyph rioglyph-question-sign margin-left-10 cursor-pointer text-color-gray" />
                </OverlayTrigger>
              </div>
              <div>
                {!!!screening?.checkOut &&
                  (files?.length === 0 || !files) &&
                  !(!screening?.checkIn || !!screening?.checkOut) && (
                    <FilePicker
                      displayMode="dropzone"
                      className="margin-bottom-20"
                      onPick={async (acceptedFiles: FileList | null) => {
                        const maxSize = 5 * 1024 * 1024;
                        const filePicked = acceptedFiles?.[0];

                        if (!filePicked) return;

                        if (filePicked.size > maxSize) {
                          return setUploadErrorMessage(
                            "O arquivo selecionado excede o limite de 5MB. Escolha um arquivo menor."
                          );
                        }

                        if (acceptedFiles?.[0] && ticketId) {
                          const uploaded = await uploadTicketImage({
                            ticketId: ticketId,
                            imageFile: Array.from(acceptedFiles),
                            step: "SCREENING",
                          });
                          if (!uploaded)
                            return setUploadErrorMessage(
                              "Arquivo invalido. Erro ao realizar o upload, verifique o arquivo e tente novamente."
                            );
                          setFiles(
                            filePicked
                              ? [
                                  {
                                    name: filePicked.name,
                                    updatedAt: new Date(
                                      filePicked.lastModified
                                    ).toLocaleDateString("pt-BR"),
                                    contentType: filePicked.type,
                                    contentSize: filePicked.size,
                                    link: URL.createObjectURL(filePicked),
                                  },
                                ]
                              : []
                          );
                        }
                      }}
                      multiple={true}
                      accept={accept}
                    >
                      {dropArea}
                    </FilePicker>
                  )}
                <div className="display-grid grid-cols-1 grid-cols-3-sm gap-25">
                  {(files ?? []).map((file) => (
                    <FilePreview
                      key={file.name}
                      file={file}
                      ticketId={ticketId}
                      removeFile={() =>
                        setFiles((prevFiles) =>
                          (prevFiles ?? []).filter((f) => f.name !== file.name)
                        )
                      }
                      disabled={!screening?.checkIn || !!screening?.checkOut}
                    />
                  ))}
                </div>
              </div>

              <ErrorDialog
                show={!!uploadErrorMessage}
                title={"Erro ao fazer upload"}
                body={uploadErrorMessage ?? ""}
                onClose={() => setUploadErrorMessage(undefined)}
              />
            </div>
          </div>

          <div className="flex justify-end w-full padding-bottom-25">
            <button
              disabled={!screening?.checkIn || !!screening?.checkOut}
              type="button"
              className="btn btn-primary btn-lg padding-left-25 padding-right-25 margin-top-10"
              onClick={setCheckOut}
            >
              <span className="text-capitalize">Concluir Triagem</span>
            </button>
          </div>
        </div>
      </div>

      <ScreeningChecklistDialog
        showDialog={checklistDialog}
        onClose={() => setChecklistDialog(false)}
        checkupParts={scheduling?.parts}
        asset={scheduling?.asset}
        partsChecked={[]}
      />
    </div>
  );
};

const dropArea = ({ isDragActive }: { isDragActive: boolean }) => (
  <div
    className={`
          rounded border border-style-dashed border-color-gray bg-lightest text-size-14
          text-center text-color-darker opacity-50 padding-25 cursor-pointer
      `}
  >
    <span className="rioglyph rioglyph-files text-size-200pct opacity-50 margin-top-20" />
    <div className="margin-top-10">
      {isDragActive
        ? "Jogue os arquivos aqui ..."
        : "Arraste arquivos de imagem para cá ou clique para selecionar no computador"}
    </div>
  </div>
);

const FilePreview = ({
  file,
  ticketId,
  removeFile,
  disabled,
}: {
  file: ITicketFile | undefined;
  ticketId: string | undefined;
  removeFile: () => void;
  disabled: boolean;
}) => {
  const [deleteFileDialog, setDeleteFileDialog] = useState<boolean>(false);

  return (
    <>
      <div
        className="panel panel-default margin-top-20 cursor-pointer"
        onClick={async () => {
          if (ticketId && file) {
            const response = await downloadTicketImage({
              ticketId: ticketId,
              step: "SCREENING",
              filename: file.name,
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file.name);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
          }
        }}
      >
        <div className="panel-body">
          {file && (
            <div className="flex">
              {file.link && (
                <div className="thumbnail margin-right-10">
                  <img
                    src={file.link}
                    alt={file.name}
                    style={{
                      width: "200px",
                      height: "120px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              )}
              <div className="flex justify-between w-full">
                <div>
                  <div>
                    <b>{`${file?.name}`}</b>
                  </div>
                  <div>tipo: {`${file?.contentType}`}</div>
                  <div>tamanho: {formatFileSize(file?.contentSize)}</div>
                  <div>
                    data da última modificação:{" "}
                    {file?.updatedAt
                      ? moment(file.updatedAt).format("DD/MM/YYYY")
                      : "Data não disponível"}
                  </div>{" "}
                  <div>caminho: {`${file?.name}`}</div>
                </div>
                {!disabled && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteFileDialog(true);
                    }}
                  >
                    <span className="rioglyph rioglyph-remove" />
                  </div>
                )}
              </div>
            </div>
          )}
          {!file && (
            <div className="text-color-dark text-italic">Nenhum arquivo</div>
          )}
        </div>
      </div>
      <ConfirmDialog
        show={deleteFileDialog}
        title="Remover arquivo"
        body="Deseja realmente remover o arquivo?"
        onClose={() => setDeleteFileDialog(false)}
        onConfirm={async () => {
          if (ticketId && file) {
            const response = await removeTicketImage({
              ticketId: ticketId,
              step: "SCREENING",
              filename: file.name,
            });
            if (response) {
              removeFile();
              setDeleteFileDialog(false);
            }
          }
        }}
      />
    </>
  );
};

export default ScreeningDiagnosis;
