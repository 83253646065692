import { Checkup } from "../models/checkup";

export const CheckupDto = (data: any): Checkup => {
  if (!data) {
    console.warn("CheckupDto recebeu dados inválidos:", data);
    return {
      id: "",
      name: "",
      description: "",
      odometer: 0,
      hourmeter: null,
      services: []
    };
  }

  const formattedStart = data?.range?.start.toLocaleString("pt-BR");

  return {
    id: data.id ?? '',
    name: data?.range ? `Revisão de ${formattedStart} km` : `Revisão realizada com ${data.odometer.toLocaleString("pt-BR")} km`,
    description: data?.range ? `Revisão de ${formattedStart} km` : `Revisão realizada com ${data.odometer.toLocaleString("pt-BR")} km`,
    odometer: data.range?.start,
    hourmeter: null,
    services: data.checkupType?.services ?? [],
    aditionalServices: (data.compls ?? []).map((compl: any) => ({
      id: compl.id,
      name: compl.name,
      price: compl.price ?? 0,
    })),
    parts: data.parts,
  };
};
