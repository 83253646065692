import { useState } from "react";
import Week from "./Week";
import Title from "./Title";
import SchedulingDetailDialog from "./dialogs/SchedulingDetailDialog";
import { Scheduling } from "../../../../../models/schedule";
import Day from "./Day";
import Month from "./Month";

interface IWeekCalendarProps {
  firstWeekDay: Date;
  lastWeekDay: Date;
  consultantMonthSchedule: Scheduling[];
  currentDate: Date;
  setCurrentDate: (date: Date) => void;
  handleScheduleSidebar: () => void;
  scheduleViewType: string;
  setScheduleViewType: (type: string) => void;
}

const Schedule = (props: IWeekCalendarProps) => {
  const [scheduling, setScheduling] = useState<Scheduling | undefined>();
  const handleScheduling = (schedulingData: Scheduling | undefined) =>
    setScheduling(schedulingData);

  return (
    <div>
      <Title
        {...props}
        scheduleViewType={props.scheduleViewType}
        setScheduleViewType={props.setScheduleViewType}
      />

      {props.scheduleViewType === "month" && (
        <Month
          {...props}
          setCurrentDate={props.setCurrentDate}
          setScheduleViewType={props.setScheduleViewType}
        />
      )}

      {props.scheduleViewType === "week" && (
        <Week
          {...props}
          handleScheduling={handleScheduling}
        />
      )}

      {props.scheduleViewType === "day" && (
        <Day
          {...props}
          handleScheduling={handleScheduling}
        />
      )}

      <SchedulingDetailDialog
        show={!!scheduling}
        onClose={() => setScheduling(undefined)}
        scheduling={scheduling}
      />
    </div>
  );
};

export default Schedule;
