import { StatusBar } from "@rio-cloud/rio-uikit";
import {
  MaintenanceMonitoringStepsEnum,
  MaintenanceMonitoringStatusEnum,
} from "../../../../../enums/maintenanceMonitoring";
import {
  MaintenanceMonitoringTimelineStep,
  Ticket,
} from "../../../../../models/ticket";
import { convertTimeToMinutes } from "../../../../../utils/dateUtils";

import "../index.css";

interface ITimelineProps {
  ticket: Ticket | undefined;
  setStep: (step: number) => void;
  currentStatusStep: number | undefined;
  doneTime: number;
  progressTime: number;
  totalTime: number;
  overTime: number;
  timeline: MaintenanceMonitoringTimelineStep[];
  startStep: (index: number) => void;
  finishStep: (index: number) => void;
}

const Timeline = ({
  ticket,
  setStep,
  currentStatusStep,
  doneTime,
  progressTime,
  totalTime,
  overTime,
  timeline,
  startStep,
  finishStep,
}: ITimelineProps) => {
  const getStepIcon = (step: MaintenanceMonitoringStepsEnum) => {
    switch (step) {
      case MaintenanceMonitoringStepsEnum.TICKET:
        return "rioglyph-warning-sign";
      case MaintenanceMonitoringStepsEnum.SCREENING:
        return "rioglyph-transfer";
      case MaintenanceMonitoringStepsEnum.REPAIR:
        return "rioglyph-workshop";
      case MaintenanceMonitoringStepsEnum.INSPECTION:
        return "rioglyph-maintenance-components";
      case MaintenanceMonitoringStepsEnum.RELEASE:
        return "rioglyph-area-out";
      default:
        return "";
    }
  };

  const getFormattedTime = (time: number): string => {
    return `${
      time < 60
        ? `${time} min`
        : `${Math.floor(time / 60)
            .toString()
            .padStart(2, "0")} h ${(time % 60).toString().padStart(2, "0")} min`
    }`;
  };

  const getStepEstimatedTime = (
    step: MaintenanceMonitoringTimelineStep
  ): number => {
    const getStringTime = () => {
      switch (step.step) {
        case MaintenanceMonitoringStepsEnum.SCREENING:
          return ticket?.screening?.estimatedTimeTroubleshooting || "00:00";
        case MaintenanceMonitoringStepsEnum.REPAIR:
          return ticket?.repair?.estimatedTime || "00:00";
        case MaintenanceMonitoringStepsEnum.INSPECTION:
          return ticket?.inspection?.estimatedTime || "00:00";
        case MaintenanceMonitoringStepsEnum.RELEASE:
          return ticket?.release?.estimatedTime || "00:00";
        default:
          return "00:00";
      }
    };

    return convertTimeToMinutes(getStringTime()) ?? step.estimatedTime;
  };

  const getStepTime = (step: MaintenanceMonitoringTimelineStep): number => {
    const stepTime =
      step.status === MaintenanceMonitoringStatusEnum.IN_PROGRESS
        ? progressTime
        : step.time;
    return stepTime ?? 0;
  };

  return (
    <div>
      <div className="display-grid grid-cols-5 margin-auto margin-top-20 width-100pct">
        {timeline.map((step: MaintenanceMonitoringTimelineStep, index) => (
          <div key={`step_${index}`} className="flex">
            <div
              className={`w-[15px] ${
                index !== 0 ? "border-b-2" : ""
              } height-45`}
            />
            <div
              className={`
                                        padding-15 border-width-1 rounded text-color-gray w-full
                                        ${
                                          step.status ===
                                          MaintenanceMonitoringStatusEnum.IN_PROGRESS
                                            ? "border-color-primary bg-white"
                                            : step.status ===
                                              MaintenanceMonitoringStatusEnum.DONE
                                            ? "border-color-success bg-white"
                                            : "border-color-gray bg-lightest"
                                        }
                                `}
            >
              <div
                className="flex items-center cursor-pointer"
                onClick={() => setStep(index)}
              >
                <div>
                  <i
                    className={`
                                                    rioglyph 
                                                    ${
                                                      step.status ===
                                                      MaintenanceMonitoringStatusEnum.DONE
                                                        ? "rioglyph-ok"
                                                        : getStepIcon(step.step)
                                                    } 
                                                    ${
                                                      step.status ===
                                                      MaintenanceMonitoringStatusEnum.IN_PROGRESS
                                                        ? "text-color-primary"
                                                        : step.status ===
                                                          MaintenanceMonitoringStatusEnum.DONE
                                                        ? "text-color-success"
                                                        : "text-color-gray"
                                                    }
                                                `}
                    style={{ fontSize: "25px" }}
                  />
                </div>
                <div className="flex flex-col margin-left-20 width-100pct">
                  <div className="border border-bottom-only flex justify-between padding-y-5">
                    <span
                      className={`
                                                        font-bold 
                                                        ${
                                                          step.status ===
                                                          MaintenanceMonitoringStatusEnum.IN_PROGRESS
                                                            ? "text-color-primary"
                                                            : step.status ===
                                                              MaintenanceMonitoringStatusEnum.DONE
                                                            ? "text-color-success"
                                                            : "text-color-gray"
                                                        }
                                                        `}
                    >
                      {step.step}
                    </span>
                    {getStepEstimatedTime(step) ? (
                      <span>
                        <i className="rioglyph rioglyph-time-alt"></i>{" "}
                        {Math.floor(getStepEstimatedTime(step) / 60)
                          .toString()
                          .padStart(2, "0")}
                        :
                        {(getStepEstimatedTime(step) % 60)
                          .toString()
                          .padStart(2, "0")}
                        h
                      </span>
                    ) : null}
                  </div>
                  <div
                    className={`
                                                    flex justify-between padding-y-5
                                                    ${
                                                      step.status ===
                                                      MaintenanceMonitoringStatusEnum.DONE
                                                        ? "text-color-success"
                                                        : ""
                                                    }
                                                `}
                  >
                    <span>
                      {step.date
                        ? `${step.date
                            .getDate()
                            .toString()
                            .padStart(2, "0")}/${(step.date.getMonth() + 1)
                            .toString()
                            .padStart(2, "0")}/${step.date.getFullYear()}`
                        : "--/--/----"}
                    </span>
                    <span
                      className={`${
                        getStepEstimatedTime(step) > 0 &&
                        getStepTime(step) > getStepEstimatedTime(step)
                          ? `text-color-danger text-bold`
                          : ""
                      }`}
                    >
                      <i className="rioglyph rioglyph-time-alt"></i>{" "}
                      {getStepTime(step) || getStepTime(step) === 0
                        ? `${Math.floor(getStepTime(step) / 60)
                            .toString()
                            .padStart(2, "0")}:${(getStepTime(step) % 60)
                            .toString()
                            .padStart(2, "0")}h`
                        : "--:--h"}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`
                                            margin-top-10 width-100pct rounded-circle border-width-1 text-center padding-2
                                            ${
                                              step.status ===
                                              MaintenanceMonitoringStatusEnum.IN_PROGRESS
                                                ? "bg-highlight-lightest border-color-highlight-light text-color-highlight-dark text-bold"
                                                : step.status ===
                                                  MaintenanceMonitoringStatusEnum.DONE
                                                ? "bg-emerald-50 border-emerald-300 text-color-success text-bold"
                                                : "bg-decent border-color-gray"
                                            }
                                            ${
                                              index === currentStatusStep
                                                ? "step-button"
                                                : ""
                                            }
                                        `}
                onMouseEnter={(e) => {
                  if (index !== currentStatusStep) return;

                  if (step.status === MaintenanceMonitoringStatusEnum.PENDING)
                    return (e.currentTarget.innerText = "Iniciar");

                  if (
                    step.status === MaintenanceMonitoringStatusEnum.IN_PROGRESS
                  )
                    return (e.currentTarget.innerText = "Finalizar");
                }}
                onMouseLeave={(e) => (e.currentTarget.innerText = step.status)}
                onClick={() => {
                  if (index !== currentStatusStep) return;

                  if (step.status === MaintenanceMonitoringStatusEnum.PENDING)
                    return startStep(index);

                  if (
                    step.status === MaintenanceMonitoringStatusEnum.IN_PROGRESS
                  )
                    return finishStep(index);
                }}
              >
                {step.status}
              </div>
            </div>
            <div
              className={`w-[15px] ${
                index !== timeline.length - 1 ? "border-b-2" : ""
              } height-45`}
            />
          </div>
        ))}
      </div>

      <div className="margin-top-20 margin-bottom-10">Tempo em manutenção</div>
      <StatusBar
        icon={{
          name: "rioglyph rioglyph-time",
          color: "text-color-status-working",
        }}
        label={{
          value: getFormattedTime(doneTime + progressTime),
          alignment: "left",
          color: "text-color-status-working",
        }}
        progress={[
          {
            percentage:
              ((doneTime + progressTime - overTime) / totalTime) * 100,
            color: "progress-bar-status-available",
            tooltip: (
              <span>
                {getFormattedTime(doneTime + progressTime - overTime)} (
                {Math.round(
                  ((doneTime + progressTime - overTime) / totalTime) * 100
                )}
                %)
              </span>
            ),
          },
          {
            percentage:
              ((totalTime - progressTime - doneTime < 0
                ? 0
                : totalTime - progressTime - doneTime) /
                totalTime) *
              100,
            color: "progress-bar-light",
            tooltip: (
              <span>
                {getFormattedTime(totalTime - progressTime - doneTime)} (
                {Math.round(
                  ((totalTime - progressTime - doneTime) / totalTime) * 100
                )}
                %)
              </span>
            ),
          },
          {
            percentage: ((overTime > 0 ? overTime : 0) / totalTime) * 100,
            color: "progress-bar-rating-1",
            tooltip: (
              <span>
                {getFormattedTime(overTime)} (
                {Math.round((overTime / totalTime) * 100)}%)
              </span>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Timeline;
