import ApiService from "../services/api";

export const sendAssessment = async (
  ticketId: string,
  body: any
): Promise<any[]> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.patch<any[]>(
      `/maintenance/ticket/${ticketId}/rating`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
