import { useEffect, useState } from "react";
import AppointmentForm, { IAssetAndDriverForm, IDateAndConsultantForm } from "./components/appointmentForm";
import AppointmentSummary from "./components/appointmentSummary";
import { AssetSummary } from "../../../models/asset";
import { Loading } from "../../../utils/loading";
import { dealershipMockData } from "./mock-data";
import { useSelector } from "react-redux";
import { getUserProfile } from "../../../configuration/login/loginSlice";
import { Dealership } from "../../../models/dealership";
import { fetchAssets } from "../../../api/fetchAssets";
import { fetchScheduleList } from "../../../api/fetchScheduleList";
import { useSearchParams } from "react-router-dom";
import { fetchScheduling } from "../../../api/fetchScheduling";
import { createAppointmentFormFromScheduling } from "../../../services/schedulingService";

export interface IAppointmentForm {
  assetsAndDrivers?: IAssetAndDriverForm;
  dealership?: Dealership;
  dateAndConsultant?: IDateAndConsultantForm;
  schedulingTypes?: string[];
}

const NewAppointment = () => {
	const userProfile = useSelector(getUserProfile);
	
	const [step, setStep] = useState(0);
	const [loading, setLoading] = useState(true);

	const [appointment, setAppointment] = useState<IAppointmentForm | undefined>({
		assetsAndDrivers: {
			driver: { id: "-", name: "-", license: "", phone: "-", email: "-" }
		},
		dealership: { id: "", name: "", address: "", geoLocation: { lat: 0, lng: 0 } },
		dateAndConsultant: {},
	  });

	const [assets, setAssets] = useState<AssetSummary[]>([]);

	const [searchParams] = useSearchParams();
	const [appointmentId, setAppointmentId] = useState<string | undefined>(undefined);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);

			const schedulings = await fetchScheduleList();
			const assets = await fetchAssets();
			
			setAssets(assets.filter(asset => !schedulings.find(scheduling => scheduling.vehicleSchedule.vehicle.assetId === asset.id)));

			const schedulingId = searchParams.get("scheduling-id");
			let appointmentToUpdate: any = undefined;
				  if (schedulingId) {
					appointmentToUpdate = await fetchScheduling(schedulingId);
					if(appointmentToUpdate) setAppointmentId(schedulingId);
				  }

			const dealership = dealershipMockData.find(d => d.consultants.includes(userProfile?.sub?.split(':')[1] || ''));
			setAppointment({
				...(appointmentToUpdate ? createAppointmentFormFromScheduling(appointmentToUpdate, []) : appointment),
				dealership: {
					id: dealership?.id || '',
					name: dealership?.name || '',
					address: dealership?.address || '',
					geoLocation: { lat: 0, lng: 0 }
				},
				dateAndConsultant: {
					consultant: {
						id: userProfile?.sub?.split(':')[1] || '',
						name: userProfile?.name || ''
					}
				}
			})

			setLoading(false);
		}

		fetchData()
	}, []);

	if (loading) return <Loading label="Carregando dados..." />;

	return step === 0 ?
		<AppointmentForm
			goToSummary={() => setStep(1)}
			form={appointment}
			setForm={setAppointment}
			assets={assets}
			appointmentId={appointmentId}
		/>
		:
		<AppointmentSummary
			goBackToForm={() => setStep(0)}
			appointment={appointment}
			appointmentId={appointmentId}
		/>
};

export default NewAppointment;
