import Page from "../Page";
import Appointmens from "./components/appointments";

import './styles.scss';

export const MyAppointmens = () => {
	return (
		<div className="mySchedule">
			<Page>
				<Appointmens />
			</Page>
		</div>
	)
}