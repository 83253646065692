import { useEffect, useState } from "react";
import AppLayout from "../../../../layout/AppLayout";
import Submenu from "./components/submenu";
import { Divider } from "@rio-cloud/rio-uikit";
import { fetchScheduling } from "../../../../api/fetchScheduling";
import { useSearchParams } from "react-router-dom";
import { Scheduling } from "../../../../models/schedule";
import { Loading } from "../../../../utils/loading";
import {
  MaintenanceMonitoringTimelineStep,
  Ticket,
} from "../../../../models/ticket";
import { fetchTicketBySchedulingId } from "../../../../api/ticket";
import { initialTimeline } from "../../../../dtos/ticketDto";
import { MaintenanceMonitoringStatusEnum } from "../../../../enums/maintenanceMonitoring";
import Rate from "./components/rate";

const Assessment = () => {
  const [searchParams] = useSearchParams();

  const [step, setStep] = useState<number>(0);
  const [currentStatusStep, setCurrentStatusStep] = useState<
    number | undefined
  >(undefined);

  const [scheduling, setScheduling] = useState<Scheduling | undefined>(
    undefined
  );
  const [ticket, setTicket] = useState<Ticket | undefined>(undefined);
  const [timeline, setTimeline] = useState<MaintenanceMonitoringTimelineStep[]>(
    []
  );

  const [doneTime, setDoneTime] = useState<number>(0);
  const [progressTime, setProgressTime] = useState<number>(0);
  const [totalTime, setTotalTime] = useState<number>(0);
  const [overTime, setOverTime] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      const schedulingId = searchParams.get("scheduling-id");
      if (!schedulingId) return setStep(0), setCurrentStatusStep(0);

      const scheduling = await fetchScheduling(schedulingId);
      if (!scheduling) return setStep(0), setCurrentStatusStep(0);
      setScheduling(scheduling);

      const ticket = await fetchTicketBySchedulingId(schedulingId);
      setTicket({
        ...ticket,
        request: { ...ticket?.request, schedulingId },
        timeline: ticket?.timeline ?? initialTimeline,
      });

      setTimeline(ticket?.timeline ?? initialTimeline);
      const currentStep = (ticket?.timeline ?? initialTimeline)?.findIndex(
        (step) => !step.time
      );
      setStep(currentStep < 0 ? 0 : currentStep);
      setCurrentStatusStep(currentStep < 0 ? 0 : currentStep);
    };

    fetchData();
  }, [searchParams]);

  useEffect(() => {
    const interval = setInterval(() => {
      const done = timeline
        .filter((step) => step.status === MaintenanceMonitoringStatusEnum.DONE)
        .reduce((acc, step) => acc + (step.time ?? 0), 0);
      setDoneTime(done);

      const progress = timeline
        .filter(
          (step) => step.status === MaintenanceMonitoringStatusEnum.IN_PROGRESS
        )
        .reduce(
          (acc, step) =>
            acc +
            Math.floor((new Date().getTime() - step?.date!.getTime()) / 60000),
          0
        );
      setProgressTime(progress);

      const estimatedTime = timeline.reduce(
        (acc, step) => acc + (step.estimatedTime ?? 0),
        0
      );
      setTotalTime(
        done + progress > estimatedTime ? done + progress : estimatedTime
      );
      setOverTime(
        done + progress - estimatedTime < 0
          ? 0
          : done + progress - estimatedTime
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [step, timeline]);

  return (
    <AppLayout>
      <>
        {currentStatusStep === undefined && (
          <Loading label="Carregando dados..." />
        )}

        {ticket && (
          <>
            <Submenu />
            <div className="container bg-white padding-20 width-100pct margin-top-20">
              <h3>Progresso da avaliação</h3>
              <Rate
                step={step}
                setStep={setStep}
                doneTime={doneTime}
                progressTime={progressTime}
                totalTime={totalTime}
                overTime={overTime}
                timeline={timeline}
                ticketId={ticket?.id}
              />

              <Divider className="margin-top-20" />
            </div>
          </>
        )}
      </>
    </AppLayout>
  );
};

export default Assessment;
