import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AppLayout from "../../../layout/AppLayout";
import AssetsAndDriversForm, {
  IAssetAndDriverForm,
} from "./components/assetsAndDriversForm";
import DealershipForm from "./components/dealershipForm";
import { Asset, AssetSummary } from "../../../models/asset";
import { Driver } from "../../../models/driver";
import { fetchDrivers } from "../../../api/fetchDrivers";
import { Loading } from "../../../utils/loading";
import { Dealership } from "../../../models/dealership";
import DateAndConsultantForm, {
  IDateAndConsultantForm,
} from "./components/dateAndConsultantForm";
import AppointmentSummary from "./components/appointmentSummary";

import { fetchScheduling } from "../../../api/fetchScheduling";
import { fetchAssets } from "../../../api/fetchAssets";
import { createAppointmentFormFromScheduling } from "../../../services/schedulingService";

import "./styles.scss";

export interface IAppointmentForm {
  assetsAndDrivers?: IAssetAndDriverForm;
  dealership?: Dealership;
  schedulingTypes?: string[];
  dateAndConsultant?: IDateAndConsultantForm;
}

export const NewAppointments = () => {
  const [loading, setLoading] = useState(true);

  const [step, setStep] = useState(1);
  const [appointmentForm, setAppointmentForm] = useState<IAppointmentForm>({
    assetsAndDrivers: {
      driver: {
        id: "",
        name: "",
        phone: "",
        email: "",
        license: "",
      },
    },
  });
  const [appointmentId, setAppointmentId] = useState<string | null>(null);

  const [assets, setAssets] = useState<AssetSummary[]>([]);
  const [drivers, setDrivers] = useState<Driver[]>([]);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const [assets, drivers] = await Promise.all([
        fetchAssets(),
        fetchDrivers(),
      ]);

      setAssets(assets);
      setDrivers(drivers);

      const schedulingId = searchParams.get("scheduling-id");
      if (schedulingId) {
        const appointment = await fetchScheduling(schedulingId);
        if (appointment) {
          setAppointmentForm(
            createAppointmentFormFromScheduling(appointment, drivers)
          );
          setStep(3);
          setAppointmentId(schedulingId);
        }
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div className="mySchedule">
      <AppLayout>
        <div className="min-h-screen w-full flex justify-center">
          {loading ? (
            <Loading label="Carregando dados..." />
          ) : (
            <div className="w-full h-full bg-white">
              <div className="stepsSchedule">
                {step === 1 && (
                  <AssetsAndDriversForm
                    setStep={setStep}
                    drivers={drivers}
                    form={appointmentForm?.assetsAndDrivers}
                    setAppointmentForm={(
                      assetsAndDriversForm: IAssetAndDriverForm
                    ) =>
                      setAppointmentForm({
                        ...appointmentForm,
                        assetsAndDrivers: assetsAndDriversForm,
                      })
                    }
                  />
                )}

                {step === 2 && (
                  <DealershipForm
                    asset={appointmentForm?.assetsAndDrivers?.asset}
                    setAsset={(asset: Asset) => {
                      setAppointmentForm({
                        ...appointmentForm,
                        assetsAndDrivers: {
                          ...appointmentForm?.assetsAndDrivers,
                          asset,
                        },
                      });
                    }}
                    dealership={appointmentForm?.dealership}
                    setDealership={(dealership: Dealership) =>
                      setAppointmentForm({ ...appointmentForm, dealership })
                    }
                    setStep={setStep}
                  />
                )}

                {step === 3 && (
                  <DateAndConsultantForm
                    dealership={appointmentForm?.dealership}
                    setStep={setStep}
                    form={appointmentForm?.dateAndConsultant}
                    isReschedule={!!appointmentId}
                    setAppointmentForm={(
                      dateAndConsultantForm: IDateAndConsultantForm
                    ) =>
                      setAppointmentForm({
                        ...appointmentForm,
                        dateAndConsultant: dateAndConsultantForm,
                      })
                    }
                  />
                )}

                {step === 4 && (
                  <AppointmentSummary
                    appointmentForm={appointmentForm}
                    setStep={setStep}
                    schedulingId={appointmentId}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </AppLayout>
    </div>
  );
};
