import { useEffect, useState } from "react";
import AppLayout from "../../../../layout/AppLayout";
import { fetchSchedulingByChassisList } from "../../../../api/fetchScheduleList";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../../utils/loading";
import Appointment from "./appointment";
import { SchedulingDto } from "../../../../dtos/schedulingDto";
import { MaintenanceMonitoringStepsEnum } from "../../../../enums/maintenanceMonitoring";
import { Scheduling } from "../../../../models/schedule";
import { fetchAssets } from "../../../../api/fetchAssets";
import { Ticket } from "../../../../models/ticket";
import { fetchTicketsByChassisList } from "../../../../api/ticket";

import "./styles.scss";

export const MonitoringListContent = (props: any) => {
  const navigate = useNavigate();
  const [scheduleList, setScheduleList] = useState<Scheduling[]>([]);
  const [loading, setLoading] = useState(true);
  const [sortedList, setSortedList] = useState<Scheduling[]>([]);
  const [selectedOption, setSelectedOption] = useState("1");
  const [appointment, setAppointment] = useState<Scheduling | null>(null);
  const [allTickets, setAllTickets] = useState<any[]>([]);

  const fetchAppointmens = async () => {
    setLoading(true);
    const assets = await fetchAssets();
    let appointments: Scheduling[] = [];

    const chunkSize = 5000;
    const chunks = [];
    for (let i = 0; i < props.assetIds.length; i += chunkSize) {
      const chunk = props.assetIds.slice(i, i + chunkSize);
      chunks.push(chunk);
    }

    const promisesLength = chunks.length / 10;
    let promises = [];
    for (let i = 0; i < promisesLength; i++) {
      promises.push([]);
      promises[i] = chunks
        .slice(i * 10, (i + 1) * 10)
        .map((chunk) =>
          fetchSchedulingByChassisList(
            assets
              .filter((asset) => chunk.includes(asset.id))
              .map((asset) => asset.chassis)
          )
        );
    }

    for (const promise of promises) {
      const responses = await Promise.all(promise);
      if (responses.length === 0) setLoading(false);
      responses.forEach((response, index) => {
        appointments = [...appointments, ...response];
        if (index === responses.length - 1) setLoading(false);
      });
    }

    const appointmentUnderMaintenance = appointments.filter(
      (appointment) => !!appointment.maintenanceStatus
    );
    setScheduleList(appointmentUnderMaintenance);

    const allTickets: Ticket[] = await fetchTicketsByChassisList({
      chassisList: appointmentUnderMaintenance.map(
        (appointment) => appointment.asset.chassis
      ),
    });
    setAllTickets(allTickets);
  };

  useEffect(() => {
    fetchAppointmens();
  }, [props.assetIds]);

  useEffect(() => {
    const sorted = [...scheduleList].sort((a, b) => {
      if (selectedOption === "1") {
        return (
          new Date(b.scheduleTime).getTime() -
          new Date(a.scheduleTime).getTime()
        );
      } else if (selectedOption === "2") {
        return (
          new Date(a.scheduleTime).getTime() -
          new Date(b.scheduleTime).getTime()
        );
      }
      return 0;
    });

    setSortedList(sorted);
  }, [scheduleList, selectedOption]);

  return (
    <div className="mySchedule">
      {loading ? (
        <Loading label="Carregando acompanhamentos..." />
      ) : appointment ? (
        <Appointment
          appointment={appointment}
          goBack={() => setAppointment(null)}
        />
      ) : (
        <div className="min-h-screen w-full flex flex-column">
          {scheduleList.length > 0 ? (
            scheduleList.map((schedule) => (
              <div
                key={schedule?.id}
                className="bg-white p-6 w-full margin-top-25 full-schedule"
              >
                <div className="flex padding-top-10 justify-between items-center mb-4">
                  <div className="flex items-center space-x-2 w-9/12">
                    <span className="text-size-18 font-semibold text-color-map-marker-asset flex items-center w-3/12 truncate">
                      <span className="rioglyph rioglyph-calendar text-color-map-marker-asset"></span>
                      {schedule?.scheduleTime.toLocaleDateString("pt-BR", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      })}
                    </span>
                    <div className="flex gap-6 schedule-tags w-9/12 media_leftRow">
                      <div className="flex items-center justify-center gap-5 schedule-tags__card">
                        <span className="rioglyph rioglyph-time-alt"></span>
                        <span>
                          {schedule?.scheduleTime.toLocaleTimeString("pt-BR", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </span>
                      </div>

                      <div className="flex items-center justify-center gap-5 schedule-tags__card truncate">
                        <span className="rioglyph rioglyph-workshop"></span>
                        <span>{schedule?.dealership?.name}</span>
                      </div>

                      <div className="flex items-center justify-center gap-5 schedule-tags__card truncate">
                        <span className="rioglyph rioglyph-pin"></span>
                        <span>{schedule?.dealership?.address}</span>
                      </div>
                    </div>
                  </div>
                  <div className="w-3/12 flex items-center gap-6 justify-end">
                    <span className="flex items-center justify-center rounded-full payment-info text-center">
                      Pagamento na concessionária
                    </span>
                  </div>
                </div>

                <hr className="border-gray-200 my-2" />

                <div className="flex items-start justify-between mb-4 padding-top-25">
                  <div className="flex space-x-2 w-9/12">
                    <div className="flex flex-col w-3/12">
                      <div className="flex items-center justify-center gap-6 payment-method">
                        <span className="text-size-16 font-bold text-color-map-marker-asset">
                          Valor a ser pago
                        </span>
                        <span className="text-size-14 font-bold flex items-center justify-around price-value">
                          {schedule?.totalPrice}
                        </span>
                      </div>

                      <div className="flex flex-col padding-top-25">
                        {schedule.comments ? (
                          <>
                            <span className="text-size-14 font-semibold text-color-highlight-darkest">
                              Importante!
                            </span>
                            <span className="text-size-14 font-normal text-color-highlight-darkest">
                              {schedule.comments}
                            </span>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex flex-col padding-right-10 media_leftRow">
                      <span className="text-size-14 font-semibold text-color-highlight-darkest">
                        Solicitante
                      </span>
                      <span className="text-size-14 font-normal text-color-highlight-darkest">
                        {schedule?.scheduledBy || schedule?.consultant}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-size-14 font-semibold text-color-highlight-darkest">
                        Status da Manutenção
                      </span>
                      <span className="text-size-14 font-normal text-color-highlight-darkest">
                        {schedule?.maintenanceStatus
                          ? MaintenanceMonitoringStepsEnum[
                              schedule?.maintenanceStatus as keyof typeof MaintenanceMonitoringStepsEnum
                            ]
                          : "Status desconhecido"}
                      </span>
                    </div>
                  </div>
                  <div className="w-3/12 flex items-center justify-end">
                    <div className="flex gap-6 items-center">
                      <img
                        src="/assets/images/vehicle-thumb.png"
                        alt="Veículo"
                        className="w-16 h-16 rounded-md"
                      />
                      <div className="flex flex-col">
                        <span className="text-size-14 font-semibold text-color-highlight-darkest">
                          {schedule.asset.name}
                        </span>
                        <span className="text-size-14 font-normal text-text-color-darker">
                          Chassi - {schedule.asset.chassis}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="display-flex gap-20 margin-top-25 margin-bottom-15">
                  <button
                    type="button"
                    className="btn btn-secondary width-250"
                    onClick={() =>
                      navigate(
                        `/fleet-manager/maintenance-monitoring/monitoring?scheduling-id=${schedule?.id}`
                      )
                    }
                  >
                    Acompanhar manutenção
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary width-250"
                    disabled={
                      allTickets.find(
                        (ticket) =>
                          ticket.maintenanceScheduleId === schedule?.id
                      )?.hasReview === true &&
                      allTickets.find(
                        (ticket) =>
                          ticket.maintenanceScheduleId === schedule?.id
                      )?.status !== "FINISHED"
                    }
                    onClick={() =>
                      navigate(
                        `/fleet-manager/maintenance-monitoring/assessment?scheduling-id=${schedule?.id}`
                      )
                    }
                  >
                    {allTickets.find(
                      (ticket) => ticket.maintenanceScheduleId === schedule?.id
                    )?.hasReview
                      ? "Avaliação Concluída"
                      : "Avaliar Manutenção"}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="flex justify-center padding-top-10">
              <span className="text-color-gray">
                Sem manutenções no momento
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const MonitoringList = () => {
  return (
    <AppLayout>
      <MonitoringListContent />
    </AppLayout>
  );
};

export default MonitoringList;
