import { Checkbox, Dialog, Divider } from "@rio-cloud/rio-uikit";
import { Asset } from "../../../../../models/asset";
import { ICheckupChecklist } from "../../../../../models/ticket";

interface IInspectionChecklistDialogProps {
    showDialog: boolean;
    onClose: () => void;
    services: ICheckupChecklist[];
    asset: Asset;
}
export const InspectionChecklistDialog = ({ showDialog, onClose, services, asset }: IInspectionChecklistDialogProps) => {
    return (
        <Dialog
            show={showDialog}
            title='Checklist'
            footer={(
                <button type="button" className="btn btn-primary" onClick={onClose}>
                    <span className="text-capitalize">Fechar</span>
                </button>
            )}
            body={(
                <div>
                    <h5 className="text-color-primary">Revisão de entrega</h5>
                    
                    <div className="flex items-start gap-4 border rounded padding-5 margin-y-15">
                        <img
                            src="/assets/images/vehicle-thumb.png"
                            alt="Vehicle"
                            className="w-15 h-15 rounded"
                        />

                        <div className="flex-1 padding-left-10">
                            <p className="text-color-black text-size-16 font-medium m-0">
                                {asset?.name || asset?.model}
                            </p>
                            <p className="text-color-status-resting text-size-14 m-0">
                                Chassi - {asset?.chassis}
                            </p>
                        </div>
                    </div>

                    {
                        services.map((service, serviceIndex) => (
                            <div key={serviceIndex}>
                                <div className="text-bold padding-bottom-10">{service.name}</div>
                                {
                                    service.items.map((check, checklistIndex) => (
                                        <div key={checklistIndex} className="padding-y-5">
                                            <Checkbox 
                                                checked={check.ok}
                                            >{check.name}</Checkbox>
                                        </div>
                                    ))
                                }
                                <Divider />
                            </div>
                        ))
                    }
                    <div className="text-color-primary">
                        Esta Concessionária garante que o veículo mencionado neste manual foi rigorosamente inspecionado para entrega ao cliente, de acordo com os procedimentos técnicos da MAN Latin America.
                    </div>
                </div>
            )}
            onClose={onClose}
            showCloseButton
            bsSize='md'
        />

    )
}