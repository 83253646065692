import { useEffect, useState } from "react";
import { RadioButton } from "@rio-cloud/rio-uikit";
import { Asset } from "../../../../../models/asset";
import { InspectionChecklistDialog } from "../dialogs/inspectionChecklistDialog";
import { TicketInspection, TicketRequest } from "../../../../../models/ticket";
import {
  downloadTicketImage,
  fetchTicketImages,
  ITicketFile,
} from "../../../../../api/ticket";
import { Scheduling } from "../../../../../models/schedule";
import moment from "moment";
import { formatFileSize } from "../../../../../utils/imageSizeUtils";

interface IInspectionProps {
  ticketId?: string | undefined;
  inspection?: TicketInspection | undefined;
  scheduling?: Scheduling | undefined;
  request?: TicketRequest | undefined;
}

const Inspection = ({
  ticketId,
  inspection,
  scheduling,
  request,
}: IInspectionProps) => {
  const [files, setFiles] = useState<ITicketFile[]>([]);
  const [, setState] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      if (inspection && ticketId) {
        const images = await fetchTicketImages({
          ticketId: ticketId,
          step: "INSPECTION",
        });
        setFiles(images);
      }
    };

    fetchData();
  }, [inspection]);

  const [checklistDialog, setChecklistDialog] = useState(false);

  return (
    <div>
      <div className="display-grid gap-20 grid-cols-4">
        <div className="form-group">
          <label htmlFor="checkinDate">Check-in</label>
          <input
            id="checkin"
            className="form-control"
            type="text"
            placeholder="--/--/----"
            disabled
            value={inspection?.checkIn?.toLocaleDateString("pt-BR")}
          />
        </div>
        <div className="form-group">
          <label htmlFor="checkinHour">Hora</label>
          <input
            id="hora-checkin"
            className="form-control"
            type="text"
            placeholder="--:--"
            disabled
            value={inspection?.checkIn?.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="checkoutDate">Check-out</label>
          <input
            id="checkout"
            className="form-control"
            type="text"
            placeholder="--/--/----"
            disabled
            value={inspection?.checkOut?.toLocaleDateString("pt-BR")}
          />
        </div>
        <div className="form-group">
          <label htmlFor="checkoutHour">Hora</label>
          <input
            id="hora-checkout"
            className="form-control"
            type="text"
            placeholder="--:--"
            disabled
            value={inspection?.checkOut?.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          />
        </div>
      </div>

      <div className="display-grid gap-20 grid-cols-4">
        <div className="display-grid gap-20 grid-cols-2">
          <div>
            <label>Validação do Serviço está OK?</label>
            <div>
              <RadioButton
                name="isServiceValid"
                inline
                checked={inspection?.serviceValidation}
                onChange={() => (inspection!.serviceValidation = true)}
                disabled
              >
                Sim
              </RadioButton>
              <RadioButton
                name="isServiceValid"
                inline
                checked={!inspection?.serviceValidation}
                onChange={() => (inspection!.serviceValidation = false)}
                disabled
              >
                Não
              </RadioButton>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-secondary margin-top-25"
            onClick={() => setChecklistDialog(true)}
          >
            <span
              className="rioglyph rioglyph-time-alt"
              aria-hidden="true"
            ></span>
            <span className="text-bold">Checklist</span>
          </button>
        </div>

        <div className="form-group">
          <label htmlFor="fanceInHour">Tempo Estimado</label>
          <div className="input-group">
            <span className="input-group-addon">
              <span className="rioglyph rioglyph-time"></span>
            </span>
            <input
              id="fanceInHour"
              className="form-control"
              type="text"
              value={inspection?.estimatedTime}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, "").slice(0, 4);
                const formattedValue = value.replace(/(\d{2})(\d{2})/, "$1:$2");
                inspection!.estimatedTime = formattedValue;
                setState((prevState) => prevState + 1);
              }}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="form-group ">
        <label htmlFor="textArea">Relato</label>
        <textarea
          className="form-control"
          rows={3}
          id="textArea"
          placeholder="Descritivo"
          value={inspection?.report}
          onChange={(e) => (inspection!.report = e.target.value)}
          disabled
        />
      </div>

      <div>
        <label>Inspeção Visual</label>
        <div>
          <div className="display-grid grid-cols-1 grid-cols-3-sm gap-25">
            {files.length > 0 ? (
              files.map((file, index) => (
                <FilePreview key={index} file={file} ticketId={ticketId} />
              ))
            ) : (
              <div className="text-color-dark text-italic">Nenhum arquivo</div>
            )}
          </div>
        </div>
      </div>

      <InspectionChecklistDialog
        showDialog={checklistDialog}
        onClose={() => setChecklistDialog(false)}
        services={request?.checkupChecklist ?? []}
        asset={scheduling?.asset ?? ({} as Asset)}
      />
    </div>
  );
};

const FilePreview = ({
  file,
  ticketId,
}: {
  file: ITicketFile | undefined;
  ticketId: string | undefined;
}) => (
  <div
    className="panel panel-default margin-top-20 cursor-pointer"
    onClick={async () => {
      if (ticketId && file) {
        const response = await downloadTicketImage({
          ticketId: ticketId,
          step: "INSPECTION",
          filename: file.name,
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.name);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      }
    }}
  >
    <div className="panel-body">
      {file && (
        <div className="flex">
          {file.link && (
            <div className="thumbnail margin-right-10">
              <img
                src={file.link}
                alt={file.name}
                style={{
                  width: "200px",
                  height: "120px",
                  objectFit: "cover",
                }}
              />
            </div>
          )}
          <div className="flex justify-between w-full">
            <div>
              <div>
                <b>{`${file?.name}`}</b>
              </div>
              <div>tipo: {`${file?.contentType}`}</div>
              <div>tamanho: {formatFileSize(file?.contentSize)}</div>
              <div>
                data da última modificação:{" "}
                {file?.updatedAt
                  ? moment(file.updatedAt).format("DD/MM/YYYY")
                  : "Data não disponível"}
              </div>{" "}
              <div>caminho: {`${file?.name}`}</div>
            </div>
          </div>
        </div>
      )}
      {!file && (
        <div className="text-color-dark text-italic">Nenhum arquivo</div>
      )}
    </div>
  </div>
);

export default Inspection;
