// export * from "./fleetManager/preventiveMaintenance";

import { useEffect } from "react";
import { Spinner } from "@rio-cloud/rio-uikit";
import { fetchUserRole } from "../api/fetchUser";
import { UserRoleEnum } from "../enums/role";

export const Main = () => {
    useEffect(() => {
        const fetchData = async () => {
            const role = await fetchUserRole();
            if(role === UserRoleEnum.CONSULTANT) {
                window.location.href = "/dealership";
                return;
            }

            if(role === UserRoleEnum.MANAGER) {
                window.location.href = "/fleet-manager";
                return;
            }

            if(role === UserRoleEnum.TOWER) {
                window.location.href = "/control-tower";
                return;
            }
        }

        fetchData();
    }, []);

    return <Spinner show text='Carregando...' isFullScreen />
}
