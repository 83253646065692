import Page from "../../Page";
import Submenu from "./components/submenu";

const Report = () => {
    return (
        <Page>
            <>
                <Submenu />
                <h3>Reportar</h3>
            </>
        </Page>
    );
}

export default Report;