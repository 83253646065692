import { useNavigate } from "react-router-dom";

import { Asset } from "../../../../models/asset"

import CheckupInfo from "./checkupInfo";

interface IToScheduleProps {
    asset: Asset;
    hasMetrics?: boolean;
}
const NextScheduling = ({ asset, hasMetrics }: IToScheduleProps) => {
    const navigate = useNavigate();

    const availableToSchedule = !asset.nextCheckup?.date && asset?.nextCheckup?.checkup?.name && !asset.nextCheckup.maintenanceStatus;
    const scheduled = asset.nextCheckup?.date && !asset.nextCheckup.maintenanceStatus;
    const inMaintenance = asset.nextCheckup?.maintenanceStatus && asset.nextCheckup?.maintenanceStatus !== 'FINISHED';

    return (
        <>
            {scheduled || inMaintenance || (hasMetrics && availableToSchedule) ?
                <div className="flex items-center justify-between padding-bottom-10">
                    <span className="truncate">
                        {asset?.nextCheckup?.checkup?.name || ''}
                    </span>
                    {asset?.nextCheckup ? <CheckupInfo scheduling={asset?.nextCheckup} /> : null}
                </div> : null
            }
            
            {hasMetrics && availableToSchedule &&  asset.chassis !== '-' ?
                <span
                    className="text-color-success cursor-pointer"
                    onClick={() => navigate(`/control-tower/new-appointment?asset=${asset.id}`)}
                >
                    Disponível para agendar
                </span> : null
            }
            
            {scheduled ?
                <span>
                    Agendada para {asset.nextCheckup?.date?.toLocaleDateString("pt-BR")}
                </span> : null
            }
            
            {inMaintenance ? <span>Em manutenção</span> : null}
        </>
    )
}

export default NextScheduling;