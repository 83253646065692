import { useEffect, useMemo, useState } from "react";
import Page from "../Page";
import { LoadMoreButton } from "@rio-cloud/rio-uikit";

import { Asset, UptimeAsset } from "../../../models/asset";

import { fetchMaintenance } from "../../../api/fetchMaintenance";

import { BigNumbers } from "./components/bigNumbers";
import MaintenanceTable from "./components/maintenanceTable";
import SkeletonTable from "./components/skeletonTable";
import EmptyTable from "./components/emptyTable";
import { fetchAssets, fetchUptimeAssets } from "../../../api/fetchAssets";
import { debounce } from "lodash";

const MaintenanceControlContent = (props: any) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false);

    const [assets, setAssets] = useState<Asset[]>([]);
    const [uptimeAssets, setUptimeAssets] = useState<UptimeAsset[]>([]);

    const [page, setPage] = useState<number>(0);
    const [assetIdsPaged, setAssetIdsPaged] = useState<string[][]>([]);

    const fetchAssetsData = async (pagedIds: string[][]) => {
        setLoading(true);
        const [assets,] = await fetchMaintenance({
            ids: pagedIds[page],
            // groupIds: props.groupIds,
            limit: 500,
        });
        setAssets(assets);
        setAssetIdsPaged(pagedIds);
        setLoading(false);

        // Get uptime assets
        const newUptimeAssets = await fetchUptimeAssets(assets.map(asset => asset.chassis));
        setUptimeAssets([...uptimeAssets, ...newUptimeAssets]);
    };

    useEffect(() => {

        if (props.assetIds.length > 0) {
            const chunkSize = 50;
            const pagedIds = [];
            for (let i = 0; i < props.assetIds.length; i += chunkSize) {
                pagedIds.push(props.assetIds.slice(i, i + chunkSize));
            }
            fetchAssetsData(pagedIds);
        } else {
            setAssets([]);
            setPage(0);
            setAssetIdsPaged([]);
        }

    }, [props.assetIds, props.groupIds]);

    const fetchTableData = async () => {
        setLoadMoreLoading(true);
        const [assetsFetched,] = await fetchMaintenance({
            ids: assetIdsPaged[page + 1],
            // groupIds: props.groupIds,
            limit: 500,
            lastAssetId: assets?.[assets.length - 1]?.id
        });
        setPage(page + 1);
        setAssets([...assets, ...assetsFetched]);
        setLoadMoreLoading(false);

        // Get uptime assets
        const newUptimeAssets = await fetchUptimeAssets(assetsFetched.map(asset => asset.chassis));
        setUptimeAssets([...uptimeAssets, ...newUptimeAssets]);
    };

    const [previousAssetIds, setPreviousAssetIds] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const handleSearchValueChange = useMemo(
        () => debounce(async (chassis: string) => {
            setLoading(true);

            if (chassis === '') {
                props.setAssetIds(previousAssetIds);
                setPreviousAssetIds([]);
            } else {
                if (previousAssetIds.length) setPreviousAssetIds(previousAssetIds);
                else setPreviousAssetIds(props.assetIds);

                const allAssets = await fetchAssets();
                const filterdAssets = allAssets.filter(asset => asset.chassis.toLowerCase().includes(chassis.trim().toLocaleLowerCase()));

                const chunkSize = 50;
                const pagedIds = [];
                for (let i = 0; i < filterdAssets.length; i += chunkSize) {
                    pagedIds.push(filterdAssets.slice(i, i + chunkSize).map(asset => asset.id));
                }
                fetchAssetsData(pagedIds);
            }
        }, 500), [props.assetIds],
    );

    return (
        <>
            <BigNumbers
                assetIds={props.assetIds}
                setAssetIds={(e: string[]) => [setPage(0), props.setAssetIds(e)]}
            />
            <div style={{ marginTop: 30 }}>
                {
                    loading ?
                        <SkeletonTable searchValue={searchValue} /> :
                        <>
                            <MaintenanceTable
                                assets={assets}
                                handleSearchValueChange={handleSearchValueChange}
                                setSearchValue={setSearchValue}
                                searchValue={searchValue}
                                uptimeAssets={uptimeAssets}
                            />
                            {
                                (assets.length > 0) && (loadMoreLoading ?
                                    <SkeletonTable onlyRows /> :
                                    <LoadMoreButton loaded={assets.length} total={props.assetIds.length} onLoadMore={fetchTableData} loadMoreMessage='Carregar mais' noMoreMessage='Tudo carregado' />)
                            }

                            {assets.length === 0 && <EmptyTable />}
                        </>
                }
            </div>
        </>
    );
};

const MaintenanceControl = () => {
    return (
        <Page>
            <MaintenanceControlContent />
        </Page>
    );
}

export default MaintenanceControl;