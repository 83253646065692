import { SchedulingDto } from "../dtos/schedulingDto";
import { Scheduling } from "../models/schedule";
import ApiService from "../services/api";

export const fetchConsultantSchedule = async (
  month: number,
  year: number
): Promise<Scheduling[]> => {
  const apiService = new ApiService();
  const query = `month=${month}&year=${year}`;
  try {
    const response = await apiService.get<any[]>(
      `/schedules/checkups/consultant?${query}`
    );
    if (response.status === 200) {
      return response.data.map(SchedulingDto);
    } else return [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchAllConsultantSchedule = async (): Promise<Scheduling[]> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.get<any[]>(
      `/schedules/checkups/schedules-by-consultant?limit=50`
    );
    if (response.status === 200) {
      return response.data.map(SchedulingDto);
    } else return [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchNewConsultantSchedule = async (
  day: number,
  month: number,
  year: number
): Promise<Scheduling[]> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.get<any[]>(
      `schedules/checkups/consultant?month=${month}&year=${year}`
    );
    if (response.status === 200) {
      return response.data.map(SchedulingDto);
    } else return [];
  } catch (error) {
    console.error(error);
    return [];
  }
};
